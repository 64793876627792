<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :canDuplicate="true" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <Template :section="section">
      <template slot="icon">
       <highliter v-show="visability('icon')" @click.stop.native="sectionSettings('icon')">
         <icon-editable :customs="section.options.content.icon"></icon-editable>
       </highliter>
     </template>

      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
       <highliter v-show="visability('description')" @click.stop.native="sectionSettings('description')">
         <content-editable
             :uid="tiptapUID('description')"
             :customs="section.options.content.description"
             v-model="section.options.content.description.text"></content-editable>
       </highliter>
     </template>

      <template slot="button">
        <highliter v-show="visability('button')"
                   :style="{textAlign: section.options.content.button.align}"
                   @click.stop.native="sectionSettings('button')">
          <es-button :customs="section.options.content.button"></es-button>
        </highliter>
      </template>
   </Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Template from './Template';

  export default {
    name: "ActionItem2",

    components: {Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
