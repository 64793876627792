<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <TestimonialItem3GroupTemplate :section="section">
      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter v-show="visability('description')"
                   @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>

      <b-row class="es-row">
        <b-col :cols="cols(4, 12)" v-for="section in section.options.content.group.items" :key="section.uid">
          <testimonial-item3 :section="section"></testimonial-item3>
        </b-col>
      </b-row>
   </TestimonialItem3GroupTemplate>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import TestimonialItem3GroupTemplate from '@pub/templates/default/sections/testimonials/TestimonialItem3GroupTemplate';

export default {
  name: "FeaturedItems1",

  components: {TestimonialItem3GroupTemplate, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
