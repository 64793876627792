<template>
  <NewsLetterItem4Template v-if="section.options.content.form.action === 'Ontraport' || section.options.content.form.selectedList" :section="section">
    <template slot="image" v-if="visability('image')">
      <image-fit
          :customs="section.options.content.image"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="section.options.content.image.size"
          :position="section.options.content.image.position"
          :height="cols('55vh', '300px')"
      ></image-fit>
    </template>

    <template slot="title" v-if="visability('title')">
      <pub-content-editable
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="subtitle" v-if="visability('subtitle')">
      <pub-content-editable
          :customs="section.options.content.subtitle"
          v-model="section.options.content.subtitle.text"></pub-content-editable>
    </template>

    <template slot="form">
      <es-form :customs="section.options.content.form" size="lg">
        <template :slot="section.options.content.form.btnPosition || 'submit'">
          <pub-es-button type="submit" :customs="section.options.content.button"></pub-es-button>
        </template>
      </es-form>
    </template>
  </NewsLetterItem4Template>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import NewsLetterItem4Template from '@pub/templates/default/sections/newsLetter/NewsLetterItem4Template';
  import EsForm from '../../../builder/utils/EsForm';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';
  import PubEsButton from "../../../builder/utils/buttons/PubEsButton";
  import ImageFit from "../../../utils/ImageFit";

  export default {
    name: "NewsLetterItem1",

    components: {ImageFit, PubEsButton, PubContentEditable, EsForm, NewsLetterItem4Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
