<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section"
             @click.stop.native="sectionSettings(true)">
    <Template :section="section">
      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="info">
        <highliter @click.stop.native="sectionSettings('info')">
          <content-editable
              :uid="tiptapUID('info')"
              :customs="section.options.content.info"
              v-model="section.options.content.info.text"></content-editable>
        </highliter>
      </template>

      <template slot="labelSocialLinks">
        <highliter @click.stop.native="sectionSettings('labelSocialLinks')">
          <content-editable
              :uid="tiptapUID('labelSocialLinks')"
              :customs="section.options.content.labelSocialLinks"
              v-model="section.options.content.labelSocialLinks.text"></content-editable>
        </highliter>
      </template>

      <template slot="labelContacts">
        <highliter @click.stop.native="sectionSettings('labelSocialLinks')">
          <content-editable
              :uid="tiptapUID('labelSocialLinks')"
              :customs="section.options.content.labelSocialLinks"
              v-model="section.options.content.labelSocialLinks.text"></content-editable>
        </highliter>
      </template>

      <!-- Social -->
      <template slot="social">
        <highliter @click.stop.native="sectionSettings('socialLinks')">
          <ul class="list-unstyled list-inline">
            <li v-for="(link, index) in section.options.content.socialLinks.links" :key="index"
                class="list-inline-item mr-3">
              <b-link :class="'social-link social-link-' + link.shape" :style="{...section.options.content.socialLinks.style, ...{color: link.color, backgroundColor: link.background}}">
                <i :class="link.icon"></i>
              </b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Social -->

      <!-- Form -->
      <template slot="form">
        <highliter @click.stop.native="sectionSettings('form')">
          <es-form :customs="section.options.content.form">
            <template :slot="cols(section.options.content.form.btnPosition, 'submit')">
              <highliter @click.stop.native="sectionSettings('button')">
                <es-button :customs="section.options.content.button"></es-button>
              </highliter>
            </template>
          </es-form>
        </highliter>
      </template>
      <!-- End Form -->
    </Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from './Template';

export default {
  name: "ActionItem2",

  components: {Template, ContentEditable, Highliter},

  mixins: [SectionMixin],
}
</script>
