<template>
  <div :class="customs.shadow">
    <b-embed v-if="!customs.placeholder || showVideo"
             ref="iframe"
             type="iframe"
             aspect="16by9"
             :src="srcEmbed"
             allowfullscreen
    ></b-embed>

    <div v-if="customs.placeholder" v-show="!showVideo" class="embed-responsive embed-responsive-16by9">
      <div class="embed-responsive-item">
        <div class="play-btn" :style="{backgroundColor: customs.playIcon.backgroundColor}" @click="customs.src ? showVideo = true : null">
          <i :style="{color: customs.playIcon.color}" :class="customs.playIcon.icon"></i>
        </div>
        <image-fit
            :customs="customs"
            :src="customs.placeholder"
            :size="'cover'"
            inner-container-class="rounded"
            position="center"
            width="100%"
            height="100%"
        ></image-fit>
      </div>
    </div>
  </div>
</template>

<script>
import ComponentProvider from '@/components/mixins/ComponentProvider';
import ImageFit from "../../utils/ImageFit";
import getEmbedUrl from 'better-video-embeds';

export default {
  name: "VideoPlay",

  components: {ImageFit},

  mixins: [ComponentProvider],

  data() {
    return {
      isShowVideo: true,
      sizePreview: {},
      isMounted: false
    }
  },

  computed: {
    srcEmbed() {
      return getEmbedUrl(this.customs.src) + String(!this.customs.disablePreview && this.customs.placeholder ? '?autoplay=1' : '')
    },
    showVideo: {
      get() {
        return this.customs.disablePreview ? true : this.isShowVideo
      },
      set(val) {
        this.isShowVideo = val
      }
    }
  },

  created() {
    if (!this.customs.playIcon) {
      this.$set(this.customs, 'playIcon', {
        icon: 'fa fa-play',
        color: '#000',
        backgroundColor: '#fff'
      })
    }
  },

  watch: {
    customs: {
      deep: true,
      handler: function (val) {
        if (!val.disablePreview && this.showVideo) {
          this.showVideo = false
        }
      }
    }
  },

  mounted() {
    const rect = this.$refs.iframe.getBoundingClientRect()

    this.sizePreview = {
      width: rect.width + 'px',
      height: rect.height + 'px'
    }

    if (!this.customs.disablePreview) {
      this.showVideo = false
    }

    setTimeout(() => {
      this.isMounted = true
    }, 2000)
  }
}
</script>

<style lang="scss">
.play-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 80px;
  font-size: 19px;
  height: 80px;
  transition: .2s;

  &:hover {
    cursor: pointer;
    width: 90px;
    height: 90px;
  }
}
</style>