<template>
  <highliter :label="section.name" :dublicateDeep="parentUID" :dublicate="section" :uid="section.uid" @click.stop.native="sectionSettings(true)">
   <TestimonialItem1Template :section="section">
    <template slot="avatar">
      <highliter v-show="visability('image')" @click.stop.native="sectionSettings('image')">
        <es-image :customs="section.options.content.image"></es-image>
      </highliter>
    </template>

    <template slot="name">
      <highliter v-show="visability('name')" @click.stop.native="sectionSettings('name')">
        <content-editable
            :customs="section.options.content.name"
            @init="(editor) => section.classes.tiptap.name = editor"
            v-model="section.options.content.name.text"></content-editable>
      </highliter>
    </template>


    <template slot="subname">
      <highliter v-show="visability('subname')" @click.stop.native="sectionSettings('subname')">
        <content-editable
            :customs="section.options.content.subname"
            @init="(editor) => section.classes.tiptap.subname = editor"
            v-model="section.options.content.subname.text"></content-editable>
      </highliter>
    </template>

    <template slot="text">
      <highliter v-show="visability('text')" @click.stop.native="sectionSettings('text')">
        <content-editable
            :customs="section.options.content.text"
            @init="(editor) => section.classes.tiptap.text = editor"
            v-model="section.options.content.text.text"></content-editable>
      </highliter>
    </template>
 </TestimonialItem1Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import TestimonialItem1Template from '@pub/templates/default/sections/testimonials/TestimonialItem1Template';

  export default {
    name: "FeaturedItems1",

    components: {TestimonialItem1Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
