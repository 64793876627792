<template>
  <b-row>
    <b-col md="6">
      <b-form @submit.prevent="integrate">
        <b-form-group label="Enter access key" class="mb-1">
          <b-input v-model="key" required></b-input>
        </b-form-group>
      </b-form>
    </b-col>

    <b-col cols="12">
      <p>
        <b-link target="_blank" href="https://unsplash.com/oauth/applications">Create a new application</b-link> to get access key in Unsplash.
      </p>

      <b-btn type="submit" variant="primary">Integrate</b-btn>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "UnsplashIntegration",

  data() {
    return {
      key: ''
    }
  },

  methods: {
    integrate() {
      this.$store.dispatch('apiIntegration/integrate', {
        service: 'unsplash',
        accessKey: this.key
      })
          .then(() => {
            this.$emit('integrated')
          })
    }
  }
}
</script>