<template>
  <ImageItem3Template :section="section">
    <image-fit
        :customs="section.options.content.image"
        :src="section.options.content.image.src || section.options.content.image.placeholder"
        :size="cols(section.options.content.image.size, 'cover')"
        :position="section.options.content.image.position"
        height="308px"
        width="290px"
    ></image-fit>
  </ImageItem3Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from "../../../builder/utils/PubContentEditable";
import ImageItem3Template from '@pub/templates/default/sections/images/ImageItem3Template';
import ImageFit from "../../../utils/ImageFit";

export default {
  name: "ImageItem3",

  components: {ImageFit, ImageItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
