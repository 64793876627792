import SectionProvider from '@/sections/SectionProvider';

export const name = 'Resource Item 2'
export const group = ''
export const defaultOptions = {
  content: {
    icon: {
      type: 'icon-mod',
      display: true,
      icon: 'fa fa-gem',
      size: 'lg',
      color: '#0C226C',
      align: 'center',
    },
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '24px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '24px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Resource</p>"
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          lineHeight: 1.3,
          color: '#6D6D6D',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          lineHeight: 1.3,
          color: '#6D6D6D',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam</p>"
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Learn More',
      resolutionStyle: {
        lg: {
          color: '#0C226C',
          backgroundColor: '#0C226C',
          size: 'md',
          shape: null,
          btnStyle: 'outlined',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#0C226C',
          backgroundColor: '#0C226C',
          size: 'md',
          shape: null,
          btnStyle: 'outlined',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {
    cols: {
      sm: 12,
      md: 4,
      lg: 4
    },
    style: {
      background: {
        background: '#ffffff'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 20,
          bottom: 20
        },
        lg: {
          top: 50,
          bottom: 50
        }
      },
      container: {
        type: 'container-customs',
        container: 'container'
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: null
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('ResourceItem2', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
