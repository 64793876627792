<template>
  <highliter class="feature-section" @click.stop.native="sectionSettings(true)">
    <FeaturedIImageTemplate :section="section">
      <template slot="image">
        <highliter @click.stop.native="sectionSettings('image')">
          <div style="width: 230px;">
            <image-fit
                :customs="section.options.content.image"
                :src="section.options.content.image.src || section.options.content.image.placeholder"
                :size="cols(section.options.content.image.size, 'cover')"
                :position="section.options.content.image.position"
                height="208px"
            ></image-fit>
          </div>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>
   </FeaturedIImageTemplate>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import FeaturedIImageTemplate from '@pub/templates/default/sections/featured/FeaturedIImageTemplate';
  import ImageFit from "../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {ImageFit, FeaturedIImageTemplate, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
