import SectionProvider from '@/sections/SectionProvider';
import TestimonialItem1 from "./TestimonialItem1";
import EsRow from "../utils/EsRow";

export const name = 'TestimonialItem1Group'

export default class TestimonialItem1Group extends SectionProvider {
  constructor(options, items) {
    super('EsRow', options);

    this.name = name
    this.group = 'testimonials'

    this.defaultOptions = new EsRow({
      customize: {
        layouts: false,
        lol: true,
        style: {
          padding: {
            type: 'height-customs',
            sm: {
              top: 35,
              bottom: 35
            },
            lg: {
              top: 115,
              bottom: 115
            }
          }
        }
      }
    }, [
      new TestimonialItem1({
        customize: {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          }
        }
      }).json(),
      new TestimonialItem1({
        customize: {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          }
        }
      }).json(),
      new TestimonialItem1({
        customize: {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          }
        }
      }).json(),
    ]).defaultOptions
  }
}
