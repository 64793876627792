<template>
  <b-navbar class="editor-header position-fixed">
    <b-navbar-brand class="d-flex align-items-center cursor-pinter" @click="$router.push({name: 'editor'})">
      <icon icon="logo-e.svg"></icon>
      <div class="h4 mb-0 ml-4">{{title}}</div>
    </b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="align-items-center ml-auto">
        <b-nav-item>
          <b-btn variant="dark" @click="$router.push({name: 'editor'})">
            Back
          </b-btn>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "ModuleHeader",

  props: {
    title: {
      type: String
    }
  }
}
</script>
