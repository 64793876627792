<template>
  <div class="custom-menu">
    <div class="d-flex align-items-center mb-4">
      <span class="indicator mr-2"></span>
      <p class="text-capitalize mb-0">{{labelCase(componentName)}}</p>

      <b-checkbox switch v-model="config.display" class="ml-auto"></b-checkbox>
    </div>

    <image-upload :image="config" class="w-100 mb-5"></image-upload>

    <animations :animations="animations"></animations>

    <div class="mb-5"></div>

    <Panel :config="config"></Panel>
  </div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import SliederToggle from '@/components/editor/controls/SliederToggle';
  import Panel from '../controls/Panel';
  import ImageUpload from "../utils/ImageUpload";
  import Animations from "../controls/Animations";

  export default {
    name: "ImageMod",
    components: {Animations, ImageUpload, Panel, SliederToggle},
    mixins: [ModMixin],

    data() {
      return {
        file: [],
        url: ''
      }
    },

    watch: {
      file() {
        // this.url = URL.createObjectURL(this.file)
        // this.config.src= this.url

        let formData = new FormData();

        formData.append("file", this.file);

        axios.post('api/media', formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
        .then(({data}) => {
          this.config.src = data.data.url
          this.config.id = data.data.id
        })
      }
    },

    computed: {
      animations: {
        get() {
          if (!this.config.hasOwnProperty('animations')) {
            this.$set(this.config, 'animations', {
              animation: null,
              once: true,
              offset: 200,
              delay: 50
            })
          }

          return this.config.animations
        },
        set(val) {
          this.config.animations = val
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .btn-es-image-upload {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #E2E5EC;
    font-size: 14px;
    font-weight: 600;
    color: #6E747A;
    height: 38px;
    box-shadow: 0 1px 2px 0 rgba(226,226,226,0.5)
  }
</style>
