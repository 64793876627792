<template>
  <NewsLetterItem3Template v-if="section.options.content.form.action === 'Ontraport' || section.options.content.form.selectedList" :section="section">
    <template slot="title" v-if="visability('title')">
        <pub-content-editable
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="subtitle" v-if="visability('subtitle')">
        <pub-content-editable
            :customs="section.options.content.subtitle"
            v-model="section.options.content.subtitle.text"></pub-content-editable>
    </template>

    <template slot="form">
        <es-form :customs="section.options.content.form" size="lg">
          <template :slot="cols(section.options.content.form.btnPosition, 'submit')">
              <pub-es-button type="submit" :customs="section.options.content.button"></pub-es-button>
          </template>
        </es-form>
    </template>
</NewsLetterItem3Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import NewsLetterItem3Template from '@pub/templates/default/sections/newsLetter/NewsLetterItem3Template';
import EsForm from '../../../builder/utils/EsForm';
import PubEsButton from '../../../builder/utils/buttons/PubEsButton';
import PubContentEditable from '../../../builder/utils/PubContentEditable';
import {mapGetters} from "vuex";

export default {
  name: "NewsLetterItem1",

  components: {PubContentEditable, PubEsButton, EsForm, NewsLetterItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
