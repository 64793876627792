<template>
  <div class="contact-form-1" :class="[...container]"
       :style="{...padding, ...opacity}">
    <div class="contact-card border rounded mb-4" :class="[cols('px-5', 'px-2'), theme]" :style="{...padding, ...backgroundColor, ...shadow, ...backgroundImage}">
      <slot name="title"></slot>
      <div :class="cols('my-5', 'my-4')"></div>
      <div class="form-rounded">
        <slot name="form"></slot>
      </div>
    </div>

    <b-row>
      <b-col cols="1"></b-col>

      <template v-show="visability('description')">
        <b-col :cols="cols(3, 12)">
          <slot name="description"></slot>
        </b-col>

        <b-col :cols="cols(1)">
          <div :class="cols('', 'horizontal')" class="vertical-devider"></div>
        </b-col>
      </template>

      <template v-show="visability('info')">
        <b-col :cols="cols(3, 12)">
          <slot name="info"></slot>
        </b-col>

        <b-col :cols="cols(1)">
          <div :class="cols('', 'horizontal')" class="vertical-devider"></div>
        </b-col>
      </template>

      <b-col :cols="cols(3, 12)">
        <div class="content-editable-margin-off" v-show="visability('labelSocialLinks')">
          <slot name="labelSocialLinks"></slot>
        </div>

        <template v-show="visability('socialLinks')">
          <slot name="social"></slot>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";
import ThemeMixin from "../../../../mixins/ThemeMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin, ThemeMixin]
}
</script>

<style lang="scss">
.contact-form-1 {
  .vertical-devider {
    position: absolute;
    left: 50%;
    top: 0;
    height: 66px;
    width: 1px;
    background: #E3E6E9;
    transform: translateX(-50%);

    &.horizontal {
      position: static;
      height: 1px;
      width: 100%;
      transform: unset;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }

  .contact-card {
    &.light {
      a,
      .editor__content,
      .pub__content {
        color: #0C226C;
      }
    }

    &.dark {
      a,
      .editor__content,
      .pub__content {
        color: #fff;
      }
    }
  }
}

.resolution-sm {
  .contact-form-1 {
    .btn {
      display: block !important;
      width: 100% !important;
    }
  }
}
</style>