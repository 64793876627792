<template>
  <div :style="{...padding, ...opacity, ...backgroundColor}">
    <div :class="container">
      <b-row no-gutters class="featured-items-4">
        <b-col :order="aligment('left') ? 0 : 1" :cols="cols(6, 12)">
          <template v-if="visability('image')">
            <slot name="image"></slot>
          </template>
        </b-col>

        <b-col :order="aligment('left') ? 1 : 0" :cols="cols(6, 12)" :style="{...shadow}">
          <div class="d-flex align-items-center h-100">
            <div class="featured-text">
              <div class="leading" v-if="visability('title')">
                <slot name="title"></slot>
              </div>

              <template v-if="visability('description')">
                <slot name="description"></slot>
              </template>

              <template v-if="visability('button')">
                <slot name="button"></slot>
              </template>

<!--              <add-inner-content name="innerCotent" :section="section"></add-inner-content>-->
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import AddInnerContent from "../../../../../modules/builder/src/components/builder/utils/AddInnerContent";

  export default {
    name: "FeaturedItems4Template",
    components: {AddInnerContent},
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .featured-items-4 {
    p {
      margin-bottom: 0;
    }

    .leading {
      letter-spacing: 0.17em;
    }

    .featured-text {
      padding: 40px;
    }
  }
</style>
