<template>
  <highliter class="feature-section" :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings('images')">
   <Carousel1Template :section="section">
     <highliter class="inner-slide" v-for="(image, index) in section.options.content.images.items" :key="index" @click.stop.native="sectionSettings({item: 'images', index})">
       <image-fit
           :customs="image"
           :src="image.src || image.placeholder"
           :size="cols(image.size, 'contain')"
           :position="image.position"
           height="285px"
       ></image-fit>
     </highliter>
   </Carousel1Template>
  </highliter>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Carousel1Template from "./Carousel1Template";
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "Carousel1",

    components: {ImageFit, Carousel1Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
