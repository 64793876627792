import SectionProvider from '@/sections/SectionProvider';

export const name = 'News Letter 1'
export const group = 'newsLetter'

export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '12px',
          fontFamily: 'Global Style',
          color: '#ADD5DF',
          align: 'left',
          padding: {
            top: 0,
            bottom: 10,
            x: 0
          }
        },
        lg: {
          fontSize: '12px',
          fontFamily: 'Global Style',
          color: '#ADD5DF',
          align: 'left',
          padding: {
            top: 0,
            bottom: 10,
            x: 0
          }
        }
      },
      text: "<p><strong>GET MY FREE GUIDE TODAY!</strong></p>"
    },
    subtitle: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '21px',
          fontFamily: 'Global Style',
          color: '#fff',
          align: 'left',
          lineHeight: 1.3,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '21px',
          fontFamily: 'Global Style',
          color: '#fff',
          align: 'left',
          lineHeight: 1.3,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Get My Free Business Maximizer Guide by Entering Your Email</p>"
    },
    form: {
      type: 'form-mod',
      display: true,
      action: null,
      selectedList: '',
      btnPosition: 'append-email',
      fields: [
        {
          display: true,
          type: 'email',
          name: 'email',
          placeholder: 'Email address',
          required: true,
          label: null,
          cols: {
            sm: 12,
            lg: 12
          }
        }
      ]
    },
    button: {
      type: 'button-mod',
      submit: true,
      display: true,
      hover: false,
      text: 'Send Form',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'sm',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    image: {
      display: true,
      type: 'image-mod',
      placeholder: require('@/assets/images/news-letter-palceholder-1.png'),
      src: null,
      size: 'cover',
      position: 'center',
    }
  },
  customize: {
    display: true,
    layouts: null,
    style: {
      background: {
        background: '#0C226C'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
        size: 'cover',
        opacity: 1
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 30,
          bottom: 30
        },
        lg: {
          top: 30,
          bottom: 30
        }
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/newsLetter/main-1.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('NewsLetterItem1', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
