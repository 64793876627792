<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
    <Template :section="section">
      <template v-slot:button="{button}">
        <pub-es-button :customs="button.section.options.content.button"></pub-es-button>
      </template>
    </Template>
  </highliter>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import Template from './Template'

  export default {
    name: "ActionItem2",

    components: {Highliter, Template},

    mixins: [SectionMixin],
  }
</script>
