import SectionProvider from '@/sections/SectionProvider';

export const name = 'Action Item 11'
export const group = 'actions'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Order Your Product Right</strong></p><p><strong>and see How it Goes</strong></p>"
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur</p><p>lobortis quam vitae posuere</p>"
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Book a Call',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    button2: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Learn More',
      resolutionStyle: {
        lg: {
          color: '#0C226C',
          backgroundColor: '#0C226C',
          size: 'md',
          shape: null,
          btnStyle: 'outlined',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    image: {
      display: true,
      type: 'image-mod',
      size: 'cover',
      position: 'left',
      placeholder: require('@/assets/svg/previews/actions/palceholder-action-11.svg'),
      src: null
    },
  },
  customize: {
    layouts: null,
    style: {
      padding: {
        type: 'height-customs',
        sm: {
          top: 70,
          bottom: 70
        },
        lg: {
          top: 115,
          bottom: 115
        }
      },
      container: {
        type: 'container-customs',
        container: 'container'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
        size: 'cover',
        opacity: 1
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/actions/main-11.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('ActionItem11', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
