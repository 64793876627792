<template>

</template>

<script>
export default {
name: "NotFound"
}
</script>

<style>

</style>