import SectionProvider from '@/sections/SectionProvider';

export const name = 'Featured Items 4'

export default class FeaturedItems4 extends SectionProvider {
  constructor(options = {}) {
    super('FeaturedItems4', options);

    this.name = name
    this.group = 'featured'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '16px',
              fontFamily: 'Global Style',
              color: '#4F68BA',
              align: 'left',
              padding: {
                top: 0,
                bottom: 10,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#4F68BA',
              align: 'left',
              padding: {
                top: 0,
                bottom: 10,
                x: 0
              }
            }
          },
          text: "<b>PRE-HEAADLINE GOES HERE</b>"
        },
        description: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '32px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'left',
            },
            lg: {
              fontSize: '38px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'left',
            }
          },
          text: "<b>Curabitur tristique, lorem et consequat, nisi nisi  ac dictum.</b>"
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Click me',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'lg',
              btnStyle: '',
              shape: null,
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'lg',
              btnStyle: '',
              shape: null,
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/images/placeholder-featured-item-4.jpg'),
          src: null,
          position: 'center',
size: 'cover'
        },
      },
      customize: {
        style: {
          aligment: {
            type: 'aligment-customs',
            align: 'left',
            images: {
              left: require('@/assets/svg/previews/featured-items-4-left.svg'),
              right: require('@/assets/svg/previews/featured-items-4-right.svg'),
            }
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
            },
            lg: {
              top: 115,
              bottom: 115
            }
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
        }
      }
    }
  }
}
