import Navbar1 from '@/sections/navigations/Navbar1';
import FeaturedItems1 from '@/sections/featured/FeaturedItems1';
import Vue from 'vue'

const state = () => ({
  page: 'home',
  mailAPIServices: []
})

const getters = {
  hasMailAPIService(state) {
    const service = state.mailAPIServices.filter(service => !!service.status)

    if (service.length) {
      return service[0]
    } else {
      return false
    }
  },

  hasLocalAPIService: (state, _, rootState) => (service) => {
    const settings = rootState.editor.globalStyles

    if (!settings.hasOwnProperty('api')) return false

    if (settings.api.hasOwnProperty(service) || settings.api[service] !== null) {
      return true
    }

    return false
  }
}

const actions = {
  getlAllMailServices({state, rootState}) {
    if (!rootState.projects.project) return false

      axios.get(`api/projects/${rootState.projects.project.id}/auto-responders`)
      .then(({data}) => {
        state.mailAPIServices = data.data.filter(ser => ['AWeber', 'MailChimp', 'GetResponse', 'Ontraport', 'Activecampaign'].includes(ser.name))
      })
  },
  async integrateMailService({rootState}, payload) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/auto-responders`, {...payload, project_id: rootState.projects.project.id})
  },
  async deleteMailService({rootState}, payload) {
    return await axios.delete(`api/projects/${rootState.projects.project.id}/auto-responders?id=${payload.auto_responder_id}`)
  },
  integrate({rootState, dispatch}, payload) {
    VEvent.fire('loader', true)

    const settings = rootState.editor.globalStyles

    if (!settings.hasOwnProperty('api')) {
      Vue.set(settings, 'api', {})
    }

    Vue.set(settings.api, payload.service, payload)

    dispatch('projects/save', {}, {root: true})
      .then(() => {
        VEvent.fire('loader', false)
      })
  }
}

const mutations = {

}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
