import Vue from 'vue'
import Navbar1 from '@/sections/navigations/Navbar1';
import FeaturedItems1 from '@/sections/featured/FeaturedItems1';
import {getIndexByUID} from '@/store/utils/helpers';
import shortid from 'shortid'
import FeaturedItems2 from '../../sections/featured/FeaturedItems2'
import FeaturedItems3 from '../../sections/featured/FeaturedItems3'
import FeaturedItems4 from '../../sections/featured/FeaturedItems4'
import FeaturedItems5 from '../../sections/featured/FeaturedItems5'
import ActionItem1 from '../../sections/actions/ActionItem1'
import ActionItem2 from '../../sections/actions/ActionItem2'
import ActionItem3 from '../../sections/actions/ActionItem3'
import ActionItem4 from '../../sections/actions/ActionItem4'
import ActionItem5 from '../../sections/actions/ActionItem5'
import TestimonialItem1 from '../../sections/testimonials/TestimonialItem1'
import EsRow from '../../sections/utils/EsRow';
import TestimonialItem1Group from "../../sections/testimonials/TestimonialItem1Group";
import VideoItem1Group from "../../sections/videos/VideoItem1Group";
import {default as NewsLetterItem1} from "../../../../../modules/builder/src/components/builder/sections/newsLetters/news-letter-1/Section.js";
import VideoItem2 from "../../sections/videos/VideoItem2";
import HeadlineItem1 from "../../sections/headlines/HeadlineItem1";
import HeadlineItem2 from "../../sections/headlines/HeadlineItem2";
import HeadlineItem3 from "../../sections/headlines/HeadlineItem3";
import HeadlineItem4 from "../../sections/headlines/HeadlineItem4";
import ListItem1 from "../../sections/list/ListItem1";
import ListItem2 from "../../sections/list/ListItem2";
import ListItem3 from "../../sections/list/ListItem3";
import VideoItem3Group from "../../sections/videos/VideoItem3Group";
import VideoItem4 from "../../sections/videos/VideoItem4";
import TestimonialItem2 from "../../sections/testimonials/TestimonialItem2";
import TestimonialItem4 from "../../sections/testimonials/TestimonialItem4";
import TestimonialItem4Group from "../../sections/testimonials/TestimonialItem4Group";
import TestimonialItem3 from "../../sections/testimonials/TestimonialItem3";
import TestimonialItem3Group from "../../sections/testimonials/TestimonialItem3Group";
import NewsLetterItem3 from "../../sections/newsLetter/NewsLetterItem3";
import NewsLetterItem4 from "../../sections/newsLetter/NewsLetterItem4";
import NewsLetterItem5 from "../../sections/newsLetter/NewsLetterItem5";
import NewsLetterItem2 from "../../sections/newsLetter/NewsLetterItem2";
import TextAndImageItem1 from '../../sections/text-and-image/TextAndImageItem1';
import TextAndImageItem1Group from '../../sections/text-and-image/TextAndImageItem1Group';
import TextAndImageItem2Group from '../../sections/text-and-image/TextAndImageItem2Group';
import TextAndImageItem3Group from '../../sections/text-and-image/TextAndImageItem3Group';
import ImageItem1 from "../../sections/images/ImageItem1";
import ImageItem2 from "../../sections/images/ImageItem2";
import ImageItem3 from "../../sections/images/ImageItem3";
import ImageItem4 from "../../sections/images/ImageItem4";
import ImageItem5 from "../../sections/images/ImageItem5";
import ImageItem6 from "../../sections/images/ImageItem6";
import ResourceItem1 from "../../sections/resource/ResourceItem1";
import QuoteItem1 from "../../sections/quotes/QuoteItem1";
import QuoteItem3 from "../../sections/quotes/QuoteItem3";
import QuoteItem2 from "../../sections/quotes/QuoteItem2";
import HeroItem1 from "../../sections/hero/HeroItem1";
import CollapseItems1 from "../../sections/collapse/CollapseItems1";

import _ from 'lodash'
import Footer2 from "../../components/builder/sections/footer/footer-2/Section";
import router from '@/router/index'

const state = () => ({
  page: {
    id: 1,
    name: 'Home',
    path: '/',
    sections: [
      new Navbar1().json()
    ]
  },
  page: 'home',
  pages: [
    {
      id: 1,
      name: 'Home',
      path: '/',
      sections: [
        new Navbar1().json()
      ]
    },
    {
      id: 2,
      name: 'About',
      path: '/about'
    }
  ],
  sections: [
    new Navbar1().json()
  ],
  // sections: [
  // new Navbar1().json(),
  // new TimeItem1().json(),
  // new ImageItem1().json(),
  // new FeaturedItems5().json(),
  // new FeaturedItems2().json(),
  // new ActionItem4().json(),
  // new FeaturedItems3().json(),
  // new FeaturedItems1().json(),
  // new ActionItem1().json(),
  // new ActionItem2().json(),
  // new ActionItem5().json(),
  // new FeaturedItems4().json(),
  // new ActionItem3().json(),
  // new TestimonialItem1Group().json(),
  // new TestimonialItem2().json(),
  // new TestimonialItem3Group().json(),
  // new TestimonialItem4Group().json(),
  // new VideoItem1Group().json(),
  // new VideoItem2().json(),
  // new VideoItem3Group().json(),
  // new VideoItem4().json(),
  // new HeadlineItem1().json(),
  // new HeadlineItem2().json(),
  // new HeadlineItem3().json(),
  // new HeadlineItem4().json(),
  // new ListItem1().json(),
  // new ListItem2().json(),
  // new ListItem3().json(),
  // new NewsLetterItem1().json(),
  // new NewsLetterItem2().json(),
  // new NewsLetterItem3().json(),
  // new NewsLetterItem4().json(),
  // new NewsLetterItem5().json(),
  // new TextAndImageItem1Group().json(),
  // new TextAndImageItem2Group().json(),
  // new TextAndImageItem3Group().json(),
  // ],
  navbar: {
    nav: [
      {
        key: 'home',
        name: 'Home',
        path: '/',
      },
      {
        key: 'about',
        name: 'About',
        path: '/about',
      }
    ],
    section: null
  },
  sections: {
    home: {
      sections: [
        // new NewsLetterItem1().json(),
        // new NewsLetterItem2().json(),
        // new NewsLetterItem3().json(),
        // new NewsLetterItem4().json(),
        // new NewsLetterItem5().json(),
        // new CollapseItems1().json()
        // new TestimonialItem1().json()
        // new HeroItem1().json()
        // new QuoteItem1().json(),
        // new QuoteItem2().json(),
        // new QuoteItem3().json(),
        // new ImageItem3().json(),
        // new ImageItem4().json(),
        // new ImageItem5().json(),
        // new ImageItem6().json(),
        // new ResourceItem1().json()
      ]
    },
    about: {
      sections: [
        // new FeaturedItems2().json()
      ]
    }
  },
  footer: null,
})

const getters = {
  getPages(state, getters, rootState) {
    let pages = [],
      pageNames = state.sections

    Object.keys(state.sections).forEach(key => {
      pages.push({
        key: key,
        name: pageNames[key].name || _.startCase(key)
      })
    })

    return pages
  },
  getNavLinks(state) {
    return state.navbar.nav
  },
  getSectionsJson(state) {
    return state.sections.map()
  },
  getSections(state, _, rootState) {
    if (state.page.search('es-') === 0) {
      return []
    }

    const section = state.sections[state.page] || {}
    return section.sections || []
  }
}

const actions = {
  addLink({state}, payload) {
    const key = _.kebabCase(payload.name)

    if (!payload.key && !payload.activeItem) {
      state.navbar.nav.push({
        ...payload,
        key
      })

      Vue.set(state.sections, key, {
        sections: []
      })

      state.page = key

    } else if (payload.key && !payload.activeItem) {
      state.navbar.nav.push({
        ...payload
      })
    } else if (!payload.key && payload.activeItem) {
      if (!payload.activeItem.page.hasOwnProperty('children')) {
        Vue.set(payload.activeItem.page, 'children', [])
      }

      payload.activeItem.page.children.push({
        name: payload.name,
        path: payload.path,
        key
      })

      Vue.set(state.sections, key, {
        sections: []
      })

      state.page = key
    } else if (payload.key && payload.activeItem) {
      if (!payload.activeItem.page.hasOwnProperty('children')) {
        Vue.set(payload.activeItem.page, 'children', [])
      }

      payload.activeItem.page.children.push({
        name: payload.name,
        path: payload.path,
        key: payload.key
      })
    }
  },
  addPage({state}, payload) {
    Vue.set(state.sections, _.kebabCase(payload.name), {
      sections: []
    })
  },
  editPage({state, rootState, dispatch }, payload) {
    Vue.set(state.sections[payload.key], 'name', payload.name)

    dispatch('projects/save', null, {root: true})
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  deletePage({state, rootState, dispatch }, payload) {
    Vue.delete(state.sections, _.kebabCase(payload))

    dispatch('projects/save', null, {root: true})
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  removeLink({state}, payload) {
    const index = payload.list.findIndex(nav => nav.name.toLowerCase() === payload.page.name.toLowerCase())

    if (index !== -1) {
      payload.list.splice(index, 1)

      // if (state.page === _.kebabCase(payload.page.name)) {
      //   state.page = _.kebabCase(state.navbar.nav[0].key)
      // }
      //
      // Vue.delete(state.sections, _.kebabCase(payload.page.name));
    }
  },
  addSection({commit, rootState, state}, payload) {
    if (payload.after) {
      const afterIndex = state.sections[state.page].sections.findIndex(s => s.uid === payload.after)

      if (payload.deep) {
        commit('ADD_SECTION_DEEP', {
          index: afterIndex,
          section: payload.section
        })
      } else if (afterIndex !== -1) {
        commit('ADD_SECTION_AFTER', {
          index: afterIndex,
          section: payload.section
        })
      } else {
        commit('ADD_SECTION', payload)
      }
    } else {
      commit('ADD_SECTION', payload)
    }
  },
  removeSection({commit, rootState, state}, payload) {
    if (typeof payload !== 'object') {
      payload = {uid: payload, deep: null}
    }

    if (payload.uid === '#navigation') {
      commit('REMOVE_NAVBAR')
      return false
    }

    if (payload.uid === '#footer') {
      commit('REMOVE_FOOTER')
      return false
    }

    if (payload.deep) {
      const parentIndex = state.sections[state.page].sections.findIndex(s => s.uid === payload.deep)
      const index = state.sections[parentIndex].options.content.group.items.findIndex(s => s.uid === payload.uid)
      commit('REMOVE_SECTION_DEEP', {parentIndex, index})
    } else {
      const index = state.sections[state.page].sections.findIndex(s => s.uid === payload.uid)
      commit('REMOVE_SECTION', index)
    }
  },
  changeLayout({state, dispatch}, payload) {
    const sectionIndex = getIndexByUID(state.sections[state.page].sections, payload.uid)

    if (sectionIndex !== -1) {
      const newSection = new payload.layout(JSON.parse(JSON.stringify(payload.oldConfig)).options).json()
      Vue.set(state.sections[state.page].sections, sectionIndex, newSection)
      dispatch('editor/sectionSettings', {section: newSection}, {root: true})
    }
  },
  changeNavbarLayout({state, dispatch}, payload) {
    const newSection = new payload.layout(JSON.parse(JSON.stringify(payload.oldConfig)).options).json()
    Vue.set(state.navbar, 'section', newSection)
    dispatch('editor/sectionSettings', {section: newSection}, {root: true})
  },
  setSections({state, rootState, commit}, paylaod) {
    state.sections[state.page].sections = paylaod
    commit('editor/SET_PAGE', 'sections', {root: true})
  }
}

const mutations = {
  ADD_SECTION(state, payload) {
    state.sections[state.page].sections.unshift(payload.section)
  },
  UNSHIFT_SECTION(state, payload) {
    state.sections[state.page].sections.unshift(payload.section)
  },
  ADD_SECTION_AFTER(state, payload) {
    state.sections[state.page].sections.splice(payload.index + 1, 0, payload.section)
  },
  ADD_SECTION_DEEP(state, payload) {
    state.sections[state.page].sections[payload.index].options.content.group.items.push(payload.section)
  },
  REMOVE_SECTION(state, index) {
    state.sections[state.page].sections.splice(index, 1)
  },
  REMOVE_SECTION_DEEP(state, payload) {
    state.sections[state.page].sections[payload.parentIndex].options.content.group.items.splice(payload.index, 1)
  },
  SET_PAGE(state, payload) {
    state.page = payload
    if (state.sections.hasOwnProperty(payload)) {
      document.title = state.sections[payload].name || _.startCase(payload)
    }
  },
  ADD_NAVBAR(state, {section}) {
    state.navbar.section = section
  },
  REMOVE_NAVBAR(state) {
    state.navbar.section = null
  },
  ADD_FOOTER(state, {section}) {
    state.footer = section
  },
  REMOVE_FOOTER(state) {
    state.footer = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
