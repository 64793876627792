<template>
  <footer :style="{...backgroundColor , ...padding, ...backgroundImage}" class="footer-1 footer-bottom" :class="theme">
    <b-container>
      <b-row>
        <b-col :cols="cols(3, 12)" :class="cols('mb-0', 'mb-4')">
          <div v-if="visability('logo')"
               class="mb-3"
          >
            <slot name="logo"></slot>
          </div>
        </b-col>

        <b-col :cols="cols(2, 12)" :class="cols('mb-0', 'mb-4')">
          <template v-if="visability('labelMenu1')">
            <slot name="labelMenu1"></slot>
          </template>

          <template v-if="visability('menu1')">
            <slot name="menu-1"></slot>
          </template>
        </b-col>

        <b-col :cols="cols(2, 12)" :class="cols('mb-0', 'mb-4')">
          <template v-if="visability('labelMenu2')">
            <slot name="labelMenu2"></slot>
          </template>

          <template v-if="visability('menu2')">
            <slot name="menu-2"></slot>
          </template>
        </b-col>

        <b-col :cols="cols(5, 12)">
          <template v-if="visability('labelMenu3')">
            <slot name="labelMenu3"></slot>
          </template>

          <div :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
            <slot name="form"></slot>
          </div>

          <div class="form-description" v-if="visability('formDescription')">
            <slot name="formDescription"></slot>
          </div>
        </b-col>

        <b-col cols="12" class="mt-3">
          <template v-if="visability('copyright')">
            <slot name="copyright"></slot>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import ThemeMixin from "../../../../mixins/ThemeMixin";
  import {mapGetters} from "vuex";

  export default {
    name: "Carousel1Template",
    mixins: [TemplateMixin, ThemeMixin],
    computed: {
      ...mapGetters('sections', [
        'getSections'
      ]),
    },
    created() {
      if (this.section.options.content.logo.type !== 'logo-mod') {
        const doctorLogoMod = {
          type: 'logo-mod',
          display: true,
          image: {
            src: this.section.options.content.logo.src || this.section.options.content.logo.placeholder
          },
          text: ''
        }

        this.section.options.content.logo = doctorLogoMod
      }
    }
  }
</script>

<style lang="scss">
.resolution-sm {
  .footer-1 {
    .btn {
      display: block;
      width: 100%;
    }
  }
}

.footer-1 {
  z-index: 1 !important;

  a,
  .editor__content,
  .pub__content {
    transition: 1s;
  }

  &.light {
    a,
    .editor__content,
    .pub__content {
      color: #44474A;
    }

    .list-unstyled,
    .form-description {
      a,
      .editor__content,
      .pub__content {
        color: #6D6D6D;
      }
    }
  }

  &.dark {
    a,
    .editor__content,
    .pub__content {
      color: #fff;
    }
  }
}
</style>
