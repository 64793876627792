<template>
 <TextAndImageItem1Template :section="section">
    <template slot="image">
      <highliter v-show="visability('image')" @click.stop.native="sectionSettings('image')">
        <image-fit
            :customs="section.options.content.image"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
            :size="cols(section.options.content.image.size, 'cover')"
            :position="section.options.content.image.position"
            height="308px"
        ></image-fit>
      </highliter>
    </template>

    <template slot="text">
      <highliter v-show="visability('text')" @click.stop.native="sectionSettings('text')">
        <content-editable
            :customs="section.options.content.text"
            @init="(editor) => section.classes.tiptap.text = editor"
            v-model="section.options.content.text.text"></content-editable>
      </highliter>
    </template>
 </TextAndImageItem1Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import TextAndImageItem1Template from '@pub/templates/default/sections/text-and-image/TextAndImageItem1Template';
  import ImageFit from "../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {ImageFit, TextAndImageItem1Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
