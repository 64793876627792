<template>
  <Template :section="section">
    <template slot="title">
      <pub-content-editable
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description">
      <pub-content-editable
          :uid="tiptapUID('description')"
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="left-icon">
      <icon-editable :customs="section.options.content.leftIcon"></icon-editable>
    </template>

    <template slot="left-title">
      <pub-content-editable
          :uid="tiptapUID('leftTitle')"
          :customs="section.options.content.leftTitle"
          v-model="section.options.content.leftTitle.text"></pub-content-editable>
    </template>

    <template slot="left-description">
      <pub-content-editable
          :uid="tiptapUID('leftDescription')"
          :customs="section.options.content.leftDescription"
          v-model="section.options.content.leftDescription.text"></pub-content-editable>
    </template>

    <template slot="left-button">
      <pub-es-button :customs="section.options.content.leftButton"></pub-es-button>
    </template>


    <template slot="right-icon">
      <icon-editable :customs="section.options.content.rightIcon"></icon-editable>
    </template>

    <template slot="right-title">
      <pub-content-editable
          :uid="tiptapUID('rightTitle')"
          :customs="section.options.content.rightTitle"
          v-model="section.options.content.rightTitle.text"></pub-content-editable>
    </template>

    <template slot="right-description">
      <pub-content-editable
          :uid="tiptapUID('rightDescription')"
          :customs="section.options.content.rightDescription"
          v-model="section.options.content.rightDescription.text"></pub-content-editable>
    </template>

    <template slot="right-button">
      <pub-es-button :customs="section.options.content.rightButton"></pub-es-button>
    </template>
  </Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from './Template';
import PubContentEditable from "../../../utils/PubContentEditable";
import PubEsButton from "../../../utils/buttons/PubEsButton";

export default {
  name: "ActionItem2",

  components: {PubEsButton, PubContentEditable, Template, ContentEditable, Highliter},

  mixins: [SectionMixin],
}
</script>
