<template>
  <div class="testimonial-item-3" :style="{...padding, ...shadow}" :class="[container, cols('', 'mb-4')]">
    <div :style="align('image')">
      <slot name="image"></slot>
    </div>
    <slot name="name"></slot>
    <slot name="subname"></slot>
    <slot name="text"></slot>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "TestimonialItem4Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .testimonial-item-3 {
    p {
      margin-bottom: 0;
    }
  }
</style>
