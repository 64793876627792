<template>
  <component
      :data-aos="animations.animation" data-aos-once="true" data-aos-offset="200" :data-aos-delay="animations.delay" :data-aos-duration="animations.duration || 400"
      :is="tag" v-bind="$attrs" class="img-responsive-bg position-relative" :style="{width: width, minHeight: height}">
    <div class="inner-container" :class="[{'rounded': rouneded}, innerContainerClass]">
      <img width="100%" height="100%" :style="{objectFit: size, objectPosition: position}"
           :src="src" alt="Image">
    </div>
  </component>
</template>

<script>
import Animations from "../mixins/Animations";

export default {
  name: "ImageFit",

  mixins: [Animations],

  props: {
    customs: {
      type: Object
    },
    innerContainerClass: {
      type: String,
      default: ''
    },
    tag: {
      default: 'div'
    },
    size: {
      required: true
    },
    src: {
      required: true
    },
    position: {
      default: 'center'
    },
    height: {
      type: String,
      default: '50vh'
    },
    width: {
      type: String,
      default: '100%'
    },
    rouneded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.img-responsive-bg {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .inner-container {
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>