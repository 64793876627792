<template>
  <b-card-header @mouseenter="menu = true" @mouseleave="menu = false" header-class="editor-toolbar-collapse-header d-flex align-items-center rounded-0">
    <slot></slot>

    <div v-if="menu" class="ml-auto">
      <slot name="menu"></slot>
    </div>
  </b-card-header>
</template>

<script>
  export default {
    name: "SectionItemHover",

    data() {
      return {
        menu: false
      }
    }
  }
</script>

<style scoped>

</style>
