<template>
  <img :src="src" :style="{color: color, width: size, height: size}" alt="Icon">
</template>

<script>
  export default {
    name: "Icon",

    props: {
      icon: {
        type: String
      },
      color: {
        type: String
      },
      size: {
        type: String
      },
      path: {
        type: String,
        default: '@/assets/icons/'
      }
    },

    computed: {
      src() {
        return this.icon ? require('@/assets/icons/' + this.icon) : ''
      }
    }
  }
</script>
