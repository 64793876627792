import SectionProvider from '@/sections/SectionProvider';

export const name = 'Hero 1'

export default class HeroItem1 extends SectionProvider {
  constructor(options = {}) {
    super('HeroItem1', options);

    this.name = name
    this.group = 'hero'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '61px',
              fontFamily: 'Global Style',
              color: '#ffffff',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '61px',
              fontFamily: 'Global Style',
              color: '#ffffff',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [
              {
                "type": "paragraph",
                "content": [
                  {"type": "text", "text": "Reaching New"},
                ]
              },
              {
                "type": "paragraph",
                "content": [
                  {"type": "text", "text": "Heights in Business"}
                ]
              }
            ]
          },
        },
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '20px',
              color: '#ffffff',
              align: 'left',
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '20px',
              color: '#ffffff',
              align: 'left',
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{
                "type": "text",
                "text": "Curabitur tristique, lorem et dignissim consequat, nisi nisi tincidunt mauris, ac dictum neque odio eget elit."
              }]
            }]
          },
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/images/hero-1-book.png'),
          src: 'cover',
          position: 'center',
size: 'cover'
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Click Here',
          resolutionStyle: {
            lg: {
              size: 'lg',
              btnStyle: '',
              color: '#ffffff',
              backgroundColor: 'colored',
              shape: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              size: 'lg',
              btnStyle: '',
              color: '#fffffff',
              backgroundColor: 'colored',
              shape: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        layouts: null,
        style: {
          aligment: {
            type: 'aligment-customs',
            align: 'left',
            images: {
              left: require('@/assets/svg/previews/hero/left-1.svg'),
              right: require('@/assets/svg/previews/hero/right-1.svg'),
            }
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: require('@/assets/images/hero-1-palceholder.png'),
            position: 'center',
size: 'cover',
            opacity: 1
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 220,
              bottom: 220
            },
            lg: {
              top: 220,
              bottom: 220
            }
          }
        }
      }
    }
  }
}
