<template>
  <div class="parapgraph-1">
    <div :class="container" :style="{...padding}">
      <b-col :cols="cols(6, 12)">
        <div class="border-left pl-3">
          <slot></slot>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "AboutUs1Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .parapgraph-1 {
    .border-left {
      border-width: 3px !important;
    }
  }
</style>
