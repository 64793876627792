import SectionProvider from '@/sections/SectionProvider';

export const name = 'Hero 1'
export const group = 'hero'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '42px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'left',
          lineHeight: 1.1,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '54px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'left',
          lineHeight: 1.1,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Reaching New</strong></p><p><strong>Heights in Business</strong></p>"
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: '#ffffff',
          align: 'left',
          lineHeight: 1.5,
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '21px',
          color: '#ffffff',
          align: 'left',
          lineHeight: 1.5,
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: {
        "type": "doc",
        "content": [{
          "type": "paragraph",
          "content": [{
            "type": "text",
            "text": "Curabitur tristique, lorem et dignissim consequat, nisi nisi tincidunt mauris, ac dictum neque odio eget elit."
          }]
        }]
      },
    },
    image: {
      display: true,
      type: 'image-mod',
      placeholder: require('@/assets/images/hero-1-book.png'),
      src: null,
      size: 'cover',
      position: 'center',
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Join Now',
      resolutionStyle: {
        lg: {
          size: 'lg',
          btnStyle: '',
          color: '#ffffff',
          backgroundColor: 'colored',
          shape: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          size: 'lg',
          btnStyle: '',
          color: '#ffffff',
          backgroundColor: 'colored',
          shape: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    button2: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Learn More',
      resolutionStyle: {
        lg: {
          size: 'lg',
          btnStyle: 'outlined',
          color: '#fff',
          backgroundColor: '#fff',
          shape: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          size: 'lg',
          btnStyle: 'outlined',
          color: '#fff',
          backgroundColor: '#fff',
          shape: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {
    layouts: null,
    style: {
      aligment: {
        type: 'aligment-customs',
        align: 'left',
        images: {
          left: require('@/assets/svg/previews/hero/left-1.svg'),
          right: require('@/assets/svg/previews/hero/right-1.svg'),
        }
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: require('@/assets/svg/previews/hero/hero-1-palceholder.png'),
        position: 'center',
size: 'cover',
        opacity: 1
      },
      container: {
        type: 'container-customs',
        container: 'container'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 80,
          bottom: 80
        },
        lg: {
          top: 220,
          bottom: 220
        }
      }
    }
  }
}

export const previews = {
  left: require('@/assets/svg/previews/hero/left-1.svg'),
  right: null,
  main: require('@/assets/svg/previews/hero/main-1.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('HeroItem1', options);

    this.name = name
    this.group = 'hero'

    this.defaultOptions = defaultOptions
  }
}
