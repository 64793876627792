import SectionProvider from '@/sections/SectionProvider';

export const name = 'Quote Item 2'

export default class QuoteItem2 extends SectionProvider {
  constructor(options = {}) {
    super('QuoteItem2', options);

    this.name = name
    this.group = 'quotes'

    this.defaultOptions = {
      content: {
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '18px',
              color: '#fff',
              align: 'center',
              lineHeight: 1.3,
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              color: '#fff',
              align: 'left',
              lineHeight: 1.3,
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{
                "type": "text",
                "marks": [{"type": "bold"}],
                "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus ultrices. Nullam consequat."
              }]
            }]
          },
        },
        signature: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '17px',
              color: '#B8BFFE',
              align: 'center',
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '17px',
              color: '#B8BFFE',
              align: 'left',
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{
                "type": "text",
                "text": "Famous Person"
              }]
            }]
          },
        }
      },
      customize: {
        style: {
          background: {
            background: '#1F3B9C'
          },
          container: {
            type: 'container-customs',
            container: null
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 30,
              bottom: 30
            },
            lg: {
              top: 30,
              bottom: 30
            }
          }
        }
      }
    }
  }
}
