<template>
  <ActionItem2Template :section="section">
    <template slot="title" v-if="visability('title')">
      <pub-content-editable
          :customs="section.options.content.title"
          @init="(editor) => section.classes.tiptap.title = editor"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description" v-if="visability('description')">
      <pub-content-editable
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="button" v-if="visability('button')">
      <pub-es-button :customs="section.options.content.button"></pub-es-button>
    </template>
  </ActionItem2Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import ActionItem2Template from '@pub/templates/default/sections/actions/ActionItem2Template';
  import PubEsButton from '../../../builder/utils/buttons/PubEsButton';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';

  export default {
    name: "ActionItem2",

    components: {PubContentEditable, PubEsButton, ActionItem2Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
