<template>
<!--  <div class="mb-4">-->
<!--    <label class="mb-2">Container</label>-->

<!--    <b-select v-model="config.container">-->
<!--      <option value="null">Default</option>-->
<!--      <option value="container">Container</option>-->
<!--      <option value="container-fluid">Container Fluid</option>-->
<!--    </b-select>-->
<!--  </div>-->
</template>

<script>
import ControlMixin from "../../mixins/ControlMixin";

export default {
  name: "Container",

  mixins: [ControlMixin],
}
</script>
