import _ from 'lodash'

export default {
  methods: {
    GetResponse() {
      let data = {}

      data.email = this.getEmail()
      data.list_id = this.customs.selectedList

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.customFieldId] = field
          }
        })
      }

      return data
    },

    MailChimp() {
      let data = {}
      data.email = this.getEmail()
      data.list_id = this.customs.selectedList

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.tag] = field
          }
        })
      }

      return data
    },

    Ontraport() {
      let data = {}
      data.email = this.getEmail()

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.label] = field
          }
        })
      }

      return data
    },

    ContactForm() {
      let data = {}
      data.email = this.customs.recipientEmail
      data.custom_fields = {}

      this.customs.fields.forEach(field => {
        data.custom_fields[_.snakeCase(field.name)] = {
          ...field,
          label: field.label || _.startCase(field.name)
        }
      })

      return data
    },

    Activecampaign() {
      let data = {}
      data.email = this.getEmail()

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.name] = field
          }
        })
      }

      return data
    },

    AWeber() {
      let data = {}
      data.email = this.getEmail()
      data.list_id = this.customs.selectedList

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.id] = {
              ...field,
              label: field.apiLabel || _.startCase(field.name)
            }
          }
        })
      }

      return data
    }
  }
}