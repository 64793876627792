<template>
  <Template :section="section">
    <template slot="image">
        <image-fit
            :customs="section.options.content.image"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
            :size="cols(section.options.content.image.size, 'cover')"
            :position="section.options.content.image.position"
            inner-container-class="rouneded-lg"
            height="230px"
            width="214px"
            rounded
        ></image-fit>
    </template>

    <template slot="title">
        <pub-content-editable
            v-if="visability('title')"
            :uid="tiptapUID('description')"
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description">
        <pub-content-editable
            v-if="visability('description')"
            :uid="tiptapUID('description')"
            :customs="section.options.content.description"
            v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="button" v-if="section.options.content.hasOwnProperty('button')">
        <pub-es-button v-if="visability('button')" :customs="section.options.content.button"></pub-es-button>
    </template>
  </Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import Template from './Template';
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {ImageFit, Template, Highliter},

    mixins: [SectionMixin]
  }
</script>
