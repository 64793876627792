<template>
  <div data-padding>
    <FeaturedItems2Template :section="section">
      <template slot="images">
        <b-row>
          <b-col :cols="cols(4, 12)" v-for="section in section.options.content.group.items" :key="section.uid">
            <featured-image :section="section"></featured-image>
          </b-col>
        </b-row>
      </template>

      <template slot="title">
        <pub-content-editable
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="description">
        <pub-content-editable
            :customs="section.options.content.description"
            v-model="section.options.content.description.text"></pub-content-editable>
      </template>
    </FeaturedItems2Template>
  </div>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import FeaturedItems2Template from '@pub/templates/default/sections/featured/FeaturedItems2Template';
import PubContentEditable from "../../../builder/utils/PubContentEditable";
import FeaturedImage from "./FeaturedImage";

export default {
  name: "FeaturedItems2",

  components: {PubContentEditable, FeaturedItems2Template, ContentEditable, Highliter, FeaturedImage},

  mixins: [SectionMixin]
}
</script>
