<template>
  <b-col v-bind="options">
    <component
        v-for="(node, index) in children"
        :key="index"
        :is="node.component"
        :options="node.options"
        :children="node.children"
    >
    </component>
  </b-col>
</template>

<script>
  import ComponentProvider from '@/components/mixins/ComponentProvider'

  export default {
    name: "EsCol",

    mixins: [ComponentProvider]
  }
</script>
