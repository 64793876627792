<template>
  <Template :section="section">
    <template slot="title">
      <pub-content-editable
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="button">
      <pub-es-button :customs="section.options.content.button"></pub-es-button>
    </template>

    <template slot="image">
      <image-fit
          :customs="section.options.content.image"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="cols(section.options.content.image.size, 'contain')"
          :position="section.options.content.image.position"
          :height="cols('50vh', '100px')"
      ></image-fit>
    </template>
  </Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from './Template';
import ImageFit from "../../../../utils/ImageFit";

export default {
  name: "HeroItem1",

  components: {ImageFit, Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
