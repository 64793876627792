import SectionProvider from '@/sections/SectionProvider';

export const name = 'Image Item 3'

export default class ImageItem3 extends SectionProvider {
  constructor(options = {}) {
    super('ImageItem3', options);

    this.name = name
    this.group = 'images'

    this.defaultOptions = {
      content: {
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/previews/images/placeholder-image-3.png'),
          src: null,
          size: 'cover',
          position: 'center'
        }
      },
      customize: {
        style: {
          background: null
        }
      }
    }
  }
}
