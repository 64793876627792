<template>
  <ImageItem5Template :section="section">
    <template slot="image">
      <img class="card-img-top" :src="section.options.content.image.src || section.options.content.image.placeholder"
           alt="Card image cap">
    </template>

    <template slot="title">
      <content-editable
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></content-editable>
    </template>

    <template slot="description">
      <content-editable
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></content-editable>
    </template>
  </ImageItem5Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from "../../../builder/utils/PubContentEditable";
import ImageItem5Template from '@pub/templates/default/sections/images/ImageItem5Template';

export default {
  name: "ImageItem3",

  components: {ImageItem5Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
