import SectionProvider from '@/sections/SectionProvider';

export const name = 'Blog Item 2'
export const group = 'blog'

export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '42px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '42px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>This is the First Blog</strong></p><p><strong>Headline Goes Here</strong></p>"
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          lineHeight: 1.7,
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          lineHeight: 1.7,
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam</p>"
    },
    date: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '14px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '14px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>November 10, 2020</p>"
    },
    image: {
      display: true,
      type: 'image-mod',
      size: 'cover',
      position: 'center',
      placeholder: require('@/assets/images/blog-peview-2.jpg'),
      src: null
    },
  },
  customize: {
    layouts: null,
    style: {
      container: {
        type: 'container-customs',
        container: 'container'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
        size: 'cover',
        opacity: 1
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 40,
          bottom: 40
        },
        lg: {
          top: 70,
          bottom: 70
        }
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/blog/main-2.jpg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('BlogItem2', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
