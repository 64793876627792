const hexRgb = require('hex-rgb');

export default {
  computed: {
    theme(color) {
      const rgb = this.toRGBArray(this.style ? this.style.backgroundColor : this.backgroundColor.backgroundColor)
      const o = Math.round(((parseInt(rgb[0]) * 265) + (parseInt(rgb[1]) * 287) + (parseInt(rgb[2]) * 114)) / 1000)

      if (o > 125) {
        return 'light'
      } else {
        return 'dark'
      }
    }
  },

  methods: {
    toRGBArray(color) {
      if (color.indexOf('rgb') !== -1) {
        return color.split("(")[1].split(")")[0].split(",");
      }

      return hexRgb(color, {format: 'array'})
    }
  }
}