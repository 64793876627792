import {mapState} from 'vuex'
import _ from "lodash";

export default {
  props: {
    componentName: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('editor', {
      allConfig: state => state.config,
      section: state => state.section,
      openedItem: state => state.openedItem,
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles,
    }),
    resolutionSync: {
      get() {
        return this.resolution
      },
      set(val) {
        this.$store.commit('editor/SET_RESOLUTION', val)
      }
    },
    getOpenedItemObj: {
      get() {
        if (typeof this.openedItem === 'object' && this.openedItem !== null) {
          return this.openedItem
        } else {
          return false
        }
      }
    }
  },
  methods: {
    sectionSettings(openedItem = null) {
      this.$store.dispatch('editor/sectionSettings', {section: this.section, openedItem})
    },
    labelCase(label) {
      return _.startCase(label)
    },
  }
}
