import SectionProvider from '@/sections/SectionProvider';
import EsRow from "../utils/EsRow";
import TestimonialItem4 from "./TestimonialItem4";
import HeadlineItem1 from "../headlines/HeadlineItem1";

export const name = 'TestimonialItem4Group'

export default class TestimonialItem4Group extends SectionProvider {
  constructor(options, items) {
    super('EsRow', options);

    this.name = name
    this.group = 'testimonials'

    this.defaultOptions = new EsRow({
      customize: {
        layouts: false,
        style: {
          background: {
            background: '#1F3B9C'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
            },
            lg: {
              top: 115,
              bottom: 115
            }
          }
        }
      }
    }, [
      new HeadlineItem1({
        content: {
          title: {
            resolutionStyle: {
              sm: {
                color: '#ffffff',
                fontSize: '42px',
                padding: {
                  top: 0,
                  bottom: 26,
                  x: 0
                }
              },
              lg: {
                color: '#ffffff'
              }
            },
            text: "<p>What they are saying…</p>"
          },
          subtitle: {
            display: false,
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 12,
            lg: 12
          },
          style: {
            background: {
              background: '#1F3B9C'
            },
            padding: {
              type: 'height-customs',
              sm: {
                top: 0,
                bottom: 0
              },
              lg: {
                top: 0,
                bottom: 40
              }
            }
          }
        }
      }).json(),
      new TestimonialItem4({
        content: {
          image: {
            placeholder: require('@/assets/images/testimonial-4-1.png')
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 6,
            lg: 6
          }
        }
      }).json(),
      new TestimonialItem4({
        content: {
          image: {
            placeholder: require('@/assets/images/testimonial-4-2.png')
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 6,
            lg: 6
          }
        }
      }).json(),
      new TestimonialItem4({
        content: {
          image: {
            placeholder: require('@/assets/images/testimonial-4-3.png')
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 6,
            lg: 6
          }
        }
      }).json(),
      new TestimonialItem4({
        content: {
          image: {
            placeholder: require('@/assets/images/testimonial-4-4.png')
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 6,
            lg: 6
          }
        }
      }).json(),
    ]).json().options
  }
}
