import SectionProvider from '@/sections/SectionProvider';

export const name = 'Button'
export const group = 'utils'
export const standalone = true
export const allowRow = true
export const defaultOptions = {
  content: {
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Click me',
      resolutionStyle: {
        lg: {
          size: 'lg',
          btnStyle: '',
          color: '#ffffff',
          backgroundColor: 'colored',
          shape: null,
          shadow: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          size: 'lg',
          btnStyle: '',
          color: '#ffffff',
          backgroundColor: 'colored',
          shape: null,
          shadow: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {}
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/row/main-3.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('EsButtonUtil', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
