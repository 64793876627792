<template>
  <div class="menubar mb-4">
    <label class="mb-2">Row Align</label>

    <b-btn-group class="d-block">
      <b-btn
          variant="menubar-button"
          @click="config.align = 'start'"
      >
        <icon v-if="config.align === 'start'" icon="align-top-active.svg"></icon>
        <icon v-else icon="align-top.svg"></icon>
      </b-btn>
      <b-btn
          variant="menubar-button"
          @click="config.align = 'center'"
      >
        <icon v-if="config.align === 'center'" icon="align-center-active.svg"></icon>
        <icon v-else icon="align-center.svg"></icon>
      </b-btn>
      <b-btn
          variant="menubar-button"
          @click="config.align = 'end'"
      >
        <icon v-if="config.align === 'end'" icon="align-bottom-active.svg"></icon>
        <icon v-else icon="align-bottom.svg"></icon>
      </b-btn>
    </b-btn-group>
  </div>
</template>

<script>
  import ControlMixin from '../../mixins/ControlMixin';

  export default {
    name: "AlignRow",

    mixins: [ControlMixin]
  }
</script>

<style lang="scss">
.menubar {
  .btn-menubar-button {
    border: 1px solid #E2E5EC;
    background-color: #fff;
    box-shadow: none !important;

    i {
      color: #aeb1b4;
    }

    &.is-active i {
      color: #44474A;
    }
  }
}
</style>
