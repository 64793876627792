import SectionProvider from '@/sections/SectionProvider';

export const name = 'Footer'
export const group = 'Footer'

export const defaultOptions = {
  content: {
    logo: {
      display: true,
      type: 'logo-mod',
      image: {
        src: require('@/assets/svg/previews/footer/placeholder-1.svg')
      },
      fontFamily: 'Global Style',
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#0C226C',
      text: 'FourPercent'
    },
    copyright: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '12px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '12px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p>© Copyright. Your Site. All Rights Reserved</p>"
    },
    labelMenu1: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p><strong>Quick Links</strong></p>"
    },
    menu1: {
      display: true,
      type: 'menu-links-mod',
      style: {
        fontSize: '15px',
        color: 'adaptive',
        fontWeight: 'bold',
        lineHeight: 2,
      },
      links: [
        {
          href: '#',
          text: 'About',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Work with Me',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Resources',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Blog',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Contact',
          customLink: false,
          blank: false
        }
      ]
    },
    labelMenu2: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p><strong>Products</strong></p>"
    },
    menu2: {
      display: true,
      type: 'menu-links-mod',
      style: {
        fontSize: '15px',
        color: 'adaptive',
        fontWeight: 'bold',
        lineHeight: 2,
      },
      links: [
        {
          href: '#',
          text: 'Products',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Videos',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Live Streaming',
          customLink: false,
          blank: false
        }
      ]
    },
    labelDescriotion: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 14,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 12,
            x: 0
          }
        }
      },
      "text": "<p><strong>Our Location</strong></p>"
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '15px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          lineHeight: 0.7,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '15px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          lineHeight: 0.7,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p>Apartment Name</p><p>Street Address</p><p>City, Zip Code</p>"
    },
    horizontalLine: {
      type: 'horizontal-line-mod',
      display: true,
      color: 'adaptive',
      width: '1px',
      pilled: false
    },
    socialLinks: {
      display: true,
      type: 'social-links-mod',
      style: {
        color: 'adaptive',
        fontSize: '14px',
      },
      links: [
        {
          shape: null,
          color: '#fff',
          href: '#',
          icon: 'fa fa-facebook',
          background: 'rgba(255, 255, 255, 0)'
        },
        {
          shape: null,
          color: '#fff',
          href: '#',
          icon: 'fa fa-google',
          background: 'rgba(255, 255, 255, 0)'
        },
        {
          shape: null,
          color: '#fff',
          href: '#',
          icon: 'fa fa-twitter',
          background: 'rgba(255, 255, 255, 0)'
        }
      ]
    },
    menu3: {
      display: true,
      type: 'menu-links-mod',
      style: {
        fontSize: '14px',
        color: 'adaptive',
        fontWeight: 'bold',
        lineHeight: 0
      },
      links: [
        {
          href: '#',
          text: 'Privacy & policy',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Terms & conditions',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Careers',
          customLink: false,
          blank: false
        }
      ]
    },
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: 'colored'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
size: 'cover',
        opacity: 1
      },
      shadow: null,
      opacity: null,
      padding: {
        type: 'height-customs',
        sm: {
          top: 40,
          bottom: 40
        },
        lg: {
          top: 80,
          bottom: 120
        }
      }
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/footer/main-1.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Footer2', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
