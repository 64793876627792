<template>
  <div class="video-item-1" :style="{...opacity, ...padding}">
    <div class="video rounded" :style="{...shadow}">
      <slot name="video"></slot>
    </div>
    <slot name="title"></slot>
    <slot v-if="visability('subtitle')" name="subtitle"></slot>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "VideoItems1Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.video-item-1 {
  .video {
    margin-bottom: 15px;
  }
}
</style>