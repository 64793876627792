<template>
  <highliter class="time-section" :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings('time')">
   <Template :section="section">
      <template v-slot:time="{shadow, backgroundColor, color}">
        <vac ref="vac" :end-time="section.options.content.time.time">
          <template
              v-slot:process="{ timeObj }">
            <div class="timer d-flex align-items-center justify-content-center timer-block" :style="{...shadow, ...backgroundColor}">
             <template v-if="!style.dontShow.value.includes('Days')">
               <div class="timer-item">
                 <span class="time" data-color>{{timeObj.d < 10 ? '0' + timeObj.d : timeObj.d}}</span>
                 <span class="subtitle" :style="{...color}">days</span>
               </div>
               <div class="timer-item">
                 <span class="time-divider">:</span>
               </div>
             </template>
              <template v-if="!style.dontShow.value.includes('Hours')">
                <div class="timer-item">
                  <span class="time" data-color>{{timeObj.h}}</span>
                  <span class="subtitle" :style="{...color}">hours</span>
                </div>
                <div class="timer-item">
                  <span class="time-divider">:</span>
                </div>
              </template>
              <template v-if="!style.dontShow.value.includes('Minutes')">
                <div class="timer-item">
                  <span class="time" data-color>{{timeObj.m}}</span>
                  <span class="subtitle" :style="{...color}">minutes</span>
                </div>
                <div class="timer-item">
                  <span class="time-divider">:</span>
                </div>
              </template>
              <div class="timer-item" v-if="!style.dontShow.value.includes('Seconds')">
                <span class="time" data-color>{{timeObj.s}}</span>
                <span class="subtitle" :style="{...color}">seconds</span>
              </div>
            </div>
          </template>
        </vac>
      </template>
   </Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from './Template';

export default {
  name: "TimeItem1",

  components: {Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created() {
    VEvent.listen('timer-update', () => {
      setTimeout(() => {
        this.$refs.vac.startCountdown(true)
      })
    })
  }
}
</script>
