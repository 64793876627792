<template>
  <div v-if="sections && section.options.customize.layouts">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">{{labelCase(componentName)}}</p>
    </div>

    <div class="d-flex justify-content-between flex-wrap">
      <div v-for="section in sections"
           :style="{backgroundImage: `url(${section.previews.left})`}"
           class="section-image"
           :class="{'active': section.name === config.name}"
           @click="section.name !== config.name ? $store.dispatch('sections/changeLayout', {uid: config.uid, oldConfig: config, layout: section.section}) : null"
      >
      </div>
    </div>
  </div>
  <div v-else>No layouts.</div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import allSections from '@/sections';

  export default {
    name: "LayoutMode",

    mixins: [ModMixin],

    computed: {
      sections() {
        return allSections[this.config.group]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .section-image {
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.26));
    width: 130px;
    height: 76px;
    background-position: center;
    border-radius: 3px;
    margin-bottom: 30px;

    &.active {
      box-shadow: 0 0 0 3px #7CACFA;
    }
  }
</style>
