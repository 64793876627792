import SectionProvider from '@/sections/SectionProvider';
import moment from "moment";

export const name = 'Time Item 1'
export const group = 'time'
export const defaultOptions = {
  content: {
    time: {
      type: 'time-mod',
      display: true,
      time: moment(moment().format('YYYY-MM-DD') + ' ' + '23:59').valueOf()
    }
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: '#ffffff',
      },
      backgroundCard: {
        background: '#f5f5f5',
        type: 'background-customs',
        label: 'Timer Card Background'
      },
      color: {
        type: 'color-customs',
        color: '#0C226C'
      },
      colorSubtitle: {
        color: '#aeb1b4',
        type: 'color-customs',
        label: 'Subtitle color'
      },
      shadow: {
        shadow: "0px 5px 22px rgba(177, 177, 177, 0.5)"
      },
      dontShow: {
        type: 'checklist-customs',
        label: 'Don`t Show',
        value: [],
        items: ['Days', 'Hours', 'Minutes', 'Seconds']
      },
      container: {
        type: 'container-customs',
        container: null
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
        size: 'cover',
        opacity: 1
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 60,
          bottom: 60
        },
        lg: {
          top: 60,
          bottom: 60
        }
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/time/main-1.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('TimeItem1', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
