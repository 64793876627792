<template>
 <div :style="{...padding, ...backgroundColor, ...opacity, ...shadow}">
   <div class="blog-2-card" :class="container">
     <b-row>
       <b-col :class="cols('', 'mb-4')" :cols="cols(5, 12)">
         <slot name="image"></slot>
       </b-col>

       <b-col :cols="cols(7, 12)">
         <slot name="title"></slot>

         <div class="my-2"></div>

         <slot name="date"></slot>

         <div class="my-2"></div>

         <slot name="description"></slot>
       </b-col>
     </b-row>
   </div>
 </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">

</style>