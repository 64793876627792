import SectionProvider from '@/sections/SectionProvider';

export const name = 'Navigation 2'

export default class Navbar2 extends SectionProvider {
  constructor(options = {}) {
    super('Navbar2', options);

    this.name = 'Navigation 2'
    this.group = 'navbar'

    this.defaultOptions = {
      content: {
        logo: {
          type: 'logo-mod',
          display: true,
          fontFamily: 'Global Style',
          fontWeight: 'bold',
          fontSize: '24px',
          color: '#0C226C',
          text: 'FourPercent'
        },
        menuItems: {
          type: 'menu-items-mod',
          display: true,
          color: '#0C226C',
          uppercase: false,
          align: 'left'
        },
        button: {
          type: 'button-mod',
          display: true,
          hover: false,
          text: 'Book Now',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'sm',
              btnStyle: 'outlined',
              shape: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'sm',
              btnStyle: 'outlined',
              shape: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        display: true,
        layouts: true
      }
    }
  }
}
