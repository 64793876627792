<template>
  <div class="custom-breadcrumb">
    <slot></slot>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "Carousel1Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
.custom-breadcrumb {
 .breadcrumb {
   background: linear-gradient(180deg, #FFFFFF 0%, #EDEDED 100%);
   border-radius: 0;
   padding-top: 0;
   padding-bottom: 0;
   overflow: hidden;
   padding: 0;
   box-shadow: 0 2px 5px #bebebe;

   .breadcrumb-item {
     padding: 0;
     margin: 0;
     a,
     span {
       font-weight: bold;
       float: left;
       color: #757575;
       text-decoration: none;
       padding: 20px 29px 20px 57px;
       background: #f4f4f4;
       position: relative;
       display: block;
       float: left;
       font-size: 14px;

       &:after {
         content: " ";
         display: block;
         width: 0;
         height: 0;
         border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
         border-bottom: 50px solid transparent;
         border-left: 30px solid #f4f4f4;
         position: absolute;
         top: 50%;
         margin-top: -50px;
         left: 100%;
         z-index: 2;
         filter: drop-shadow(3px 0 1px rgba(0, 0, 0, 0.1987))
       }

       &:before {
         content: " ";
         display: block;
         width: 0;
         height: 0;
         border-top: 50px solid transparent;
         border-bottom: 50px solid transparent;
         border-left: 30px solid white;
         position: absolute;
         top: 50%;
         margin-top: -50px;
         margin-left: 1px;
         left: 100%;
         z-index: 1;
       }
     }

     span {
       color: #000;
       background: #e7e7e7;

       &:after {
         border-left-color: #e7e7e7;
       }
     }
   }
 }
}
</style>
