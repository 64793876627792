<template>
  <autoresponder-mod :config="config" :component-name="componentName" v-if="section.group === 'newsLetter'" />
  <email-form-mod :config="config" :component-name="componentName" v-else />
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin';
import AutoresponderMod from "../controls/AutoresponderMod";
import EmailFormMod from "../controls/EmailFormMod";

export default {
  name: "FormMod",

  components: {EmailFormMod, AutoresponderMod},

  mixins: [ModMixin],
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.move {
  cursor: move;
}

.form-mod-inner {
  position: relative;
  padding-bottom: 15px;

  .form-mod-inner-index {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 113%;
    height: 100%;
    background: #F5F5F5;
  }
}
</style>
