import SectionProvider from '@/sections/SectionProvider';

export const name = 'Action Item 4'

export default class ActionItem4 extends SectionProvider {
  constructor(options = {}) {
    super('ActionItem4', options);

    this.name = name
    this.group = 'actions'

    this.defaultOptions = {
      content: {
        leading: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '16px',
              fontFamily: 'Global Style',
              color: '#4F68BA',
              align: 'center',
              padding: {
                top: 0,
                bottom: 15,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#4F68BA',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: "<b>GET EARLY BIRD ACCESS FOR LESS.</b>"
        },
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '32px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
              padding: {
                top: 0,
                bottom: 15,
                x: 0
              }
            },
            lg: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "Order Your Product Right Here"}]
            }]
          },
        },
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lilineHeight: 1.3,
              color: '#6B747C',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6B747C',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "text": "Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem."}]
            }]
          },
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Order Today',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        layouts: null,
        style: {
          background: {
            background: '#ffffff'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
            },
            lg: {
              top: 115,
              bottom: 115
            }
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
        }
      }
    }
  }
}
