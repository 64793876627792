<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <NewsLetterItem3Template :section="section">
      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="subtitle">
        <highliter v-show="visability('subtitle')" @click.stop.native="sectionSettings('subtitle')">
          <content-editable
              :uid="tiptapUID('subtitle')"
              :customs="section.options.content.subtitle"
              v-model="section.options.content.subtitle.text"></content-editable>
        </highliter>
      </template>

      <template slot="form">
        <highliter @click.stop.native="sectionSettings('form')">
          <es-form :customs="section.options.content.form" size="lg">
            <template :slot="cols(section.options.content.form.btnPosition, 'submit')">
              <highliter v-show="visability('button')"
                         autoWidth
                         @click.stop.native="sectionSettings('button')">
                <es-button :customs="section.options.content.button"></es-button>
              </highliter>
            </template>
          </es-form>
        </highliter>
      </template>
   </NewsLetterItem3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import NewsLetterItem3Template from '@pub/templates/default/sections/newsLetter/NewsLetterItem3Template';
import EsForm from "../../utils/EsForm";

export default {
  name: "NewsLetterItem1",

  components: {EsForm, NewsLetterItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
