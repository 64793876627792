<template>
  <div :style="{...backgroundColor, ...opacity, ...padding, ...shadow, ...backgroundImage}" v-if="visability('group')">
    <div :class="container">
      <div class="video-item-3">
        <b-row>
          <slot></slot>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "VideoItems3Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.video-item-3 {
}
</style>