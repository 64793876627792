import SectionProvider from '@/sections/SectionProvider';

export const name = 'Text And Image Item 1'

export default class TextAndImageItem1 extends SectionProvider {
  constructor(options = {}) {
    super('TextAndImageItem1', options);

    this.name = name
    this.group = 'text-and-image'

    this.defaultOptions = {
      content: {
        text: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'left',
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'left',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit."}]}]},
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/previews/textAndImage/placeholder-1.png'),
          src: '',
          size: 'cover',
          position: 'center',
        },
      },
      customize: {
       style: {
         background: null,
         padding: {
           type: 'height-customs',
           sm: {
             top: 20,
             bottom: 20
           },
           lg: {
             top: 115,
             bottom: 115
           }
         },
       }
      }
    }
  }
}
