import SectionProvider from '@/sections/SectionProvider';

export const name = 'Hero Image'
export const group = 'hero'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '38px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 40,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '54px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<strong>Reach New Heights With Our Newest App</strong>",
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: '#6D6D6D',
          align: 'left',
          fontFamily: 'Global Style',
          lineHeight: 1.3,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '21px',
          color: '#6D6D6D',
          align: 'left',
          fontFamily: 'Global Style',
          lineHeight: 1.3,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.  sollicitudin </p>",
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Join Now',
      resolutionStyle: {
        lg: {
          size: 'lg',
          btnStyle: '',
          color: '#fff',
          backgroundColor: 'colored',
          shape: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          size: 'lg',
          btnStyle: '',
          color: '#fff',
          backgroundColor: 'colored',
          shape: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    image: {
      display: true,
      type: 'image-mod',
      size: 'contain',
      position: 'top',
      placeholder: require('@/assets/images/placeholder-hero-3.png'),
      src: null
    },
  },
  customize: {
    layouts: null,
    style: {
      shadow: null,
      background: {
        background: '#ffffff'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'top',
        size: 'contain',
        opacity: 1
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 70,
          bottom: 70
        },
        lg: {
          top: 115,
          bottom: 115
        }
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/hero/main-3.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('HeroItem3', options);

    this.name = name
    this.group = 'hero'

    this.defaultOptions = defaultOptions
  }
}
