<template>
  <div :style="{...opacity, ...padding, ...backgroundColor}">
    <div :class="container">
      <b-row no-gutters>
        <b-col :order="aligment('left') ? 0 : 1" :cols="cols(6, 12)">
          <template v-if="visability('image')">
            <slot name="image"></slot>
          </template>
        </b-col>

        <b-col :order="aligment('left') ? 1 : 0" :cols="cols(6, 12)" :style="{...backgroundImage, ...shadow}">
          <div class="d-flex align-items-center h-100">
            <div class="featured-text">
              <template v-if="visability('title')">
                <slot name="title"></slot>
              </template>

              <template v-if="visability('description')">
                <slot name="description"></slot>
              </template>

              <template v-if="visability('button')">
                <slot name="button"></slot>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import CustomStylesMixin from '../../../../../modules/builder/src/components/mixins/CustomStylesMixin';

  export default {
    name: "FeaturedItems1Template",
    mixins: [TemplateMixin]
  }
</script>
