<template>
  <TestimonialItem4Template :section="section">
    <template slot="image" v-if="visability('image')">
      <b-img width="206" height="169" :src="section.options.content.image.src || section.options.content.image.placeholder"></b-img>
    </template>

    <template slot="title" v-if="visability('name')">
        <pub-content-editable
            :customs="section.options.content.name"
            v-model="section.options.content.name.text"></pub-content-editable>
    </template>

    <template slot="subtitle" v-if="visability('subname')">
        <pub-content-editable
            :customs="section.options.content.subname"
            v-model="section.options.content.subname.text"></pub-content-editable>
    </template>

    <template slot="text" v-if="visability('text')">
        <pub-content-editable
            :customs="section.options.content.text"
            v-model="section.options.content.text.text"></pub-content-editable>
    </template>
  </TestimonialItem4Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import TestimonialItem4Template from '@pub/templates/default/sections/testimonials/TestimonialItem4Template';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';

  export default {
    name: "FeaturedItems1",

    components: {PubContentEditable, TestimonialItem4Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
