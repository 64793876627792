<template>
  <div class="position-relative h-100 custom-menu overflow-hidden">
    <b-card-header header-class="editor-toolbar-card-header">
      <h5 class="editor-toolbar-title mb-0">Sections</h5>
    </b-card-header>

    <div class="overflow-auto scoller-sections">
      <b-card no-body
              @click="$store.dispatch('editor/sectionSettings', {section: navbar.section})"
              class="border-0">
        <section-item-hover v-if="navbar.section">
          <icon class="chevron mr-3 d-block" icon="chevron.svg"/>
          <div block>Navigation</div>
          <!--        {{navbar.section.options.customize.display}}-->

          <template slot="menu">
            <b-dropdown>
              <template slot="button-content">
                <icon class="dot-menu cursor-pinter" icon="dot menu-primary.svg"></icon>
              </template>

              <b-dropdown-item class="d-flex align-items-center" @click.native.stop="navbar.section.options.customize.display = !navbar.section.options.customize.display">
                <template v-if="navbar.section.options.customize.display">
                  <icon class="mr-2" icon="eye.svg"></icon> Hide
                </template>
                <template v-else>
                  <icon class="mr-2" icon="eye-disable.svg"></icon> Show
                </template>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </section-item-hover>
      </b-card>
      <draggable v-model="sections" handle=".move">
        <transition-group name="shufle">
          <b-card v-for="section in sections"
                  :key="section.uid"
                  no-body
                  @click="$store.dispatch('editor/sectionSettings', {section: section})"
                  class="border-0">
            <section-item-hover>
              <icon class="chevron mr-3" icon="chevron.svg"/>
              <icon class="move mr-3" icon="move.svg"/>
              <div block> {{label(section.name)}}</div>

              <template slot="menu">
                <b-dropdown>
                  <template slot="button-content">
                    <icon class="dot-menu cursor-pinter" icon="dot menu-primary.svg"></icon>
                  </template>

                  <b-dropdown-item class="d-flex align-items-center" @click.native.stop="$store.dispatch('sections/removeSection', section.uid)">
                    <icon class="mr-2" icon="trash.svg"></icon> Delete
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </section-item-hover>
          </b-card>
        </transition-group>
      </draggable>
      <b-card no-body
              v-if="footer"
              @click="$store.dispatch('editor/sectionSettings', {section: footer})"
              class="border-0">
        <section-item-hover>
          <icon class="chevron mr-3 d-block" icon="chevron.svg"/>
          <div block>Footer</div>

          <template slot="menu">
            <b-dropdown>
              <template slot="button-content">
                <icon class="dot-menu cursor-pinter" icon="dot menu-primary.svg"></icon>
              </template>

              <b-dropdown-item class="d-flex align-items-center" @click.native.stop="footer.options.customize.display = !footer.options.customize.display">
                <template v-if="footer.options.customize.display">
                  <icon class="mr-2" icon="eye.svg"></icon> Hide
                </template>
                <template v-else>
                  <icon class="mr-2" icon="eye-disable.svg"></icon> Show
                </template>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </section-item-hover>
      </b-card>
    </div>

    <div class="bg-white" style="position: absolute; width: 100%; bottom: 68px;">
      <div class="editor-toolbar-btn-spacing">
        <b-btn variant="editor-soft-primary" size="lg" block @click="newSectionModal">
          Add New Section
          <icon class="ml-1" icon="plus.svg"/>
        </b-btn>
      </div>
    </div>

    <b-card-footer class="editor-toolbar-footer border p-0">
      <b-row no-gutters>
        <b-col cols="7" class="text-uppercase border-right text-center"
               @click="$store.commit('editor/SET_PAGE', 'global-styles')">
          <icon class="mr-2" icon="bucket.svg"/>
          Global Style
        </b-col>
        <b-col cols="5" class="text-uppercase"
               @click="$store.commit('editor/SET_PAGE', 'page-settings')">
          <icon class="mr-2" icon="levels.svg"/>
          Settings
        </b-col>
      </b-row>
    </b-card-footer>
  </div>
</template>

<script>
  import FeaturedItems1 from '@/sections/featured/FeaturedItems1'
  import {mapGetters, mapState} from 'vuex'
  import Icon from '@/components/editor/utils/Icon';
  import draggable from 'vuedraggable'
  import SectionItemHover from '../utils/SectionItemHover';
  import _ from "lodash";

  export default {
    name: "Sections",
    components: {SectionItemHover, Icon, draggable},
    computed: {
      ...mapGetters('sections', [
        'getSections'
      ]),
      ...mapState('sections', {
        navbar: state => state.navbar,
        footer: state => state.footer
      }),
      sections: {
        get() {
          return this.getSections
        },
        set(val) {
          this.$store.dispatch('sections/setSections', val)
        }
      }
    },

    methods: {
      label(label) {
        return _.startCase(label)
      },
      newSectionModal() {
        // this.$store.dispatch('sections/addSection', new FeaturedItems1().json())
        this.$store.commit('editor/SET_SECTION_MODAL', this.sections.length ? this.sections[this.sections.length - 1].uid : 'none')
      }
    }
  }
</script>

<style lang="scss">
  .custom-menu {
    .dropdown-toggle {
      &,
      &:active,
      &:focus {
        padding: 0;
        background: transparent !important;
        border: 0 !important;
        box-shadow: none !important;
        line-height: 0;
      }


      &:after {
        display: none;
      }
    }

    .dropdown-item {
      padding: 12px 17px;
    }
  }
</style>
