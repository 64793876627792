import {mergeDeep} from 'bootstrap-vue/esm/utils/object';
import deepmerge from "deepmerge";
import shortid from 'shortid'

export default class SectionProvider {
  constructor(component, options = {}) {
    this.component = component
    this.options = options
    this.defaultOptions = null
    this.name = null
    this.group = null
    this.stateSafly = true
    this.customize = {
      customize: {
        layouts: null,
        style: {
          background: {
            type: 'background-customs',
            background: '#ffffff'
          },
          shadow: {
            type: 'shadow-customs',
            shadow: null
          },
          opacity: {
            type: 'opacity-customs',
            opacity: 1
          }
        }
      }
    }
  }

  json() {
    this.defaultOptions = deepmerge(this.customize, this.defaultOptions)

    return {
      uid: shortid.generate(),
      name: this.name,
      component: this.component,
      group: this.group,
      options: mergeDeep(this.defaultOptions, this.options)
    }
  }
}
