<template>
  <section class="hero-1" :style="{...backgroundImage, ...padding, ...backgroundColor}">

    <div class="container">
      <b-row class="align-items-center">
        <b-col :cols="cols(6, 12)" :offset="aligment('left') ? 0 : 1" :order="aligment('left') ? 1 : 2">
          <div class="hero-1_title"  v-if="visability('title')">
            <slot name="title"></slot>
          </div>

          <div class="hero-1_description" v-if="visability('description')">
            <slot name="description"></slot>
          </div>

          <div class="d-flex align-items-center flex-wrap btns">
            <div v-if="visability('button')" :class="cols('', 'mb-3')">
              <slot name="button"></slot>
            </div>

            <div v-if="visability('button2')">
              <slot name="button2"></slot>
            </div>
          </div>
        </b-col>

        <b-col :cols="cols(5, 12)" :offset="aligment('left') ? 1 : 0" :order="aligment('left') ? '2 mb-4' : 1">
          <div class="hero-1_image" v-if="visability('image')">
            <slot name="image"></slot>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "HeroItem1Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.resolution-sm {
  .hero-1 {
    .btn,
    .btns {
      display: block !important;
      width: 100% !important;
    }
  }
}

.hero-1 {
  background: #3D76C4;

  &_title {
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
    }
  }

  &_description {
    margin-bottom: 30px;
  }
}
</style>