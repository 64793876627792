import Navbar1 from '@/sections/navigations/Navbar1';
import FeaturedItems1 from '@/sections/featured/FeaturedItems1';
import Vue from 'vue'

const state = () => ({
  blog: []
})

const getters = {}

const actions = {
  async getPosts({state, rootState, dispatch}, payload) {
    await axios.get(`api/projects/${rootState.projects.project.id}/blog`)
      .then(({data}) => {
        state.blog = data
      })
  }
}

const mutations = {
  ADD_POST(state, payload) {
    if (payload.status !== 'published') return false
    state.blog.data.unshift(payload.data)
  },
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
