<template>
  <div class="contact-form-2" :class="[container, theme]"
       :style="{...padding, ...opacity, ...backgroundColor, ...shadow, ...backgroundImage}">

    <slot name="title"></slot>
    <div :class="cols('my-5', 'my-4')"></div>

    <b-row>
      <b-col :cols="cols(9, 12)">
        <div class="form-rounded">
          <slot name="form"></slot>
        </div>
      </b-col>

      <b-col :cols="cols(3, 12)">
        <div :class="cols('mb-5', 'mb-3')">
          <div class="content-editable-margin-off" v-show="visability('labelSocialLinks')">
            <slot name="labelContacts"></slot>
          </div>

          <template v-show="visability('info')">
            <div>
              <slot name="info"></slot>
            </div>
          </template>
        </div>

        <div class="horizontal-devider" v-if="cols(false, true)"></div>

        <div>
          <div class="content-editable-margin-off" v-show="visability('labelSocialLinks')">
            <slot name="labelSocialLinks"></slot>
          </div>

          <template v-show="visability('socialLinks')">
            <slot name="social"></slot>
          </template>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";
import ThemeMixin from "../../../../mixins/ThemeMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin, ThemeMixin]
}
</script>

<style lang="scss">
.contact-form-2 {
  .horizontal-devider {
    position: static;
    height: 1px;
    width: 100%;
    background: #E3E6E9;
    margin-bottom: 20px;
    margin-top: 10px;
  }


  &.light {
    a,
    .editor__content,
    .pub__content {
      color: #0C226C;
    }
  }

  &.dark {
    a,
    .editor__content,
    .pub__content {
      color: #fff;
    }
  }
}

.resolution-sm {
  .contact-form-2 {
    .btn {
      display: block !important;
      width: 100% !important;
    }
  }
}
</style>