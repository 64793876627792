<template>
  <div class="pr-3">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <label>Padding</label>
      <b-form-radio-group v-model="position" class="resolution-style-tabs" buttons-variant="light" value-field="key" text-field="text" size="sm" buttons :options="[{text: 'TOP', key: 'top'}, {text: 'BOTTOM', key: 'bottom'}, {text: 'LEFT / RIGHT', key: 'x'}]"></b-form-radio-group>
    </div>

    <b-row no-gutters class="align-items-center">
      <b-col cols="9" class="mr-2">
        <b-input type="range" v-model="config.resolutionStyle[resolution].padding[position]" min="0" max="100"></b-input>
      </b-col>
      <b-col>
        <b-input min="0" type="number" step="1" v-model="config.resolutionStyle[resolution].padding[position]" size="sm"></b-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import ControlMixin from "../../mixins/ControlMixin";

  export default {
    name: "Padding",

    mixins: [ControlMixin],

    data() {
      return {
        position: 'top'
      }
    }
  }
</script>
