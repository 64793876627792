<template>
  <div :style="{...backgroundImage, ...padding}">
    <div :class="container">
      <b-row no-gutters :style="{...opacity}">
        <b-col :order="aligment('left') ? 0 : 1" :cols="cols(6, 12)" class="image">
          <template v-if="visability('image')">
            <slot name="image"></slot>
          </template>
        </b-col>

        <b-col :order="aligment('left') ? 1 : 0" :cols="cols(6, 12)" :style="{...backgroundColor, ...shadow}">
          <div class="d-flex align-items-center h-100">
            <div class="featured-text position-relative">
              <div class="title" v-if="visability('title')">
                <slot name="title"></slot>
              </div>
              <div class="d-flex" v-if="visability('horizontalLine')">
                <div class="title-divider" :style="{height: section.options.content.horizontalLine.width, background: section.options.content.horizontalLine.color, borderRadius: section.options.content.horizontalLine.pilled ? '25rem' : 0}"></div>
              </div>
              <template v-if="visability('description')">
                <slot name="description"></slot>
              </template>
              <div class="my-4"></div>
              <div class="w-100 text-center" v-if="visability('button')">
                <slot name="button"></slot>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "FeaturedItems1Template",
    mixins: [TemplateMixin]
  }
</script>

<style scoped lang="scss">
  .title-divider {
    position: absolute;
    width: 40%;
    left: 50%;
    transform: translate(-50%, -25px);
  }

  .title {
    margin-bottom: 41px;
  }
</style>
