import {default as FeaturedItems1, name as FeaturedItems1Name} from './featured/FeaturedItems1';
import {default as FeaturedItems2, name as FeaturedItems2Name} from './featured/FeaturedItems2';
import {default as FeaturedItems3, name as FeaturedItems3Name} from './featured/FeaturedItems3';
import {default as FeaturedItems4, name as FeaturedItems4Name} from './featured/FeaturedItems4';
import {default as FeaturedItems5, name as FeaturedItems5Name} from './featured/FeaturedItems5';

import {default as ActionItem1, name as ActionItem1Name} from './actions/ActionItem1';
import {default as ActionItem2, name as ActionItem2Name} from './actions/ActionItem2';
import {default as ActionItem3, name as ActionItem3Name} from './actions/ActionItem3';
import {default as ActionItem4, name as ActionItem4Name} from './actions/ActionItem4';
import {default as ActionItem5, name as ActionItem5Name} from './actions/ActionItem5';

import {
  default as TestimonialItem1Group,
  name as TestimonialItem1GroupName
} from './testimonials/TestimonialItem1Group';
import {default as TestimonialItem3, name as TestimonialItem3Name} from './testimonials/TestimonialItem3';
import {default as TestimonialItem2, name as TestimonialItem2Name} from './testimonials/TestimonialItem2';
import {
  default as TestimonialItem3Group,
  name as TestimonialItem3GroupName
} from './testimonials/TestimonialItem3Group';
import {
  default as TestimonialItem4Group,
  name as TestimonialItem4GroupName
} from './testimonials/TestimonialItem4Group';
import {
  default as TestimonialItem4,
  name as TestimonialItem4Name
} from './testimonials/TestimonialItem4';

import {default as VideoItem1Group, name as VideoItem1GroupName} from "./videos/VideoItem1Group";
import {default as VideoItem2, name as VideoItem2Name} from "./videos/VideoItem2";
import {default as VideoItem3Group, name as VideoItem3GroupName} from "./videos/VideoItem3Group";
import {default as VideoItem4, name as VideoItem4Name} from "./videos/VideoItem4";
import {default as VideoItem5, name as VideoItem5Name} from "./videos/VideoItem5";

import {default as EsButton, name as EsButtonName} from "./utils/EsButton";

import {default as NewsLetterItem1, name as NewsLetterItem1Name} from "./newsLetter/NewsLetterItem1";
import {default as NewsLetterItem2, name as NewsLetterItem2Name} from "./newsLetter/NewsLetterItem2";
import {default as NewsLetterItem3, name as NewsLetterItem3Name} from "./newsLetter/NewsLetterItem3";
import {default as NewsLetterItem4, name as NewsLetterItem4Name} from "./newsLetter/NewsLetterItem4";
import {default as NewsLetterItem5, name as NewsLetterItem5Name} from "./newsLetter/NewsLetterItem5";

import {default as HeadLineItem1, name as HeadLineItem1Name} from "./headlines/HeadlineItem1";
import {default as HeadLineItem2, name as HeadLineItem2Name} from "./headlines/HeadlineItem2";
import {default as HeadLineItem3, name as HeadLineItem3Name} from "./headlines/HeadlineItem3";
import {default as HeadLineItem4, name as HeadLineItem4Name} from "./headlines/HeadlineItem4";

import {default as TextAndImageItem1, name as TextAndImageItem1Name} from './text-and-image/TextAndImageItem1Group';
import {default as TextAndImageItem2, name as TextAndImageItem2Name} from './text-and-image/TextAndImageItem2Group';
import {default as TextAndImageItem3, name as TextAndImageItem3Name} from './text-and-image/TextAndImageItem3Group';

import {default as ListItem1, name as ListItem1Name} from './list/ListItem1';
import {default as ListItem2, name as ListItem2Name} from './list/ListItem2';
import {default as ListItem3, name as ListItem3Name} from './list/ListItem3';

import {default as ImageItem1, name as ImageItem1Name} from './images/ImageItem1';
import {default as ImageItem2, name as ImageItem2Name} from './images/ImageItem2';
import {default as ImageItem3, name as ImageItem3Name} from './images/ImageItem3';
import {default as ImageItem4, name as ImageItem4Name} from './images/ImageItem4';
import {default as ImageItem5, name as ImageItem5Name} from './images/ImageItem5';
import {default as ImageItem6, name as ImageItem6Name} from './images/ImageItem6';

import {default as QuoteItem1, name as QuoteItem1Name} from './quotes/QuoteItem1';
import {default as QuoteItem2, name as QuoteItem2Name} from './quotes/QuoteItem2';
import {default as QuoteItem3, name as QuoteItem3Name} from './quotes/QuoteItem3';

import {default as EsRow, name as EsRowName} from './utils/EsRow';

import {default as VideoItem1, name as VideoItem1Name} from './videos/VideoItem1';

import {default as CollapseItem1, name as CollapseItem1Name} from './collapse/CollapseItems1';

import {default as HeroItem1, name as HeroItem1Name} from './hero/HeroItem1';
import deepmerge from "deepmerge";

const sections = require.context('@/components/builder/sections', true, /Section\.js/)

let sec = {}

sections.keys().forEach(fileName => {
  if (!sections(fileName).group) return false

  !sec.hasOwnProperty(sections(fileName).group) ? sec[sections(fileName).group] = [] : null

  sec[sections(fileName).group].push({
    ...sections(fileName),
    section: sections(fileName).default
  })
})

export default deepmerge(sec, {
  featured: [
    {
      previews: {
        left: require('@/assets/svg/previews/featured-items-1-left.png'),
        right: require('@/assets/svg/previews/featured-items-1-left.png'),
        main: require('@/assets/svg/previews/features/main-1.svg')
      },
      name: HeroItem1,
      section: FeaturedItems1
    },
    {
      previews: {
        left: require('@/assets/svg/previews/featured-items-2-left.svg'),
        right: require('@/assets/svg/previews/featured-items-2-left.svg'),
        main: require('@/assets/svg/previews/feature-items-2-main.svg')
      },
      name: FeaturedItems2Name,
      section: FeaturedItems2
    },
    {
      previews: {
        left: require('@/assets/svg/previews/featured-items-3-left.svg'),
        right: require('@/assets/svg/previews/featured-items-3-left.svg'),
        main: require('@/assets/svg/previews/feature-items-3-main.svg')
      },
      name: FeaturedItems3Name,
      section: FeaturedItems3
    },
    {
      previews: {
        left: require('@/assets/svg/previews/featured-items-4-left.svg'),
        right: require('@/assets/svg/previews/featured-items-4-left.svg'),
        main: require('@/assets/svg/previews/features/main-4.svg')
      },
      name: FeaturedItems4Name,
      section: FeaturedItems4
    },
    {
      previews: {
        left: require('@/assets/svg/previews/featured-items-5-left.svg'),
        right: require('@/assets/svg/previews/featured-items-5-left.svg'),
        main: require('@/assets/svg/previews/features/main-5.png')
      },
      name: FeaturedItems5Name,
      section: FeaturedItems5
    }
  ],
  actions: [
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/actions/main-1.png'),
      },
      name: ActionItem1Name,
      section: ActionItem1
    },
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/actions/main-2.png'),
      },
      name: ActionItem2Name,
      section: ActionItem2
    },
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/actions/main-3.png'),
      },
      name: ActionItem3Name,
      section: ActionItem3
    },
    {
      allowRow: true,
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/actions/main-4.png'),
      },
      name: ActionItem4Name,
      section: ActionItem4
    },
  ],
  testimonials: [
    {
      allowRow: true,
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/testimonials/main-single-3.svg'),
      },
      name: TestimonialItem3Name,
      section: TestimonialItem3
    },
    {
      previews: {
        left: require('@/assets/svg/previews/testimonials/left-1.svg'),
        right: null,
        main: require('@/assets/svg/previews/testimonials/main-1.svg'),
      },
      name: TestimonialItem1GroupName,
      section: TestimonialItem1Group
    },
    {
      previews: {
        left: require('@/assets/svg/previews/testimonials/left-2.svg'),
        right: null,
        main: require('@/assets/svg/previews/testimonials/main-2.svg'),
      },
      name: TestimonialItem2Name,
      section: TestimonialItem2
    },
    {
      previews: {
        left: require('@/assets/svg/previews/testimonials/left-3.svg'),
        right: null,
        main: require('@/assets/svg/previews/testimonials/main-3.svg'),
      },
      name: TestimonialItem3GroupName,
      section: TestimonialItem3Group
    },
    {
      previews: {
        left: require('@/assets/svg/previews/testimonials/left-4.svg'),
        right: null,
        main: require('@/assets/svg/previews/testimonials/main-4.svg'),
      },
      name: TestimonialItem4GroupName,
      section: TestimonialItem4Group
    },
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/testimonials/main-4.png'),
      },
      allowRowOnly: true,
      name: TestimonialItem4,
      section: TestimonialItem4
    },
  ],
  videos: [
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/videos/main-1.png'),
      },
      name: VideoItem1GroupName,
      section: VideoItem1Group
    },
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/videos/main-2.svg'),
      },
      name: VideoItem2Name,
      section: VideoItem2
    },
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/videos/main-3.png'),
      },
      name: VideoItem3GroupName,
      section: VideoItem3Group
    },
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/videos/main-4.png'),
      },
      name: VideoItem4Name,
      section: VideoItem4
    },
    {
      previews: {
        left: null,
        right: null,
        main: require('@/assets/svg/previews/videos/main-5.svg'),
      },
      name: VideoItem5Name,
      section: VideoItem5
    }
  ],
  newsLetter: [
    // {
    //   previews: {
    //     left: require('@/assets/svg/previews/newsLetter/left-1.svg'),
    //     right: null,
    //     main: require('@/assets/svg/previews/newsLetter/main-1.svg'),
    //   },
    //   name: NewsLetterItem1Name,
    //   section: NewsLetterItem1
    // },
    // {
    //   previews: {
    //     left: require('@/assets/svg/previews/newsLetter/left-2.svg'),
    //     right: null,
    //     main: require('@/assets/svg/previews/newsLetter/main-2.svg'),
    //   },
    //   name: NewsLetterItem2Name,
    //   section: NewsLetterItem2
    // },
    {
      previews: {
        left: require('@/assets/svg/previews/newsLetter/left-3.svg'),
        right: null,
        main: require('@/assets/svg/previews/newsLetter/main-3.png'),
      },
      name: NewsLetterItem3Name,
      section: NewsLetterItem3
    },
    {
      previews: {
        left: require('@/assets/svg/previews/newsLetter/left-4.svg'),
        right: null,
        main: require('@/assets/svg/previews/newsLetter/main-4.svg'),
      },
      name: NewsLetterItem4Name,
      section: NewsLetterItem4
    },
    {
      previews: {
        left: require('@/assets/svg/previews/newsLetter/left-5.svg'),
        right: null,
        main: require('@/assets/svg/previews/newsLetter/main-5.svg'),
      },
      name: NewsLetterItem5Name,
      section: NewsLetterItem5
    }
  ],
  headlines: [
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/headlines/left-1.svg'),
        right: null,
        main: require('@/assets/svg/previews/headlines/main-1.svg'),
      },
      name: HeadLineItem1Name,
      section: HeadLineItem1
    },
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/headlines/left-2.svg'),
        right: null,
        main: require('@/assets/svg/previews/headlines/main-2.svg'),
      },
      name: HeadLineItem2Name,
      section: HeadLineItem2
    },
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/headlines/left-3.svg'),
        right: null,
        main: require('@/assets/svg/previews/headlines/main-3.svg'),
      },
      name: HeadLineItem3Name,
      section: HeadLineItem3
    },
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/headlines/left-4.svg'),
        right: null,
        main: require('@/assets/svg/previews/headlines/main-4.svg'),
      },
      name: HeadLineItem4Name,
      section: HeadLineItem4
    }
  ],
  textAndImage: [
    {
      previews: {
        left: require('@/assets/svg/previews/textAndImage/left-1.svg'),
        right: null,
        main: require('@/assets/svg/previews/textAndImage/main-1.svg'),
      },
      name: TextAndImageItem1Name,
      section: TextAndImageItem1
    },
    {
      previews: {
        left: require('@/assets/svg/previews/textAndImage/left-2.svg'),
        right: null,
        main: require('@/assets/svg/previews/textAndImage/main-2.svg'),
      },
      name: TextAndImageItem2Name,
      section: TextAndImageItem2
    },
    {
      previews: {
        left: require('@/assets/svg/previews/textAndImage/left-3.svg'),
        right: null,
        main: require('@/assets/svg/previews/textAndImage/main-3.svg'),
      },
      name: TextAndImageItem3Name,
      section: TextAndImageItem3
    }
  ],
  list: [
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/list/left-1.svg'),
        right: null,
        main: require('@/assets/svg/previews/list/main-1.svg'),
      },
      name: ListItem1Name,
      section: ListItem1
    },
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/list/left-2.svg'),
        right: null,
        main: require('@/assets/svg/previews/list/main-2.svg'),
      },
      name: ListItem2Name,
      section: ListItem2
    },
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/list/left-3.svg'),
        right: null,
        main: require('@/assets/svg/previews/list/main-3.svg'),
      },
      name: ListItem3Name,
      section: ListItem3
    },
  ],
  // images: [
  //   {
  //     allowRow: true,
  //     previews: {
  //       left: require('@/assets/svg/previews/images/left-1.svg'),
  //       right: null,
  //       main: require('@/assets/svg/previews/images/main-1.svg'),
  //     },
  //     name: ImageItem1Name,
  //     section: ImageItem1
  //   },
  //   {
  //     allowRow: true,
  //     previews: {
  //       left: require('@/assets/svg/previews/images/left-2.svg'),
  //       right: null,
  //       main: require('@/assets/svg/previews/images/main-2.svg'),
  //     },
  //     name: ImageItem2Name,
  //     section: ImageItem2
  //   },
  //   {
  //     allowRow: true,
  //     previews: {
  //       left: require('@/assets/svg/previews/images/left-3.svg'),
  //       right: null,
  //       main: require('@/assets/svg/previews/images/main-3.svg'),
  //     },
  //     name: ImageItem3Name,
  //     section: ImageItem3
  //   },
  //   {
  //     allowRow: true,
  //     previews: {
  //       left: require('@/assets/svg/previews/images/left-4.svg'),
  //       right: null,
  //       main: require('@/assets/svg/previews/images/main-4.svg'),
  //     },
  //     name: ImageItem4Name,
  //     section: ImageItem4
  //   },
  //   {
  //     allowRow: true,
  //     previews: {
  //       left: require('@/assets/svg/previews/images/left-5.svg'),
  //       right: null,
  //       main: require('@/assets/svg/previews/images/main-5.svg'),
  //     },
  //     name: ImageItem5Name,
  //     section: ImageItem5
  //   },
  //   {
  //     allowRow: true,
  //     previews: {
  //       left: require('@/assets/svg/previews/images/left-6.svg'),
  //       right: null,
  //       main: require('@/assets/svg/previews/images/main-6.svg'),
  //     },
  //     name: ImageItem6Name,
  //     section: ImageItem6
  //   },
  // ],
  utils: [
    // {
    //   previews: {
    //     left: null,
    //     right: null,
    //     main: require('@/assets/svg/previews/row/main-1.svg'),
    //   },
    //   name: EsRowName,
    //   section: EsRow
    // },
    // {
    //   allowRow: true,
    //   previews: {
    //     left: require('@/assets/svg/previews/videos/left-2.svg'),
    //     right: null,
    //     main: require('@/assets/svg/previews/videos/main-2.svg'),
    //   },
    //   name: VideoItem1Name,
    //   section: VideoItem1
    // },
    // {
    //   allowRow: true,
    //   previews: {
    //     left: null,
    //     right: null,
    //     main: require('@/assets/svg/previews/row/main-3.svg'),
    //   },
    //   name: EsButtonName,
    //   section: EsButton
    // },
  ],
  quotes: [
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/quotes/left-1.svg'),
        right: null,
        main: require('@/assets/svg/previews/quotes/main-1.svg'),
      },
      name: QuoteItem1Name,
      section: QuoteItem1
    },
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/quotes/left-2.svg'),
        right: null,
        main: require('@/assets/svg/previews/quotes/main-2.svg'),
      },
      name: QuoteItem2Name,
      section: QuoteItem2
    },
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/quotes/left-3.svg'),
        right: null,
        main: require('@/assets/svg/previews/quotes/main-3.svg'),
      },
      name: QuoteItem3Name,
      section: QuoteItem3
    },
  ],
  collapse: [
    {
      allowRow: true,
      previews: {
        left: require('@/assets/svg/previews/collapse/left-1.svg'),
        right: null,
        main: require('@/assets/svg/previews/collapse/main-1.svg'),
      },
      name: CollapseItem1Name,
      section: CollapseItem1
    }
  ]
})
