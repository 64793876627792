<template>
  <highliter :label="section.name" :uid="section.uid" @click.stop.native="sectionSettings()">
    <ImageItem5Template :section="section">
      <template slot="image">
        <highliter @click.stop.native="sectionSettings('image')">
          <img class="card-img-top" :src="section.options.content.image.src || section.options.content.image.placeholder" alt="Card image cap">
        </highliter>
      </template>

      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>
    </ImageItem5Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ImageItem5Template from '@pub/templates/default/sections/images/ImageItem5Template';

export default {
  name: "ImageItem3",

  components: {ImageItem5Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
