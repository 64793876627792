<template>
  <VideoItems1Template :section="section">
    <template slot="video" v-if="visability('video')">
      <div :class="{'video-overlay': overlay}" class="position-relative">
        <video-play :customs="section.options.content.video"></video-play>
      </div>
    </template>

    <template slot="title" v-if="visability('title')">
        <pub-content-editable
            :customs="section.options.content.title"
            @init="(editor) => section.classes.tiptap.title = editor"
            v-model="section.options.content.title.text"></pub-content-editable>
    </template>


    <template slot="subtitle" v-if="visability('subtitle')">
      <div v-show="visability('subtitle')">
        <pub-content-editable
            :customs="section.options.content.subtitle"
            v-model="section.options.content.subtitle.text"></pub-content-editable>
      </div>
    </template>
  </VideoItems1Template>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import VideoItems1Template from '@pub/templates/default/sections/videos/VideoItems1Template';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';

  export default {
    name: "FeaturedItems1",

    components: {PubContentEditable, VideoItems1Template, ContentEditable, Highliter},

    mixins: [SectionMixin],

    props: {
      overlay: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss">
.video-overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 1;
  cursor: pointer;
}
</style>
