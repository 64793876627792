<template>
  <div :style="{...padding, ...backgroundColor, ...opacity}">
    <div :class="container">
      <b-row no-gutters class="featured-items-3 align-items-end">
        <b-col :cols="cols(8, 12)">
          <div class="image">
            <template v-if="visability('image')">
              <slot name="image"></slot>
            </template>
          </div>

          <div :class="{'featured-text': visability('image') && resolution === 'lg'}" :style="{...shadow, ...backgroundColorCustom('Card')}">
            <template v-if="visability('description')">
              <slot name="description"></slot>
            </template>

<!--            <add-inner-content name="innerCotent" :section="section"></add-inner-content>-->
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "FeaturedItems3Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .featured-items-3 {
    .featured-text {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 0;
      minHeight: 50%;
      right: -50%;
      width: 80%;
      background: #FBFBFB;

      p {
        margin-bottom: 0;
      }

      transform: translateY(-50%);
    }
  }
</style>
