<template>
  <highliter v-if="section.options.customize.display" label="Navigation" uid="#navigation" :canDelete="false" :canDuplicate="false" class="navbar-2" :class="'navbar-' + resolution" @click.stop.native="sectionSettings(true)">
    <div :style="{...backgroundColor, ...shadow, ...opacity}">
      <b-navbar :toggleable="resolution === 'sm'">
        <b-navbar-brand>
          <highliter v-if="visability('logo')"
                     @click.stop.native="sectionSettings('logo')">
            <es-logo :customs="section.options.content.logo"></es-logo>
          </highliter>
        </b-navbar-brand>

        <!--      <b-navbar-toggle v-if="cols(false, true)" class="pr-0" target="nav-collapse"></b-navbar-toggle>-->

        <b-collapse id="nav-collapse" is-nav>
          <highliter v-if="visability('menuItems')"
                     class="links"
                     @click.stop.native="sectionSettings('menuItems')">
            <es-menu-items :customs="section.options.content.menuItems"></es-menu-items>
          </highliter>

          <b-navbar-nav :class="{'ml-auto': !visability('menuItems')}">
            <highliter v-if="visability('button')"
                       auto-width
                       @click.stop.native="sectionSettings('button')">
              <es-button :customs="section.options.content.button"></es-button>
            </highliter>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </highliter>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin';
  import Highliter from '@/components/builder/utils/Highliter';
  import EsMenuItems from '@/components/builder/utils/EsMenuItems';

  export default {
    name: "Navbar1",

    components: {EsMenuItems, Highliter},

    mixins: [SectionMixin],

    created() {
      if (!this.section.options.content.menuItems.hasOwnProperty('align')) {
        this.$set(this.section.options.content.menuItems, 'align', '')
      }
    }
  }
</script>

<style lang="scss">

.navbar-2 {
  .nav-item {
    font-weight: bold;
  }
}

.resolution-lg {
  .navbar-2 {
    .links {
      margin-right: auto;
    }

    .navbar-brand {
      margin-right: 90px;
    }

    .nav-item:not(:last-child) {
      margin-right: 70px !important;
    }
  }
}

 .resolution-sm {
   .navbar-2 {
     .btn {
       display: block;
       width: 100%;
     }
   }
  }
</style>
