import SectionProvider from '@/sections/SectionProvider';

export const name = 'NewsLetterItem5'

export default class NewsLetterItem5 extends SectionProvider {
    constructor(options = {}) {
        super('NewsLetterItem5', options);

        this.name = name
        this.group = 'newsLetter'

        this.defaultOptions = {
            content: {
                title: {
                    type: 'tiptap-mod',
                    display: true,
                    resolutionStyle: {
                        sm: {
                            fontSize: '41px',
                            fontFamily: 'Global Style',
                            color: '#0C226C',
                            align: 'center',
                        },
                        lg: {
                            fontSize: '41px',
                            fontFamily: 'Global Style',
                            color: '#0C226C',
                            align: 'center',
                        }
                    },
                    text: {
                        "type": "doc",
                        "content": [{
                            "type": "paragraph",
                            "content": [{
                                "type": "text",
                                "marks": [{"type": "bold"}],
                                "text": "Subscribe to our newsletter"
                            }]
                        }]
                    },
                },
                subtitle: {
                    type: 'tiptap-mod',
                    display: true,
                    resolutionStyle: {
                        sm: {
                            fontSize: '18px',
                            fontFamily: 'Global Style',
                            color: '#6D6D6D',
                            align: 'center',
                        },
                        lg: {
                            fontSize: '18px',
                            fontFamily: 'Global Style',
                            color: '#6D6D6D',
                            align: 'center',
                        }
                    },
                    text: {
                        "type": "doc",
                        "content": [{
                            "type": "paragraph",
                            "content": [{"type": "text", "text": "Curabitur tristique, lorem et dignissim consequat."}]
                        }]
                    },
                },
                form: {
                    type: 'form-mod',
                    btnPosition: 'submit',
                    action: null,
                    selectedList: '',
                    fields: [
                        {
                            display: true,
                            type: 'text',
                            name: 'name',
                            placeholder: 'Enter your first name',
                            required: true,
                            label: null,
                            cols: {
                                sm: 12,
                                lg: 12
                            }
                        },
                        {
                            display: true,
                            type: 'email',
                            name: 'email',
                            placeholder: 'Enter your email address',
                            required: true,
                            label: null,
                            cols: {
                                sm: 12,
                                lg: 12
                            }
                        }
                    ]
                },
                button: {
                    type: 'button-mod',
                    submit: true,
                    display: true,
                    hover: false,
                    text: 'Sign Up To Newsletter',
                    resolutionStyle: {
                        lg: {
                            color: '#ffffff',
                            backgroundColor: 'colored',
                            size: 'md',
                            shape: null,
                            btnStyle: '',
                            align: 'center',
                            padding: {
                                top: 0,
                                bottom: 0,
                                x: 0
                            }
                        },
                        sm: {
                            color: '#ffffff',
                            backgroundColor: 'colored',
                            size: 'md',
                            shape: null,
                            btnStyle: '',
                            align: 'center',
                            padding: {
                                top: 0,
                                bottom: 0,
                                x: 0
                            }
                        }
                    }
                },
                image: {
                    display: true,
                    type: 'image-mod',
                    placeholder: require('@/assets/svg/previews/newsLetter/placeholder-newsletter-5.svg'),
                    src: null,
                    size: 'cover'
                },
            },
            customize: {
                style: {
                    container: {
                        type: 'container-customs',
                        container: 'container'
                    },
                    backgroundImage: {
                        type: 'background-image-customs',
                        src: null,
                        position: 'center',
size: 'cover',
                        opacity: 1
                    },
                    padding: {
                        type: 'height-customs',
                        sm: {
                            top: 100,
                            bottom: 100
                        },
                        lg: {
                            top: 100,
                            bottom: 100
                        }
                    }
                }
            }
        }
    }
}
