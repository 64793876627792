<template>
  <b-navbar class="editor-header position-fixed">
    <b-navbar-brand @click="$router.push('/')">
      <icon icon="logo-e.svg"></icon>
    </b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="align-items-center">
        <div class="d-flex align-items-center ml-5 mr-5">
          <v-select left label="name" v-model="page" style="min-width: 200px;" :reduce="option => option.key" class="custom-select mr-2" :options="pages" :searchable="false"></v-select>
          <b-btn variant="success" size="sm" @click="newPageModal" v-if="!isPereview">
            <icon icon="plus-white.svg"></icon>
          </b-btn>
        </div>

        <b-nav-item>
          <b-btn-group class="resolution-toggle">
            <b-btn variant="light" class="pl-2" @click="setResolution('lg')">
              <icon v-if="resolution === 'lg'" icon="desktop-active.svg"></icon>
              <icon v-else icon="desktop.svg"></icon>
            </b-btn>
            <b-btn variant="light" @click="setResolution('sm')">
              <icon v-if="resolution === 'sm'" icon="smartphone-active.svg"></icon>
              <icon v-else icon="smartphone.svg"></icon>
            </b-btn>
            <b-btn variant="light" @click="$router.push({name: 'settings'})">
              <i class="fa fa-sliders-h text-muted"></i>
            </b-btn>
          </b-btn-group>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-center ml-auto">
        <b-btn v-if="!isPereview" variant="secondary" class="font-weight-bold btn-wide mr-2" @click="preview">Preview</b-btn>
        <b-btn v-else variant="secondary" class="font-weight-bold btn-wide mr-2" @click="inspect">Edit</b-btn>
        <b-btn variant="primary" class="d-flex align-items-center mr-2 btn-wide font-weight-bold"
               @click="published = true">Publish Now
          <icon class="ml-2" icon="plus-white.svg"></icon>
        </b-btn>
        <!--        <b-btn variant="primary" class="font-weight-bold btn-wide" @click="$store.dispatch('projects/save')">Save</b-btn>-->
      </b-navbar-nav>
    </b-collapse>

    <b-modal v-model="published" size="lg" hide-footer hide-header>
      <icon class="cursor-pinter ml-3" icon="close.svg" @click.native="published = false"></icon>

      <div class="text-center py-5 my-5">
        <icon size="100px" icon="positive-vote.svg"></icon>
        <h4 class="h3 text-center mt-4 mb-3">Your Site Has Been Published</h4>
        <b-btn variant="outline-primary" target="_blank" :href="getSiteHome">View Online</b-btn>
      </div>
    </b-modal>
  </b-navbar>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import RouterMixin from "../../mixins/RouterMixin";

export default {
  name: "EditorHeader",

  mixins: [RouterMixin],

  data() {
    return {
      published: false,
      url: window.location.origin + '/'
    }
  },

  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapGetters('sections', {
      pages: 'getPages'
    }),
    page: {
      get() {
        return this.$store.state.sections.page
      },
      set(val) {
        this.$store.dispatch('router/setPage', val)
      }
    },
    isPereview() {
      return this.$route.name === 'preview'
    }
  },

  methods: {
    newPageModal() {
      VEvent.fire('newPageModal')
    },
    async preview() {
      VEvent.fire('loader', true)

      this.$store.dispatch('projects/save')
      .then(() => {
        this.$store.commit('editor/SET_OPENED_MENU', null)
        this.$store.commit('editor/SET_TIPTAP', null)
        this.$router.push('/builder/' + 'preview/' + this.$route.params.id)
      })
      .finally(() => {
        VEvent.fire('loader', false)
      })

      // this.$store.commit('editor/SET_OPENED_MENU', null)
      // this.$store.commit('editor/SET_TIPTAP', null)
      // this.$router.push('/site/' + this.$route.params.id)
    },
    async inspect() {
      VEvent.fire('loader', true)

      this.$store.dispatch('projects/save')
      .then(() => {
        this.$store.commit('editor/SET_OPENED_MENU', null)
        this.$store.commit('editor/SET_TIPTAP', null)
        this.$router.push('/builder/' + this.$route.params.id)
      })
      .finally(() => {
        VEvent.fire('loader', false)
      })
    },
    setResolution(resolution) {
      this.$store.commit('editor/SET_RESOLUTION', resolution)
    }
  }
}
</script>

<style lang="scss">
.resolution-toggle {
  .btn {
    width: 41px;
    border: 1px solid #F0F1F2;

    &:focus {
      box-shadow: none !important;
    }
  }
}
</style>
