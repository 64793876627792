<template>
  <highliter :label="section.name" :uid="section.uid" :dublicate="section" @click.stop.native="sectionSettings()">
    <QuoteItem1Template :section="section">
      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>
    </QuoteItem1Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import QuoteItem1Template from '@pub/templates/default/sections/quotes/QuoteItem1Template';

export default {
  name: "ImageItem3",

  components: {QuoteItem1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
