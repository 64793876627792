import SectionProvider from '@/sections/SectionProvider';

export const name = 'Breadcrumb'
export const group = 'Breadcrumb'

export const defaultOptions = {
  content: {

  },
  customize: {
    layouts: false,
    style: {
      background: {
        background: '#ffffff'
      },
      color: {
        type: 'color-customs',
        color: '#000000'
      },
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/breadcrumb/main-1.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Breadcrumb1', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
