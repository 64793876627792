import SectionProvider from '@/sections/SectionProvider';

export const name = 'About Us Images'
export const group = 'About Us'

export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '27px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'center',
          padding: {
            top: 0,
            bottom: 26,
            x: 0
          }
        },
        lg: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 0,
            bottom: 26,
            x: 0
          }
        }
      },
      text: "<p><strong>Curabitur arcu erat,</strong></p><p><strong>accumsan id imperdiet </strong></p>",
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6E747A',
          align: 'center',
          lineHeight: '1.5',
          padding: {
            top: 0,
            bottom: 40,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6E747A',
          align: 'left',
          lineHeight: '1.5'
        }
      },
      text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus ultrices. Nullam consequat leo vel nunc pellentesque molestie. Aenean aliquam orci quam</p>"
    },
    image: {
      display: true,
      type: 'images-mod',
      items: [
        {
          placeholder: require('@/assets/svg/previews/about-us/placeholder-about-us-2-1.png'),
          src: require('@/assets/svg/previews/about-us/placeholder-about-us-2-1.png'),
          position: 'center',
          size: 'cover',
          opacity: 1,
          alt: ''
        },
        {
          placeholder: require('@/assets/svg/previews/about-us/placeholder-about-us-2-2.png'),
          src: require('@/assets/svg/previews/about-us/placeholder-about-us-2-2.png'),
          position: 'center',
          size: 'cover',
          opacity: 1,
          alt: ''
        },
        {
          placeholder: require('@/assets/svg/previews/about-us/placeholder-about-us-2-3.png'),
          src: require('@/assets/svg/previews/about-us/placeholder-about-us-2-3.png'),
          position: 'center',
          size: 'cover',
          opacity: 1,
          alt: ''
        }
      ]
    }
  },
  customize: {
    layouts: null,
    style: {
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
size: 'cover',
        opacity: 1
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 70,
          bottom: 70
        },
        lg: {
          top: 115,
          bottom: 115
        }
      }
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/about-us/main-2.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('AboutUs2', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
