<template>
    <div data-padding>
      <FeaturedItems3Template :section="section">
        <template slot="image">
          <image-fit
              :customs="section.options.content.image"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
            :size="cols(section.options.content.image.size, 'contain')"
            :position="section.options.content.image.position"
            :height="cols('50vh', '200px')"
        ></image-fit>
        </template>

        <template slot="description">
          <pub-content-editable
              class="ProseMirror"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></pub-content-editable>
        </template>
      </FeaturedItems3Template>
    </div>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import FeaturedItems3Template from '@pub/templates/default/sections/featured/FeaturedItems3Template';
  import PubContentEditable from "../../../builder/utils/PubContentEditable";
  import ImageFit from "../../../utils/ImageFit";

  export default {
    name: "FeaturedItems3",

    components: {ImageFit, PubContentEditable, FeaturedItems3Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
