<template>
  <Template :section="section">
    <!-- Logo -->
    <template slot="logo">
      <pub-logo :customs="section.options.content.logo"></pub-logo>
    </template>
    <!-- End Logo -->

    <!-- Copyright -->
    <template slot="copyright">
      <pub-content-editable
          :uid="tiptapUID('copyright')"
          :customs="section.options.content.copyright"
          v-model="section.options.content.copyright.text"></pub-content-editable>
    </template>
    <!-- End Copyright -->

    <!-- Menu 1 -->
    <template slot="labelMenu1">
      <pub-content-editable
          :uid="tiptapUID('labelMenu1')"
          :customs="section.options.content.labelMenu1"
          v-model="section.options.content.labelMenu1.text"></pub-content-editable>
    </template>

    <template slot="menu-1">
      <ul class="list-unstyled">
        <li v-for="(link, index) in section.options.content.menu1.links" :key="index" :href="link.href">
          <b-link :style="section.options.content.menu1.style" @click="goPage(link)">{{ link.text }}</b-link>
        </li>
      </ul>
    </template>
    <!-- End Menu 1 -->

    <!-- Menu 2 -->
    <template slot="labelMenu2">
      <pub-content-editable
          :uid="tiptapUID('labelMenu2')"
          :customs="section.options.content.labelMenu2"
          v-model="section.options.content.labelMenu2.text"></pub-content-editable>
    </template>

    <template slot="menu-2">
      <ul class="list-unstyled">
        <li v-for="(link, index) in section.options.content.menu2.links" :key="index" :href="link.href">
          <b-link :style="section.options.content.menu2.style" @click="goPage(link)">{{ link.text }}</b-link>
        </li>
      </ul>
    </template>
    <!-- End Menu 2 -->

    <!-- Menu Label 3 -->
    <template slot="labelMenu3">
      <pub-content-editable
          :uid="tiptapUID('labelMenu3')"
          :customs="section.options.content.labelMenu3"
          v-model="section.options.content.labelMenu3.text"></pub-content-editable>
    </template>
    <!-- Menu Label 3 -->

    <!-- Description -->
    <template slot="formDescription">
      <pub-content-editable
          :uid="tiptapUID('formDescription')"
          :customs="section.options.content.formDescription"
          v-model="section.options.content.formDescription.text"></pub-content-editable>
    </template>
    <!-- End Description -->

<!--    &lt;!&ndash; Form &ndash;&gt;-->
<!--    <template slot="form">-->
<!--      <es-form :customs="section.options.content.form">-->
<!--        <template :slot="cols(section.options.content.form.btnPosition, 'submit')">-->
<!--          <pub-es-button :customs="section.options.content.button"></pub-es-button>-->
<!--        </template>-->
<!--      </es-form>-->
<!--    </template>-->
<!--    &lt;!&ndash; End Form &ndash;&gt;-->
  </Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from "./Template";
import PubEsButton from "../../../utils/buttons/PubEsButton";

export default {
  name: "Carousel1",

  components: {PubEsButton, Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  methods: {
    goPage(link) {
      if (!link.href || link.href === '#') {
        return false
      }

      if (!link.customLink) {
        if (link.blank) {
          window.open(`${window.location.origin}/site/${this.project.subdomain}/${link.href}`)
        } else {
          this.$store.dispatch('router/setPage', link.href)
        }
      } else {
        if (link.blank) {
          window.open(link.href)
        } else {
          window.location = link.href
        }
      }
    },
  }
}
</script>
