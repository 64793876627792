<template>
  <AboutUs1Template :section="section">
    <template slot="title">
      <pub-content-editable
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description-1">
      <pub-content-editable
          :uid="tiptapUID('description')"
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="description-2">
      <pub-content-editable
          :uid="tiptapUID('description2')"
          :customs="section.options.content.description2"
          v-model="section.options.content.description2.text"></pub-content-editable>
    </template>
  </AboutUs1Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ActionItem1Template from '@pub/templates/default/sections/actions/ActionItem1Template';
import PubContentEditable from '@/components/builder/utils/PubContentEditable';
import EsImage from "@/components/builder/utils/EsImage";
import AboutUs1Template from "./AboutUs1Template";

export default {
  name: "FeaturedItems1",

  components: {
    AboutUs1Template,
    EsImage, PubContentEditable, ActionItem1Template, ContentEditable, Highliter
  },

  mixins: [SectionMixin],
}
</script>
