<template>
  <highliter class="feature-section hide-add"
             :label="section.name"
             uid="#footer"
             @click.stop.native="sectionSettings()">
    <Footer2Template :section="section">
      <!-- Logo -->
      <template slot="logo">
        <highliter @click.stop.native="sectionSettings('logo')">
          <es-logo :customs="section.options.content.logo"></es-logo>
        </highliter>
      </template>
      <!-- End Logo -->

      <!-- Copyright -->
      <template slot="copyright">
        <highliter @click.stop.native="sectionSettings('copyright')">
          <content-editable
              :uid="tiptapUID('copyright')"
              :customs="section.options.content.copyright"
              v-model="section.options.content.copyright.text"></content-editable>
        </highliter>
      </template>
      <!-- End Copyright -->

      <!-- Menu 1 -->
      <template slot="labelMenu1">
        <highliter @click.stop.native="sectionSettings('labelMenu1')">
          <content-editable
              :uid="tiptapUID('labelMenu1')"
              :customs="section.options.content.labelMenu1"
              v-model="section.options.content.labelMenu1.text"></content-editable>
        </highliter>
      </template>

      <template slot="menu-1">
        <highliter @click.stop.native="sectionSettings('menu1')">
          <ul class="list-unstyled">
            <li v-for="(link, index) in section.options.content.menu1.links" :key="index">
              <b-link :style="section.options.content.menu1.style">{{ link.text }}</b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Menu 1 -->

      <!-- Menu 2 -->
      <template slot="labelMenu2">
        <highliter @click.stop.native="sectionSettings('labelMenu2')">
          <content-editable
              :uid="tiptapUID('labelMenu2')"
              :customs="section.options.content.labelMenu2"
              v-model="section.options.content.labelMenu2.text"></content-editable>
        </highliter>
      </template>

      <template slot="menu-2">
        <highliter @click.stop.native="sectionSettings('menu2')">
          <ul class="list-unstyled">
            <li v-for="(link, index) in section.options.content.menu2.links" :key="index">
              <b-link :style="section.options.content.menu2.style">{{ link.text }}</b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Menu 2 -->

      <!-- Menu 3 -->
      <template slot="menu-3">
        <highliter @click.stop.native="sectionSettings('menu3')">
          <ul class="list-unstyled list-inline">
            <li v-for="(link, index) in section.options.content.menu3.links" :key="index" class="list-inline-item mr-4">
              <b-link :style="section.options.content.menu3.style">{{ link.text }}</b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Menu 3 -->

      <!-- Social -->
      <template slot="social">
        <highliter @click.stop.native="sectionSettings('socialLinks')">
          <ul class="list-unstyled list-inline">
            <li v-for="(link, index) in section.options.content.socialLinks.links" :key="index"
                class="list-inline-item mr-3">
              <b-link :class="'social-link social-link-' + link.shape"
                      :style="{...section.options.content.socialLinks.style, ...{color: link.color, backgroundColor: link.background}}">
                <i :class="link.icon"></i>
              </b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Social -->

      <!-- Description -->
      <template slot="labelDescriotion">
        <highliter @click.stop.native="sectionSettings('labelDescriotion')">
          <content-editable
              :uid="tiptapUID('labelDescriotion')"
              :customs="section.options.content.labelDescriotion"
              v-model="section.options.content.labelDescriotion.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>
      <!-- End Description -->
    </Footer2Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Footer2Template from "./Footer2Template";

export default {
  name: "Carousel1",

  components: {Footer2Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>