<template>
  <div class="feature-section">
    <ImageItem1Template v-show="visability('image')">
      <div v-for="(image, index) in section.options.content.image.items" :key="index" :style="{opacity: image.opacity}" class="transform-image">
        <image-fit
            :customs="section.options.content.image"
            :src="image.src || image.placeholder"
            :size="cols(image.size, 'contain')"
            :position="image.position"
        ></image-fit>
      </div>
    </ImageItem1Template>
  </div>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ImageItem1Template from '@pub/templates/default/sections/images/ImageItem1Template';
import ImageFit from "../../../utils/ImageFit";

export default {
  name: "ImageItem",

  components: {ImageFit, ImageItem1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
