<template>
  <QuoteItem3Template :section="section">
    <template slot="description">
      <content-editable
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></content-editable>
    </template>
  </QuoteItem3Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from "../../../builder/utils/PubContentEditable";
import QuoteItem3Template from '@pub/templates/default/sections/quotes/QuoteItem3Template';

export default {
  name: "ImageItem3",

  components: {QuoteItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
