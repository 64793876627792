<template>
  <TestimonialItem3Template :section="section">
    <template slot="image" v-if="visability('image')">
      <image-fit
          :customs="section.options.content.image"
          :class="[{'mx-auto': section.options.content.image.align === 'center'}, {'ml-auto': section.options.content.image.align === 'right'}]"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="cols(section.options.content.image.size, 'contain')"
          :position="section.options.content.image.position"
          width="86px"
          height="86px"
      ></image-fit>
    </template>

    <template slot="name" v-if="visability('name')">
        <pub-content-editable
            :customs="section.options.content.name"
            v-model="section.options.content.name.text"></pub-content-editable>
    </template>


    <template slot="subname" v-if="visability('subname')">
        <pub-content-editable
            :customs="section.options.content.subname"
            v-model="section.options.content.subname.text"></pub-content-editable>
    </template>

    <template slot="text" v-if="visability('text')">
        <pub-content-editable
            :customs="section.options.content.text"
            v-model="section.options.content.text.text"></pub-content-editable>
    </template>
  </TestimonialItem3Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import TestimonialItem3Template from '@pub/templates/default/sections/testimonials/TestimonialItem3Template';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';
  import ImageFit from "../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {ImageFit, PubContentEditable, TestimonialItem3Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
