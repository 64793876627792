<template>
  <div class="panel-settings border-top">
    <b-btn-group>
      <b-btn squared variant="light" @click="config.display = true" :class="{'active': config.display === true}">
        ALL
      </b-btn>
      <b-btn squared variant="light" @click="config.display = 'lg'">
        <icon v-if="config.display === 'lg'" icon="desktop-active.svg" size="19px"></icon>
        <icon v-else icon="desktop-2.svg" size="19px"></icon>
      </b-btn>
      <b-btn squared variant="light" @click="config.display = 'sm'">
        <icon v-if="config.display === 'sm'" icon="smartphone-active.svg" size="19px"></icon>
        <icon v-else icon="smartphone-2.svg" size="19px"></icon>
      </b-btn>
      <b-btn squared variant="light" @click="config.display = false">
        <icon v-if="config.display === false" icon="eye-disable.svg" size="19px"></icon>
        <icon v-else icon="eye.svg" size="19px"></icon>
      </b-btn>
<!--      <b-btn squared variant="light" class="ml-n1" v-b-tooltip.hover :title="section.uid">-->
<!--        #CSS-->
<!--      </b-btn>-->
<!--      <b-btn squared variant="light">-->
<!--        <icon icon="delete.svg" size="15px"></icon>-->
<!--      </b-btn>-->
    </b-btn-group>
  </div>
</template>

<script>
  import ControlMixin from "../../mixins/ControlMixin";

  export default {
    name: "panel",

    mixins: [ControlMixin],

    data() {
      return {
        position: 'top'
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";

  .panel-settings {
    position: absolute;
    width: 100%;
    bottom: 2px;
    left: 0;

    .btn {
      background: #fff;
      color: #AEB1B4;
      font-size: 10px;
      font-weight: bold;
      min-width: 56px;
      border-left: none;

      &:not(:first-child) {
        border-left: 1px solid #E2E5EC;
      }

      &.active {
        background: #FAFBFD !important;
        border-top: none !important;
        border-bottom: none !important;
        color: $primary !important;
      }

      &:active,
      &:focus {
        box-shadow: none !important;
      }
    }
  }
</style>
