<template>
  <div class="position-relative">
    <b-row class="es-row inner-content-section">
      <b-col
          v-for="(child, index) in section.options.content[name].items"
          :key="child.uid"
          :offset="offset(child.options.customize)"
          :order="order(child.options.customize)"
          :cols="columns(child.options.customize)">
        <div class="inner-content-section">
          <component
              :is="child.component"
              :section="child"
              :parentUID="section.uid"
          >
          </component>
          <b-link class="inner-content-section__delete" variant="outline-primary" @click="deleteInnerSection(index)">
            <icon size="14px" icon="delete-primary.svg"></icon>
          </b-link>
        </div>
      </b-col>
    </b-row>

    <div class="higliter-add" @click="openModal">
      <icon icon="plus-section.svg"/>
    </div>

    <new-inner-section-modal
        ref="modal"
        @addedSection="addSection"
    />
  </div>
</template>

<script>
import NewInnerSectionModal from "../../editor/modals/NewInnerSectionModal";
import {mapState} from "vuex";

export default {
  name: "AddInnerContent",
  components: {NewInnerSectionModal},
  props: {
    name: {
      type: String,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution,
    })
  },

  methods: {
    deleteInnerSection(index) {
      this.section.options.content[this.name].items.splice(index, 1)
    },
    offset(obj) {
      if (!obj.hasOwnProperty('offset')) {
        this.$set(obj, 'offset', {
          lg: 0,
          sm: 0
        })
      }

      return obj.offset[this.resolution]
    },
    order(obj) {
      if (obj.hasOwnProperty('order')) {
        return obj.order[this.resolution]
      }
    },
    columns(obj) {
      if (!obj.hasOwnProperty('cols')) {
        this.$set(obj, 'cols',
            {
              sm: 12,
              md: 12,
              lg: 12
            })
      }

      return obj.cols[this.resolution]
    },
    addSection(section) {
      this.section.options.content[this.name].items.push(section)
    },
    openModal() {
      this.$refs.modal.openModal()
    },
    doctor() {
      const content = this.section.options.content

      if (!content.hasOwnProperty(this.name)) {
        this.$set(content, this.name, {
          type: 'row-mod',
          display: true,
          items: []
        })
      }
    }
  },
  created() {
    this.doctor()
  }
}
</script>

<style lang="scss">
.es-row {
  .copy-section {
    display: none;
  }
}

.inner-content-section {
  &__delete {
    display: none;
    position: absolute;
    top: 10px;
    z-index: 10;
    right: 30px;
    padding: 2px 8px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #4F83E3;
  }

  .higliter.active + &__delete,
  &__delete:hover {
    display: block !important;
  }
}

.higliter-add {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px !important;
  height: 30px !important;
  border: 1px solid #4F83E3;
  display: none;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
}

.higliter.active {
  .higliter-add {
    display: flex;
  }
}
</style>