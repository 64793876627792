<template>
  <div :style="{...backgroundImage, backgroundColor}">
    <div class="media quote-3" :class="container" :style="{...opacity, ...padding}">
      <div class="media-body quote-border-y py-4">
        <slot name="description" v-if="visability('description')"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "ImageItem5Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
  .quote-3 {
    .quote-border-y {
      border-top: 2px solid #1F3B9C;
      border-bottom: 2px solid #1F3B9C;
    }
  }
</style>