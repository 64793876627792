<template>
  <highliter class="feature-section" :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
    <ImageItem1Template :section="section" v-show="visability('image')">
      <highliter v-for="(image, index) in section.options.content.image.items" :key="index" @click.stop.native="sectionSettings({item: 'image', index})" :style="{opacity: image.opacity}">
        <image-fit
            :customs="image"
            :src="image.src || image.placeholder"
            :size="cols(image.size, 'contain')"
            :position="image.position"
        ></image-fit>
      </highliter>
   </ImageItem1Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ImageItem1Template from '@pub/templates/default/sections/images/ImageItem1Template';
import ImageFit from "../../../utils/ImageFit";

export default {
  name: "ImageItem",

  components: {ImageFit, ImageItem1Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
