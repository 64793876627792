import SectionProvider from '@/sections/SectionProvider';

export const name = 'Featured Image'

export default class FeaturedImage extends SectionProvider {
  constructor(options = {}) {
    super('FeaturedImage', options);

    this.name = name
    this.group = 'featured'

    this.defaultOptions = {
      content: {
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '19px',
              color: '#111111',
              align: 'center',
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '19px',
              color: '#111111',
              align: 'center',
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{
                "type": "text",
                "text": "Item Name"
              }]
            }]
          },
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/placeholder-image-2.svg'),
          src: null,
          position: 'center',
size: 'cover'
        }
      },
      customize: {
        style: {
          shadow: null,
          background: null
        }
      }
    }
  }
}
