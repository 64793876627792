import SectionProvider from '@/sections/SectionProvider';
import EsRow from "../../../../../sections/utils/EsRow";
import {default as Resource2} from '../resource-2/Section'
import ResourceItem1 from "../../../../../sections/resource/ResourceItem1";

export const name = 'Resource 4'
export const group = 'resource'
export const defaultOptions = new EsRow({
  customize: {
    style: {
      padding: {
        type: 'height-customs',
        sm: {
          top: 40,
          bottom: 40
        },
        lg: {
          top: 100,
          bottom: 100
        }
      }
    }
  }
}, [
  new ResourceItem1({
    content: {
      button: {
        display: false
      }
    },
    customize: {
      style: {
        padding: {
          type: 'height-customs',
          sm: {
            top: 0,
            bottom: 0
          },
          lg: {
            top: 0,
            bottom: 40
          }
        }
      }
    }
  }).json(),
  new ResourceItem1({
    content: {
      button: {
        display: false
      }
    },
    customize: {
      style: {
        padding: {
          type: 'height-customs',
          sm: {
            top: 0,
            bottom: 0
          },
          lg: {
            top: 0,
            bottom: 40
          }
        }
      }
    }
  }).json(),
  new ResourceItem1({
    content: {
      button: {
        display: false
      }
    }
  }).json(),
  new ResourceItem1({
    content: {
      button: {
        display: false
      }
    }
  }).json()
]).json().options

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/resource/main-4.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('EsRow', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
