<template>
  <div :style="{...backgroundImage, ...padding, ...backgroundColor, ...opacity, ...shadow}">
    <div class="action-item-10" :class="container">
      <slot name="title"></slot>
      <div class="my-2"></div>
      <slot name="description"></slot>

      <div class="my-5"></div>

      <b-row>
        <b-col :cols="cols(3, 12)" :offset-md="cols(2, 0)" :class="cols('', 'mb-5')">
          <slot name="left-icon"></slot>
          <div class="my-4"></div>
          <slot name="left-title"></slot>
          <div class="my-2"></div>
          <slot name="left-description"></slot>
          <div class="my-4"></div>
          <slot name="left-button"></slot>
        </b-col>

        <b-col :cols="cols(2, 12)">
          <div class="vertical-devider"></div>
        </b-col>

        <b-col :cols="cols(3, 12)">
          <slot name="right-icon"></slot>
          <div class="my-4"></div>
          <slot name="right-title"></slot>
          <div class="my-2"></div>
          <slot name="right-description"></slot>
          <div class="my-4"></div>
          <slot name="right-button"></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.action-item-10 {
  .vertical-devider {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 1px;
    background: #D8D8D8;
    transform: translateX(-50%);
  }
}
</style>