<template>
  <div :style="{...backgroundImage, ...backgroundColor, ...opacity, ...shadow, ...padding}">
    <div class="action-item-11" :class="container">
      <b-row class="align-items-center">
        <b-col :cols="cols(6, 12)" :order="cols(1, 2)">
          <div v-show="visability('title')">
            <slot name="title"></slot>
          </div>

          <div class="my-2"></div>

          <div v-show="visability('description')">
            <slot name="description"></slot>
          </div>

          <div class="my-5"></div>
          <div class="d-flex align-items-center">
            <div v-show="visability('button')">
              <slot name="button"></slot>
            </div>

            <div v-show="visability('button2')">
              <slot name="button2"></slot>
            </div>
          </div>
        </b-col>

        <b-col :cols="cols(6, 12)" :order="cols(2, 1)" v-show="visability('image')">
          <slot name="image"></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">

</style>