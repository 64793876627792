<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <Template :section="section">
     <template slot="image">
       <highliter @click.stop.native="sectionSettings('image')">
         <image-fit
             :customs="section.options.content.image"
             :src="section.options.content.image.src || section.options.content.image.placeholder"
             :size="section.options.content.image.size"
             height="297px"
             :position="section.options.content.image.position"
         ></image-fit>
       </highliter>
     </template>

      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

     <template slot="description">
       <highliter v-show="visability('description')" @click.stop.native="sectionSettings('description')">
         <content-editable
             :uid="tiptapUID('description')"
             :customs="section.options.content.description"
             v-model="section.options.content.description.text"></content-editable>
       </highliter>
     </template>
     
     <template slot="date">
       <highliter v-show="visability('date')" @click.stop.native="sectionSettings('date')">
         <content-editable
             :uid="tiptapUID('date')"
             :customs="section.options.content.date"
             v-model="section.options.content.date.text"></content-editable>
       </highliter>
     </template>
   </Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Template from './Template';
  import ImageFit from "../../../../utils/ImageFit";
  import VideoPlay from "../../../utils/VideoPlay";

  export default {
    name: "BlogItem2",

    components: {VideoPlay, ImageFit, Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
