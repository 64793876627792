import SectionProvider from '@/sections/SectionProvider';

export const name = 'Text'
export const group = 'utils'
export const standalone = true
export const allowRow = true
export const defaultOptions = {
  content: {
    text: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '16px',
          fontFamily: 'Global Style',
          color: '#44474A',
          align: 'left'
        },
        lg: {
          fontSize: '16px',
          fontFamily: 'Global Style',
          color: '#44474A',
          align: 'left'
        }
      },
      text: "<p>Text here.</p>",
    },
  },
  customize: {}
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/utils/text-util.jpg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('EsTextUtil', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
