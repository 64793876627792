import SectionProvider from '@/sections/SectionProvider';

export const name = 'Featured Items 5'

export default class FeaturedItems5 extends SectionProvider {
  constructor(options = {}) {
    super('FeaturedItems5', options);

    this.name = name
    this.group = 'featured'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '38px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '38px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: "<p><strong>Curabitur arcu erat,</strong></p><p><strong>accumsan id</strong></p>"
        },
        description: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '16px',
              fontFamily: 'Global Style',
              lineHeight: 1.5,
              color: '#6D6D6D',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus ultrices. Nullam consequat leo vel nunc pellentesque molestie. Aenean aliquam orci quam, </p>"
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Learn More',
          resolutionStyle: {
            lg: {
              color: '#0C226C',
              backgroundColor: '#0C226C',
              size: 'lg',
              btnStyle: 'outlined',
              shape: null,
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#0C226C',
              backgroundColor: '#0C226C',
              size: 'lg',
              btnStyle: 'outlined',
              shape: null,
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        style: {
          background: {
            background: '#ffffff'
          },
          backgroundCard: {
            background: '#ffffff',
            type: 'background-customs',
            label: 'Card Background'
          },
          aligment: {
            type: 'aligment-customs',
            align: 'left',
            images: {
              left: require('@/assets/svg/previews/features/featured-items-5-left.png'),
              right: require('@/assets/svg/previews/features/featured-items-5-right.png'),
            }
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: require('@/assets/images/placeholder-featured-5.png'),
            position: 'center',
size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 0,
              bottom: 0
            },
            lg: {
              top: 0,
              bottom: 0
            }
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
        },
      }
    }
  }
}
