<template>
  <div>
    <div v-if="image.src">
      <div class="selected w-100">
        <slot name="prepend"></slot>
        <div class="d-flex px-2 btn-es-image-upload btn-block shadow-none">
          <div class="mini-preview mr-3" :style="{background: `url(${image.src})`}"></div>
          <span class="text-left font-weight-normal text-truncate"
                style="width: 70%; color: #6e747a; font-size: 14px;">{{ image.name }}</span>
          <b-dropdown>
            <template slot="button-content">
              <i class="fa fa-ellipsis-h dot-menu fa-lg link-muted cursor-pinter" icon="dot-menu.svg"></i>
            </template>

            <b-dropdown-item class="d-flex align-items-center" v-if="!inFavorites" @click.native="saveToMyLibrary">
              <i class="fa fa-star-o mr-2"></i> Add to favorite
            </b-dropdown-item>
            <b-dropdown-item class="d-flex align-items-center" v-else @click.native="removeFromMyLibrary">
              <i class="fa fa-star text-primary mr-2"></i> Favorite
            </b-dropdown-item>
            <b-dropdown-item class="d-flex align-items-center" @click.native="deleteImage">
              <icon class="mr-2" icon="trash.svg"></icon> Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

     <div class="d-flex align-items-end">
       <b-form-group class="d-block mr-4" v-if="image.hasOwnProperty('size')">
         <span class="d-block section-subtitle mt-3 mb-2">Image style</span>

         <slieder-toggle v-model="image.size" label="Size" :options="['cover', 'contain', 'unset']"></slieder-toggle>
       </b-form-group>

       <b-form-group class="d-block" v-if="image.hasOwnProperty('position')">
         <slieder-toggle v-model="image.position" label="Position" :options="['center', 'top', 'left', 'right', 'bottom']">
           <template #icon>
             <i class="fa fa-crosshairs fa-2x text-dark icon"></i>
           </template>
         </slieder-toggle>
       </b-form-group>
     </div>

      <b-form-group class="menubar mt-4" v-if="image.hasOwnProperty('align')">
        <span class="d-block section-subtitle mt-2 mb-1">Image Align</span>
        <b-btn-group>
          <b-btn
              variant="menubar-button"
              :class="{ 'is-active': image.align === 'left' }"
              @click="image.align = 'left'"
          >
            <i class="fa fa-align-left"></i>
          </b-btn>
          <b-btn
              variant="menubar-button"
              :class="{ 'is-active': image.align === 'center' }"
              @click="image.align = 'center'"
          >
            <i class="fa fa-align-center"></i>
          </b-btn>
          <b-btn
              variant="menubar-button"
              :class="{ 'is-active': image.align === 'right' }"
              @click="image.align = 'right'"
          >
            <i class="fa fa-align-right"></i>
          </b-btn>
        </b-btn-group>
      </b-form-group>
    </div>

    <div v-else class="position-relative w-100 mb-0" @click="modal = true">
      <div class="btn-group w-100">
        <slot name="prepend"></slot>
        <div class="btn btn-es-image-upload btn-block">
          <i class="fa fa-plus text-primary mr-2"></i>

          Upload Image…
        </div>
      </div>
    </div>

    <images-exploerer-modal v-model="modal" :image="image" @uploaded="image => $emit('uploaded', image)" />
  </div>
</template>

<script>
import SliederToggle from "../controls/SliederToggle";
import ImagesExploererModal from "../modals/ImagesExploererModal";
import {mapState} from "vuex";
export default {
  name: "ImageUpload",
  components: {ImagesExploererModal, SliederToggle},
  props: {
    image: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
    }),
    favorites() {
      return this.globalStyles.libraryImages || []
    },
    imagePath() {
      return !!this.image.src ? this.image.src : this.image.placeholder
    },
    inFavorites() {
      return this.favorites.includes(this.imagePath)
    }
  },

  data() {
    return {
      modal: false,
    }
  },

  methods: {
    removeFromMyLibrary() {
      this.globalStyles.libraryImages.splice(this.globalStyles.libraryImages.findIndex(path => path === this.imagePath), 1)
    },
    deleteImage() {
      this.$set(this.image, 'src', null)
      this.$emit('imageDeleted')
    },
    saveToMyLibrary() {
      !this.globalStyles.hasOwnProperty('libraryImages') ? this.$set(this.globalStyles, 'libraryImages', []) : null
      this.globalStyles.libraryImages.push(this.imagePath)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-es-image-upload {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #E2E5EC;
  font-size: 14px;
  font-weight: 600;
  color: #6E747A;
  height: 38px;
  box-shadow: 0 1px 2px 0 rgba(226, 226, 226, 0.5);

  .mini-preview {
    width: 100% !important;
    background-size: contain !important;
    height: 26px !important;
    object-position: bottom !important;
    background-repeat: no-repeat !important;
  }
}
</style>
