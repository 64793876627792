<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)" data-padding>
    <FeaturedItems2Template :section="section">
      <template slot="images">
        <b-row>
          <b-col :cols="cols(4, 12)"  v-for="section in section.options.content.group.items" :key="section.uid">
            <featured-image :section="section"></featured-image>
          </b-col>
        </b-row>
      </template>

      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>
   </FeaturedItems2Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import FeaturedItems2Template from '@pub/templates/default/sections/featured/FeaturedItems2Template';

  export default {
    name: "FeaturedItems2",

    components: {FeaturedItems2Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
