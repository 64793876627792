<template>
  <TextAndImageItem1Template :section="section">
    <template slot="image" v-if="visability('image')">
      <image-fit
          :customs="section.options.content.image"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="cols(section.options.content.image.size, 'cover')"
          :position="section.options.content.image.position"
          height="308px"
      ></image-fit>
    </template>

    <template slot="text" v-if="visability('text')">
        <pub-content-editable
            :customs="section.options.content.text"
            v-model="section.options.content.text.text"></pub-content-editable>
    </template>
  </TextAndImageItem1Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import TextAndImageItem1Template from '@pub/templates/default/sections/text-and-image/TextAndImageItem1Template';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';
  import ImageFit from "../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {ImageFit, PubContentEditable, TextAndImageItem1Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
