<template>
  <b-navbar-nav class="align-items-center" :class="{'text-uppercase': customs.uppercase}">
    <div
        v-for="(page, index) in getNavLinks"
        :key="index">
      <b-nav-item
          v-if="!page.children || !page.children.length"
          class="mr-0"
          @click="goToPage(page)">
        <div
            class="text-nowrap"
            :class="{'active': page.key === $store.state.sections.page}"
            :style="{color: page.key === $store.state.sections.page ? globalStyles.colors.primary : customs.color, fontFamily: globalStyles.font.style || ''}">
          {{ page.name }}
        </div>
      </b-nav-item>

      <Menu :label="page.name" :items="page.children" :customs="customs" v-else></Menu>

<!--      <b-nav-item-dropdown v-else>-->
        <!-- Using 'button-content' slot -->
<!--        <template #button-content>-->
<!--          <div class="d-flex align-items-center">-->
<!--            {{ page.name }} <i class="fa fa-angle-down ml-2"></i>-->
<!--          </div>-->
<!--        </template>-->
<!--        <b-dropdown-item :active="page.key === $store.state.sections.page" @click="$store.dispatch('router/setPage', page.key)">{{ page.name }}</b-dropdown-item>-->
<!--        <b-dropdown-item v-for="child in page.children" :key="child.key" :active="child.key === $store.state.sections.page" @click="$store.dispatch('router/setPage', child.key)">{{child.name}}</b-dropdown-item>-->
<!--      </b-nav-item-dropdown>-->
    </div>
  </b-navbar-nav>
</template>

<script>
import ComponentProvider from '@/components/mixins/ComponentProvider'
import {mapGetters} from 'vuex';
import Menu from "./menu/Menu";

export default {
  name: "EsMenuItems",
  components: {Menu},
  mixins: [ComponentProvider],

  computed: {
    ...mapGetters('sections', [
      'getNavLinks'
    ])
  },

  methods: {
    addhttp(url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
      }
      return url;
    },
    goToPage(page) {
      if (page.custom_link) {
          window.open(this.addhttp(page.custom_link))
      } else {
        this.$store.dispatch('router/setPage', page.key)
      }
    }
  }
}
</script>
