import SectionProvider from '@/sections/SectionProvider';

export const name = 'Contact Form 2'
export const group = 'contact-forms'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '36px',
          fontFamily: 'Global Style',
          color: 'adaptive',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: 'adaptive',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Send Me An Email</strong></p>"
    },
    info: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '16px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '16px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>1-888-699-4968</p><p>9am - 5pm CST, M-F</p>"
    },
    socialLinks: {
      display: true,
      type: 'social-links-mod',
      style: {
        color: false,
        fontSize: '14px',
      },
      links: [
        {
          href: '#',
          icon: 'fa fa-twitter',
          background: '#309EEB',
          color: '#fff',
          shape: 'circle'
        },
        {
          href: '#',
          icon: 'fa fa-facebook',
          background: '#5D83E1',
          color: '#fff',
          shape: 'circle'
        },
      ]
    },
    labelSocialLinks: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '21px',
          color: '#0C226C',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 14,
            x: 0
          }
        },
        lg: {
          fontSize: '21px',
          color: '#0C226C',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 12,
            x: 0
          }
        }
      },
      text: "<p><strong>My Socials</strong></p>"
    },
    labelContacts: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '21px',
          color: '#0C226C',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 14,
            x: 0
          }
        },
        lg: {
          fontSize: '21px',
          color: '#0C226C',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 12,
            x: 0
          }
        }
      },
      text: "<p><strong>Contact Me By Phone</strong></p>"
    },
    form: {
      type: 'form-mod',
      display: true,
      action: null,
      selectedList: '',
      btnPosition: 'submit',
      fields: [
        {
          display: true,
          type: 'text',
          name: 'name',
          placeholder: 'Jack Wayley',
          required: true,
          label: 'YOUR NAME',
          cols: {
            sm: 12,
            lg: 6
          }
        },
        {
          display: true,
          type: 'email',
          name: 'email',
          placeholder: 'donnelly.trever@crona.biz',
          required: true,
          label: 'YOUR EMAIL ADDRESS',
          cols: {
            sm: 12,
            lg: 6
          }
        },
        {
          display: true,
          type: 'text',
          name: 'subject',
          placeholder: 'Webdesign',
          required: true,
          label: 'SUBJECT',
          cols: {
            sm: 12,
            lg: 6
          }
        },
        {
          display: true,
          type: 'text',
          name: 'phone',
          placeholder: '1-800-643-4500',
          required: true,
          label: 'YOUR PHONE NUMBER',
          cols: {
            sm: 12,
            lg: 6
          }
        },
        {
          display: true,
          type: 'text',
          name: 'comments',
          fieldType: 'textarea',
          placeholder: 'Hi there, I would like to ...',
          required: true,
          label: 'HOW CAN WE HELP YOU?',
          cols: {
            sm: 12,
            lg: 12
          }
        }
      ]
    },
    button: {
      type: 'button-mod',
      submit: true,
      display: true,
      hover: false,
      text: 'Send Email',
      resolutionStyle: {
        lg: {
          color: '#0C226C',
          backgroundColor: '#0C226C',
          size: 'md',
          shape: null,
          btnStyle: 'outlined',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'sm',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: '#ffffff'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 30,
          bottom: 30
        },
        lg: {
          top: 90,
          bottom: 90
        }
      },
      container: {
        type: 'container-customs',
        container: 'container'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: '',
        position: 'center',
        size: 'cover',
        opacity: 1
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/contact-forms/main-2.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('ContactForm2', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
