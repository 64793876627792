<template>
  <div>
    <div v-for="(field, index) in fieldson" :key="index">
      <slot name="field" :removeField="() => removeField(index)" :index="index" :fieldson="field"></slot>
    </div>

    <slot v-if="fieldson.length < max" name="add-field-trigger" :addField="addField"></slot>
  </div>
</template>

<script>
export default {
  name: "HsAddField",

  props: {
    defaultFields: {
      type: Array,
      required: true
    },
    template: {
      type: Object,
      required: true
    },
    max: {
      type: Number,
      default: 10
    }
  },

  data() {
    return {
      fieldson: this.defaultFields
    }
  },

  methods: {
    addField() {
      const index = this.fieldson.length + 1
      this.fieldson.push(this.template)
      this.$emit('add', index)
    },

    removeField(index) {
      this.$emit('input', index)
      this.fieldson.splice(index, 1)
    }
  }
}
</script>