import SectionProvider from '@/sections/SectionProvider';
import PostSection from '../blog-1/Section'
import store from '@/store'

export const name = 'Blog Container'
export const group = 'blog'

export const defaultOptions = {
  content: {
    title: {
      type: 'blog-text-mod',
      color: store.state.editor.globalStyles.colors.primary,
      fontWeight: '600',
      fontFamily: store.state.editor.globalStyles.font.style
    },
    description: {
      type: 'blog-text-mod',
      color: '',
      fontWeight: '200',
      fontFamily: store.state.editor.globalStyles.font.style,
    },
    date: {
      type: 'blog-text-mod',
      color: '',
      fontWeight: '',
      fontFamily: store.state.editor.globalStyles.font.style,
    },
    button: {
      type: 'blog-button-mod',
      color: store.state.editor.globalStyles.colors.primary,
      btnType: 'link',
      text: 'Read More',
      fontFamily: store.state.editor.globalStyles.font.style,
      link: {
        underline: true
      },
      button: {
        shape: '',
        size: 'sm'
      }
    },
    pagination: {
      type: 'pagination-mod',
      perPage: 9,
      color: store.state.editor.globalStyles.colors.primary,
    }
  },
  customize: {
    layouts: null,
    style: {
      padding: {
        type: 'height-customs',
        sm: {
          top: 20,
          bottom: 20
        },
        lg: {
          top: 20,
          bottom: 20
        }
      },
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/blog/main-3.jpg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('BlogContainer', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
