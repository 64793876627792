import {mapState} from "vuex";

export default {
  data() {
    return {
      reactiveInjection: true
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
    }),
    ...mapState('router', {
      page: state => state.page,
    }),
    getPageInjections() {
      if (!this.globalStyles.hasOwnProperty(this.page)) {
        this.$set(this.globalStyles, this.page, {})
      }

      if (!this.globalStyles[this.page].hasOwnProperty('code_injection')) {
        this.$set(this.globalStyles[this.page], 'code_injection', {
          head: '',
          body: '',
          cdn: []
        })
      }

      return this.globalStyles[this.page].code_injection
    },
  },

  methods: {
    insertPageInjections(script, container) {
      const vWrapper = document.createElement('div'),
        wrapper = document.createElement('div'),
        oldWrapper = document.querySelector('[code-injection]')
        vWrapper.innerHTML = script

      if (oldWrapper) {
        oldWrapper.parentNode.removeChild(oldWrapper)
      }

      wrapper.setAttribute('code-injection', true)

      const scripts = vWrapper.children

      scripts.forEach($s => {
        var dEl = document.createElement($s.tagName),
          attrs = $s.attributes

        dEl.innerHTML = $s.innerHTML
        for (var i = attrs.length - 1; i >= 0; i--) {
          dEl.setAttribute(attrs[i].name, attrs[i].value)
        }
        wrapper.appendChild(dEl)
      })

      container.appendChild(wrapper)
    },
    injectPageAllScripts() {
      if (!this.reactiveInjection) return false
      this.insertPageInjections(this.getPageInjections.head, document.head)
      this.insertPageInjections(this.getPageInjections.body, document.body)
    }
  },

  watch: {
    page() {
      this.injectPageAllScripts()
    }
  },

  mounted() {
    setTimeout(() => this.injectPageAllScripts(), 1000)
  }
}