<template>
  <div :style="{...backgroundColor, ...opacity, ...padding, ...shadow}">
    <div class="video-item-4" :class="container">
      <slot name="title"></slot>
      <slot name="subtitle"></slot>

      <div class="video">
        <slot name="video"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "VideoItems3Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.video-item-4 {
  .video {
    margin-top: 15px;
  }
}
</style>