<template>
  <div class="news-letter-1" :style="{...backgroundColor, ...backgroundImage, ...opacity, ...padding, ...shadow}">
    <div :class="container">
      <b-row class="align-items-center">
        <b-col v-if="visability('image')" :cols="cols(3, 12)">
          <div :class="cols('image-transform', 'my-4')">
            <slot name="image"></slot>
          </div>
        </b-col>

        <b-col :cols="cols(visability('image') ? 4 : 7, 12)">
          <slot name="title"></slot>
          <slot name="subtitle"></slot>
        </b-col>

        <b-col :cols="cols(5, 12)"
               :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
          <slot name="form"></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";
import ImageFit from "../../../../utils/ImageFit";

export default {
  name: "NewsLetterItem1Template",
  components: {ImageFit},
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.editor {
  .news-letter-1 {
    .input-group-append {
      top: -3px;
    }
  }
}

.news-letter-1 {
  background-color: #EDEDED;

  .form-append {
    .form-control {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .form-prepend {
    .form-control {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
  }

  .input-group-append {
    position: relative;

    .ml-3 {
      margin-left: 0 !important;

      button {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }
    }
  }

  .input-group-prepend {
    position: relative;

    .ml-3 {
      margin-right: 0 !important;

      button {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .image-transform {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 1;
  }
}

.resolution-sm {
  .news-letter-1 {
    .btn {
      display: block;
      width: 100%;
    }
  }
}

.preview {
  .news-letter-1 {
    background-color: #EDEDED;

    .input-group-append {
      position: relative;
      top: 0;

      .ml-3 {
        margin-left: 0 !important;

        button {
          border-bottom-left-radius: 0 !important;
          border-top-left-radius: 0 !important;
        }
      }
    }
  }
}
</style>