<template>
  <div class="action-item" :style="{...opacity, ...shadow, ...backgroundColor, ...padding, ...backgroundImage}">
    <div :class="container">
      <slot name="title"></slot>
      <slot name="description"></slot>
      <div class="w-100 text-center">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "FeaturedItems2Template",
    mixins: [TemplateMixin]
  }
</script>

<style scoped lang="scss">

</style>
