<template>
  <highliter class="feature-section" :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
    <VideoItems5Template :section="section">
      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="subtitle">
        <highliter @click.stop.native="sectionSettings('subtitle')">
          <content-editable
              :uid="tiptapUID('subtitle')"
              :customs="section.options.content.subtitle"
              v-model="section.options.content.subtitle.text"></content-editable>
        </highliter>
      </template>

      <template slot="button">
        <highliter autoWidth
                   @click.stop.native="sectionSettings('button')">
          <es-button :customs="section.options.content.button"></es-button>
        </highliter>
      </template>

      <template slot="video">
        <pub-content-editable
            :hardStyles="{fontSize: '32px', color: '#0F0F31', textAlign: 'center', display: 'flex', justifyContent: 'center'}"
            :uid="tiptapUID('title')"
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>

        <pub-content-editable
            :uid="tiptapUID('subtitle')"
            :customs="section.options.content.subtitle"
            v-model="section.options.content.subtitle.text"></pub-content-editable>

        <div class="d-flex justify-content-center w-100">
          <div class="my-4 w-75">
            <video-play  :customs="section.options.content.video"></video-play>
          </div>
        </div>

        <div class="d-block text-center">
          <es-button :customs="section.options.content.button"></es-button>
        </div>
      </template>
   </VideoItems5Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import VideoItems5Template from '@pub/templates/default/sections/videos/VideoItems5Template';
  import PubContentEditable from "../../utils/PubContentEditable";

  export default {
    name: "FeaturedItems1",

    components: {PubContentEditable, VideoItems5Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
