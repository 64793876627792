<template>
  <div :style="{...backgroundColor, ...backgroundImage, ...shadow}">
    <div class="headline-item-3" :class="container" :style="{...opacity, ...padding}">
      <slot name="title"></slot>
      <div class="d-flex my-4" v-if="visability('horizontalLine')">
        <div class="headline-divider"
             :style="{height: section.options.content.horizontalLine.width, background: section.options.content.horizontalLine.color, borderRadius: section.options.content.horizontalLine.pilled ? '25rem' : 0}"></div>
      </div>
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "HeadlineItemTemplate1",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.headline-item-3 {
  .headline-divider {
    height: 13px;
    width: 30%;
    background-color: #1D1D1D;
  }

  p {
    line-height: 1;
    margin-bottom: 0;
  }
}
</style>