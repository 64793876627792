<template>
  <CollapseItems1Template :section="section">
    <template slot="header" scope="content">
      <div v-html="content.content.title" class="d-block w-100"></div>
    </template>

    <template slot="body" scope="content">
      <div v-html="content.content.text"></div>
    </template>
  </CollapseItems1Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import CollapseItems1Template from '@pub/templates/default/sections/collapse/CollapseItems1Template';
  import Editable from "../../../builder/utils/Editable";

  export default {
    name: "CollapseItem1",

    components: {Editable, CollapseItems1Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
