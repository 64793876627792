<template>
    <b-card class="editor-toolbar position-fixed" no-body>
      <transition name="fade" mode="out-in">
        <component :is="page" style="animation-duration: 0.3s"></component>
      </transition>
    </b-card>
</template>

<script>
  import {mapState} from 'vuex'
  import Sections from '@/components/editor/navigation/Sections';
  import SectionSettings from '@/components/editor/navigation/SectionSettings';
  import GlobalStyles from '@/components/editor/navigation/GlobalStyles';
  import FontAdvanced from '@/components/editor/navigation/FontAdvanced';
  import BlogSettings from '@/components/editor/navigation/BlogSettings';
  import PageSettings from "./PageSettings";

  export default {
    name: "EditorToolbar",
    components: {PageSettings, GlobalStyles, SectionSettings, Sections, FontAdvanced, BlogSettings},
    computed: {
      ...mapState( {
        page: state => state.editor.page,
      })
    }
  }
</script>
