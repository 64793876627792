<template>
  <main id="content" role="main" class="main dashboard">
    <div class="row justify-content-center">
      <div class="col-12 col-md-7 col-lg-4">
        <!-- Card -->
        <div class="card card-lg mb-5">
          <div class="card-body">
            <!-- Form -->
            <b-form @submit.prevent="signIn" class="js-validate">
              <div class="text-center">
                <div class="mb-5">
                  <h1 class="display-4">Sign in</h1>
                </div>
              </div>

              <!-- Form Group -->
              <div class="js-form-message form-group">
                <label class="input-label" for="signinSrEmail">Your email</label>

                <input type="email" v-model="form.email" class="form-control form-control-lg" name="email"
                       id="signinSrEmail" tabindex="1" placeholder="email@address.com"
                       aria-label="email@address.com" required data-msg="Please enter a valid email address.">
                <span class="text-danger">{{ error }}</span>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div class="js-form-message form-group">
                <label class="input-label" for="signupSrPassword" tabindex="0">
                        <span class="d-flex justify-content-between align-items-center w-100">
                          Password
                        </span>
                </label>

                <div class="input-group input-group-merge">
                  <input type="password" v-model="form.password"
                         class="js-toggle-password form-control form-control-lg" name="password"
                         id="signupSrPassword" placeholder="8+ characters required"
                         aria-label="8+ characters required" required
                         data-msg="Your password is invalid. Please try again."
                         data-hs-toggle-password-options='{
                                 "target": "#changePassTarget",
                                 "defaultClass": "tio-hidden-outlined",
                                 "showClass": "tio-visible-outlined",
                                 "classChangeTarget": "#changePassIcon"
                               }'>
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Checkbox -->
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="termsCheckbox" name="termsCheckbox">
                  <label class="custom-control-label text-muted" for="termsCheckbox"> Remember me</label>
                </div>
              </div>
              <!-- End Checkbox -->

              <b-btn variant="primary" size="lg" type="submit" block>Sign in</b-btn>
<!--              <span class="d-block text-center my-3">OR</span>-->
<!--              <b-btn block size="lg" to="sign-up">Sign Up</b-btn>-->
            </b-form>
            <!-- End Form -->
          </div>
        </div>
        <!-- End Card -->
      </div>
    </div>
  </main>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "Login",

  data() {
    return {
      error: null,
      form: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    signIn() {
      VEvent.fire('loader', true)

      this.$store.dispatch('auth/signIn', this.form)
          .then(() => {
            this.$router.push('/')
          })
          .catch(error => {
            this.error = error.response.data.message
          })
          .finally(() => {
            VEvent.fire('loader', false)
          })
    }
  }
}
</script>
