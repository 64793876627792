import SectionProvider from '@/sections/SectionProvider';

export const name = 'Blog Item 1'
export const group = ''

export const defaultOptions = {
  content: {
    title: {
      type: 'blog-text-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '20px',
          fontFamily: 'Global Style',
          lineHeight: 1.3,
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '20px',
          fontFamily: 'Global Style',
          lineHeight: 1.3,
          color: '#0C226C',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Don’t Make This One Mistake in Email …Marketing</strong></p>"
    },
    description: {
      type: 'blog-text-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '15px',
          fontFamily: 'Global Style',
          lineHeight: 1.7,
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '15px',
          fontFamily: 'Global Style',
          lineHeight: 1.7,
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Effortless Selling Strategies for Getting Your Prospects to Say 'YES' Quickly! </p>"
    },
    date: {
      type: 'blog-text-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '14px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '14px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>November 10, 2020</p>"
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'READ MORE',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'sm',
          shape: null,
          btnStyle: 'outlined',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    image: {
      display: true,
      type: 'image-mod',
      size: 'cover',
      position: 'center',
      placeholder: require('@/assets/images/blog-peview-1.jpg'),
      src: null
    },
  },
  customize: {
    layouts: null,
    style: {
      padding: {
        type: 'height-customs',
        sm: {
          top: 20,
          bottom: 20
        },
        lg: {
          top: 20,
          bottom: 20
        }
      },
    },
    cols: {
      sm: 12,
      md: 4,
      lg: 4
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/blog/main-1.jpg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('BlogItem1', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
