<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section"
             @click.stop.native="sectionSettings(true)">
   <VideoItems1Template :section="section">
      <template slot="video">
        <highliter v-show="visability('video')" @click.stop.native="sectionSettings('video')">
<!--          <b-embed v-if="section.options.content.video.src"-->
<!--                   type="iframe"-->
<!--                   aspect="16by9"-->
<!--                   :src="section.options.content.video.src"-->
<!--                   allowfullscreen-->
<!--          ></b-embed>-->
<!--          <b-img v-else class="w-100" :src="section.options.content.video.placeholder"></b-img>-->
          <video-play :customs="section.options.content.video"></video-play>
        </highliter>
      </template>

      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>


      <template slot="subtitle">
        <highliter @click.stop.native="sectionSettings('subtitle')">
          <content-editable
              :uid="tiptapUID('subtitle')"
              :customs="section.options.content.subtitle"
              v-model="section.options.content.subtitle.text"></content-editable>
        </highliter>
      </template>
   </VideoItems1Template>
  </highliter>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import VideoItems1Template from '@pub/templates/default/sections/videos/VideoItems1Template';

  export default {
    name: "FeaturedItems1",

    components: {VideoItems1Template, ContentEditable, Highliter},

    mixins: [SectionMixin],

    created() {
      this.section.classes = {
        tiptap: {}
      }
    }
  }
</script>
