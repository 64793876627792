import SectionProvider from '@/sections/SectionProvider';

export const name = 'Quote Item 3'

export default class QuoteItem3 extends SectionProvider {
  constructor(options = {}) {
    super('QuoteItem3', options);

    this.name = name
    this.group = 'quotes'

    this.defaultOptions = {
      content: {
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '18px',
              color: '#6D6D6D',
              align: 'right',
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              color: '#6D6D6D',
              align: 'right',
              fontFamily: 'Global Style',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{
                "type": "text",
                "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus ultrices. Nullam consequat leo vel nunc pellentesque molestie. Aenean aliquam orci quam, commodo suscipit erat imperdiet ut. Donec cursus tellus tempus, iaculis orci."
              }]
            }]
          },
        }
      },
      customize: {
        style: {
          padding: {
            type: 'height-customs',
            sm: {
              top: 30,
              bottom: 30
            },
            lg: {
              top: 30,
              bottom: 30
            }
          },
          container: {
            type: 'container-customs',
            container: null
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
        }
      }
    }
  }
}
