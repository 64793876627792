import SectionProvider from '@/sections/SectionProvider';

export const name = 'About Us'
export const group = 'About Us'

export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '27px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'center',
        },
        lg: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
        }
      },
      text: "<strong>About Us</strong>",
    },
    horizontalLine: {
      type: 'horizontal-line-mod',
      display: true,
      color: '#0C226C',
      width: '3px',
      pilled: false
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'center',
          lineHeight: '1.5'
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          lineHeight: '1.5'
        }
      },
      text: {
        "type": "doc",
        "content": [{
          "type": "paragraph",
          "content": [{
            "type": "text",
            "text": "Duis condimentum malesuada arcu sit amet facilisis. Vivamus egestas, arcu id laoreet mattis, massa nibh consectetur libero, quis ultricies neque tellus vitae arcu. Quisque venenatis mauris quis nunc vulputate fringilla. Nulla in dui vitae libero finibus suscipit in at sem."
          }]
        }]
      },
    },
    image: {
      display: true,
      type: 'image-mod',
      placeholder: require('@/assets/svg/previews/about-us/placeholder-about-us-1-1.png'),
      src: null,
      size: 'cover',
      position: 'center',
    }
  },
  customize: {
    style: {
      backgroundImage: {
        type: 'background-image-customs',
        src: require('@/assets/svg/previews/about-us/placeholder-about-us-1-2.png'),
        position: 'center',
size: 'cover',
        opacity: 1
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 70,
          bottom: 70
        },
        lg: {
          top: 117,
          bottom: 117
        }
      }
    },
  }
}

export const previews = {
  left: require('@/assets/svg/previews/about-us/left.svg'),
  right: null,
  main: require('@/assets/svg/previews/about-us/main.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('AboutUs1', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
