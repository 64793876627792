<template>
  <b-modal v-model="dialog" size="lg" hide-footer hide-header>
    <icon class="cursor-pinter ml-3 mt-3" icon="close.svg" @click.native="dialog = false"></icon>

    <h4 class="h2 text-center my-4">{{page ? 'Edit Page' : 'Add Page'}}</h4>

    <b-form v-if="!page" @submit.prevent="addPage">
      <b-row class="pb-5">
        <b-col cols="8" offset="2">
          <div class="w-100">

            <b-form-group label="Page Name">
              <b-input v-model="name" required></b-input>
            </b-form-group>

            <div class="d-flex">
              <b-btn class="btn-wide d-flex align-items-center mx-auto" variant="primary" type="submit"><i class="fa fa-plus mr-2"></i>Add Page Now</b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
    <div v-else>
      <b-form @submit.prevent="editPage">
        <b-row class="pb-5">
          <b-col cols="8" offset="2">
            <div class="w-100">

              <b-form-group label="Page Name">
                <b-input v-model="page.name" required></b-input>
              </b-form-group>

              <div class="d-flex">
                <b-btn class="btn-wide d-flex align-items-center mx-auto" variant="primary" type="submit">Save</b-btn>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-list-group flush style="max-height: 400px" class="overflow-auto">
      <b-list-group-item v-for="(item, index) in pages" :key="index">
        <div class="d-flex justify-content-between">
          {{item.name}}

          <div>
            <delete-confirm
                title="Are you sure?"
                :description="`The <b>${item.name}</b> page will be removed along with the content content.`"
                @confirm="deletePage(item.key)">
              <template v-slot:activator="{on}">
                <b-btn size="sm" variant="secondary" class="mr-3" @click="setEditablePage(item.key)">
                  <i class="fa fa-pencil"></i>
                </b-btn>
                <b-btn size="sm" variant="danger" v-on="on">
                  <i class="fa fa-trash-o"></i>
                </b-btn>
              </template>
            </delete-confirm>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import DeleteConfirm from "./DeleteConfirm";

export default {
  name: "NewPageModal",
  components: {DeleteConfirm},
  data() {
    return {
      dialog: false,
      name: null,
      page: ''
    }
  },
  computed: {
    ...mapGetters('sections', {
      pages: 'getPages',
    }),
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    ...mapState('sections', {
      sections: state => state.sections
    }),
  },
  methods: {
    addPage() {
      this.$store.dispatch('sections/addPage', {
        name: this.name
      })

      this.dialog = false
      this.name = null
    },
    editPage() {
      VEvent.fire('loader', true)

      this.$store.dispatch('sections/editPage', {
        name: this.page.name,
        key:  _.kebabCase(this.page.key)
      })
    },
    deletePage(key) {
      VEvent.fire('loader', true)
      this.$store.dispatch('sections/deletePage', key)
    },
    setEditablePage(page) {
      const p = this.sections[page].name || _.startCase(page)
      if (page) this.page = {
        name: p,
        key: page
      }
    }
  },
  mounted() {
    VEvent.listen('newPageModal', (page = null) => {
      this.dialog = true
      if (page) {
        this.setEditablePage(page)
      }
    })
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.page = null
      }
    }
  }
}
</script>

<style scoped>

</style>