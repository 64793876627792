<template>
  <div :style="{...padding, ...backgroundColor, ...opacity, ...shadow}">
    <slot name="icon"></slot>
    <div class="my-4"></div>
    <slot name="title"></slot>
    <div class="my-4"></div>
    <slot name="description"></slot>
    <div class="my-4"></div>
    <slot name="button"></slot>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>