<template>
  <div>
    <div class="card h-100 testimonial-item-1" :style="{...opacity, ...shadow, ...backgroundColor, padding}" :class="[cols('', 'mb-4'), theme]">
      <div class="card-body">
        <div class="text-center">
          <b-img :src="require('@pub/assets/png/testimonials.png')"></b-img>
        </div>

        <div class="my-5"></div>

        <slot name="text"></slot>

        <div class="my-5"></div>

        <b-media class="author align-items-center">
          <template slot="aside">
            <slot name="avatar"></slot>
          </template>

          <div class="name">
            <slot name="name"></slot>
          </div>
          <slot name="subname"></slot>
        </b-media>
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import ThemeMixin from "@/components/mixins/ThemeMixin";

  export default {
    name: "FeaturedItems1Template",
    mixins: [TemplateMixin, ThemeMixin]
  }
</script>

<style lang="scss">
  .author {
    p {
      margin-bottom: 0;
    }
  }

  .testimonial-item-1.dark,
  .testimonial-item-1.dark .editor__content,
  .testimonial-item-1.dark .pub__content {
    color: #fff;
  }

  .testimonial-item-1.light,
  .testimonial-item-1.light .editor__content,
  .testimonial-item-1.light .pub__content {
    color: #6E747A;
  }

  .testimonial-item-1.light .name {
    .editor__content,
    .pub__content {
      color: #0C226C
    }
  }
</style>
