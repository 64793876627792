<template>
  <div class="container position-static">
    <img v-if="visability('image')" class="position-absolute float-image img-fluid" :src="section.options.content.image.src" alt="Float Image" :style="{marginTop: section.options.content.image.top + '%', marginLeft: section.options.content.image.left + '%'}">
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin';
import Highliter from '../../../utils/Highliter';
import CustomStylesMixin from '../../../../mixins/CustomStylesMixin';
import {defaultOptions} from "./Section";

export default {
  name: "EsButtonUtil",
  components: {Highliter},
  mixins: [SectionMixin, CustomStylesMixin],

  created() {
    this.merger(defaultOptions)
  }
}
</script>

