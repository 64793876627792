<template>
  <div class="custom-menu">
    <div class="d-flex align-items-center mb-4">
      <span class="indicator mr-2"></span>
      <p class="text-capitalize mb-0">{{labelCase(componentName)}}</p>
    </div>

    <image-upload :image="config" class="w-100 mb-3"></image-upload>

    <b-form-radio-group v-model="position" class="resolution-style-tabs" buttons-variant="light" value-field="key" text-field="text" size="sm" buttons :options="[{text: 'TOP', key: 'top'}, {text: 'LEFT', key: 'left'}]"></b-form-radio-group>

    <b-row no-gutters class="align-items-center">
      <b-col cols="9" class="mr-2">
        <b-input type="range" v-model="config[position]" min="-100" max="200"></b-input>
      </b-col>
      <b-col>
        <b-input min="0" type="number" step="1" v-model="config[position]" size="sm"></b-input>
      </b-col>
    </b-row>

    <div class="mb-5"></div>
    <Panel :config="config"></Panel>
  </div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import SliederToggle from '@/components/editor/controls/SliederToggle';
  import Panel from '../controls/Panel';
  import ImageUpload from "../utils/ImageUpload";

  export default {
    name: "ImageMod",
    components: {ImageUpload, Panel, SliederToggle},
    mixins: [ModMixin],

    data() {
      return {
        position: 'top'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn-es-image-upload {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #E2E5EC;
    font-size: 14px;
    font-weight: 600;
    color: #6E747A;
    height: 38px;
    box-shadow: 0 1px 2px 0 rgba(226,226,226,0.5)
  }
</style>
