<template>
  <Price1Template :section="section">
    <b-breadcrumb>
      <b-breadcrumb-item>Home</b-breadcrumb-item>
      <b-breadcrumb-item>Services</b-breadcrumb-item>
      <b-breadcrumb-item active>Done For You</b-breadcrumb-item>
    </b-breadcrumb>
  </Price1Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Price1Template from "./Price1Template";
import PubContentEditable from "../../../utils/PubContentEditable";
import PubEsButton from "../../../utils/buttons/PubEsButton";

export default {
  name: "Carousel1",

  components: {PubEsButton, PubContentEditable, Price1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
