<template>
  <div v-if="section.options.customize.display" class="navbar-1 navbar-scroll"
       :style="{...backgroundColor, ...shadow, ...opacity}">
    <b-navbar :toggleable="resolution === 'sm'">
      <div class="container">
        <b-navbar-brand>
          <div v-if="visability('logo')">
            <pub-logo :customs="section.options.content.logo"></pub-logo>
          </div>
        </b-navbar-brand>

        <b-navbar-toggle class="pr-0" target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <div v-show="visability('menuItems')"
               class="links"
               v-if="visability('menuItems')"
               auto-width>
            <es-menu-items :customs="section.options.content.menuItems"></es-menu-items>
          </div>

          <b-navbar-nav :class="{'ml-auto': !visability('menuItems')}">
            <div v-if="visability('button')"
                 auto-width>
              <pub-es-button :customs="section.options.content.button"></pub-es-button>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin';
import Highliter from '@/components/builder/utils/Highliter';
import NavbarScroller from "../../../mixins/NavbarScroller";

export default {
  name: "Navbar1",

  components: {Highliter},

  mixins: [SectionMixin, NavbarScroller],

  created() {
    if (!this.section.options.content.menuItems.hasOwnProperty('align')) {
      this.$set(this.section.options.content.menuItems, 'align', 'right')
    }
  }
}
</script>

<style lang="scss">
.resolution-lg {
  .navbar-1 {
    .links {
      margin-left: auto;
    }

    .navbar-brand {
      margin-right: 90px;
    }

    .nav-item {
      margin-right: 20px !important;
    }
  }
}

.resolution-sm {
  .navbar-1 {
    .btn {
      display: block;
      width: 100%;
    }
  }
}
</style>
