<template>
<div class="d-flex align-items-center justify-content-center vh-100">
  <div class="text-center">
    <h4 class="text-muted mb-4"><i class="fa fa-info-circle mr-2"></i> Copy and paste the key below into the service integration window on the previous browser tab in the "Code after redirect" field</h4>
    <div class="d-flex align-items-center justify-content-center">
      <h2 class="mb-0">{{$route.query.key}}</h2>
      <b-btn class="ml-3" variant="primary"><i class="far fa-copy"></i></b-btn>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: "CodeAPI"
}
</script>

<style scoped>

</style>