<template>
  <div>
    <settings-page-card title="Domain Management">
      <div class="px-4">
        <b-row>
          <b-col md="7">
            <doamin-connect-form/>
          </b-col>

          <b-col md="8">

            <hr class="my-5">

            <b-alert show variant="info" class="mb-4">
              <b>Note: </b>
              <span>it can take 24-48 hours for DNS Records to fully propagate.</span>
            </b-alert>

            <h5>Target domain for CNAME record</h5>
            <p>Below in the text field, you can copy the address that you must specify for the CNAME record from your
              registrar.</p>
            <b-row>
              <b-col md="6">
                <b-input-group>
                  <b-input :value="target" readonly></b-input>
                  <b-input-group-append>
                    <b-btn variant="primary"
                           v-b-tooltip.top="tooltipText"
                           v-clipboard:copy="target"
                           v-clipboard:success="onCopy">
                      <i class="fa fa-clipboard"></i>
                    </b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <hr class="my-5">

            <h5>Setup Cloudflare</h5>
            <ul>
              <li class="mb-2 font-weight-bold text-muted">Register a domain name if you don't have one yet (GoDaddy, NameCheap, or even Cloudflare)</li>
              <li class="mb-2 font-weight-bold text-muted">Signup for a "free" account with <a target="_blank" href="https://cloudflare.com">Cloudflare.com</a> account</li>
              <li class="mb-3 font-weight-bold text-muted">
                Add your domain name into Cloudflare.
                <img class="img-fluid mt-2" src="@/assets/images/domain-settings/3 - Add new domain into cloudflare.png">
              </li>
              <li class="mb-3 font-weight-bold text-muted">
                Choose free Cloudflare option and click continue.
                <img class="img-fluid mt-2" src="@/assets/images/domain-settings/4 - Choose Free Cloudflare option.png">
              </li>
              <li class="mb-3 font-weight-bold text-muted">
                Delete all records previously imported by Cloudflare.
                <img class="img-fluid mt-2" src="@/assets/images/domain-settings/5 - Delete all records automatically imported by Cloudflare.png">
              </li>
              <li class="mb-3 font-weight-bold text-muted">
                Add "www" CNAME pointing to "{{target}}"
                <img class="img-fluid mt-2" src="@/assets/images/domain-settings/6 - Add 'www' CNAME pointing to 'target.estage.com'.png">
              </li>
              <li class="mb-3 font-weight-bold text-muted">
                Add "@" CNAME pointing to "{{target}}"
                <img class="img-fluid mt-2" src="@/assets/images/domain-settings/6b - Add '@' CNAME pointing to 'target.estage.com'.png">
              </li>
              <li class="mb-3 font-weight-bold text-muted">
                Add TXT record for domain value = "estage" (also put "estage" in the CONTENT box).
                <img class="img-fluid mt-2" src="@/assets/images/domain-settings/6c - Add TXT record for domain value = 'estage'.png">
              </li>
              <li class="mb-2 font-weight-bold text-muted">Save and change nameservers in your domain registrar (GoDaddy or Namecheap, etc..) to point to Cloudflare (if domain is registered wit Cloudflare - skip this step).</li>
            </ul>

            <b-alert show variant="info">
              Make sure that orange cloud is enabled for all records with Cloudflare (this is what will create the SSL)
            </b-alert>

            <ul>
              <li class="mb-2 font-weight-bold text-muted">Under Cloudflare settings, set the SSL value to "Full".</li>
            </ul>
          </b-col>
        </b-row>
      </div>
    </settings-page-card>
  </div>
</template>

<script>
import ModuleHeader from "../../components/editor/header/ModuleHeader";
import DoaminConnectForm from "./DoaminConnectForm";
import SettingsPageCard from "../../layouts/dashboard/SettingsPageCard";

export default {
  name: "Domain",
  components: {SettingsPageCard, DoaminConnectForm, ModuleHeader},
  data() {
    return {
      tooltipText: 'Copy',
      target: 'target.estage.com'
    }
  },
  methods: {
    onCopy() {
      this.tooltipText = 'Copied!'

      setTimeout(() => {
        this.tooltipText = 'Copy'
      }, 800)
    }
  }
}
</script>