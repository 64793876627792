  import SectionProvider from '@/sections/SectionProvider';

export const name = 'HeadlineItem2'

export default class HeadlineItem2 extends SectionProvider {
  constructor(options = {}) {
    super('HeadlineItem2', options);

    this.name = name
    this.group = 'headlines'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '54px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
            },
            lg: {
              fontSize: '54px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "Your Best Headline Ever"}]
            }]
          },
        },
        horizontalLine: {
          type: 'horizontal-line-mod',
          display: true,
          color: '#AEB1B4',
          width: '2px',
          pilled: false
        },
        subtitle: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "text": "Explore our wide selection today"}]
            }]
          },
        }
      },
      customize: {
        style: {
          container: {
            type: 'container-customs',
            container: 'container'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 160,
              bottom: 160
            },
            lg: {
              top: 160,
              bottom: 160
            }
          }
        }
      }
    }
  }
}
