import SectionProvider from '@/sections/SectionProvider';

export const name = 'Contact Form 1'
export const group = 'contact-forms'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '36px',
          fontFamily: 'Global Style',
          color: 'adaptive',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: 'adaptive',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Contact Us Today</strong></p>"
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '16px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '16px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Your Company Name</p><p>1467 Highway Blv</p><p>Hollywood, California, 90210</p>"
    },
    info: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '16px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '16px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Ph: (045) 345 645 4545</p><p><strong><span style='color:#0C226C'>support@gmail.com</span></strong></p>"
    },
    socialLinks: {
      display: true,
      type: 'social-links-mod',
      style: {
        color: false,
        fontSize: '14px',
      },
      links: [
        {
          href: '#',
          icon: 'fa fa-twitter',
          background: '#309EEB',
          color: '#fff',
          shape: 'circle'
        },
        {
          href: '#',
          icon: 'fa fa-facebook',
          background: '#5D83E1',
          color: '#fff',
          shape: 'circle'
        },
      ]
    },
    labelSocialLinks: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '16px',
          color: '#6B747C',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 14,
            x: 0
          }
        },
        lg: {
          fontSize: '16px',
          color: '#6B747C',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 12,
            x: 0
          }
        }
      },
      text: "<p><strong>My Socials</strong></p>"
    },
    form: {
      type: 'form-mod',
      display: true,
      action: null,
      selectedList: '',
      btnPosition: 'submit',
      fields: [
        {
          display: true,
          type: 'text',
          name: 'name',
          placeholder: 'Your Name',
          required: true,
          label: null,
          cols: {
            sm: 12,
            lg: 6
          }
        },
        {
          display: true,
          type: 'email',
          name: 'email',
          placeholder: 'Email address',
          required: true,
          label: null,
          cols: {
            sm: 12,
            lg: 6
          }
        },
        {
          display: true,
          type: 'text',
          name: 'comments',
          fieldType: 'textarea',
          placeholder: 'Your Comments',
          required: true,
          label: null,
          cols: {
            sm: 12,
            lg: 12
          }
        }
      ]
    },
    button: {
      type: 'button-mod',
      submit: true,
      display: true,
      hover: false,
      text: 'Send Form',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'sm',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'sm',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: '#ffffff'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 30,
          bottom: 30
        },
        lg: {
          top: 90,
          bottom: 90
        }
      },
      container: {
        type: 'container-customs',
        container: 'container'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: '',
        position: 'center',
        size: 'cover',
        opacity: 1
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/contact-forms/main-1.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('ContactForm1', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
