<template>
  <div :style="{...backgroundImage, ...padding, ...backgroundColor, ...opacity, ...shadow}">
    <div class="action-item-6" :class="container">
      <div class="leading">
        <slot name="leading"></slot>
      </div>
      <div class="my-2"></div>
      <slot name="title"></slot>
      <div class="my-4"></div>
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.action-item-6 {
  .leading {
    letter-spacing: 0.17em;
    text-transform: uppercase;
  }
}
</style>