<template>
  <highliter class="feature-section" :label="section.name" :dublicate="section" :uid="section.uid" @click.stop.native="sectionSettings(true)">
    <FeaturedItems1Template :section="section">
      <template slot="image">
        <highliter @click.stop.native="sectionSettings('image')">
          <image-fit
              :customs="section.options.content.image"
              :src="section.options.content.image.src || section.options.content.image.placeholder"
              :size="cols('cover', 'contain')"
              :height="cols('55vh', '300px')"
              :position="section.options.content.image.position"
          ></image-fit>
        </highliter>
      </template>

      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>

      <template slot="button">
        <highliter autoWidth
                   @click.stop.native="sectionSettings('button')">
          <es-button :customs="section.options.content.button"></es-button>
        </highliter>
      </template>
   </FeaturedItems1Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import FeaturedItems1Template from '@pub/templates/default/sections/featured/FeaturedItems1Template';
  import ImageFit from "../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {ImageFit, FeaturedItems1Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
