<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <AboutUs1Template :section="section">
     <highliter @click.stop.native="sectionSettings('description')">
       <content-editable
           :uid="tiptapUID('description')"
           :customs="section.options.content.description"
           v-model="section.options.content.description.text"></content-editable>
     </highliter>
   </AboutUs1Template>
  </highliter>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import AboutUs1Template from "./AboutUs1Template";

  export default {
    name: "FeaturedItems1",

    components: {AboutUs1Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
