import SectionProvider from '@/sections/SectionProvider';
import EsRow from "../utils/EsRow";
import TextAndImageItem1 from './TextAndImageItem1';
import TextAndImageItem2 from './TextAndImageItem2';

export const name = 'TextAndImageItem2Group'

export default class TextAndImageItem2Group extends SectionProvider {
  constructor(options, items) {
    super('EsRow', options);

    this.name = name
    this.group = 'textAndImage'

    this.defaultOptions = new EsRow({
      customize: {
        style: {
          padding: {
            type: 'height-customs',
            sm: {
              top: 50,
              bottom: 50
            },
            lg: {
              top: 115,
              bottom: 115
            }
          },
          background: {
            background: '#1F3B9C'
          }
        }
      }
    }, [
      new TextAndImageItem2({
        content: {
          title: {
            type: 'tiptap-mod',
            display: true,
            resolutionStyle: {
              sm: {
                fontSize: '32px',
                fontFamily: 'Global Style',
                color: '#fff',
                align: 'left',
              },
              lg: {
                fontSize: '46px',
                fontFamily: 'Global Style',
                color: '#fff',
                align: 'left',
              }
            },
            text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text", "marks": [{"type": "bold"}], "text":"Curabitur tristique, lorem et "}]}]},
          },
          text: {
            type: 'tiptap-mod',
            display: true,
            resolutionStyle: {
              sm: {
                fontSize: '18px',
                fontFamily: 'Global Style',
                lineHeight: 1.3,
                color: '#8E9CCD',
                align: 'left',
                padding: {
                  bottom: 20
                }
              },
              lg: {
                fontSize: '18px',
                fontFamily: 'Global Style',
                lineHeight: 1.3,
                color: '#8E9CCD',
                align: 'left',
              }
            },
            text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit. Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit."}]}]},
          },
          image: {
            placeholder: require('@/assets/images/placeholder-text-and-image-2-1.jpg'),
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 12,
            lg: 12
          }
        }
      }).json(),
      new TextAndImageItem2({
        content: {
          title: {
            type: 'tiptap-mod',
            display: true,
            resolutionStyle: {
              sm: {
                fontSize: '32px',
                fontFamily: 'Global Style',
                color: '#fff',
                align: 'left',
              },
              lg: {
                fontSize: '46px',
                fontFamily: 'Global Style',
                color: '#fff',
                align: 'left',
              }
            },
            text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text", "marks": [{"type": "bold"}], "text":"Curabitur tristique, lorem et "}]}]},
          },
          text: {
            type: 'tiptap-mod',
            display: true,
            resolutionStyle: {
              sm: {
                fontSize: '18px',
                fontFamily: 'Global Style',
                lineHeight: 1.3,
                color: '#8E9CCD',
                align: 'left'
              },
              lg: {
                fontSize: '18px',
                fontFamily: 'Global Style',
                lineHeight: 1.3,
                color: '#8E9CCD',
                align: 'left',
              }
            },
            text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit. Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit."}]}]},
          },
          image: {
            placeholder: require('@/assets/images/placeholder-text-and-image-2-2.jpg'),
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 12,
            lg: 12
          }
        }
      }).json()
    ]).json().options
  }
}
