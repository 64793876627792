<template>
  <div :style="{...backgroundImage, ...padding, ...backgroundColor, ...opacity, ...shadow}">
    <div class="action-item-12" :class="container">
      <b-row class="align-items-center">
        <b-col :cols="cols(6, 12)">
          <slot name="title"></slot>
        </b-col>

        <b-col :cols="cols(6, 12)">
          <div class="d-flex align-items-center">
            <slot name="button"></slot>
            <slot name="button2"></slot>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.action-item-10 {
  .vertical-devider {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 1px;
    background: #D8D8D8;
    transform: translateX(-50%);
  }
}
</style>