<template>
  <b-row class="px-4"
  >
    <b-col cols="12">
      <h5>{{ service.name }} <span v-if="service.status">(<span class="text-success small">Connected</span>)</span></h5>
      <p class="text-muted">{{ service.short_description }}</p>
    </b-col>

    <b-col md="6">
      <b-form>
        <template v-if="!service.status">
          <template v-if="codeStep">
            <b-form-group v-if="true" label="Code after redirect">
              <b-input v-model="service.client_secret" type="password"></b-input>
            </b-form-group>

            <b-btn variant="primary"
                   @click="integrateMailService"
                   :disabled="!service.client_secret"
            >
              Intergrate
            </b-btn>
          </template>

          <b-btn v-else variant="primary"
                 @click="authorize"
          >
            Authorize
          </b-btn>
        </template>

        <b-btn v-else
               variant="danger"
               @click="deleteMailService(service)"
        >
          Delete
        </b-btn>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import AutoResponderIntergationMixin from "./mixins/AutoResponderIntergationMixin";
import {mapState} from "vuex";
import Cookie from "../../../mixins/Cookie";

export default {
  name: "AWeber",
  mixins: [AutoResponderIntergationMixin, Cookie],

  data() {
    return {
      codeStep: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  methods: {
    async authorize() {
      await axios.get(`api/projects/${this.project.id}/auto-responders/${this.service.id}/auth-url`)
          .then(({data}) => {
            this.set_cookie('mailServiceAPI', this.project.id + ',' + this.service.id)
            window.location = data.data
          })
    },

    async integrateMailService() {
      VEvent.fire('loader', true)

      await axios.get(`api/projects/${this.project.id}/auto-responders/${this.service.id}/token`, {params: {code: this.service.client_secret}})
          .then(({data}) => {
            this.service.status = data.status
            this.$emit('integrated')
          })
          .finally(() => {
            VEvent.fire('loader', false)
          })
    }
  }
}
</script>