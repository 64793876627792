<template>
  <div :style="{...opacity}">
    <div class="mb-3">
      <slot name="image"></slot>
    </div>

    <div >
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems1Template",
  mixins: [TemplateMixin]
}
</script>
