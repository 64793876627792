import {Mark, Plugin} from 'tiptap'
import { updateMark } from 'tiptap-commands'
import { Link } from 'tiptap-extensions'

export default class CustomLink extends Link {
  get schema() {
    return {
      attrs: {
        href: {
          default: null,
        },
        dataVueHref: {
          default: null,
        }
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: dom => {
            return ({
              dataVueHref: dom.getAttribute('dataVueHref'),
              href: dom.getAttribute('href'),
            })
          },
        },
      ],
      toDOM: node => {
        return ['a', {
          ...node.attrs,
          dataVueHref: node.attrs.dataVueHref,
          href: node.attrs.href
        }, 0]
      },
    }
  }

  get plugins() {
    if (!this.options.openOnClick) {
      return [];
    }

    return [new Plugin({
      props: {
        handleClick: (view, pos, event) => {
          return false;
        }
      }
    })];
  }
}