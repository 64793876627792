<template>
  <Template :section="section">
    <template slot="title">
      <pub-content-editable
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description">
      <pub-content-editable
          :uid="tiptapUID('description')"
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>
    
    <template slot="date">
      <pub-content-editable
          :uid="tiptapUID('date')"
          :customs="section.options.content.date"
          v-model="section.options.content.date.text"></pub-content-editable>
    </template>

    <template slot="image">
      <image-fit
          :customs="section.options.content.image"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="section.options.content.image.size"
          height="297px"
          :position="section.options.content.image.position"
      ></image-fit>
    </template>
  </Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Template from './Template';
  import ImageFit from "../../../../utils/ImageFit";
  import PubContentEditable from "../../../utils/PubContentEditable";
  import PubEsButton from "../../../utils/buttons/PubEsButton";

  export default {
    name: "ActionItem2",

    components: {PubEsButton, PubContentEditable, ImageFit, Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
