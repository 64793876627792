<template>
  <div :style="{...padding, ...backgroundColor, ...opacity, ...shadow}">
    <div :class="container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "AboutUs1Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .about-us-4 {
    .headline-divider {
      width: 180px;
    }
  }
</style>
