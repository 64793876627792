export default {
  computed: {
    backgroundImage() {
      try {
        return {
          backgroundImage: `url(${this.section.options.customize.style.backgroundImage.src})`,
          backgroundSize: this.section.options.customize.style.backgroundImage.size,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: this.section.options.customize.style.backgroundImage.position
        }
      } catch {
        this.$set(this.section.options.customize.style, 'backgroundImage', {
          type: 'background-image-customs',
          src: null,
          position: 'center',
size: 'cover',
          opacity: 1
        })

        return {
          backgroundImage: `url(${this.section.options.customize.style.backgroundImage.src})`,
          backgroundSize: this.section.options.customize.style.backgroundImage.size,
          backgroundRepeat: 'no-repeat'
        }
      }
    },
    padding() {
      try {
        return {
          paddingTop: this.section.options.customize.style.padding[this.resolution].top + 'px',
          paddingBottom: this.section.options.customize.style.padding[this.resolution].bottom + 'px'
        }
      } catch {
        this.$set(this.section.options.customize.style, 'padding', {
          type: 'height-customs',
          sm: {
            top: 0,
            bottom: 0
          },
          lg: {
            top: 0,
            bottom: 0
          }
        })

        return {
          paddingTop: this.section.options.customize.style.padding[this.resolution].top + 'px',
          paddingBottom: this.section.options.customize.style.padding[this.resolution].bottom + 'px'
        }
      }
    },
    container() {
      return this.globalStyles.container || 'container'
    },
    shadow() {
      return {
        boxShadow: this.section.options.customize.style.shadow.shadow
      }
    },
    dropShadow() {
      return {
        filter: `drop-shadow(${this.section.options.customize.style.shadow.shadow})`
      }
    },
    opacity() {
      return {
        opacity: this.section.options.customize.style.opacity.opacity
      }
    },
    backgroundColor() {
      return {
        backgroundColor: this.section.options.customize.style.background.background === 'colored' ? this.globalStyles.colors.primary : this.section.options.customize.style.background.background
      }
    },
    color() {
      return {
        color: this.section.options.customize.style.color.color
      }
    }
  },
  methods: {
    backgroundColorCustom(name) {
      return {
        backgroundColor: this.section.options.customize.style['background' + name].background === 'colored' ? this.globalStyles.colors.primary : this.section.options.customize.style['background' + name].background
      }
    },
    colorCustom(name) {
      return {
        color: this.section.options.customize.style['color' + name].color === 'adaptive' ? '' : this.section.options.customize.style['color' + name].color
      }
    },
  }
}
