<template>
  <div :style="{...opacity, ...padding}">
    <div :style="{...shadow}">
      <slot name="image"></slot>
    </div>
    <div class="my-3"></div>
    <slot name="text"></slot>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "TextAndImageItem1Template",
    mixins: [TemplateMixin]
  }
</script>

<style scoped>

</style>
