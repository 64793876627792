import SectionProvider from '@/sections/SectionProvider';

export const name = 'ListItem2'

export default class ListItem2 extends SectionProvider {
  constructor(options = {}) {
    super('ListItem2', options);

    this.name = name
    this.group = 'list'

    this.defaultOptions = {
      content: {
        list: {
          type: 'list-mod',
          display: true,
          rows: 1,
          items: {
            1: [
              {
                label: 'Item 1',
                text: 'Curabitur tristique, lorem et dignissim consequat,'
              },
              {
                label: 'Item 2',
                text: 'nisi nisi tincidunt mauris, ac dictum',
              },
              {
                label: 'Item 3',
                text: 'neque odio eget elit.',
              },
              {
                label: 'Item 4',
                text: 'In sit amet malesuada risus.'
              }
            ],
            2: [
              {
                label: 'Item 1',
                text: 'Curabitur tristique, lorem et dignissim consequat,'
              },
              {
                label: 'Item 2',
                text: 'nisi nisi tincidunt mauris, ac dictum',
              },
              {
                label: 'Item 3',
                text: 'neque odio eget elit.',
              },
              {
                label: 'Item 4',
                text: 'In sit amet malesuada risus.'
              }
            ],
            3: [
              {
                label: 'Item 1',
                text: 'Curabitur tristique, lorem et dignissim consequat,'
              },
              {
                label: 'Item 2',
                text: 'nisi nisi tincidunt mauris, ac dictum',
              },
              {
                label: 'Item 3',
                text: 'neque odio eget elit.',
              },
              {
                label: 'Item 4',
                text: 'In sit amet malesuada risus.'
              }
            ]
          }
        }
      },
      customize: {
        style: {
          listIcon: {
            type: 'list-icon-customs',
            icon: 'fa fa-chevron-right',
            color: '#149FA7'
          },
          color: {
            type: 'color-customs',
            color: '#8C8C8C'
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 40,
              bottom: 40
            },
            lg: {
              top: 40,
              bottom: 40
            }
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
        },
      }
    }
  }
}
