<template>
  <div class="d-block w-100 text-center">
    <pub-es-button :customs="section.options.content.button"></pub-es-button>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin';
import CustomStylesMixin from '../../../mixins/CustomStylesMixin';

export default {
  name: "EsRow",
  mixins: [SectionMixin, CustomStylesMixin],
}
</script>
