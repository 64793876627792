<template>
  <AboutUs2Template :section="section">
    <template slot="image">
      <ImageItem1Template :section="section" v-show="visability('image')">
        <div v-for="(image, index) in section.options.content.image.items" :key="index" class="img-transform"
             :style="{opacity: image.opacity}">
          <image-fit
              :customs="image"
              :src="image.src || image.placeholder"
              :size="cols(image.size, 'contain')"
              :position="image.position"
          ></image-fit>
        </div>
      </ImageItem1Template>
    </template>

    <template slot="title">
      <pub-content-editable
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description">
      <pub-content-editable
          :uid="tiptapUID('description')"
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>
  </AboutUs2Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ActionItem1Template from '@pub/templates/default/sections/actions/ActionItem1Template';
import PubContentEditable from '@/components/builder/utils/PubContentEditable';
import EsImage from "@/components/builder/utils/EsImage";
import AboutUs2Template from "./AboutUs2Template";
import ImageItem1Template from '@pub/templates/default/sections/images/ImageItem1Template'
import ImageFit from "../../../../utils/ImageFit";

export default {
  name: "FeaturedItems1",

  components: {
    ImageFit,
    AboutUs2Template,
    EsImage, PubContentEditable, ActionItem1Template, ContentEditable, Highliter,
    ImageItem1Template
  },

  mixins: [SectionMixin],
}
</script>
