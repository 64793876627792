<template>
  <div class="global-styles overflow-y-auto pb-3">
    <b-card-header header-class="editor-toolbar-card-header d-flex justify-content-between align-items-center">
      <h5 class="editor-toolbar-title mb-0">Page Settings</h5>

      <b-link class="d-flex align-items-center font-weight-light small link-muted"
              @click="back">
        <icon class="mr-2" icon="left-arrow.svg"></icon>
        Go Back
      </b-link>
    </b-card-header>

    <b-card class="border-0 mb-4">
      <div>
        <div class="d-flex align-items-center mb-4">
          <span class="indicator mr-2"></span>
          <p class="text-capitalize mb-0">Blog</p>
        </div>

        <b-form-group>
          <b-input type="search" placeholder="Search in the blog"></b-input>
        </b-form-group>


        <b-btn variant="outline-primary" block v-b-modal.blog-modal>New Post</b-btn>



      </div>
    </b-card>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import NewPostModal from "../modals/NewPostModal";

export default {
  name: "PageSettings",
  components: {NewPostModal},
  methods: {
    back() {
      this.$store.dispatch('router/setPage', 'home')
      this.$store.commit('editor/SET_PAGE', 'page-settings')
    }
  }
}
</script>

