<template>
  <div class="feature-section">
    <FeaturedItems1Template :section="section">
      <template slot="image">
          <image-fit
              :customs="section.options.content.image"
              :src="section.options.content.image.src || section.options.content.image.placeholder"
              :size="cols('cover', 'contain')"
              :height="cols('55vh', '300px')"
              :position="section.options.content.image.position"
          ></image-fit>
      </template>

      <template slot="title">
        <pub-content-editable
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="description">
        <pub-content-editable
            :customs="section.options.content.description"
            v-model="section.options.content.description.text"></pub-content-editable>
      </template>

      <template slot="button">
        <pub-es-button :customs="section.options.content.button"></pub-es-button>
      </template>
    </FeaturedItems1Template>
  </div>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import FeaturedItems1Template from '@pub/templates/default/sections/featured/FeaturedItems1Template';
import PubContentEditable from "../../../builder/utils/PubContentEditable";
import PubEsButton from "../../../builder/utils/buttons/PubEsButton";
import ImageFit from "../../../utils/ImageFit";

export default {
  name: "FeaturedItems1",

  components: {ImageFit, PubEsButton, PubContentEditable, FeaturedItems1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
