<template>
  <Template :section="section">
    <template slot="title">
      <pub-content-editable
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description">
      <pub-content-editable
          :uid="tiptapUID('description')"
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="info">
      <pub-content-editable
          :uid="tiptapUID('info')"
          :customs="section.options.content.info"
          v-model="section.options.content.info.text"></pub-content-editable>
    </template>

    <template slot="labelSocialLinks">
      <pub-content-editable
          :uid="tiptapUID('labelSocialLinks')"
          :customs="section.options.content.labelSocialLinks"
          v-model="section.options.content.labelSocialLinks.text"></pub-content-editable>
    </template>

    <!-- Social -->
    <template slot="social">
      <ul class="list-unstyled list-inline">
        <li v-for="(link, index) in section.options.content.socialLinks.links" :key="index"
            class="list-inline-item mr-3">
          <b-link :class="'social-link social-link-' + link.shape"
                  :style="{...section.options.content.socialLinks.style, ...{color: link.color, backgroundColor: link.background}}">
            <i :class="link.icon"></i>
          </b-link>
        </li>
      </ul>
    </template>
    <!-- End Social -->

    <!-- Form -->
    <template slot="form">
      <es-form :customs="section.options.content.form">
        <template :slot="cols(section.options.content.form.btnPosition, 'submit')">
          <pub-es-button type="submit" :customs="section.options.content.button"></pub-es-button>
        </template>
      </es-form>
    </template>
    <!-- End Form -->
  </Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from './Template';
import PubContentEditable from "../../../utils/PubContentEditable";
import PubEsButton from "../../../utils/buttons/PubEsButton";

export default {
  name: "ActionItem2",

  components: {PubEsButton, PubContentEditable, Template, ContentEditable, Highliter},

  mixins: [SectionMixin],
}
</script>
