<template>
  <div class="d-flex position-relative" :style="{...opacity, ...shadow}" :class="container">
    <slot name="image"></slot>

      <div class="action-item ml-auto" :style="{...backgroundColor}">
      <slot name="title"></slot>
      <div class="my-4"></div>
      <slot name="description"></slot>
      <div class="my-4"></div>
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "FeaturedItems2Template",
    mixins: [TemplateMixin]
  }
</script>

<style scoped lang="scss">
  .action-item {
    background-color: #AEB1B4;
    padding: 90px;
    padding-left: 120px;
    width: 65%;
    -webkit-clip-path: polygon(63% 0, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(17% 0, 100% 0%, 100% 100%, 0% 100%);
  }

  .image {
    position: absolute;
    left: 0;
    height: 100%;
    width: 50%;
    background-position: left;
    -webkit-clip-path: polygon(0 0, 100% 0, 61% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
    background-color: red;
  }
</style>
