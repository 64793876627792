<template>
    <ResourceItem1Template :section="section">
      <template slot="icon">
        <highliter v-show="visability('icon')" @click.stop.native="sectionSettings('icon')">
          <icon-editable :customs="section.options.content.icon"></icon-editable>
        </highliter>
      </template>

      <template slot="title">
          <content-editable
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
      </template>

      <template slot="description">
          <content-editable
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
      </template>

      <template slot="button">
          <pub-es-button :customs="section.options.content.button"></pub-es-button>
      </template>
    </ResourceItem1Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from "../../../builder/utils/PubContentEditable";
import ResourceItem1Template from '@pub/templates/default/sections/resource/ResourceItem1Template';
import PubEsButton from "../../../builder/utils/buttons/PubEsButton";

export default {
  name: "ImageItem3",

  components: {PubEsButton, ResourceItem1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
