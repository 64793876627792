<template>
  <div>
    <settings-page-card title="General Settings">
      <b-form @submit.prevent="onSubmit">
        <div class="px-4">
          <b-row>
            <b-col md="5">
              <b-form-group>
                <slot name="label">
                  <h6 class="font-weight-sami-bold text-dark">Project Name</h6>
                </slot>
                <b-input v-model="projectName"
                         name="name"
                         :state="errors.has('name') ? false : null"
                         v-validate="'required'"></b-input>
              </b-form-group>

              <b-form-group>
                <slot name="label">
                  <h6 class="font-weight-sami-bold text-dark">Subdomain</h6>
                </slot>
                <b-input v-model="subdomain"
                         name="subdomain"
                         :state="errors.has('subdomain') ? false : null"
                         v-validate="'required'"></b-input>
              </b-form-group>
            </b-col>
          </b-row>

          <hr class="my-4">

          <favicon-uploader class="mb-4"/>

          <div class="d-flex justify-content-end">
            <b-btn variant="primary" type="submit">Save Changes</b-btn>
          </div>
        </div>
      </b-form>
    </settings-page-card>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
import SettingsPageCard from "../../layouts/dashboard/SettingsPageCard";
import FaviconUploader from "../../components/editor/controls/FaviconUploader";

export default {
  name: "GeneralSettings",

  components: {FaviconUploader, SettingsPageCard},

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    projectName: {
      get() {
        return this.project.name
      },
      set(val) {
        this.project.name = val
      }
    },
    subdomain: {
      get() {
        return this.project.subdomain
      },
      set(val) {
        this.project.subdomain = _.kebabCase(val)
      }
    }
  },

  methods: {
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          VEvent.fire('loader', true)
          this.$store.dispatch('projects/save')
              .catch(errors => {
                const arrErrors = Object.keys(errors.response.data.errors),
                    errorMsg = arrErrors.length ? errors.response.data.errors[Object.keys(errors.response.data.errors)[0]] : errors.response.data.message

                this.$bvToast.toast(errorMsg, {
                  title: 'Error',
                  autoHideDelay: 2000,
                  appendToast: false,
                  variant: 'danger'
                })
              })
              .finally(() => {
                VEvent.fire('loader', false)
              })
        }
      })
    }
  }
}
</script>