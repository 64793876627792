<template>
  <b-row class="px-4"
  >
    <b-col cols="12">
      <h5>{{ service.name }} <span v-if="service.status">(<span class="text-success small">Connected</span>)</span></h5>
      <p class="text-muted">{{ service.short_description }}</p>
    </b-col>

    <b-col md="6">
      <b-form>
        <b-form-group label="Secret">
          <b-input v-model="service.client_secret" :disabled="!!service.status" type="password"></b-input>
        </b-form-group>

        <b-btn variant="primary"
               v-if="!service.status"
               :disabled="!service.client_secret"
               @click="integrateMailService"
        >
          Intergrate
        </b-btn>

        <b-btn v-else
               variant="danger"
               @click="deleteMailService"
        >
          Delete
        </b-btn>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import AutoResponderIntergationMixin from "./mixins/AutoResponderIntergationMixin";

export default {
  name: "GetResponse",
  mixins: [AutoResponderIntergationMixin]
}
</script>