import SectionProvider from '@/sections/SectionProvider';

export const name = 'Action Item 1'

export default class ActionItem2 extends SectionProvider {
  constructor(options = {}) {
    super('ActionItem1', options);

    this.name = name
    this.group = 'actions'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '32px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'center',
              "padding": {"top": 15, "bottom": 0, "x": 0}
            },
            lg: {
              fontSize: '45px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'center',
            }
          },
          text: "<b class='h1'>Join the The\n" +
            "New Community</b>",
        },
        horizontalLine: {
          type: 'horizontal-line-mod',
          display: true,
          color: '#D8D8D8',
          width: '1px',
          pilled: true
        },
        description: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
              lineHeight: 1.3,
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
            }
          },
          text: "<p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.  sollicitudin molestie malesuada.</p>"
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/images/placeholder-action-1.jpg'),
          src: null,
          position: 'center',
          size: 'cover'
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Join Now',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        layouts: null,
        style: {
          container: {
            type: 'container-customs',
            container: 'container'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
            },
            lg: {
              top: 115,
              bottom: 115
            }
          },
          aligment: {
            type: 'aligment-customs',
            align: 'left',
            images: {
              left: require('@/assets/svg/previews/featured-items-1-left.png'),
              right: require('@/assets/svg/previews/featured-items-1-right.png'),
            }
          }
        },
      }
    }
  }
}
