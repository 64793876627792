<template>
  <div>
    <h5 class="title font-weight-sami-bold mb-3">Fav Icon</h5>
    <b-row>
      <b-col cols="3">
        <image-upload :image="image" class="w-100 mb-3" @uploaded="uploaded" @imageDeleted="imageDeleted"></image-upload>
        <small class="text-muted">Upload a 32 x 32 pixel <br> ICO, PNG, GIF, or JPG</small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ImageUpload from "../utils/ImageUpload";

export default {
  name: "FaviconUploader",
  components: {ImageUpload},
  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    favicon: {
      get() {
        return this.globalStyles.favicon || ''
      },
      set(val) {
        this.globalStyles.favicon = val
      }
    }
  },
  data() {
    return {
      image: {
        src: ''
      }
    }
  },

  methods: {
    uploaded(image) {
      VEvent.fire('loader', true)
      this.favicon = image.src
      this.$store.dispatch('projects/save')
          .finally(() => {
            VEvent.fire('loader', false)
          })
    },
    imageDeleted() {
      this.uploaded({src: ''})
    }
  },
  mounted() {
    this.image.src = this.favicon
  }
}
</script>