<template>
  <div :style="{...backgroundColor, ...backgroundImage, ...shadow}">
    <div class="headline-item-2" :style="{...opacity, ...padding}" :class="container">
      <slot name="title"></slot>
      <div class="d-flex" v-if="visability('horizontalLine')">
        <div class="headline-divider mx-auto my-3"
             :style="{height: section.options.content.horizontalLine.width, background: section.options.content.horizontalLine.color, borderRadius: section.options.content.horizontalLine.pilled ? '25rem' : 0}"></div>
      </div>
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "HeadlineItemTemplate1",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.headline-item-2 {
  .headline-divider {
    width: 50%;
  }

  p {
    line-height: normal;
    margin-bottom: 5px;
  }
}
</style>