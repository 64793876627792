<template>

</template>

<script>
import ModMixin from '@/components/mixins/ModMixin';
import ThemeMixin from '@/components/mixins/ThemeMixin';
import {mapGetters, mapState} from 'vuex';
import NewPostModal from "../modals/NewPostModal";

export default {
  name: "ButtonMod",
  components: {NewPostModal},
  mixins: [ModMixin, ThemeMixin],

  data() {
    return {

    }
  }
}
</script>
