<template>
  <div id="app">
<!--    <b-btn to="/login">asd</b-btn>-->
<!--    <b-btn @click="cors">Test cors</b-btn>-->
    <router-view></router-view>

    <div v-if="loader" style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; background: rgba(225, 225, 225, .7); z-index: 1000000;" class="text-center mb-3 d-flex align-items-center justify-content-center">
      <b-spinner
          style="width: 6rem; height: 6rem;"
          variant="primary"
          type="grow"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    cors() {
      axios.post(`http://${process.env.VUE_APP_API_HOST}/api/auth/login`)
    }
  },

  data() {
    return {
      loader: false
    }
  },

  created() {
    VEvent.listen('loader', (state) => {
      this.loader = state
    })
  }
}
</script>

<style lang="scss">
 @import "@/assets/scss/main.scss";
</style>
