<template>
    <VideoItems2Template :section="section">
      <b-col cols="12">
        <component :is="section.options.content.group.items[0].component"
                   :section="section.options.content.group.items[0]"></component>
      </b-col>
    </VideoItems2Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import VideoItems2Template from '@pub/templates/default/sections/videos/VideoItems2Template';

export default {
  name: "VideoItem2",

  components: {VideoItems2Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
