<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <span class="indicator mr-2"></span>
      <p class="text-capitalize mb-0">Horizontal Line</p>

      <b-checkbox class="ml-auto" switch v-model="config.display"></b-checkbox>
    </div>

    <b-row class="align-items-center" no-gutters>
      <b-col cols="10">
        <b-form-group label="Line Thickness" class="mr-2">
          <v-select left v-model="config.width" class="custom-select" :options="fontSizes"></v-select>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group class="mt-4">
          <color-picker :color="config.color"
                        @input="event => config.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"></color-picker>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group>
      <b-checkbox v-model="config.pilled">Pilled</b-checkbox>
    </b-form-group>
  </div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import {Editor, EditorMenuBar} from 'tiptap';
  import {
    Blockquote, Bold,
    BulletList, Code,
    CodeBlock,
    HardBreak,
    Heading, History, Italic, Link,
    ListItem,
    OrderedList, Strike,
    TodoItem, TodoList, Underline
  } from 'tiptap-extensions';
  import TextColor from '@/plugins/TiptapTextColor';
  import {mapState} from 'vuex'

  export default {
    name: "TiptapMod",

    mixins: [ModMixin],

    components: { EditorMenuBar },

    data() {
      return {
        fontSizes: ['1px',
          '2px',
          '3px',
          '4px',
          '5px',
          '6px',
          '7px',
          '8px',
          '9px',
          '10px'],
        fonts: [
          'Global Style',
          'Roboto',
          'Montserrat',
          'Georgia',
          'Playfair Display',
          'Raleway',
          'Spectral',
          'Rubik',
        ]
      }
    }
  }
</script>
