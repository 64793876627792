<template>
    <div>
      <TestimonialItem3GroupTemplate :section="section">
        <template slot="title" v-if="visability('title')">
          <pub-content-editable
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></pub-content-editable>
        </template>

        <template slot="description" v-if="visability('description')">
          <pub-content-editable
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></pub-content-editable>
        </template>

        <b-row>
          <b-col :cols="cols(4, 12)" v-for="section in section.options.content.group.items" :key="section.uid">
            <preview-testimonial-item3 :section="section"></preview-testimonial-item3>
          </b-col>
        </b-row>
      </TestimonialItem3GroupTemplate>
    </div>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import TestimonialItem3GroupTemplate from '@pub/templates/default/sections/testimonials/TestimonialItem3GroupTemplate';
import PubContentEditable from '../../../builder/utils/PubContentEditable';
import Preview from '../../../../views/preview/Preview';

export default {
  name: "FeaturedItems1",

  components: {Preview, PubContentEditable, TestimonialItem3GroupTemplate, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
