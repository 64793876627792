<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <Template :section="section">
     <template slot="image">
       <highliter @click.stop.native="sectionSettings('image')">
         <image-fit
             :customs="section.options.content.image"
             :src="section.options.content.image.src || section.options.content.image.placeholder"
             :size="cols(section.options.content.image.size, 'contain')"
             :height="cols('55vh', '300px')"
             :position="section.options.content.image.position"
         ></image-fit>
       </highliter>
     </template>

      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

     <template slot="description">
       <highliter v-show="visability('description')" @click.stop.native="sectionSettings('description')">
         <content-editable
             :uid="tiptapUID('description')"
             :customs="section.options.content.description"
             v-model="section.options.content.description.text"></content-editable>
       </highliter>
     </template>

      <template slot="button">
        <highliter v-show="visability('button')"
                   :style="{textAlign: section.options.content.button.align}"
                   @click.stop.native="sectionSettings('button')">
          <es-button :customs="section.options.content.button"></es-button>
        </highliter>
      </template>
   </Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Template from './Template';
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "ActionItem2",

    components: {ImageFit, Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
