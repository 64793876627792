import router from '@/router/index'
import Vue from 'vue'
import {eraForDateTime} from "luxon/src/impl/english";
import {isCurrentCustomDomain} from "../../utils/urlHelper";

var _ = require('lodash');

var CircularJSON = require('circular-json');

const state = () => ({
  project: null,
  projects: [],
  loaded: false
})

const getters = {

}

const actions = {
  async create({rootState, state}, payload) {

    return await axios.post('api/projects', {
      name: payload.name,
      subdomain: payload.subdomain,
      favicon: '',
      template_id: payload.temp
    })
      .then(({data}) => {
        state.project = data.data
        rootState.editor.globalStyles = JSON.parse(data.data.global_settings)

        const content = JSON.parse(data.data.content)
        rootState.sections.sections = content.content
        rootState.sections.navbar = content.navbar
        rootState.sections.footer = content.footer || null

        state.loaded = true

        router.push('/builder/' + data.data.id)
      })
  },
  projects({state}) {
    axios.get('api/projects')
      .then(({data}) => {
        state.projects = data.data.data
      })
  },
  async load({state, rootState}, payload) {
    if (!router.currentRoute.params.id) return false

    return await axios.get(`api/projects/${router.currentRoute.params.id}`)
      .then(({data}) => {
        state.project = data.data
        rootState.editor.globalStyles = JSON.parse(state.project.global_settings)

        if (rootState.editor.globalStyles.hasOwnProperty('favicon') && rootState.editor.globalStyles.favicon) {
          const link = document.querySelector("link[rel~='icon']")
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }

          link.href = rootState.editor.globalStyles.favicon
        }

        const content = JSON.parse(data.data.content)
        rootState.sections.navbar = content.navbar
        rootState.sections.footer = content.footer || null
        rootState.sections.sections = content.content

        state.loaded = true
      })
      .catch(() => {
        router.push('/')
      })
  },
  async loadPreview({state, rootState, commit}, payload) {
    return await axios.get(`api/site/${payload}`)
      .then(({data}) => {
        state.project = data.data
        rootState.editor.globalStyles = JSON.parse(state.project.global_settings)

        if (rootState.editor.globalStyles.hasOwnProperty('favicon') && rootState.editor.globalStyles.favicon) {
          const link = document.querySelector("link[rel~='icon']")
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }

          link.href = rootState.editor.globalStyles.favicon
        }

        const content = JSON.parse(data.data.content)
        rootState.sections.navbar = content.navbar
        rootState.sections.footer = content.footer || null
        rootState.sections.sections = content.content

        state.loaded = true

        document.title = 'Home'

        const arrPages = router.currentRoute.params.path ? router.currentRoute.params.path.split('/') : ''

        if (isCurrentCustomDomain()) {
          if (arrPages.length > 0) {
            commit('sections/SET_PAGE', arrPages[0], {root: true})
          }
        } else {
          if (arrPages.length > 1) {
            commit('sections/SET_PAGE', arrPages[1], {root: true})
          }
        }
      })
  },
  async delete({state, rootState}, payload) {
    return await axios.delete(`api/projects/${payload.id}`)
      .then(({data}) => {
        state.projects.splice(payload.index, 1)
      })

  },
  async save({state, rootState}) {
    let global_settings = rootState.editor.globalStyles
    let sections = rootState.sections.sections
    let navbar = rootState.sections.navbar
    let footer = rootState.sections.footer




    // let sections = _.cloneDeep(rootState.sections.sections)

    // sections.forEach(section => {
    //   if (section.hasOwnProperty('classes')) {
    //     delete section.classes
    //   }
    //
    //   if (section.options.content.hasOwnProperty('group')) {
    //     section.options.content.group.items.forEach(sec => {
    //       if (sec.hasOwnProperty('classes')) {
    //         delete sec.classes
    //       }
    //     })
    //   }
    // })
    //
    // state.project.content = JSON.stringify(sections)
    //

    // console.log(state.project.content)
    // let project = JSON.parse(_.cloneDeep(state.project))
    state.project.content = {
      content: sections,
      navbar: navbar,
      footer: footer
    }

    // console.log(JSON.stringify(state.project.content))

    state.project.global_settings = global_settings

    return await axios.put(`api/projects/${state.project.id}`, {...state.project})
  }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
