<template>
  <div class="overflow-y-auto h-100">
    <b-card-header header-class="editor-toolbar-card-header d-flex justify-content-between align-items-center">
      <h5 class="editor-toolbar-title mb-0">{{section.name}}</h5>
      <b-link class="d-flex align-items-center font-weight-light small link-muted" @click="back('sections')">
        <icon class="mr-2" icon="left-arrow.svg"></icon> Go Back
      </b-link>
    </b-card-header>
    <b-card no-body class="border-0">
      <b-card-header header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between rounded-0" header-tag="header" role="tab" :class="{'collapsed': !!openedItem === false}" @click="$store.commit('editor/SET_OPENED_MENU', !openedItem)">
        <div class="font-weight-bold text-capitalize" block>Content</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="content" accordion="section-settings" role="tabpanel" :visible="!!openedItem">
        <div v-for="(item, index) in section.options.content" :key="index" class="section-settings">
          <b-card no-body class="border-0 noselect">
            <b-card-header v-if="index !== openedItem" header-class="editor-toolbar-collapse-header d-flex align-items-center rounded-0" header-tag="header" role="tab" @click="openMenu(index)">
              <div class="text-capitalize" block>{{label(index)}}</div>
            </b-card-header>
            <b-collapse :id="index" accordion="content" role="tabpanel" :visible="index === openedItem">
              <b-card-body class="border-top-0 pr-3">
                <component :is="item.type" :component-name="index" :config="item"></component>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-collapse>
    </b-card>

    <b-card no-body class="border-0" v-if="section.options.customize">
      <b-card-header v-b-toggle="'customize'" header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between rounded-0" header-tag="header" role="tab" @click="$store.commit('editor/SET_OPENED_MENU', null)">
        <div class="font-weight-bold text-capitalize" block>Customize</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="customize" accordion="section-settings" class="section-settings" role="tabpanel">
        <b-card v-if="section.group === 'navbar'" no-body class="border-0 noselect">
          <b-card-header v-b-toggle="'layout'" header-class="editor-toolbar-collapse-header d-flex align-items-center rounded-0" header-tag="header" role="tab">
            Layout
          </b-card-header>
          <b-collapse id="layout" accordion="content" role="tabpanel" visible>
            <b-card-body class="border-top-0">
              <layout-navbar-mode component-name="Layout" :config="section"></layout-navbar-mode>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card v-if="section.options.customize.layouts" no-body class="border-0 noselect">
          <b-card-header v-b-toggle="'layout'" header-class="editor-toolbar-collapse-header d-flex align-items-center rounded-0" header-tag="header" role="tab">
            Layout
          </b-card-header>
          <b-collapse id="layout" accordion="content" role="tabpanel" visible>
            <b-card-body class="border-top-0">
              <layout-mode component-name="Layout" :config="section"></layout-mode>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card v-if="section.options.customize.hasOwnProperty('cols')" no-body class="border-0 noselect">
          <b-card-header v-b-toggle="'grid'" header-class="editor-toolbar-collapse-header d-flex align-items-center rounded-0" header-tag="header" role="tab">
            Grid
          </b-card-header>
          <b-collapse id="grid" accordion="content" role="tabpanel">
            <b-card-body class="border-top-0">
              <grid-mod component-name="Layout" :config="section"></grid-mod>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="border-0 noselect" v-if="section.options.customize.style">
          <b-card-header v-b-toggle="'style'" header-class="editor-toolbar-collapse-header d-flex align-items-center rounded-0" header-tag="header" role="tab">
            Style
          </b-card-header>
          <b-collapse id="style" accordion="content" role="tabpanel">
            <b-card-body class="border-top-0">
              <div v-if="section.group === 'navbar'">
                <div class="d-flex justify-content-between align-items-center mb-4">
                  <p class="text-capitalize mb-0">Navbar</p>
                </div>
              </div>

              <component v-if="customs" :is="customs.type" v-for="(customs, index) in section.options.customize.style" :key="index" :config="customs"></component>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import LayoutMode from '@/components/editor/mods/LayoutMode';
  import LayoutNavbarMode from "../mods/LayoutNavbarMode";
  import _ from 'lodash'
  import NavigationSettings from "../../mixins/NavigationSettings";

  export default {
    name: "SectionSettings",
    components: {LayoutNavbarMode, LayoutMode},

    mixins: [NavigationSettings],

    methods: {
      label(label) {
        return _.startCase(label)
      },
      openMenu(index) {
        this.$store.commit('editor/SET_OPENED_MENU', index)
      }
    },

    computed: {
      ...mapState( {
        section: state => state.editor.section
      }),
      openedItem: {
        get() {
          let openedItem = this.$store.state.editor.openedItem

          if (typeof openedItem === "object" && openedItem !== null) {
           return openedItem.item
          }

          return openedItem
        }
      },
      isOpenedItem: {
        get() {
          return typeof this.openedItem === 'object' || typeof this.openedItem === 'string'
        },
        set(value) {
          this.$store.commit('editor/SET_OPENED_MENU', value ? this.openedItem : null)
        }
      }
    }
  }
</script>

