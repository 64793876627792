<template>
  <div class="news-letter-2" :style="{...backgroundColor, ...opacity, ...padding, ...shadow}">
    <div :class="container">
      <b-row class="align-items-center">
        <b-col :cols="cols(3, 12)">
          <slot name="title"></slot>
        </b-col>

        <b-col :cols="cols(9, 12)" class="form d-flex justify-content-end">
          <slot name="form"></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "NewsLetterItem1Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
  .news-letter-2 {
    padding-left: 50px;
    padding-right: 50px;
    background-color: #EDEDED;

    .form {
      .input-group > .form-control {
        width: 300px;
      }

      .form-group {
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
</style>