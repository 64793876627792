<template>
  <div  class="container" :style="{...shadow, ...padding}">
    <b-row no-gutters class="about-us-1 align-items-center" :style="{...opacity}">
      <image-fit
          tag="b-col"
          :customs="section.options.content.backgroundImage"
          :src="section.options.customize.style.backgroundImage.src"
          :size="section.options.customize.style.backgroundImage.size"
          :position="section.options.customize.style.backgroundImage.position"
          :cols="cols(5, 12)"></image-fit>


      <b-col class="position-relative" :class="cols('offset-left-lg', '')" :cols="cols(7, 12)" :style="{...backgroundColor}">
        <slot name="title"></slot>
        <div class="d-flex divider my-4" :class="cols('', 'headline-divider-sm')" v-if="visability('horizontalLine')">
          <div class="headline-divider"
               :style="{height: section.options.content.horizontalLine.width, background: section.options.content.horizontalLine.color, borderRadius: section.options.content.horizontalLine.pilled ? '25rem' : 0}"></div>
        </div>
        <slot name="description"></slot>
        <div class="transform-image" :class="cols('transform-image-lg', '')">
          <slot name="image"></slot>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "AboutUs1Template",
    components: {ImageFit},
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .about-us-1 {
    .headline-divider {
      width: 230px;
    }

    .headline-divider-sm {
      display: flex;
      justify-content: center
    }

    .offset-left-lg {
      padding-left: 10rem !important;
    }

    .transform-image-lg {
      position: absolute;
      top: 50%;
      left: -362px;
      transform: translateY(-50%);
      height: 285px;
      width: 459px;
    }
  }
</style>
