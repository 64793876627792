import SectionProvider from '@/sections/SectionProvider';

export const name = 'About Us With Image'
export const group = 'About Us'

export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '17px',
          fontFamily: 'Global Style',
          color: '#4F68BA',
          align: 'center',
        },
        lg: {
          fontSize: '17px',
          fontFamily: 'Global Style',
          color: '#4F68BA',
          align: 'center',
        }
      },
      text: "<p><strong>O U R  M I S S I O N</strong></p>",
    },
    horizontalLine: {
      type: 'horizontal-line-mod',
      display: true,
      color: '#4F68BA',
      width: '3px',
      pilled: false
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '27px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 89
          }
        },
        lg: {
          fontSize: '38px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 89
          }
        }
      },
      text: "<p><strong>Curabitur arcu erat,</strong></p><p><strong>accumsan id.</strong></p>"
    },
    description2: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'center',
          lineHeight: '1.5',
          padding: {
            top: 0,
            bottom: 0,
            x: 85
          }
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6D6D6D',
          align: 'center',
          lineHeight: '1.5',
          padding: {
            top: 0,
            bottom: 0,
            x: 85
          }
        }
      },
      text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus ultrices. Nullam consequat leo vel nunc pellentesque molestie. Aenean </p>"
    },
  },
  customize: {
    layouts: null,
    style: {
      backgroundImage: {
        type: 'background-image-customs',
        src: require('@/assets/svg/previews/about-us/placeholder-about-us-4.png'),
        position: 'center',
        size: 'cover',
        opacity: 1
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 70,
          bottom: 70
        },
        lg: {
          top: 117,
          bottom: 117
        }
      }
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/about-us/main-3.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('AboutUs4', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
