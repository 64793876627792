<template>
  <div class="position-relative nav-item" @mouseover="openMenu" @mouseleave="closeMenu">
    <div class="d-flex align-items-center nav-link" :style="{color: isActive ? globalStyles.colors.primary : customs.color, fontFamily: globalStyles.font.style || ''}">
      {{ label }} <i class="fa fa-angle-down ml-2"></i>
    </div>

    <div class="hs-mega-menu-container w-100" v-if="menu">
      <div class="hs-mega-menu w-100" :style="{borderTopColor: globalStyles.colors.primary}">
        <ul class="list-unstyled">
          <template v-for="item in items">
            <div class="dropdown-item cursor-pinter" v-if="!item.children || !item.children.length"
                             @click="$store.dispatch('router/setPage', item.key)">
              <div class="py-1"
                   :style="{color: item.key === $store.state.sections.page ? globalStyles.colors.primary : customs.color, fontFamily: globalStyles.font.style || ''}">
                {{ item.name }}
              </div>
            </div>
            <submenu v-else :items="item.children" :label="item.name"></submenu>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Submenu from "./Submenu";

export default {
  name: "Menu",
  components: {Submenu},
  props: {
    label: String,
    items: Array,
    customs: Object
  },

  data() {
    return {
      menu: false,
      active: false,
      timer: null
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    isActive() {
      let active = false

      const recurse = (arr) => {
        if (active) return false

        arr.forEach(item => {
          if (item.key === this.$store.state.sections.page) {
            active = true
          } else if (item.hasOwnProperty('children')) {
            recurse(item.children)
          }
        })
      }

      recurse(this.items)
      return active
    }
  },

  methods: {
    closeMenu() {
      this.timer = setTimeout(() => {
        this.menu = false
      }, 300)
    },
    openMenu() {
      VEvent.fire('close-all-menus')
      this.menu = true
    }
  },

  mounted() {
    VEvent.listen('close-all-menus', () => {
      clearTimeout(this.timer)
      this.menu = false
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.resolution-lg {
  .hs-mega-menu-container {
    padding-top: 20px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    min-width: 250px;

    .hs-mega-menu {
      border-top: 2px solid;
      background: #fff;
      box-shadow: 0 1px 2px #ccc;
      z-index: 100;

      .dropdown-item {
        &:active,
        &:focus {
          background: inherit;
          color: inherit;
        }
      }
    }
  }
}

.resolution-sm {
  .navbar-nav > div {
    width: 100%;
  }

  .hs-mega-menu {
    margin-top: 10px;
    border-top: 2px solid;
  }
}
</style>