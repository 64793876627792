<template>
  <highliter class="feature-section" :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <HeadlineItem3Template :section="section">
      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="subtitle">
        <highliter v-show="visability('subtitle')" @click.stop.native="sectionSettings('subtitle')">
          <content-editable
              :uid="tiptapUID('subtitle')"
              :customs="section.options.content.subtitle"
              v-model="section.options.content.subtitle.text"></content-editable>
        </highliter>
      </template>
   </HeadlineItem3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import HeadlineItem3Template from '@pub/templates/default/sections/headlines/HeadlineItem3Template';

export default {
  name: "HeadlineItem3",

  components: {HeadlineItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
