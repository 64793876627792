<template>
  <div :style="{...backgroundColor, ...backgroundImage, ...padding, ...shadow, ...opacity}">
    <div :class="container">
      <b-row class="es-row" :class="'align-items-' + section.options.customize.style.alignRow.align" :no-gutters="section.options.customize.noGutters" data-padding data-bg>
        <b-col v-for="child in sections"
               :key="child.uid"
               :offset="offset(child.options.customize)"
               :order="order(child.options.customize)"
               :cols="child.options.customize.cols[resolution]">
          <component
              :is="'preview-' + child.component"
              :section="child"
              :parentUID="section.uid"
          >
          </component>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin';
import CustomStylesMixin from '../../../mixins/CustomStylesMixin';

export default {
  name: "EsRow",
  mixins: [SectionMixin, CustomStylesMixin],
  computed: {
    sections() {
      return this.section.options.content.group.items
    }
  },
}
</script>

<style lang="scss">

</style>
