<template>
  <div :style="{...backgroundImage}">
    <div class="list-3" :class="container" :style="{...backgroundColor, ...opacity, ...shadow, ...padding}">
      <ul class="list-unstyled" :style="{...color}">
        <slot name="list"></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "ListItem1Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.list-3 {
  ul {
    li {
      position: relative;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>