<template>
  <div class="control-box text-color position-relative">
    <color-picker class="position-relative" :color="value" @input="event => $emit('input', `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`)">
      <div class="position-absolute bg-white d-flex align-items-center justify-content-center" style="height: 100%; width: 100%;">
        <icon icon="text-color.svg"></icon>
      </div>
    </color-picker>
    <div class="reflector-line border" :style="{backgroundColor: color || value}"></div>
  </div>
</template>

<script>
  import ColorPicker from "../utils/ColorPicker";
  export default {
    name: "TextColor",
    components: {ColorPicker},
    props: {
      color: {
        type: String,
        default: null
      },
      value: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  .text-color {
    input {
      height: 0;
      padding: 0;
      margin: 0;
      opacity: 0;
    }
  }

  .reflector-line {
    position: absolute;
    top: 28px;
    left: 7px;
    height: 4px;
    background: #000;
    width: 24px;
    z-index: 2;
  }
</style>
