<template>
  <div v-html="value" :style="style" style="overflow-wrap: anywhere;" class="success-message"/>
</template>

<script>

export default {
  name: "PubFormSettingsEditor",

  props: {
    value: {
      required: true
    },
    config: {
      required: true
    }
  },

  computed: {
    style() {
      return {
        color: this.config.color,
        textAlign: this.config.align,
        fontFamily: this.config.fontFamily,
        fontSize: this.config.fontSize,
      }
    }
  }
}
</script>

<style lang="scss">
.success-message {
  > .ProseMirror {
    min-height: 200px;
  }
}
</style>
