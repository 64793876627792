<template>
  <div :style="{...backgroundImage}">
    <div :class="container" :style="{...padding}">
      <div class="media quote-2 py-4" :style="{...opacity, ...shadow, ...backgroundColor}" :class="cols('', 'd-block')">
        <div class="media-aside px-5 pt-2" :class="cols('', 'justify-content-center')">
          <icon icon="quote-2.svg"></icon>
        </div>

        <div class="media-body py-4">
          <slot name="description" v-if="visability('description')"></slot>
          <slot name="signature" v-if="visability('signature')"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "ImageItem5Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">

</style>