<template>
  <Template :section="section"></Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Template from './Template'

  export default {
    name: "ActionItem2",

    components: {Template},

    mixins: [SectionMixin],
  }
</script>
