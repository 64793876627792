<template>
  <highliter :label="section.name" :uid="section.uid" :dublicate="section" @click.stop.native="sectionSettings()">
    <ResourceItem1Template :section="section">
      <template slot="icon">
        <highliter v-show="visability('icon')" @click.stop.native="sectionSettings('icon')">
          <icon-editable :customs="section.options.content.icon"></icon-editable>
        </highliter>
      </template>

      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>

      <template slot="button">
        <highliter v-show="visability('button')"
                   autoWidth
                   @click.stop.native="sectionSettings('button')">
          <es-button :customs="section.options.content.button"></es-button>
        </highliter>
      </template>
    </ResourceItem1Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ResourceItem1Template from '@pub/templates/default/sections/resource/ResourceItem1Template';

export default {
  name: "ImageItem3",

  components: {ResourceItem1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
