<template>
  <Price1Template :section="section">
    <pub-content-editable
        :uid="tiptapUID('title')"
        :customs="section.options.content.title"
        v-model="section.options.content.title.text"></pub-content-editable>

    <pub-content-editable
        :uid="tiptapUID('price')"
        :customs="section.options.content.price"
        v-model="section.options.content.price.text"></pub-content-editable>

    <pub-content-editable
        :uid="tiptapUID('description')"
        :customs="section.options.content.description"
        v-model="section.options.content.description.text"></pub-content-editable>

    <ul class="list-unstyled my-4">
      <li v-for="(item, index) in section.options.content.list.items" :key="item.label"
          class="list-item d-flex align-items-center mb-4">
<!--        <div-->
<!--            :style="{webkitMask: `url(${section.options.customize.style.listIcon.icon}) center / contain no-repeat`, backgroundColor: section.options.customize.style.listIcon.color}"-->
<!--            class="list-icon mr-2" alt="List Icon"></div>-->
        <i class="mr-2" :style="{color: section.options.customize.style.listIcon.color}" :class="section.options.customize.style.listIcon.icon"></i>
        <div contenteditable="true" @input="event => item.text = event.target.innerText">{{ item.text }}</div>
      </li>
    </ul>

    <pub-es-button :customs="section.options.content.button"></pub-es-button>
  </Price1Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Price1Template from "./Price1Template";
import PubContentEditable from "../../../utils/PubContentEditable";
import PubEsButton from "../../../utils/buttons/PubEsButton";

export default {
  name: "Carousel1",

  components: {PubEsButton, PubContentEditable, Price1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
