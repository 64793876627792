import SectionProvider from '@/sections/SectionProvider';

export const name = 'VideoItem4'

export default class VideoItem4 extends SectionProvider {
  constructor(options = {}) {
    super('VideoItem4', options);

    this.name = name
    this.group = 'videos'
    this.stateSafly = false

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '32px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
              "padding": {"top": 0, "bottom": 10, "x": 0}
            },
            lg: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
              "padding": {"top": 0, "bottom": 10, "x": 0}
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"Our Latest Video Tutorial"}]}]},
        },
        subtitle: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
              "padding": {"top": 0, "bottom": 10, "x": 0}
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
              "padding": {"top": 0, "bottom": 10, "x": 0}
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Vivamus sagittis, leo vitae commodo condimentum, metus dui efficitur orci, sed iaculis purus ligula in eros. Ut vel molestie neque. In non varius tortor."}]}]},
        },
        video: {
          display: true,
          type: 'video-mod',
          placeholder: require('@/assets/svg/previews/features/placeholder-3.png'),
          src: null,
          position: 'center',
size: 'cover'
        }
      },
      customize: {
        style: {
          container: {
            type: 'container-customs',
            container: null
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
           },
           lg: {
              top: 70,
              bottom: 70
           }
          }
        }
      }
    }
  }
}
