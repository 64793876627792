<template>
  <div :style="{...backgroundImage, ...padding, ...backgroundColor}">
    <div class="action-item" :style="{...opacity, ...shadow}" :class="container">
      <div class="leading">
        <slot name="leading"></slot>
      </div>
      <div class="my-2"></div>
      <slot name="title"></slot>
      <div class="my-2"></div>
      <slot name="description"></slot>
      <div class="my-4"></div>
      <div class="w-100 text-center">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "FeaturedItems4Template",
    mixins: [TemplateMixin]
  }
</script>

<style>
  .leading {
    letter-spacing: 0.17em;
  }
</style>