import SectionProvider from '@/sections/SectionProvider';

export const name = 'Image Item 2'

export default class ImageItem2 extends SectionProvider {
  constructor(options = {}) {
    super('ImageItem2', options);

    this.name = name
    this.group = 'images'

    this.defaultOptions = {
      content: {
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/previews/images/placeholder-image-2.png'),
          src: null,
          size: 'cover',
          position: 'center',
        }
      },
      customize: {
        style: {
          background: {
            background: '#F0F0F0'
          }
        }
      }
    }
  }
}
