<template>
  <div class="mb-4">
    <label class="mb-2">{{ config.label || 'Text Color' }}</label>

    <div>
      <text-color v-model="config.color"></text-color>
    </div>
  </div>
</template>

<script>
import ControlMixin from "../../mixins/ControlMixin";
import TextColor from "./TextColor";

export default {
  name: "Color",
  components: {TextColor},
  mixins: [ControlMixin]
}
</script>
