import SectionProvider from '@/sections/SectionProvider';

export const name = 'About Us Text'
export const group = 'About Us'

export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '38px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'center',
           padding: {
            top: 0,
            bottom: 36,
            x: 0
          }
        },
        lg: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'center',
           padding: {
            top: 0,
            bottom: 36,
            x: 0
          }
        }
      },
      text: "<p><strong>Your Future Ahead</strong></p>",
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6A737B',
          align: 'center',
          lineHeight: '1.5'
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6A737B',
          align: 'left',
          lineHeight: '1.5'
        }
      },
      text: {
        "type": "doc",
        "content": [{
          "type": "paragraph",
          "content": [{
            "type": "text",
            "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus ultrices. Nullam consequat leo vel nunc pellentesque molestie. Aenean aliquam orci quam, commodo suscipit erat imperdiet ut. Donec cursus tellus tempus, iaculis orci vestibulum, suscipit ipsum. Vestibulum gravida ex diam, nec finibus risus eleifend sed. Interdum et malesuada fames ac ante ipsum primis in faucibus."
          }]
        }]
      },
    },
    description2: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6A737B',
          align: 'center',
          lineHeight: '1.5'
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#6A737B',
          align: 'left',
          lineHeight: '1.5'
        }
      },
      text: {
        "type": "doc",
        "content": [{
          "type": "paragraph",
          "content": [{
            "type": "text",
            "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus ultrices. Nullam consequat leo vel nunc pellentesque molestie. Aenean aliquam orci quam, commodo suscipit erat imperdiet ut. Donec cursus tellus tempus, iaculis orci vestibulum, suscipit ipsum. Vestibulum gravida ex diam, nec finibus risus eleifend sed. Interdum et malesuada fames ac ante ipsum primis in faucibus."
          }]
        }]
      },
    }
  },
  customize: {
    style: {
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
size: 'cover',
        opacity: 1
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 70,
          bottom: 70
        },
        lg: {
          top: 115,
          bottom: 115
        }
      }
    },
  }
}

export const previews = {
  left: require('@/assets/svg/previews/about-us/left.svg'),
  right: null,
  main: require('@/assets/svg/previews/about-us/main-4.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('AboutUs3', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
