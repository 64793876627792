import { render, staticRenderFns } from "./TextAndImageItem2Template.vue?vue&type=template&id=d5a5975e&scoped=true&"
import script from "./TextAndImageItem2Template.vue?vue&type=script&lang=js&"
export * from "./TextAndImageItem2Template.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5a5975e",
  null
  
)

export default component.exports