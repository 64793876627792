import SectionProvider from '@/sections/SectionProvider';
import FeaturedImage from './FeaturedImage';

export const name = 'Featured Items 2'

export default class FeaturedItems2 extends SectionProvider {
  constructor(options = {}) {
    super('FeaturedItems2', options);

    this.name = name
    this.group = 'featured'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '38px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '54px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "Featured Items"}]
            }]
          },
        },
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "text": "Explore our wide selection today"}]
            }]
          },
        },
        group: {
          type: 'row-mod',
          display: true,
          items: [
            new FeaturedImage({
              content: {
                image: {
                  placeholder: require('@/assets/svg/previews/features/placeholder-feature-items-2-1.svg')
                }
              }
            }).json(),
            new FeaturedImage({
              content: {
                image: {
                  placeholder: require('@/assets/svg/previews/features/placeholder-feature-items-2-2.svg')
                }
              }
            }).json(),
            new FeaturedImage({
              content: {
                image: {
                  placeholder: require('@/assets/svg/previews/features/placeholder-feature-items-2-3.svg')
                }
              }
            }).json(),
          ]
        }
      },
      customize: {
        style: {
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
            size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 35,
              bottom: 35
            },
            lg: {
              top: 35,
              bottom: 35
            }
          },
          container: {
            type: 'container-customs',
            container: null
          },
        }
      }
    }
  }
}
