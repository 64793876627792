<template>
  <div>
    <settings-page-card title="Integrations">
      <div class="d-flex align-content-center border-top p-4">
        <icon icon="api-email.svg"></icon>
        <h6 class="api-service ml-3 mb-0">Autoresponder</h6>
      </div>

     <div class="px-4 mb-4">
       <b-alert :show="!!hasAPI.name" variant="danger" class="bg-white">
         When changing the autoresponder, the previous forms associated with the current autoresponder will be deactivated
       </b-alert>

       <b-form-group label="Select service">
         <v-select :disabled="!!hasAPI.name" left label="name" :reduce="serveice => serveice.name" v-model="selectedService" class="custom-select" :searchable="false" :options="mailAPIServices"></v-select>
       </b-form-group>
     </div>

      <component v-for="service in mailAPIServices"
                 v-if="service.name === selectedService"
                 :key="service.id"
                 :is="camelCase(service.name)"
                 :service="service"
                 @integrated="$emit('integrated')"
                 @removeIntegration="$emit('remove-integration')"
      />
    </settings-page-card>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import SettingsPageCard from "../../layouts/dashboard/SettingsPageCard";
import TextMixins from "../../components/mixins/TextMixins";

export default {
  name: "Integrations",

  components: {SettingsPageCard},

  mixins: [TextMixins],

  data() {
    return {
      selectedService: ''
    }
  },

  computed: {
    ...mapGetters('apiIntegration', {
      hasAPI: 'hasMailAPIService'
    }),
    ...mapState('apiIntegration', {
      mailAPIServices: state => state.mailAPIServices
    })
  },

  mounted() {
    this.selectedService = this.hasAPI.name || this.mailAPIServices[0].name
  }
}
</script>

<style lang="scss">
.api-service {
  font-size: 17px;
  line-height: 1.5
}
</style>
