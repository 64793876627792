<template>
  <div class="option-2" :style="{...opacity}">
    <div :style="{...backgroundImage, ...padding, ...backgroundColor, ...shadow}">
      <div :class="container">
        <b-row class="align-items-center">
          <b-col :cols="cols(7, 12)">
            <template v-show="visability('title')">
              <slot name="title"></slot>
            </template>

            <template v-show="visability('text')">
              <slot name="text"></slot>
            </template>
          </b-col>

          <b-col :class="cols('', 'mb-4')" :cols="cols(5, 12)">
            <template v-show="visability('image')">
              <slot name="image"></slot>
            </template>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="pt-4" :style="{...backgroundColorCustom('Form')}">
      <div :class="container">
        <b-row class="align-items-center">
          <b-col :cols="cols(3, 12)">
            <div v-show="visability('formLabel')">
              <slot name="formLabel"></slot>
            </div>
          </b-col>
          <b-col :cols="cols(9, 12)">
            <div v-show="visability('form')"
                 :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
              <slot name="form"></slot>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.resolution-sm {
  .option-2 {
    .btn {
      display: block;
      width: 100%;
    }
  }
}
</style>