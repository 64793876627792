import SectionProvider from '@/sections/SectionProvider';
import VideoItem1 from "./VideoItem1";
import EsRow from "../utils/EsRow";

export const name = 'Video Items Group'

export default class VideoItem1Group extends SectionProvider {
  constructor(options, items) {
    super('EsRow', options);

    this.name = name
    this.group = 'videos'
    this.stateSafly = false

    this.defaultOptions = new EsRow({
      customize: {
        style: {
          padding: {
            type: 'height-customs',
            sm: {
              top: 50,
              bottom: 50
            },
            lg: {
              top: 100,
              bottom: 100
            }
          }
        }
      }
    }, [
      new VideoItem1({
          customize: {
            cols: {
              sm: 12,
              md: 4,
              lg: 4
            }
          }
      }).json(),
      new VideoItem1({
          customize: {
            cols: {
              sm: 12,
              md: 4,
              lg: 4
            }
          }
      }).json(),
      new VideoItem1({
          customize: {
            cols: {
              sm: 12,
              md: 4,
              lg: 4
            }
          }
      }).json(),
    ]).json().options
  }
}
