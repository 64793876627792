<template>
  <section class="hero-2" :style="{...backgroundImage, ...padding, ...backgroundColor, ...shadow}">

    <div class="container">
      <b-row class="justify-content-center align-items-center">
        <div class="hero-1_title"  v-if="visability('title')">
          <slot name="title"></slot>
        </div>

        <div class="hero-1_description" v-if="visability('description')">
          <slot name="description"></slot>
        </div>
      </b-row>
    </div>
  </section>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "HeroItem1Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.hero-2 {
  background: #3D76C4;

  &_title {
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
    }
  }

  &_description {
    margin-bottom: 30px;
  }
}
</style>