<template>
  <div>
    <editor-header></editor-header>
    <div class="d-flex justify-content-center pb-5 preview">
      <div class="es-root web position-relative" :class="['resolution-' + resolution, globalStyles.font.style]"
           :style="{background: globalStyles.colors.siteBackground}">
        <component
            v-if="navbar.section"
            :is="'Preview' + navbar.section.component"
            :section="navbar.section"
        >
        </component>

        <component
            v-for="section in getSections"
            :key="section.uid"
            :is="'Preview' + section.component"
            :section="section"
        >
        </component>

        <component
            v-if="footer"
            :is="'Preview' + footer.component"
            :section="footer"
        >
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import CodeInjection from "../../components/mixins/CodeInjection";
import EditorHeader from "../../components/editor/header/EditorHeader";
import TiptapLinkPreventRedirect from "../../components/mixins/TiptapLinkPreventRedirect";
import CodeInjectionPageMixin from "../../components/mixins/CodeInjectionPageMixin";

export default {
  name: "Preview",
  components: {EditorHeader},
  computed: {
    ...mapState('sections', {
      navbar: state => state.navbar,
      footer: state => state.footer
    }),
    ...mapGetters('sections', [
      'getSections'
    ]),
    ...mapState('editor', {
      theme: state => state.theme,
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles
    })
  },

  mixins: [CodeInjection, TiptapLinkPreventRedirect, CodeInjectionPageMixin],

  methods: {
    onResize(event) {
      if (window.screen.width < 1300) {
        if (this.resolution === 'lg') {
          this.$store.commit('editor/SET_RESOLUTION', 'sm')
        }
      } else {
        if (this.resolution === 'sm') {
          this.$store.commit('editor/SET_RESOLUTION', 'lg')
        }
      }
    }
  },

  async created() {
    await this.$store.dispatch('projects/load', {id: this.$route.params.id, path: this.$route.params.path})
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
.preview {
  .btn {
    margin-right: 10px;
  }
}
</style>