import SectionProvider from '@/sections/SectionProvider';
import shortid from "shortid";

export const name = 'Collapse Item 1'

export default class CollapseItems1 extends SectionProvider {
  constructor(options = {}) {
    super('CollapseItem1', options);

    this.name = name
    this.group = 'featured'

    this.defaultOptions = {
      content: {
        collapse: {
          type: 'collapse-mod',
          display: true,
          items: [
            {
              uid: shortid.generate(),
              title: 'What will happen if I click This Text?',
              text: 'Suspendisse et condimentum ex. Phasellus tempor malesuada sodales. Cras elementum ut dolor eget fermentum. Donec tempor congue purus, a rhoncus risus suscipit eget. Integer magna dolor, commodo sit amet dolor sed, viverra tempor tortor. Donec eget iaculis enim. Ut lobortis velit ut mauris posuere posuere. Donec id magna dapibus, pretium enim fermentum, tincidunt quam. Pellentesque semper non justo quis cursus. Aliquam massa tortor, dapibus vel venenatis quis, vulputate sit amet nisl. Integer in bibendum lacus.'
            },
            {
              uid: shortid.generate(),
              title: 'What will happen if I click This Text?',
              text: 'Suspendisse et condimentum ex. Phasellus tempor malesuada sodales. Cras elementum ut dolor eget fermentum. Donec tempor congue purus, a rhoncus risus suscipit eget. Integer magna dolor, commodo sit amet dolor sed, viverra tempor tortor. Donec eget iaculis enim. Ut lobortis velit ut mauris posuere posuere. Donec id magna dapibus, pretium enim fermentum, tincidunt quam. Pellentesque semper non justo quis cursus. Aliquam massa tortor, dapibus vel venenatis quis, vulputate sit amet nisl. Integer in bibendum lacus.'
            }
          ]
        }
      },
      customize: {
        layouts: null,
        style: {
          shadow: null,
          opacity: null,
          color: {
            type: 'color-customs',
            color: '#0C226C'
          },
          bodyColor: {
            type: 'color-customs',
            color: '#6E747A'
          },
          background: {
            background: '#FBFBFB'
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 30,
              bottom: 30
            },
            lg: {
              top: 30,
              bottom: 30
            }
          }
        }
      }
    }
  }
}
