<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">{{ labelCase(componentName) }}</p>
    </div>

    <b-form-group>
      <b-checkbox class="mb-2" v-model="countdownType" value="date">Countdown from date</b-checkbox>
      <date-picker class="w-100" input-class="form-control" :disabled-date="disabledBeforeToday" :clearable="false"
                   v-model="fromDate" value-type="format" format="YYYY-MM-DD hh:mm" type="datetime"></date-picker>
    </b-form-group>

    <b-form-group>
      <b-checkbox class="mb-2" v-model="countdownType" value="time">Countdown from time</b-checkbox>
      <date-picker class="w-100" input-class="form-control" :clearable="false" v-model="fromTime" value-type="format"
                   format="HH:mm:ss" type="time"></date-picker>
    </b-form-group>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin';
import {mapState} from 'vuex';
import TextColor from '@/components/editor/controls/TextColor';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "ButtonMod",
  components: {TextColor, DatePicker},
  mixins: [ModMixin],

  data() {
    return {
      fromDate: this.moment().add(1, 'days').format('YYYY-MM-DD hh:mm'),
      fromTime: this.moment().add(1, 'hours').format('HH:mm:ss'),
      countdownType: 'date'
    }
  },

  methods: {
    disabledBeforeToday(date) {
      const today = this.moment().format('YYYY-MM-DD hh:mm')

      return this.moment(date).isBefore(today)
    }
  },

  computed: {
    getTargetDate() {
      if (this.countdownType === 'date') {
        return this.moment(this.fromDate).valueOf()
      } else {
        return new Date().getTime() + this.moment.duration(this.fromTime).asMilliseconds()
      }
    },
  },

  watch: {
    getTargetDate(val) {
      this.config.time = val
      VEvent.fire('timer-update')
    }
  }
}
</script>
