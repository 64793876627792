<template>
  <div class="dashboard">
    <router-view></router-view>
  </div>
</template>

<script>
import DashboardHeader from "./DashboardHeader";
export default {
  name: "DashboardLayout",
  components: {DashboardHeader}
}
</script>

<style>
  .dashboard {
    padding-top: 144px;
  }
</style>
