import DashboardLayout from "../layouts/dashboard/DashboardLayout";
import Dashboard from "../views/dashboard/Dashboard";
import Login from "../views/login/Login";
import store from '../store'
import Builder from "../views/builder/Builder";
import Register from "../views/login/Register";
import Preview from "../views/preview/Preview";
import Projects from '../views/dashboard/Projects';
import PublicPreview from "../views/preview/PublicPreview";
import EsRoot from "../components/builder/EsRoot";
import Integrations from "../views/dashboard/Integrations";
import Settings from "../layouts/dashboard/Settings";
import CodeAPI from "../views/dashboard/CodeAPI";
import NotFound from "../views/errors/NotFound";
import EsBlog from "../views/blog/EsBlog";
import PublicEsBlog from "../views/blog/PublicEsBlog";
import Modules from "../views/modules/Modules";
import Domain from "../views/domain/Domain";
import General from "../views/dashboard/General";
import CodeInjection from "../components/mixins/CodeInjection";
import InjectionModal from "../components/editor/modals/InjectionModal";

export default [
  {
    path: '/',
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        component: Dashboard
      },
      {
        path: '/templates',
        component: Projects
      },
      {
        path: '/settings/:id',
        component: Settings,
        name: 'settings',
        children: [
          {
            name: 'settings',
            path: '/',
            component: General
          },
          {
            name: 'settings.domain',
            path: 'domain-settings',
            component: Domain
          },
          {
            name: 'settings.integrations',
            path: 'integrations',
            component: Integrations
          },
          {
            name: 'settings.injection',
            path: 'injection',
            component: InjectionModal
          }
        ]
      },
      {
        path: '/builder',
        component: Builder,
        name: 'builder',
        children: [
          {
            path: ':id',
            name: 'editor',
            component: EsRoot,
          },
          {
            path: '/modules',
            component: Modules,
            children: [
              {
                path: '/builder/:id/modules/blog',
                name: 'module.blog',
                component: EsBlog
              },
              {
                path: '/builder/:id/modules/domain',
                name: 'module.domain',
                component: Domain
              }
            ]
          },
          {
            name: 'preview',
            path: 'preview/:id',
            component: Preview
          }
        ]
      },
    ]
  },
  {
    path: '/preview/:id',
    component: Preview,
  },
  {
    path: '/site/:path*',
    name: 'published',
    component: PublicPreview,
    children: [
      {
        path: '/site/:path*/blog/:postSlug',
        name: 'published-blog',
        meta: {
          reserved: true
        },
        component: PublicEsBlog
      },
    ]
  },
  {
    path: '/login',
    component: Login,
    beforeEnter(to, from, next) {
      const user = store.state.auth.user
      if (user) {
        next({ path: '/' })
      } else {
        next();
      }
    },
  },
  // {
  //   path: '/code-api',
  //   component: CodeAPI,
  //   // beforeEnter(to, from, next) {
  //   //   const user = store.state.auth.user
  //   //   if (user) {
  //   //     next({ path: '/' })
  //   //   } else {
  //   //     next();
  //   //   }
  //   // },
  // },
  // {
  //   path: '/sign-up',
  //   component: Register,
  //   beforeEnter(to, from, next) {
  //     const user = store.state.auth.user
  //     if (user) {
  //       next({ path: '/' })
  //     } else {
  //       next();
  //     }
  //   },
  // }
]
