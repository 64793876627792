export default {
  methods: {
    back(section) {
      const page = this.$router.currentRoute.meta.page || null

      switch (page) {
        case 'blog':
          this.go('blog-settings')
          break;
        default:
          this.go(section)
      }
    },
    go(section) {
      this.$store.commit('editor/SET_PAGE', section)
    }
  }
}