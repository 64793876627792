<template>
  <highliter :label="section.name" :uid="section.uid" @click.stop.native="sectionSettings()">
    <ImageItem4Template :section="section">
      <highliter @click.stop.native="sectionSettings('image')">
       <b-img :src="section.options.content.image.src || section.options.content.image.placeholder"></b-img>
      </highliter>
    </ImageItem4Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ImageItem4Template from '@pub/templates/default/sections/images/ImageItem4Template';

export default {
  name: "ImageItem3",

  components: {ImageItem4Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
