import Navbar1 from '@/sections/navigations/Navbar1';
import FeaturedItems1 from '@/sections/featured/FeaturedItems1';
import router from '@/router/index'
import _ from 'lodash'
import {isCurrentCustomDomain} from "../../utils/urlHelper";

const state = () => ({
  page: 'home'
})

const actions = {
  go({commit, dispatch}, page) {
    const home = [
      new Navbar1().json(),
      new FeaturedItems1().json()
    ]

    const about = [
      new Navbar1().json(),
    ]

    if (page === 1) {
      dispatch('sections/setSections', home, {root: true})
    } else if (page === 2) {
      dispatch('sections/setSections', about, {root: true})
    }
  },
  setPage({commit, rootState, dispatch}, page) {
    const kebabPage = _.kebabCase(page)

    if (isCurrentCustomDomain()) {
      router.push(`/site/${kebabPage}`)
    } else {
      if (router.currentRoute.name.search('published') !== -1) {
        router.push(`/site/${rootState.projects.project.subdomain}/${kebabPage}`)
      } else if (router.currentRoute.name === 'es-reserve') {
        if (router.currentRoute.matched.filter(route => route.name === 'published').length) {
          router.push(`/site/${rootState.projects.project.subdomain}/${kebabPage}`)
        } else {
          router.push(`/builder/${router.currentRoute.params.id}`)
        }
      }
    }

    commit('SET_PAGE', kebabPage)
    commit('sections/SET_PAGE', kebabPage, {root: true})

    window.scrollTo(0, 0);
  }
}

const mutations = {
  SET_PAGE(state, page) {
    state.page = page
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
