import SectionProvider from '@/sections/SectionProvider';
import EsRow from "../utils/EsRow";
import TextAndImageItem1 from './TextAndImageItem1';

export const name = 'TextAndImageItem1Group'

export default class TextAndImageItem1Group extends SectionProvider {
  constructor(options, items) {
    super('EsRow', options);

    this.name = name
    this.group = 'textAndImage'

    this.defaultOptions = new EsRow({}, [
      new TextAndImageItem1({
        content: {
          image: {
            placeholder: require('@/assets/images/placeholder-text-image-1-1.jpg'),
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          }
        }
      }).json(),
      new TextAndImageItem1({
        content: {
          image: {
            placeholder: require('@/assets/images/placeholder-text-image-1-2.jpg'),
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          }
        }
      }).json(),
      new TextAndImageItem1({
        content: {
          image: {
            placeholder: require('@/assets/images/placeholder-text-image-1-3.jpg'),
          }
        },
        customize: {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          }
        }
      }).json(),
    ]).defaultOptions
  }
}
