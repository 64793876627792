<template>
  <div>
    <dashboard-header></dashboard-header>

    <div class="mb-3">Your Projects</div>

    <div class="d-flex flex-wrap">
      <div class="project-card-parent d-flex align-items-center justify-content-center mb-4">
        <div class="d-block project-card h-auto mb-2">
          <b-btn class="btn-wide" variant="primary" @click="$router.push('/templates')">New Project
            <icon class="ml-2" icon="plus-white.svg"></icon>
          </b-btn>
          <div class="site-title text-primary">Create a New Project</div>
        </div>
      </div>

      <div v-for="(project, index) in filteredProjects" :key="project.id" class="d-flex mb-4">
        <div class="card project-card-parent">
          <div class="project-card mb-2" @click="loadProject(project.id)">
            <div class="overlay">Edit</div>
            <div v-if="project.screenshot" class="screenshot"
                 :style="{backgroundImage: `url(${project.screenshot})`}"></div>
          </div>

          <div class="d-flex justify-content-between align-items-center px-3 pb-2">
            <div class="site-title">{{ project.name }}</div>

            <b-dropdown
                variant="link"
                size="lg"
                toggle-class="text-decoration-none"
                no-caret
                menu-class="py-0 project-menu"
                right
            >
              <template slot="button-content">
                <b class="text-dark">...</b>
              </template>
              <b-dropdown-item @click="$router.push(`settings/${project.id}`)"
                               class="d-flex align-items-center"
                               link-class="dashboard-menu-link">
                <i class="fa fa-cog text-muted mr-2"></i>
                Settings
              </b-dropdown-item>
              <b-dropdown-item @click="deleteProject(project.id, index)"
                               class="d-flex align-items-center"
                               link-class="dashboard-menu-link">
                <i class="fa fa-trash-o text-muted mr-2"></i>
                Delete
              </b-dropdown-item>
              <b-dropdown-item target="_blank" :href="getSiteUrl(project)"
                               class="d-flex align-items-center"
                               link-class="dashboard-menu-link d-flex flex-nowrap align-items-center justify-content-between">
               <div>
                 <i class="fa fa-eye text-muted mr-2"></i>
                 View
               </div>
                <b-badge variant="soft-success" class="font-weight-normal">Published</b-badge>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Cookie from "../../components/mixins/Cookie";
import DashboardHeader from "../../layouts/dashboard/DashboardHeader";

export default {
  name: "Dashboard",
  components: {DashboardHeader},
  mixins: [Cookie],

  computed: {
    ...mapState('projects', {
      projects: state => state.projects
    }),
    ...mapState('filter', {
      byName: state => state.byName
    }),
    filteredProjects() {
      return this.projects.filter(project => project.name.toLowerCase().indexOf(this.byName.toLowerCase()) !== -1)
    }
  },

  methods: {
    getSiteUrl(project) {
      return project.domain ? 'https://' + project.domain : `${window.location.origin}` + `/site/${project.subdomain}`
    },
    async deleteProject(id, index) {
      VEvent.fire('loader', true)

      await this.$store.dispatch('projects/delete', {id, index})
          .finally(() => {
            VEvent.fire('loader', false)
          })
    },
    async loadProject(id) {
      await this.$store.dispatch('projects/load', id)
          .then(() => {
            this.$router.push('/builder/' + id)
            this.$store.commit('filter/SET_BY_NAME', '')
          })
    },
    async checkIntegrationRedirect() {
      if (this.$route.query.code && this.get_cookie('mailServiceAPI')) {
        const awerb = this.get_cookie('mailServiceAPI').split(',')

        this.loadProject(awerb[0])

        await axios.get(`api/projects/${awerb[0]}/auto-responders/${awerb[1]}/token`, {params: {code: this.$route.query.code}})
            .then(({data}) => {
              this.$swal({
                icon: 'success',
                title: 'Mail service integrated!',
                showConfirmButton: false,
                timer: 1500
              });

              this.delete_cookie('mailServiceAPI')
            })
            .finally(() => {
              VEvent.fire('loader', false)
            })
      }
    }
  },

  async created() {
    VEvent.fire('loader', true)

    await this.checkIntegrationRedirect()

    await this.$store.dispatch('projects/projects')
        .finally(() => {
          setTimeout(() => {
            VEvent.fire('loader', false)
          }, 2000)
        })
  }
}
</script>

<style lang="scss">
.site-title {
  font-size: 15px;
  color: #44474a;
}

.site-sub-title {
  font-size: 14px;
  color: #6e747a;
}

.project-card-parent {
  height: 236px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);
  border: 1px solid #e2e5ec;
  border-radius: 3px;
  margin-right: 20px;
  width: 240px;
}

.project-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
  text-align: center;
  color: #b8bec8;
  text-transform: uppercase;
  height: 190px;
  background: #fff;
  cursor: pointer;
  overflow: hidden;

  .overlay {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    color: #fff;
    opacity: 0;
    transition: .4s;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}

.screenshot {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.dashboard-menu-link {
  padding: 10px 20px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}

.project-menu {
  min-width: 250px !important;
}
</style>
