<template>
  <div :style="{...backgroundColor, ...opacity, ...shadow, ...padding, ...backgroundImage}" :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
    <div class="news-letter-3" :class="container">
      <b-row>
         <b-col :cols="cols(8, 12)" :offset="cols(2, 0)">
           <slot name="title"></slot>
           <slot name="subtitle"></slot>
         </b-col>
      </b-row>
      <div class="my-4"></div>

      <b-row>
        <b-col :cols="cols(8, 12)" :offset="cols(2, 0)" :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
          <slot name="form"></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "NewsLetterItem1Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
  .news-letter-3 {
    .es-form {
      input {
      }
    }
  }

  .resolution-sm {
    .news-letter-3 {
      .btn {
        display: block;
        width: 100%;
      }
    }
  }
</style>