import _ from "lodash";
import shortid from "shortid";

export default {
  data() {
    return {

    }
  },

  methods: {
    MailChimp(field) {
      axios.post(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields`,
        {
          ...field,
          label: String(field.name),
          name: shortid.generate().substring(0, 7),
        })
        .then(({data}) => {
          if (data.data.httpStatus === 400) {

          } else {
            this.config.fields.push({...field, ...data.data})
          }
        })
    },

    async DeleteMailChimp(index) {
      return await axios.delete(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields?fieldId=${this.config.fields[index].customFieldId}&listId=${this.config.merge_id}`,)
        .then(({data}) => {
          this.config.fields.splice(index, 1)
        })
    },

    Ontraport(field) {
      axios.post(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields`,
        {
          ...field,
          label: _.snakeCase(field.name)
        })
        .then(({data}) => {
          this.config.fields.push({...field, ...data.data})
        })
    },

    async DeleteOntraport(index) {
      return await axios.delete(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields?fieldId=${this.config.fields[index].customFieldId}`,)
        .then(({data}) => {
          this.config.fields.splice(index, 1)
        })
    },

    GetResponse(field) {
      field.name = _.snakeCase(field.name)

      axios.post(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields`,
        field)
        .then(({data}) => {
          if (data.data.httpStatus === 400) {

          } else {
            this.config.fields.push({...field, ...data.data})
          }
        })
    },

    async DeleteGetResponse(index) {
      return await axios.delete(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields?fieldId=${this.config.fields[index].customFieldId}`,)
        .then(({data}) => {
          this.config.fields.splice(index, 1)
        })
    },

    Activecampaign(field) {
      field.name = `${_.kebabCase(field.name)}`

      axios.post(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields`,
        {
          label: _.startCase(field.name),
          ...field
        })
        .then(({data}) => {
          this.config.fields.push({...field, ...data.data})
        })
    },

    async DeleteActivecampaign(index) {
      return await axios.delete(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields?fieldId=${this.config.fields[index].customFieldId}`,)
        .then(({data}) => {
          this.config.fields.splice(index, 1)
        })
    },

    AWeber(field) {
      field.apiLabel = String(field.label) || String(field.name)
      field.name = `Estage_${_.snakeCase(field.name)}`

      axios.post(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields`,
        {
          ...field,
          label: field.apiLabel
        })
        .then(({data}) => {
          this.config.fields.push({...field, ...data.data, value: ''})
        })
    },

    async DeleteAWeber(index) {

      return await axios.delete(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields?fieldId=${this.config.fields[index].id}&listId=${this.config.selectedList}`,)
        .then(({data}) => {
          this.config.fields.splice(index, 1)
        })
    },
  }
}
