import SectionProvider from '@/sections/SectionProvider';

export const name = 'Hero'
export const group = 'hero'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '40px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '61px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: {
        "type": "doc",
        "content": [
          {
            "type": "paragraph",
            "content": [
              {"type": "text", "text": "Reaching New Heights in"},
            ]
          },
          {
            "type": "paragraph",
            "content": [
              {"type": "text", "text": "Business"}
            ]
          }
        ]
      },
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: '#ffffff',
          align: 'center',
          fontFamily: 'Global Style',
          lineHeight: 2,
          padding: {
            top: 0,
            bottom: 0,
            x: 20
          }
        },
        lg: {
          fontSize: '18px',
          color: '#ffffff',
          align: 'center',
          fontFamily: 'Global Style',
          lineHeight: 2,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: {
        "type": "doc",
        "content": [{
          "type": "paragraph",
          "content": [{
            "type": "text",
            "text": "Curabitur tristique, lorem et dignissim consequat, nisi nisi tincidunt mauris, ac dictum neque odio eget elit."
          }]
        }]
      },
    },
  },
  customize: {
    layouts: null,
    style: {
      backgroundImage: {
        type: 'background-image-customs',
        src: require('@/assets/svg/previews/hero/palceholder-hero-2.svg'),
        position: 'center',
size: 'cover',
        opacity: 1
      },
      container: {
        type: 'container-customs',
        container: 'container'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 220,
          bottom: 220
        },
        lg: {
          top: 300,
          bottom: 300
        }
      }
    }
  }
}

export const previews = {
  left: require('@/assets/svg/previews/hero/left-2.png'),
  right: null,
  main: require('@/assets/svg/previews/hero/main-2.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('HeroItem2', options);

    this.name = name
    this.group = 'hero'

    this.defaultOptions = defaultOptions
  }
}
