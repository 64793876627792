<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">{{ labelCase(componentName) }}</p>

      <b-checkbox switch size="lg" v-model="config.display"></b-checkbox>
    </div>

    <b-row no-gutters>
      <b-col class="pr-2">
        <b-form-group label="Size">
          <v-select left v-model="config.style.fontSize" class="custom-select" :options="fontSizes"></v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <draggable v-model="config.links" handle=".move" class="list-group mb-3">
      <b-list-group-item v-for="(item, index) in config.links" :key="index"
                         class="d-flex align-items-center text-capitalize">
        <icon class="move mr-3" icon="move.svg"/>
        <i class="text-truncate text-primary" :class="item.icon"></i>

        <icon class="cursor-pinter ml-auto mr-3" icon="edit.svg" @click.native="newListItemModal = index"/>
        <icon class="cursor-pinter" icon="trash.svg" @click.native="config.links.splice(index, 1)"/>
      </b-list-group-item>
    </draggable>

    <div class="d-flex align-items-center justify-content-end cursor-pinter font-weight-bold text-primary w-100"
         @click="newListItemModal = true">
      <icon class="mr-2" icon="plus.svg"></icon>
      Add Link
    </div>

    <b-modal v-model="getNewListItemModal" hide-footer hide-header>
      <icon class="cursor-pinter ml-3" icon="close.svg" @click.native="newListItemModal = false"></icon>

      <h4 class="h2 font-weight-bold text-center my-4">Add Link</h4>

      <div class="p-5">
        <b-form-group v-if="typeof newListItemModal !== 'number'" label="Icon">
          <v-select v-model="icon" :options="options" placeholder="Select Icon" :reduce="social => social.key"
                    class="w-100 position-relative custom-select">
            <template v-slot:selected-option="option">
              <div class="d-flex align-items-center">
                <i :class="option.key" class="text-primary mr-2"></i>
                <div>{{option.label}}</div>
              </div>
            </template>

            <template v-slot:option="option">
              <div class="d-flex align-items-center">
                <i :class="option.key" class="text-primary mr-3"></i>
                <div>{{option.label}}</div>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group v-else label="Icon">
          <v-select v-model="config.links[newListItemModal].icon" :options="options" placeholder="Select Icon" :reduce="social => social.key"
                    class="w-100 position-relative custom-select">
            <template v-slot:selected-option="option">
              <div class="d-flex align-items-center">
                <i :class="option.key" class="text-primary mr-2"></i>
                <div>{{option.label}}</div>
              </div>
            </template>

            <template v-slot:option="option">
              <div class="d-flex align-items-center">
                <i :class="option.key" class="text-primary mr-3"></i>
                <div>{{option.label}}</div>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-row>
          <b-col cols="4">
            <b-form-group label="Background Color">
              <color-picker v-if="typeof newListItemModal !== 'number'" :color="background" @input="event => background = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"></color-picker>
              <color-picker v-else :color="config.links[newListItemModal].background" @input="event => config.links[newListItemModal].background = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"></color-picker>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Color">
              <color-picker v-if="typeof newListItemModal !== 'number'" :color="color" @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"></color-picker>
              <color-picker v-else :color="config.links[newListItemModal].color" @input="event => config.links[newListItemModal].color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"></color-picker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Shape">
          <v-select v-if="typeof newListItemModal !== 'number'" v-model="shape" :options="shapes"
                    :reduce="shape => shape.key"
                    class="w-100 position-relative custom-select" :searchable="false"></v-select>
          <v-select v-else v-model="config.links[newListItemModal].shape" :options="shapes"
                    :reduce="shape => shape.key"
                    class="w-100 position-relative custom-select" :searchable="false"></v-select>
        </b-form-group>

        <b-form-group label="Link">
          <b-input v-if="typeof newListItemModal !== 'number'" v-model="href"></b-input>
          <b-input v-else v-model="config.links[newListItemModal].href"></b-input>
        </b-form-group>

        <b-form-group v-if="typeof newListItemModal !== 'number'">
          <b-checkbox v-model="blank">Open in new tab</b-checkbox>
        </b-form-group>

        <b-form-group v-else>
          <b-checkbox v-model="config.links[newListItemModal].blank">Open in new tab</b-checkbox>
        </b-form-group>

        <div v-if="typeof newListItemModal !== 'number'" class="d-flex justify-content-center">
          <b-btn variant="primary" @click="addListItem">Add Link</b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin';
import draggable from 'vuedraggable'
import {mapGetters} from "vuex";
import ColorPicker from "../utils/ColorPicker";

export default {
  name: "ListMod",
  mixins: [ModMixin],
  components: {ColorPicker, draggable},
  data() {
    return {
      newListItemModal: false,
      icon: '',
      href: '',
      blank: '',
      shape: 'None',
      color: '#ffffff',
      background: '#1f3b9c',
      shapes: [
        {
          key: null,
          label: 'None'
        },
        {
          key: 'circle',
          label: 'Circle'
        },
      ],
      options: [
        {
          key: 'fa fa-google',
          label: 'Google'
        },
        {
          key: 'fa fa-facebook',
          label: 'Fecebook'
        },
        {
          key: 'fa fa-twitter',
          label: 'Twitter'
        },
        {
          key: 'fa fa-telegram',
          label: 'Telegram'
        },
        {
          key: 'fa fa-vk',
          label: 'VKontakte'
        }
      ],
      fontSizes: ['1px',
        '2px',
        '3px',
        '4px',
        '5px',
        '6px',
        '7px',
        '8px',
        '9px',
        '10px',
        '11px',
        '12px',
        '13px',
        '14px',
        '15px',
        '16px',
        '17px',
        '18px',
        '19px',
        '20px',
        '21px',
        '22px',
        '23px',
        '24px',
        '25px',
        '26px',
        '27px',
        '28px',
        '29px',
        '30px',
        '31px',
        '32px',
        '33px',
        '34px',
        '35px',
        '36px',
        '37px',
        '38px',
        '39px',
        '40px',
        '41px',
        '42px',
        '43px',
        '44px',
        '45px',
        '46px',
        '47px',
        '48px',
        '49px',
        '50px',
        '51px',
        '52px',
        '53px',
        '54px',
        '55px',
        '56px',
        '57px',
        '58px',
        '59px',
        '60px',
        '61px',
        '62px',
        '63px',
        '64px',
        '65px',
        '66px',
        '67px',
        '68px',
        '69px',
        '70px',
        '71px',
        '72px',
        '73px',
        '74px',
        '75px',
        '76px',
        '77px',
        '78px',
        '79px',
        '80px',
        '81px',
        '82px',
        '83px',
        '84px',
        '85px',
        '86px',
        '87px',
        '88px',
        '89px',
        '90px',
        '91px',
        '92px',
        '93px',
        '94px',
        '95px',
        '96px',
        '97px',
        '98px',
        '99px',
        '100px'],
    }
  },
  computed: {
    ...mapGetters('sections', {
      pages: 'getPages'
    }),
    getNewListItemModal: {
      get() {
        if (this.newListItemModal === 0) {
          return true
        }

        return !!this.newListItemModal
      },
      set(val) {
        this.newListItemModal = val
      }
    }
  },
  methods: {
    addListItem() {
      this.config.links.push({
        icon: this.icon,
        href: this.href,
        blank: this.blank,
        color: this.color,
        shape: this.shape,
        background: this.background
      })

      this.newListItemModal = false
    }
  }
}
</script>
