<template>
  <div>
    <VideoItems4Template :section="section">
      <template slot="video" v-if="visability('video')">
        <div v-show="visability('video')">
          <video-play  :customs="section.options.content.video"></video-play>
        </div>
      </template>

      <template slot="title" v-if="visability('title')">
        <div v-show="visability('title')">
          <pub-content-editable
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></pub-content-editable>
        </div>
      </template>


      <template slot="subtitle" v-if="visability('subtitle')">
        <div v-show="visability('subtitle')">
          <pub-content-editable
              :customs="section.options.content.subtitle"
              v-model="section.options.content.subtitle.text"></pub-content-editable>
        </div>
      </template>
    </VideoItems4Template>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import VideoItems4Template from '@pub/templates/default/sections/videos/VideoItems4Template';
import PubContentEditable from '../../../builder/utils/PubContentEditable';

export default {
  name: "VideoItem3",

  components: {PubContentEditable, VideoItems4Template, ContentEditable},

  mixins: [SectionMixin],

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
