<template>
  <b-navbar class="editor-header position-fixed" toggleable="md">
    <b-navbar-brand class="cursor-pinter" @click="$router.push('/')">
      <icon icon="main-logo.svg"></icon>
    </b-navbar-brand>

    <b-navbar-toggle class="pr-0 border-0" target="nav-collapse-editor"></b-navbar-toggle>

    <b-collapse id="nav-collapse-editor" is-nav>
      <b-navbar-nav class="align-items-center">
        <div class="d-flex align-items-center ml-5">

        </div>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-center ml-auto my-4 my-md-0">
        <div class="position-relative mr-md-4 mb-4 mb-md-0" style="min-width: 300px;">
          <b-input placeholder="Search projects…" v-model="byName"></b-input>
          <icon icon="search.svg" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%)"></icon>
        </div>
        <b-btn variant="primary" to="/templates"
               class="d-flex align-items-center btn-wide font-weight-bold mr-md-4 mb-4 mb-md-0">New Project
          <icon class="ml-2" icon="plus-white.svg"></icon>
        </b-btn>

        <b-btn slot="navigation" variant="dark" @click="$store.dispatch('auth/signOut')"
               class="d-flex align-items-center btn-wide font-weight-bold mr-md-4 mb-4 mb-md-0">Logout
        </b-btn>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "DashboardHeader",
  computed: {
    byName: {
      get() {
        return this.$store.state.filter.byName
      },
      set(val) {
        this.$store.commit('filter/SET_BY_NAME', val)
      }
    }
  }
}
</script>
