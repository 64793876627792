<template>
  <b-modal class="position-relative" v-model="modal" body-class="py-0" content-class="new-section-modal" size="xl" hide-footer hide-header>
    <icon class="close-icon cursor-pinter ml-3" icon="close.svg" @click.native="modal = false"></icon>

    <b-row class="new-section-modal-height">
      <b-col md="3" class="offset-top px-0" style="border-right: 10px solid #F7F8F9">
        <h3 class="mb-4">Add Section</h3>

        <ul class="sections-scroll list-unstyled">
          <b-card no-body class="border-0" v-for="(section, index) in sections" v-if="forRow ? hasToShow(section) : true" :key="index">
            <b-card-header v-b-toggle="index" :header-class="[{'active': index === group.name}, ' new-section-modal-toolbar-collapse-header d-flex align-items-center rounded-0']" header-tag="header" role="tab" @click="group = {name: index, sections: section}">
              <icon v-if="index === group.name" size="25px" :icon="'menu-sections/' + index + '-active.svg'"></icon>
              <icon v-else size="25px" :icon="'menu-sections/' + index + '.svg'"></icon>
              <div class="text-capitalize ml-4" block>{{startCase(index)}}</div>
            </b-card-header>
          </b-card>
        </ul>
      </b-col>

      <b-col md="9" class="offset-top" v-if="group">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h5 class="text-capitalize">{{group.name}}</h5>
          <b-btn variant="primary" class="font-weight-bold px-4" :disabled="!Object.keys(selected).length" @click="addSection">Insert Now</b-btn>
        </div>
<!--        {{group.sections}}-->

        <b-row class="scroller">
          <b-col v-if="forRow ? (!!section.allowRow || !!section.allowRowOnly) : !section.allowRowOnly" v-for="(section, index) in group.sections" :key="index" cols="6" class="section mb-4" @click="selected = section">
            <div class="section-preview-container overflow-hidden" :class="{'active': isActive(section.name)}">
              <b-img class="cursor-pinter" :src="section.previews.main" fluid></b-img>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
  import {mapState} from 'vuex'
  import sections from '@/sections'
  import TextMixins from "../../mixins/TextMixins";
  import _ from "lodash";

  export default {
    name: "NewSectionModal",

    mixins: [TextMixins],

    data() {
      return {
        sections: sections,
        group: {},
        selected: {}
      }
    },

    methods: {
      hasToShow(group) {
        return group.filter(section => section.allowRowOnly || section.allowRow).length
      },
      isActive(name) {
        if (this.selected.hasOwnProperty('name')) {
          return this.selected.name === name
        }
      },
      addSection() {
        const section = new this.selected.section().json()

        if (_.kebabCase(section.group) === _.kebabCase('navbar')) {
          this.$store.commit('sections/ADD_NAVBAR', {
            section: new this.selected.section().json()
          })
        } else if (_.kebabCase(section.group) === _.kebabCase('footer')) {
          this.$store.commit('sections/ADD_FOOTER', {
            section: new this.selected.section().json()
          })
        } else {
          this.$store.dispatch('sections/addSection', {
            after: this.getUID(),
            deep: this.forRow,
            section: new this.selected.section().json()
          })
        }

        this.modal = false
      },
      getUID() {
        return typeof this.newSectionModal === 'object' ? this.newSectionModal.uid : this.newSectionModal
      }
    },

    computed: {
      forRow() {
        return typeof this.newSectionModal === 'object'
      },
      modal: {
        get() {
          const sectionName = Object.keys(this.sections)[0]

          this.group = {
            name: sectionName,
            sections: this.sections[sectionName]
          }

          return !!this.$store.state.editor.newSectionModal
        },
        set() {
          this.$store.commit('editor/SET_SECTION_MODAL', false)
          this.selected = false
        }
      },
      ...mapState('editor', {
        newSectionModal: state => state.newSectionModal
      })
    }
  }
</script>
