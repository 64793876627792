<template>
  <footer :style="{...backgroundColor , ...padding, ...backgroundImage}" class="footer-3" :class="theme">
    <b-container>
      <b-row class="align-items-center">
        <b-col :cols="cols(3, 12)">
          <div v-if="visability('logo')"
               class="mb-3"
          >
            <slot name="logo"></slot>
          </div>

          <template v-if="visability('copyright')">
            <slot name="copyright"></slot>
          </template>
        </b-col>

        <b-col :cols="cols(7, 12)" :class="cols('text-center', '')">
          <template v-if="visability('menu')">
            <slot name="menu"></slot>
          </template>
        </b-col>

        <b-col :cols="cols(2, 12)">
          <template v-if="visability('socialLinks')">
            <slot name="social"></slot>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import ThemeMixin from "../../../../mixins/ThemeMixin";
  import {mapGetters} from "vuex";

  export default {
    name: "Carousel1Template",
    mixins: [TemplateMixin, ThemeMixin],
    computed: {
      ...mapGetters('sections', [
        'getSections'
      ]),
    },
    created() {
      if (this.section.options.content.logo.type !== 'logo-mod') {
        const doctorLogoMod = {
          type: 'logo-mod',
          display: true,
          image: {
            src: this.section.options.content.logo.src || this.section.options.content.logo.placeholder
          },
          text: ''
        }

        this.section.options.content.logo = doctorLogoMod
      }
    }
  }
</script>

<style lang="scss">
.footer-3 {
  z-index: 1 !important;

  a,
  .editor__content,
  .pub__content {
    transition: 1s;
  }

  &.light {
    a,
    .editor__content,
    .pub__content {
      color: #000;
    }
  }

  &.dark {
    a,
    .editor__content,
    .pub__content {
      color: #fff;
    }
  }
}
</style>
