<template>
  <div class="global-styles overflow-y-auto pb-3">
    <b-card-header header-class="editor-toolbar-card-header d-flex justify-content-between align-items-center">
      <h5 class="editor-toolbar-title mb-0">Page Settings</h5>

      <b-link class="d-flex align-items-center font-weight-light small link-muted"
              @click="$store.commit('editor/SET_PAGE', 'sections')">
        <icon class="mr-2" icon="left-arrow.svg"></icon>
        Go Back
      </b-link>
    </b-card-header>

    <b-card no-body class="border-0 mb-4">
      <b-card-header v-b-toggle="'general'"
                     header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between rounded-0 border-bottom-0"
                     header-tag="header" role="tab">
        <div class="text-capitalize" block>General</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="general" role="tabpanel" visible>
        <b-container class="px-4">
          <!--         <b-form-group label="Page Name">-->
          <!--           <b-input value="Home"></b-input>-->
          <!--         </b-form-group>-->

          <b-form-group label="Your original URL">
            <b-input :value="url + 'site/' + project.subdomain" readonly></b-input>
          </b-form-group>

<!--          <b-form-group label="Title">-->
<!--            <b-input v-model="globalStyles.title" placeholder="Enter title for your website" @input="changeTitle"></b-input>-->
<!--          </b-form-group>-->

          <hr class="my-3">

          <b-form-group>
            <div class="d-flex align-items-center shadow-sm border rounded cursor-pinter p-2"
                 @click="$router.push({name: 'module.blog'})">
              <icon class="mr-2" icon="right-arrow.svg"></icon>
              <span class="mb-0">Estage Blog</span>
            </div>
          </b-form-group>

          <!--         <b-form-group label="Site Availability">-->
          <!--           <b-select></b-select>-->
          <!--         </b-form-group>-->
        </b-container>
      </b-collapse>
    </b-card>

    <b-card v-if="false" no-body class="border-0 mb-4">
      <b-card-header v-b-toggle="'seo'"
                     header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between border-bottom-0 border-top rounded-0"
                     header-tag="header" role="tab">
        <div class="text-capitalize" block>Search engine optimization (SEO)</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="seo" role="tabpanel" visible>
        <b-container class="px-4">
          <b-form-group label="SEO page title">
            <b-input value="Home"></b-input>
          </b-form-group>

          <b-form-group label="SEO page description">
            <b-textarea></b-textarea>
          </b-form-group>

          <!--          <b-form-group label="Search visibilty">-->
          <!--            <b-form-radio v-model="selected" name="some-radios" value="A">Option A</b-form-radio>-->
          <!--            <b-form-radio v-model="selected" name="some-radios" value="B">Option B</b-form-radio>-->
          <!--          </b-form-group>-->
        </b-container>
      </b-collapse>
    </b-card>

        <b-card no-body class="border-0 mb-4">
          <b-card-header v-b-toggle="'code'" header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between rounded-0 border-bottom-0 border-top" header-tag="header" role="tab">
            <div class="text-capitalize" block>Add Custom Scripts / Code</div>
            <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
          </b-card-header>
          <b-collapse id="code" role="tabpanel" visible>
            <b-container class="px-4">
              <div class="d-flex align-items-center shadow-sm border rounded cursor-pinter p-2"
                   @click="injectionModal">
                <icon class="mr-2" icon="right-arrow.svg"></icon>
                <label class="cursor-pinter mb-0">Add Code Injection</label>
              </div>
            </b-container>
          </b-collapse>
        </b-card>

    <!--    <b-container class="px-4 mb-4">-->
    <!--      <b-btn variant="primary" class="btn-wide">Update Settings</b-btn>-->
    <!--    </b-container>-->
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "PageSettings",

  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
  },

  data() {
    return {
      selected: 'A',
      url: window.location.origin + '/',
      preview: {
        src: ''
      },
    }
  },

  mounted() {
    this.preview.src = this.globalStyles.favicon || ''
  },

  methods: {
    changeTitle(val) {
      document.title = val || 'Estage Builder';
    },
    setFavicon(src) {
      this.globalStyles.favicon = src

      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = src
    },
    imageDeleted() {
      this.setFavicon('/favicon.png')
    },
    updateFavicon(image) {
      this.setFavicon(image.src)
    },
    injectionModal() {
      VEvent.fire('injection-code')
    }
  }
}
</script>

<style lang="scss">
.global-styles {
  .select-box {
    border-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
    text-align: center;
    font-size: 34px;
    width: 88px;
    height: 80px;
    display: flex !important;
    border: 1px solid #e2e5ec;
    align-items: center;
    justify-content: center;

    &.active {
      box-shadow: 0 0 0 2px #7CACFA !important;
    }
  }

  label {
    font-size: 15px;
  }

  .color-picker {
    padding: 0 !important;
    border: none !important;
  }
}
</style>
