<template>
  <highliter class="feature-section"
             :label="section.name"
             :uid="section.uid"
             @click.stop.native="sectionSettings('images')">
    <Price1Template :section="section">
      <highliter @click.stop.native="sectionSettings('title')">
        <content-editable
            :uid="tiptapUID('title')"
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></content-editable>
      </highliter>

      <highliter @click.stop.native="sectionSettings('price')">
        <content-editable
            :uid="tiptapUID('price')"
            :customs="section.options.content.price"
            v-model="section.options.content.price.text"></content-editable>
      </highliter>

      <highliter @click.stop.native="sectionSettings('description')">
        <content-editable
            :uid="tiptapUID('description')"
            :customs="section.options.content.description"
            v-model="section.options.content.description.text"></content-editable>
      </highliter>

      <highliter v-show="visability('list')" @click.stop.native="sectionSettings('list')">
        <ul class="list-unstyled my-4">
          <li v-for="(item, index) in section.options.content.list.items" :key="item.label" class="list-item d-flex align-items-center mb-4">
<!--            <div :style="{webkitMask: `url(${section.options.customize.style.listIcon.icon}) center / contain no-repeat`, backgroundColor: section.options.customize.style.listIcon.color}"  class="list-icon mr-2" alt="List Icon"></div>-->
            <i class="mr-2" :style="{color: section.options.customize.style.listIcon.color !== 'adaptive' ? section.options.customize.style.listIcon.color : null}" :class="section.options.customize.style.listIcon.icon"></i>
            <editable v-model="item.text"></editable>
          </li>
        </ul>
      </highliter>

      <highliter @click.stop.native="sectionSettings('button')">
        <es-button :customs="section.options.content.button"></es-button>
      </highliter>
    </Price1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Price1Template from "./Price1Template";
import Editable from "../../../utils/Editable";

export default {
  name: "PriceItem",

  components: {Editable, Price1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
