<template>
  <div class="d-flex align-items-center justify-content-center min-vh-100" v-if="loading">
    <b-spinner large></b-spinner>
  </div>
  <b-container class="min-vh-100 py-5" v-else>
  <div class="text-center mb-5">
    <h1 :style="{color: globalStyles.colors.primary}">
      <b>{{post.title}}</b>
    </h1>

    <div class="d-flex align-items-center justify-content-center">
      <div v-if="post.author">Posted by <span :style="{color: globalStyles.colors.primary}">{{post.author}}</span></div> <span v-if="post.author" class="mx-2">|</span> <time>{{post.created_at | moment("from", "now")}}</time>
    </div>
  </div>

    <image-fit
        v-if="!post.video"
        :customs="{}"
        :src="JSON.parse(post.cover).src"
        height="40vh"
        size="contain"
        class="mb-5"
    />
    <video-play v-else :customs="{
      display: true,
      placeholder: JSON.parse(post.cover).src,
      src: post.video,
      height: '40vh',
      position: 'center',
      size: 'cover'
    }" class="mb-5" />

    <div v-html="post.description"></div>

  </b-container>
</template>

<script>
import {mapState} from "vuex";
import ImageFit from "../../components/utils/ImageFit";
import VideoPlay from "../../components/builder/utils/VideoPlay";
import _ from "lodash";

export default {
  name: "PublicEsBlog",
  components: {VideoPlay, ImageFit},
  data() {
    return {
      post: {},
      loading: true
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
  },

  methods: {
    async getPost() {
      await axios.get(`api/projects/${this.project.id}/blog/${this.$route.params.postSlug}`)
          .then(({data}) => {
            this.post = data.data
            document.title = this.post.title
          })
          .finally(() => {
            this.loading = false
          })
    }
  },

  created() {
    this.getPost()
  },

  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>