<template>
  <div :style="{...backgroundImage, ...backgroundColor, ...padding, ...opacity, ...shadow}">
    <div class="action-item-11" :class="container">
      <b-row class="align-items-center">
        <b-col :order="aligment('left') ? 0 : 1" :md="cols(7, 12)">
          <slot name="video"></slot>
        </b-col>

        <b-col :order="aligment('left') ? 1 : 0" :md="cols(5, 12)">
          <slot name="title"></slot>
          <div class="my-2"></div>
          <slot name="description"></slot>
          <div :class="cols('my-4', 'my-2')"></div>
          <slot name="button"></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">

</style>