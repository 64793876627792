<template>
  <div class="mb-4">
    <label class="mb-2">Opacity</label>
    <b-input type="range" min="0" max="1" step="0.1" v-model="config.opacity"></b-input>
  </div>
</template>

<script>
import ControlMixin from "../../mixins/ControlMixin";

export default {
  name: "Opacity",

  mixins: [ControlMixin]
}
</script>