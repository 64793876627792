<template>
  <div :style="{...padding, ...backgroundColor, ...backgroundImage, ...opacity, ...shadow}">
    <div :class="container">
      <b-row class="about-us-2 align-items-center">
        <b-col :cols="cols(7, 12)" :class="cols('', 'mb-4')">
          <slot name="title"></slot>
          <slot name="description"></slot>
        </b-col>

        <b-col :cols="cols(5, 12)">
          <slot name="image"></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "AboutUs1Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
.about-us-2 {
  .images-group-1 {
    padding: 0 !important;
    background: unset !important;
  }
}
</style>
