<template>
  <div class="feature-section">
    <HeadlineItem4Template :section="section">
      <template slot="title" v-if="visability('title')">
          <pub-content-editable
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="subtitle" v-if="visability('subtitle')">
          <pub-content-editable
              :customs="section.options.content.subtitle"
              v-model="section.options.content.subtitle.text"></pub-content-editable>
      </template>

      <template slot="button" v-if="visability('button')">
          <pub-es-button :customs="section.options.content.button"></pub-es-button>
      </template>
    </HeadlineItem4Template>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import HeadlineItem4Template from '@pub/templates/default/sections/headlines/HeadlineItem4Template';
import PubContentEditable from '../../../builder/utils/PubContentEditable';
import PubEsButton from '../../../builder/utils/buttons/PubEsButton';

export default {
  name: "HeadlineItem4",

  components: {PubEsButton, PubContentEditable, HeadlineItem4Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
