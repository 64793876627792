<template>
  <div>
    <b-modal v-model="modal" title="Code Injection" hide-footer size="lg">
      <b-form @submit.prevent="submit">
        <b-form-group>
          <slot name="label">
            <h6>Embed Custom/Tracking Code Into The &lt;HEAD&gt;</h6>
          </slot>
          <b-textarea v-model="injection.head" rows="5" class="mb-2"></b-textarea>
        </b-form-group>

        <b-form-group>
          <slot name="label">
            <h6>Embed Custom/Tracking Code Into The &lt;BODY&gt;</h6>
          </slot>
          <b-textarea v-model="injection.body" rows="5" class="mb-2"></b-textarea>
        </b-form-group>

        <div class="d-flex w-100 justify-content-end">
          <b-btn variant="primary" type="submit">Save Changes</b-btn>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SettingsPageCard from "../../../layouts/dashboard/SettingsPageCard";
import HsAddField from "../../../plugins/hs-add-field/HsAddField";
import CodeInjectionPageMixin from "../../mixins/CodeInjectionPageMixin";

export default {
  name: "InjectionPageModal",
  components: {HsAddField, SettingsPageCard},

  mixins: [CodeInjectionPageMixin],

  data() {
    return {
      cdn: [],
      modal: false,
      injection: {},
      reactiveInjection: false
    }
  },

  methods: {
    submit() {
      VEvent.fire('loader', true)

      this.$store.dispatch('projects/save')
          .finally(() => {
            VEvent.fire('loader', false)
          })
    },
    inj() {
      this.injection = this.getPageInjections
      this.modal = true
    }
  },

  created() {
    VEvent.listen('injection-code', () => this.inj())
  }
}
</script>