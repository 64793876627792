<template>
  <FeaturedItems5Template :section="section">
    <template slot="title">
      <pub-content-editable
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description">
      <pub-content-editable
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="button">
      <pub-es-button :customs="section.options.content.button"></pub-es-button>
    </template>
  </FeaturedItems5Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import FeaturedItems5Template from '@pub/templates/default/sections/featured/FeaturedItems5Template';
  import PubContentEditable from "../../../builder/utils/PubContentEditable";
  import PubEsButton from "../../../builder/utils/buttons/PubEsButton";

  export default {
    name: "FeaturedItems5",

    components: {PubEsButton, PubContentEditable, FeaturedItems5Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
