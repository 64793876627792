import SectionProvider from '@/sections/SectionProvider';

export const name = 'Option 1'
export const group = 'option-forms'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: '#fff',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '46px',
          fontFamily: 'Global Style',
          color: '#fff',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Get Your Free Report</strong></p><p><strong>Download Today</strong></p>"
    },
    text: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          lineHeight: 1.3,
          color: '#FFFFFF',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          lineHeight: 1.4,
          color: '#FFFFFF',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Curabitur tristique, lorem et dignissim consequat urabitur tristique, lorem et dignissim consequat. Curabitur tristique, lorem</p>"
    },
    image: {
      display: true,
      type: 'image-mod',
      size: 'cover',
      position: 'left',
      placeholder: require('@/assets/svg/previews/options/placeholder-1.png'),
      src: null
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'View Demo',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    form: {
      type: 'form-mod',
      display: true,
      action: null,
      selectedList: '',
      btnPosition: 'append-email',
      fields: [
        {
          display: true,
          type: 'email',
          name: 'email',
          placeholder: 'Enter your email address',
          required: true,
          label: null,
          cols: {
            sm: 12,
            lg: 12
          }
        }
      ]
    },
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: '#1F3B9C'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 70,
          bottom: 70
        },
        lg: {
          top: 115,
          bottom: 115
        }
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
        size: 'cover',
        opacity: 1
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/options/main-1.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Option1', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
