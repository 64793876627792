<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">Menu Items</p>

      <b-checkbox switch v-model="config.display"></b-checkbox>
    </div>

    <div class="d-flex align-items-center shadow-sm border rounded cursor-pinter p-2 mb-3" @click="dialog(true)">
      <icon class="mr-2" icon="right-arrow.svg"></icon>
      <label class="mb-0">Go to Site Navigation</label>
    </div>

    <b-form-group>
      <b-checkbox v-model="config.uppercase">All CAPS</b-checkbox>
    </b-form-group>

    <b-form-group class="mb-0">
      <text-color v-model="config.color"></text-color>
    </b-form-group>

    <b-modal body-class="nav-modal p-0" v-model="linksDialog" size="lg" hide-footer hide-header>
      <icon class="cursor-pinter ml-3 mt-3" icon="close.svg" @click.native="dialog(false)"></icon>

      <h4 class="h2 text-center my-4">Site Navigation</h4>

      <b-row class="border-top" no-gutters>
        <b-col class="border-bottom pb-2 pt-3 px-3" cols="12">
          <h6 class="font-weight-bold">Site Navigation</h6>
        </b-col>

        <b-col class="border-right pb-3" :cols="5">
          <div class="nav-items-els">
            <nested-draggable :children="navbar.nav">
              <template slot="item" slot-scope="{page}">
                <section-item-hover :class="{'active': page.name === activeItem.page.name}" @click.native.stop="active(page, navbar.nav)">
                  <icon class="move mr-3" icon="move.svg"/>
                  <div class="text-capitalize" block>{{ page.name }}</div>
                </section-item-hover>

                <nested-draggable v-if="page.children" :children="page.children">
                  <template slot="item" slot-scope="subPage">
                    <section-item-hover :class="{'active': subPage.page.name === activeItem.page.name}" @click.native.stop="active(subPage.page, page.children)">
                      <icon class="move mr-3" icon="move.svg"/>
                      <div class="text-capitalize" block>{{ subPage.page.name }}</div>
                    </section-item-hover>

                    <nested-draggable v-if="subPage.page.children" :children="subPage.page.children">
                      <template slot="item" slot-scope="subSubPage">
                        <section-item-hover :class="{'active': subSubPage.page.name === activeItem.page.name}" @click.native.stop="active(subSubPage.page, subPage.children)">
                          <icon class="move mr-3" icon="move.svg"/>
                          <div class="text-capitalize" block>{{ subSubPage.page.name }}</div>
                        </section-item-hover>
                      </template>
                    </nested-draggable>
                  </template>
                </nested-draggable>
              </template>
            </nested-draggable>
          </div>

          <div class="px-3 mt-4">
            <b-btn class="btn-wide" variant="primary" @click="dialogNewLink(false)">Add Nav Link</b-btn>
          </div>
        </b-col>

        <b-col class="d-flex flex-wrap py-3 px-4" :cols="7">
          <div class="w-100">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <h6 class="font-weight-bold text-capitalize">{{ activeItem.page.name }}</h6>

              <icon v-if="navbar.nav.length > 1" class="cursor-pinter" icon="trash.svg" @click.native="confirmDialog = true"></icon>
            </div>

            <b-form-group label="Name">
              <b-input v-model="activeItem.page.name"></b-input>
            </b-form-group>

            <b-form-group label="Page">
              <v-select class="custom-select" v-model="activeItem.page.key" :options="getPages" label="name" :reduce="option => option.key"></v-select>
              <b-link v-if="activeItem.page.key" class="d-block mt-2" @click="editPage">Edit page</b-link>
            </b-form-group>

            <b-form-group label="Custom Link">
              <b-input v-model="activeItem.page.custom_link"></b-input>
            </b-form-group>

            <b-form-group>
              <b-btn class="btn-wide" variant="outline-primary" @click="dialogNewLink(true)">Add Sub Link</b-btn>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal body-class="text-center py-5" v-model="confirmDialog" size="lg" hide-footer hide-header centered>
      <h4 class="h3 font-weight-bold my-4">Are you sure you want to delete this link?</h4>

      <p>The link <span class="text-primary">{{activeItem.page.name}}</span> {{activeItem.page.children ? 'and everything under the links of this category will be removed:' : 'will be removed'}}</p>

      <div v-if="activeItem.page.children" class="d-flex justify-content-center">
        <ul class="mb-4">
          <li v-for="link in activeItem.page.children">{{link.name}}</li>
        </ul>
      </div>


     <div class="d-flex align-items-center justify-content-center">
       <b-btn variant="danger" class="btn-wide mr-2" @click="removeLink">Yes</b-btn>
       <b-btn class="btn-wide" @click="confirmDialog = false">No</b-btn>
     </div>
    </b-modal>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin';
import {mapGetters, mapState} from 'vuex';
import TextColor from '@/components/editor/controls/TextColor';
import SectionItemHover from "../utils/SectionItemHover";
import draggable from 'vuedraggable'
import NestedDraggable from "../utils/NestedDraggable";

export default {
  name: "ButtonMod",
  components: {NestedDraggable, SectionItemHover, TextColor, draggable},
  mixins: [ModMixin],
  data() {
    return {
      linksDialog: false,
      confirmDialog: false,
      activeItem: {
        page: {
          key: '',
          name: '',
          path: '',
        },
        list: null
      }
    }
  },
  computed: {
    ...mapGetters('sections', [
      'getPages'
    ]),
    ...mapState('sections', {
      navbar: state => state.navbar
    })
  },
  methods: {
    editPage() {
      VEvent.fire('newPageModal', this.activeItem.page.key)
      this.linksDialog = false
    },
    active(page, list) {
      this.activeItem = {
        page: page,
        list: list
      }
    },
    dialog(state) {
      this.linksDialog = state
    },
    removeLink() {
      this.$store.dispatch('sections/removeLink', this.activeItem)

      this.activeItem = {
        page: {
          key: '',
          name: '',
          path: '',
        },
        list: null
      }

      this.dialog(true)
      this.confirmDialog = false
    },
    dialogNewLink(state) {
      this.linksDialog = false
      VEvent.fire('newPageDialog', this.activeItem.page.key && state ? this.activeItem : null)
    }
  }
}
</script>

<style lang="scss">
.nav-modal {
  .nav-items-els {
    height: 350px;
    overflow-y: auto;
  }

  .editor-toolbar-collapse-header {
    padding: 18.8px 22px;
    cursor: pointer;
    font-size: 16px;
    background-color: #fff;
    color: #44474A;

    &:hover,
    &.active {
      background-color: #FAFCFF;
      color: #4F83E3;
    }
  }
}
</style>
