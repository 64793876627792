import {mapState} from "vuex";

export default {
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    getSubdomainIfNotDomain() {
      return this.project.domain ? '' : this.project.subdomain
    },
    getPublicLoadProjectId() {
      return window.location.host === process.env.VUE_APP_LOCAL_HOST || this.removeWWW(window.location.host) === process.env.VUE_APP_EDITOR_HOST ? window.location.pathname.split('/')[2] : this.removeWWW()
    },
    isCurrentCustomDomain() {
      return window.location.host !== process.env.VUE_APP_LOCAL_HOST && this.removeWWW(window.location.host) !== process.env.VUE_APP_EDITOR_HOST
    },
    getPageBlankURL() {
      return `${window.location.origin}/site${!this.isCurrentCustomDomain ? '/' + this.project.subdomain : ''}`
    },
    getSiteHome() {
      return this.project.domain ? this.addhttp(this.project.domain) : `${window.location.origin}` + `/site/${this.project.subdomain}`
    }
  },

  methods: {
    addhttp(url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
      }
      return url;
    },
    removeWWW(host) {
      if (!host) host = window.location.host
      const result = host.match(/^www\.(.*)/)
      return result ? result[1] : host
    }
  }
}