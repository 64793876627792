<template>
  <b-form @submit.prevent="submit">
    <b-form-group>
      <div slot="label">
        <h6 class="text-dark font-weight-bold">Custom Domain</h6>
        <span class="text-muted">Add your domain below. Domain should be pointing to the E-Stage IP Address.</span>
      </div>
      <div class="d-flex position-relative">
        <i class="fa fa-globe position-absolute" style="left: 10px; top: 50%; transform: translateY(-50%)"></i>
        <b-input v-model="domain"
                 name="domain"
                 placeholder="www.domain.com"
                 :state="errors.has('domain') ? false : null"
                 autocomplete="off"
                 style="padding-left: 30px;"
                 v-validate="'required|domain'">
        </b-input>

        <div class="ml-2" v-if="project.domain && !verify">
          <div class="btn btn-white flex-nowrap text-nowrap d-flex align-items-center h-100 border text-danger">
            <i class="fa fa-wifi mr-2"></i>
            Not connected
          </div>
        </div>

        <div class="ml-2" v-if="verify">
          <div class="btn btn-white flex-nowrap text-nowrap d-flex align-items-center h-100 border text-success">
            <i class="fa fa-wifi mr-2"></i>
            Connected
          </div>
        </div>

        <div class="ml-2" v-if="project.domain && !loading">
          <b-btn :disabled="loading"
                 variant="white"
                 @click="deleteDomain"
                 class="d-flex align-items-center h-100 border">
            <i class="fa fa-trash-o"></i>
          </b-btn>
        </div>
      </div>
      <span v-show="errors.has('domain')" class="d-block text-danger mt-1">{{ errors.first('domain') }}</span>
    </b-form-group>

    <b-btn :disabled="loading || project.domain === domain"
           type="submit"
           variant="success"
           class="d-flex align-items-center">
      Add Custom Domain
      <b-spinner v-if="loading"
                 class="ml-2"
                 variant="white"
                 small/>
    </b-btn>
  </b-form>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import {mapState} from "vuex";
import RouterMixin from "../../components/mixins/RouterMixin";

export default {
  name: "DoaminConnectForm",

  mixins: [RouterMixin],

  directives: {
    mask: VueMaskDirective
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
  },

  data() {
    return {
      domain: '',
      loading: false,
      verify: false
    }
  },

  methods: {
    deleteDomain() {
      this.domain = ''
      this.project.domain = ''
      this.loading = true
      this.verify = false
      this.$validator.reset()

      this.$store.dispatch('projects/save')
          .then(() => {
            this.$bvToast.toast(`Domain deleted`, {
              title: 'Info',
              autoHideDelay: 2000,
              appendToast: false,
              variant: 'info'
            })
          })
          .finally(() => {
            this.loading = false
          })
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true
          this.project.domain = this.removeWWW(this.domain)

          this.$store.dispatch('projects/save')
              .then(() => {
                this.checkDomain()
                this.$bvToast.toast(`Domain connected.`, {
                  title: 'Info',
                  autoHideDelay: 2000,
                  appendToast: false,
                  variant: 'info'
                })
              })
              .catch(errors => {
                this.project.domain = ''
                const arrErrors = Object.keys(errors.response.data.errors),
                    errorMsg = arrErrors.length ? errors.response.data.errors[Object.keys(errors.response.data.errors)[0]] : errors.response.data.message

                this.$bvToast.toast(errorMsg, {
                  title: 'Error',
                  autoHideDelay: 2000,
                  appendToast: false,
                  variant: 'danger'
                })
              })
              .finally(() => {
                this.loading = false
              })
        }
      })
    },
    checkDomain() {
      if (!this.project.domain) return false

      axios.get(`api/projects/${this.project.id}/check-domain`)
          .then(({data}) => {
            this.verify = data.data
          })
    }
  },
  created() {
    if (this.project.domain) {
      this.domain = this.project.domain
      this.checkDomain()
    }
  }
}
</script>