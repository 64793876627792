<template>
  <highliter class="feature-section"
             :label="section.name"
             :uid="section.uid"
             @click.stop.native="sectionSettings('images')">
    <Price1Template :section="section">
      <b-breadcrumb>
        <b-breadcrumb-item>Home</b-breadcrumb-item>
        <b-breadcrumb-item>Services</b-breadcrumb-item>
        <b-breadcrumb-item active>Done For You</b-breadcrumb-item>
      </b-breadcrumb>
    </Price1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Price1Template from "./Price1Template";

export default {
  name: "Carousel1",

  components: {Price1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
