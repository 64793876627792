import SectionProvider from '@/sections/SectionProvider';

export const name = 'Action Item 5'

export default class ActionItem5 extends SectionProvider {
  constructor(options = {}) {
    super('ActionItem5', options);

    this.name = name
    this.group = ''

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '43px',
              fontFamily: 'Global Style',
              color: '#EDEDED',
              align: 'center',
            },
            lg: {
              fontSize: '43px',
              fontFamily: 'Global Style',
              color: '#EDEDED',
              align: 'center',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"Get a Free 15-Minute Consult With Me"}]}]},
        },
        description: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#EDEDED',
              align: 'center',
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#EDEDED',
              align: 'center',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus ultrices. Nullam consequat leo vel"}]}]},
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/placeholder-image.svg'),
          src: null,
          position: 'center',
size: 'cover'
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'BOOK A CALL',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'xl',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'xl',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        style: {
          background: {
            background: '#AEB1B4'
          },
          container: {
            type: 'container-customs',
            container: null
          },
        }
      }
    }
  }
}
