import SectionProvider from '@/sections/SectionProvider';

export const name = 'Row Container'
export const group = 'utils'
export const defaultOptions = {
  content: {
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Click me',
      resolutionStyle: {
        lg: {
          size: 'lg',
          btnStyle: '',
          color: '#ffffff',
          backgroundColor: 'colored',
          shape: null,
          shadow: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          size: 'lg',
          btnStyle: '',
          color: '#ffffff',
          backgroundColor: 'colored',
          shape: null,
          shadow: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {}
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/row/main-1.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}, items = []) {
    super('EsRow', options);

    this.name = name
    this.group = group

    this.defaultOptions = {
      content: {
        group: {
          type: 'row-mod',
          display: true,
          items
        }
      },
      customize: {
        noGutters: false,
        layouts: false,
        style: {
          alignRow: {
            type: 'align-row-customs',
            align: 'start'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
            size: 'cover',
            opacity: 1
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 50,
              bottom: 50
            },
            lg: {
              top: 50,
              bottom: 50
            }
          }
        }
      }
    }
  }
}
