<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <span class="indicator mr-2"></span>
        <p class="text-capitalize mb-0">{{ labelCase(componentName) }}</p>
      </div>

      <b-checkbox switch v-model="config.display"></b-checkbox>
    </div>

    <div class="d-flex align-items-center justify-content-between mb-2 pr-2">
      <label class="mb-2">Font</label>

      <b-form-radio-group v-model="resolutionSync" class="resolution-style-tabs" buttons-variant="light"
                          value-field="key" text-field="text" size="sm" buttons
                          :options="[{text: 'Desktop', key: 'lg'}, {text: 'Mobile', key: 'sm'}]"></b-form-radio-group>
    </div>

    <div class="position-relative">
      <editor-menu-bar class="mb-4" v-if="editor" :editor="editor"
                       v-slot="{ commands, isActive, getNodeAttrs, getMarkAttrs}">
        <div class="menubar">
          <b-row no-gutters>
            <b-col cols="5" class="mr-2">
              <b-form-group>
                <v-select left v-model="config.resolutionStyle[resolution].fontFamily" class="custom-select"
                          :options="fonts"></v-select>
              </b-form-group>
            </b-col>

            <b-col cols="4" class="mr-2">
              <b-form-group>
                <v-select left ref="fontSizeSelect" v-model="config.resolutionStyle[resolution].fontSize"
                          @input="event => applyFontSize(event, commands, getNodeAttrs)" class="custom-select"
                          :options="fontSizes"></v-select>
              </b-form-group>
            </b-col>

            <b-col cols="1">
              <b-form-group>
                <color-picker :color="getPickedColorAttributes(getMarkAttrs('textcolor'))"
                              @change="event => applyTextColor(`rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`, commands, getNodeAttrs)"></color-picker>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="d-flex flex-wrap mb-2">
            <b-btn-group class="pr-3 mb-2">
              <b-btn
                  v-b-tooltip="'Bold'"
                  variant="menubar-button"
                  :class="{ 'is-active': isActive.bold() }"
                  @click="commands.bold"
              >
                <i class="fa fa-bold"></i>
              </b-btn>
              <b-btn
                  v-b-tooltip="'Italic'"
                  variant="menubar-button"
                  :class="{ 'is-active': isActive.italic() }"
                  @click="commands.italic"
              >
                <i class="fa fa-italic"></i>
              </b-btn>
              <b-btn
                  v-b-tooltip="'Underline'"
                  variant="menubar-button"
                  :class="{ 'is-active': isActive.underline() }"
                  @click="commands.underline"
              >
                <i class="fa fa-underline"></i>
              </b-btn>
              <b-btn
                  v-if="config.hasOwnProperty('mods') && config.mods.includes('strike')"
                  variant="menubar-button"
                  :class="{ 'is-active': isActive.strike() }"
                  @click="commands.strike"
              >
                <i class="fa fa-strikethrough"></i>
              </b-btn>
            </b-btn-group>

            <b-btn-group class="mb-2">
              <b-btn
                  v-b-tooltip="'Text align left'"
                  variant="menubar-button"
                  :class="{ 'is-active': config.resolutionStyle[resolution].align === 'left' }"
                  @click="config.resolutionStyle[resolution].align = 'left'"
              >
                <i class="fa fa-align-left"></i>
              </b-btn>
              <b-btn
                  v-b-tooltip="'Text align center'"
                  variant="menubar-button"
                  :class="{ 'is-active': config.resolutionStyle[resolution].align === 'center' }"
                  @click="config.resolutionStyle[resolution].align = 'center'"
              >
                <i class="fa fa-align-center"></i>
              </b-btn>
              <b-btn
                  v-b-tooltip="'Text align right'"
                  variant="menubar-button"
                  :class="{ 'is-active': config.resolutionStyle[resolution].align === 'right' }"
                  @click="config.resolutionStyle[resolution].align = 'right'"
              >
                <i class="fa fa-align-right"></i>
              </b-btn>
            </b-btn-group>

            <b-btn-group v-if="config.hasOwnProperty('mods') && config.mods.includes('list')" class="ml-3 mb-2">
              <b-btn
                  variant="menubar-button"
                  :class="{ 'is-active': isActive.bullet_list() }"
                  @click="commands.bullet_list"
              >
                <i class="fa fa-list-ul"></i>
              </b-btn>

              <b-btn
                  variant="menubar-button"
                  v-b-tooltip="'Orderd list'"
                  :class="{ 'is-active': isActive.ordered_list() }"
                  @click="commands.ordered_list"
              >
                <i class="fa fa-list-ol"></i>
              </b-btn>
            </b-btn-group>
          </div>

          <b-form-group label="Font Weight" class="mr-4">
            <v-select left :searchable="false" v-model="localFontWeight" class="custom-select"
                      @input="event => applyFontWeight(event, commands, getNodeAttrs)" :reduce="option => option.value"
                      :options="fontsWeight"></v-select>
          </b-form-group>

          <div class="pr-4">
            <div class="d-flex align-items-center">
              <b-btn
                  variant="menubar-button"
                  v-b-tooltip="'Link'"
                  :class="{ 'is-active': isActive.link() || linkMenuIsActive }"
                  @click="linkMenuIsActive = !linkMenuIsActive"
              >
                <i class="fa fa-link"></i>
              </b-btn>

              <template v-if="linkMenuIsActive">
                <b-btn variant="link"
                       class="ml-auto"
                       v-b-tooltip="linkSaveText"
                       @click="setLinkUrl(commands.link, linkAttrs)">
                  <i class="fa fa-save"></i>
                </b-btn>

                <b-btn variant="link"
                       v-b-tooltip="linkDeleteText"
                       @click="deleteLinkUrl(commands)">
                  <i class="fa fa-trash"></i>
                </b-btn>
              </template>
            </div>

            <template v-if="linkMenuIsActive">
              <tiptap-link class="mt-2"
                           :value="getLinkAttributes(getMarkAttrs('link'))"
                           @change="link => linkAttrs = link"/>
            </template>
          </div>
        </div>
      </editor-menu-bar>
    </div>

    <b-form-group label="Line Height">
      <b-row no-gutters class="align-items-center">
        <b-col cols="9" class="mr-2">
          <b-input min="0.1" max="4" step=".1" type="range"
                   v-model="config.resolutionStyle[resolution].lineHeight"></b-input>
        </b-col>

        <b-col>
          <b-input type="number" step=".1" min="0" v-model="config.resolutionStyle[resolution].lineHeight"
                   size="sm"></b-input>
        </b-col>
      </b-row>
    </b-form-group>

    <padding-customs class="mb-5" :config="config"></padding-customs>
    <animations :animations="animations"></animations>

    <Panel :config="config"></Panel>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin';
import {Editor, EditorMenuBar} from 'tiptap';
import {
  Blockquote, Bold,
  BulletList, Code,
  CodeBlock,
  HardBreak,
  Heading, History, Italic, Link,
  ListItem,
  OrderedList, Strike,
  TodoItem, TodoList, Underline
} from 'tiptap-extensions';
import {mapState} from 'vuex'
import ColorPicker from "../utils/ColorPicker";
import Animations from "../controls/Animations";
import TiptapLink from "../controls/TiptapLink";

export default {
  name: "TiptapMod",

  mixins: [ModMixin],

  components: {TiptapLink, Animations, ColorPicker, EditorMenuBar},

  data() {
    return {
      pickedColor: '',
      localFontWeight: null,
      fontSizes: ['1px',
        '2px',
        '3px',
        '4px',
        '5px',
        '6px',
        '7px',
        '8px',
        '9px',
        '10px',
        '11px',
        '12px',
        '13px',
        '14px',
        '15px',
        '16px',
        '17px',
        '18px',
        '19px',
        '20px',
        '21px',
        '22px',
        '23px',
        '24px',
        '25px',
        '26px',
        '27px',
        '28px',
        '29px',
        '30px',
        '31px',
        '32px',
        '33px',
        '34px',
        '35px',
        '36px',
        '37px',
        '38px',
        '39px',
        '40px',
        '41px',
        '42px',
        '43px',
        '44px',
        '45px',
        '46px',
        '47px',
        '48px',
        '49px',
        '50px',
        '51px',
        '52px',
        '53px',
        '54px',
        '55px',
        '56px',
        '57px',
        '58px',
        '59px',
        '60px',
        '61px',
        '62px',
        '63px',
        '64px',
        '65px',
        '66px',
        '67px',
        '68px',
        '69px',
        '70px',
        '71px',
        '72px',
        '73px',
        '74px',
        '75px',
        '76px',
        '77px',
        '78px',
        '79px',
        '80px',
        '81px',
        '82px',
        '83px',
        '84px',
        '85px',
        '86px',
        '87px',
        '88px',
        '89px',
        '90px',
        '91px',
        '92px',
        '93px',
        '94px',
        '95px',
        '96px',
        '97px',
        '98px',
        '99px',
        '100px'],
      fonts: [
        'Global Style',
        'Roboto',
        'Montserrat',
        'Georgia',
        'Playfair Display',
        'Raleway',
        'Spectral',
        'Rubik',
      ],
      fontsWeight: [
        {
          label: 'Font Weight',
          value: null
        },
        {
          label: 'Light',
          value: '300'
        },
        {
          label: 'Regular',
          value: '400'
        },
        {
          label: 'Medium',
          value: '500'
        },
        {
          label: 'Bold',
          value: '600'
        }
      ],
      linkMenuIsActive: false,
      linkAttrs: {},
      linkSaveText: 'Save changes',
      linkDeleteText: 'Delete link'
    }
  },

  computed: {
    animations: {
      get() {
        if (!this.config.hasOwnProperty('animations')) {
          this.$set(this.config, 'animations', {
            animation: null,
            once: true,
            offset: 200,
            delay: 50
          })
        }

        return this.config.animations
      },
      set(val) {
        this.config.animations = val
      }
    },
    ...mapState('editor', {
      editor: state => state.tiptap,
      openedItem: state => state.openedItem
    }),
  },

  watch: {
    openedItem(val) {
      if (val === this.componentName) {
        VEvent.fire(this.section.uid + '_' + this.componentName)
      }
    }
  },

  methods: {
    applyTextColor(val, commands, getNodeAttrs) {
      const {state} = this.editor
      const empty = state.selection.empty
      this.pickedColor = val

      if (empty) {
        this.config.resolutionStyle[this.resolution].color = val
      } else {
        commands.textcolor({color: val})
      }
    },
    applyFontWeight(val, commands, getNodeAttrs) {
      const {state} = this.editor
      const empty = state.selection.empty

      setTimeout(() => {
        commands.fontweight({fontWeight: val})
      }, 100)

      this.localFontWeight = null
    },
    applyFontSize(val, commands, getNodeAttrs) {
      const {state} = this.editor
      const empty = state.selection.empty

      if (empty) {
        this.config.resolutionStyle[this.resolution].fontSize = val
      } else {
        setTimeout(() => {
          commands.fontsize({fontSize: val})
        }, 100)
      }
    },
    getLinkAttributes(attrs) {
      attrs = attrs.dataVueHref ? JSON.parse(attrs.dataVueHref) : attrs

      return {
        href: attrs.href || '',
        target: attrs.target || '__blank'
      }
    },
    setLinkUrl(command, url) {
      command({
        href: url.href ? '#' : '',
        dataVueHref: JSON.stringify({href: url.href, target: url.target || '__self'})
      })
      this.linkSaveText = 'Saved!'

      setTimeout(() => this.linkSaveText = 'Save changes', 800)
    },
    deleteLinkUrl(commands) {
      this.setLinkUrl(commands.link, {href: '', target: '__self'})
      this.linkDeleteText = 'Deleted!'
      setTimeout(() => this.linkDeleteText = 'Delete link', 800)
    },
    getPickedColorAttributes(attrs, node) {
      this.pickedColor = attrs.color || this.config.resolutionStyle[this.resolution].color
      return this.pickedColor
    },
  },

  mounted() {
    VEvent.listen('tiptap-mod-color', color => {
      this.linkMenuIsActive = false
    })
    VEvent.listen('tiptap-mod-font-weight', fontWeight => this.localFontWeight = fontWeight)
  }
}
</script>

<style lang="scss" scoped>
.menubar {
  .btn-menubar-button {
    border: 1px solid #E2E5EC;
    background-color: #fff;

    i {
      color: #aeb1b4;
    }

    &.is-active i {
      color: #44474A;
    }
  }
}
</style>
