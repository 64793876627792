import {mapState} from 'vuex';
import CustomStylesMixin from "./CustomStylesMixin";

export default {
  props: {
    section: Object
  },

  mixins: [CustomStylesMixin],

  methods: {
    cols(lg, sm) {
      return this.resolution === 'lg' ? lg : sm
    },
    aligment(align) {
      try {
        return this.section.options.customize.style.aligment.align === align
      } catch {
        return false
      }
    },
    visability(el) {
      const display = this.section.options.content[el].display
      return display === true || display === this.resolution
    },
    align(el) {
      return {
        textAlign: this.section.options.content[el].align
      }
    }
  },

  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles
    })
  }
}
