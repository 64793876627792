<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <FeaturedItems4Template :section="section">
      <template slot="image">
        <highliter @click.stop.native="sectionSettings('image')">
          <image-fit
              :customs="section.options.content.image"
              :src="section.options.content.image.src || section.options.content.image.placeholder"
              :size="cols(section.options.content.image.size, 'contain')"
              :position="section.options.content.image.position"
              :height="cols('50vh', '200px')"
          ></image-fit>
        </highliter>
      </template>

      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>
   </FeaturedItems4Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import FeaturedItems4Template from '@pub/templates/default/sections/featured/FeaturedItems4Template';
  import ImageFit from "../../../utils/ImageFit";
  import AddInnerContent from "../../utils/AddInnerContent";

  export default {
    name: "FeaturedItems4",

    components: {AddInnerContent, ImageFit, FeaturedItems4Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
