<template>
  <div>
    <div class="d-flex align-items-center" v-if="loaded">
      <b-img v-if="customs.image.src" :src="customs.image.src" :height="logoSize" class="mr-3"></b-img>
      <div v-else :style="styles">{{customs.text}}</div>
    </div>
  </div>
</template>

<script>
  import ComponentProvider from '@/components/mixins/ComponentProvider';

  export default {
    name: "EsLogo",

    mixins: [ComponentProvider],

    computed: {
      loaded() {
        return this.customs.hasOwnProperty('image')
      },
      logoSize() {
        if (!this.customs.image.hasOwnProperty('logoSize')) {
          this.$set(this.customs.image, 'logoSize', '40px')
        }

        return this.customs.image.logoSize
      },
      styles() {
        return {
          fontWeight: this.customs.fontWeight,
          fontSize: this.customs.fontSize,
          fontFamily: this.customs.fontFamily === 'Global Style' ? this.globalStyles.font.style : this.customs.fontFamily,
          color: this.customs.color
        }
      }
    },

    created() {
      if (!this.customs.hasOwnProperty('image')) {
        this.$set(this.customs, 'image', {
          src: null
        })
      }
    }
  }
</script>

<style scoped>

</style>
