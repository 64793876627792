<template>
  <highliter class="feature-section" :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <AboutUs2Template :section="section">
      <template slot="image">
        <ImageItem1Template :section="section" v-show="visability('image')">
          <highliter v-for="(image, index) in section.options.content.image.items" :key="index" @click.stop.native="sectionSettings({item: 'image', index})" :style="{opacity: image.opacity}">
            <image-fit
                :customs="image"
                :src="image.src || image.placeholder"
                :size="cols(image.size, 'contain')"
                :position="image.position"
            ></image-fit>
          </highliter>
        </ImageItem1Template>
      </template>

      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>
   </AboutUs2Template>
  </highliter>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import AboutUs2Template from "./AboutUs2Template";
  import ImageItem1Template from '@pub/templates/default/sections/images/ImageItem1Template'
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {ImageFit, AboutUs2Template, ContentEditable, Highliter, ImageItem1Template},

    mixins: [SectionMixin]
  }
</script>
