import store from "../../store";
import RouterMixin from "./RouterMixin";

export default {
  mixins: [RouterMixin],

  methods: {
    addhttp(url) {
      if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
        url = "http://" + url;
      }
      return url;
    }
  },

  data() {
    return {
      eventListenerTiptapLinksPrevent: null
    }
  },

  mounted() {
    this.eventListenerTiptapLinksPrevent = e => {
      const target = e.target.closest('[dataVueHref]')

      if (target) {
        e.preventDefault()
        const data = JSON.parse(target.getAttribute('dataVueHref')),
          isCustomPage = !store.getters['sections/getPages'].filter(p => p.key === data.href).length

        if (!isCustomPage) {
          if (data.target === '__blank') {
            window.open(`${this.getPageBlankURL}/${data.href}`)
          } else {
            this.$store.dispatch('router/setPage', data.href)
          }
        } else {
          if (data.target === '__blank') {
            window.open(this.addhttp(data.href))
          } else {
            window.location = this.addhttp(data.href)
          }
        }
      }
    }
    document.addEventListener('click', this.eventListenerTiptapLinksPrevent, false)
  },

  destroy() {
    document.removeEventListener('click', this.eventListenerTiptapLinksPrevent)
  }
}