<template>
  <highliter :label="section.name" :dublicateDeep="parentUID" :dublicate="section" :uid="section.uid"
             @click.stop.native="sectionSettings(true)">
    <Template :section="section">
      <template slot="image">
        <highliter @click.stop.native="sectionSettings('image')">
          <image-fit
              :customs="section.options.content.image"
              :src="section.options.content.image.src || section.options.content.image.placeholder"
              :size="cols(section.options.content.image.size, 'cover')"
              :position="section.options.content.image.position"
              inner-container-class="rouneded-lg"
              height="230px"
              width="214px"
              rounded
          ></image-fit>
        </highliter>
      </template>

      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter v-show="visability('description')" @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>

      <template slot="button" v-if="section.options.content.hasOwnProperty('button')">
        <highliter @click.stop.native="sectionSettings('button')">
          <es-button v-if="visability('button')" :customs="section.options.content.button"></es-button>
        </highliter>
      </template>
    </Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import Template from './Template';
import ImageFit from "../../../../utils/ImageFit";

export default {
  name: "FeaturedItems1",

  components: {ImageFit, Template, Highliter},

  mixins: [SectionMixin],
}
</script>
