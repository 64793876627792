export default {
  mounted() {
    setTimeout(() => {
      if (!this.globalStyles.hasOwnProperty('code_injection')) {
        this.$set(this.globalStyles, 'code_injection', {
          head: '',
          body: ''
        })
      }

      const insert = (script, container) => {
        const vWrapper = document.createElement('div'),
          wrapper = document.createElement('div'),
          oldWrapper = document.querySelector('[global-code-injection]')
        vWrapper.innerHTML = script

        if (oldWrapper) {
          oldWrapper.parentNode.removeChild(oldWrapper)
        }

        wrapper.setAttribute('global-code-injection', true)

        const scripts = vWrapper.children

        scripts.forEach($s => {
          var dEl = document.createElement($s.tagName),
            attrs = $s.attributes

          dEl.innerHTML = $s.innerHTML
          for (var i = attrs.length - 1; i >= 0; i--) {
            dEl.setAttribute(attrs[i].name, attrs[i].value)
          }
          wrapper.appendChild(dEl)
        })

        container.appendChild(wrapper)
      }

      insert(this.globalStyles.code_injection.head, document.head)
      insert(this.globalStyles.code_injection.body, document.body)
    }, 1000)
  }
}