import SectionProvider from '@/sections/SectionProvider';

export const name = 'EsRow'

export default class EsRow extends SectionProvider {
  constructor(options, items = []) {
    super('EsRow', options);

    this.name = name
    this.group = 'utils'

    this.defaultOptions = {
      content: {
        group: {
          type: 'row-mod',
          display: true,
          items
        }
      },
      customize: {
        noGutters: false,
        layouts: false,
        style: {
          alignRow: {
            type: 'align-row-customs',
            align: 'start'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 50,
              bottom: 50
           },
           lg: {
              top: 50,
              bottom: 50
           }
          }
        }
      }
    }
  }
}
