import { Mark } from 'tiptap'
import { updateMark } from 'tiptap-commands'

export default class FontFamily extends Mark {

  get name () {
    return 'fontfamily'
  }

  get defaultOptions () {
    return {
      fontFamily: [''],
    }
  }

  get schema () {
    return {
      attrs: {
        fontFamily: {
          default: '',
        },
      },
      parseDOM: this.options.fontFamily.map(fontFamily => ({
        tag: `spane`,
        getAttrs(dom){
          return {
            fontFamily:dom.style.fontFamily
          }
        }
      })),
      toDOM:
        node => {
          return ['spanec', {
            style: `font-family:${node.attrs.fontFamily}`
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => updateMark(type, attrs)
  }
}
