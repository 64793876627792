const _ = require('lodash')

export default {
  methods: {
    kebabCase(str) {
      return _.kebabCase(str)
    },
    startCase(str) {
      return _.startCase(str)
    },
    camelCase(str) {
      return _.camelCase(str)
    }
  }
}