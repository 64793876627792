<template>
  <div class="feature-section">
    <HeadlineItem3Template :section="section">
      <template slot="title" v-if="visability('title')">
          <pub-content-editable
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="subtitle" v-if="visability('subtitle')">
          <pub-content-editable
              :customs="section.options.content.subtitle"
              v-model="section.options.content.subtitle.text"></pub-content-editable>
      </template>
    </HeadlineItem3Template>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import HeadlineItem3Template from '@pub/templates/default/sections/headlines/HeadlineItem3Template';
import PubContentEditable from '../../../builder/utils/PubContentEditable';

export default {
  name: "HeadlineItem3",

  components: {PubContentEditable, HeadlineItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
