import {isCurrentCustomDomain} from "./utils/urlHelper";

require('dotenv').config()

import Vue from 'vue'

// Register All Global Components and Sections
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const builderComponents = require.context('./components/builder/sections', true, /[A-Z]\w+\.(vue)$/)
const previewComponents = require.context('./components/preview/sections', true, /[A-Z]\w+\.(vue)$/)
const modComponents = require.context('./components/editor/mods', false, /[A-Z]\w+\.(vue)$/)
const controlComponents = require.context('./components/editor/controls', false, /[A-Z]\w+\.(vue)$/)
const utilsComponents = require.context('./components/editor/utils', false, /[A-Z]\w+\.(vue)$/)
const utilsBuilderComponents = require.context('./components/builder/utils', true, /[A-Z]\w+\.(vue)$/)

function registerComponents(requireComponent, prefix = '') {
  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    const componentName = upperFirst(
      camelCase(
        // Gets the file name regardless of folder depth
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
      )
    )

    // Register component globally
    Vue.component(
      prefix + componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    )
  })
}

registerComponents(modComponents)
registerComponents(controlComponents)
registerComponents(utilsComponents)
registerComponents(utilsBuilderComponents)
registerComponents(builderComponents)
registerComponents(previewComponents, 'Preview')


// Router
import router from "./router"


// Vuex
import store from './store'


// Plugins
import './plugins/bootstrap-vue'
import './plugins/vue-animate'
import './plugins/moment'
import './plugins/colorPicker'
import './plugins/axios'
import './plugins/vue-animate'
import './plugins/sweetalert'
import './plugins/vEvent'
import './plugins/slick'
import './plugins/select'
import './plugins/unsplash'
import './plugins/countdown'
import './plugins/aos'
import './plugins/veevalidate'
import './plugins/vue-clipboard2'


// App Component
import App from './App.vue'

if (isCurrentCustomDomain() && window.location.pathname.split('/').length < 3) {
  router.push({name: 'published'})
}

// Router Middleware
router.beforeEach((to, from, next) => {
  const user = store.state.auth.user
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !user) {
    next({
      path: '/login'
    })
  } else if (requiresAuth && user) {
    next()
  } else {
    next()
  }
})


// Initialize Vue Application
Vue.config.productionTip = false


store.dispatch('auth/me')
  .then(() => {
    new Vue({
      store,
      router,
      render: h => h(App),
    }).$mount('#app')
  })
