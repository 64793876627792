<template>
  <div v-if="project">
    <dashboard-header></dashboard-header>

    <div class="d-flex align-items-center justify-content-between mb-4">
      <h3 class="mr-2">{{ project.name }}</h3>
      <b-btn :to="{name: 'editor'}"><i class="fa fa-hammer mr-2"></i>Open Editor</b-btn>
    </div>

    <settings-nav></settings-nav>

    <router-view></router-view>
  </div>
</template>

<script>
import SettingsNav from "./SettingsNav";
import {mapGetters, mapState} from "vuex";
import DashboardHeader from "./DashboardHeader";

export default {
  name: "Settings",

  components: {DashboardHeader, SettingsNav},

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
  },

  async created() {
    VEvent.fire('loader', true)

    await this.$store.dispatch('projects/load', {id: this.$route.params.id, path: this.$route.params.path})
        .then(() => {
          this.$store.dispatch('apiIntegration/getlAllMailServices')
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
  }
}
</script>