import SectionProvider from '@/sections/SectionProvider';

export const name = 'Action Item 3'

export default class ActionItem3 extends SectionProvider {
  constructor(options = {}) {
    super('ActionItem3', options);

    this.name = name
    this.group = 'actions'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
              padding: {
                top: 0,
                bottom: 7,
                x: 0
              }
            },
            lg: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
              padding: {
                top: 0,
                bottom: 7,
                x: 0
              }
            }
          },
          text: "<b>Order Your Product Below </b>"
        },
        description: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
              padding: {
                top: 0,
                bottom: 40,
                x: 0
              }
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
              padding: {
                top: 0,
                bottom: 40,
                x: 0
              }
            }
          },
          text: "<p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.  sollicitudin molestie malesuada.</p>"
        },
        price: {
          type: 'price-mod',
          display: true,
          color: '#0C226C',
          text: '$29,95',
          fontFamily: 'Global Style',
          fontSize: '77px'
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Order Now',
          icon: 'fa fa-arrow-right',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        layouts: null,
        style: {
          background: {
            background: '#F9F9F9'
          },
          payments: {
            type: 'checklist-customs',
            label: 'Payments Icons',
            value: ['Visa', 'Amex', 'MasterCard', 'Paypal'],
            items: ['None', 'Visa', 'Amex', 'MasterCard', 'Paypal']
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 90,
              bottom: 90
            },
            lg: {
              top: 115,
              bottom: 115
            }
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          shadow: {
            type: 'shadow-customs',
            shadow: null
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
        }
      }
    }
  }
}
