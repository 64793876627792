<template>
 <div :style="{...padding, ...opacity}" :class="['form-' + section.options.content.button.resolutionStyle['lg'].shape, container]">
   <b-row class="news-letter-4">
     <b-col :cols="cols(6, 12)" :order="cols(0, 1)" :style="{...backgroundColor, ...shadow}">
       <div class="d-flex align-items-center justify-content-center text-content h-100 w-100">
         <div :class="cols('', 'px-2')">
           <slot name="title"></slot>
           <slot name="subtitle"></slot>
           <div class="my-4"></div>
           <div :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
             <slot name="form"></slot>
           </div>
         </div>
       </div>
     </b-col>

     <b-col :cols="cols(6, 12)" :class="{'mb-4' : resolution === 'sm'}" :order="cols(1, 0)">
       <slot name="image"></slot>
     </b-col>
   </b-row>
 </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "NewsLetterItem4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.resolution-sm .news-letter-4 {
  .btn {
    display: block;
    width: 100%;
  }
}

  .news-letter-4 {
    .text-content {


      p {
        line-height: 1;
      }
    }
  }
</style>