<template>
  <div>
    <FeaturedItems4Template :section="section">
      <template slot="image">
        <image-fit
            :customs="section.options.content.image"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
            :size="cols(section.options.content.image.size, 'contain')"
            :position="section.options.content.image.position"
            :height="cols('50vh', '200px')"
        ></image-fit>
      </template>

      <template slot="title">
        <pub-content-editable
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="description">
        <pub-content-editable
            :customs="section.options.content.description"
            v-model="section.options.content.description.text"></pub-content-editable>
      </template>
    </FeaturedItems4Template>
  </div>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import FeaturedItems4Template from '@pub/templates/default/sections/featured/FeaturedItems4Template';
import PubContentEditable from "../../../builder/utils/PubContentEditable";
import ImageFit from "../../../utils/ImageFit";

export default {
  name: "FeaturedItems4",

  components: {ImageFit, PubContentEditable, FeaturedItems4Template, ContentEditable, Highliter},

  mixins: [SectionMixin],
}
</script>
