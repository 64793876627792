import SectionProvider from '@/sections/SectionProvider';

export const name = 'PopPup Video'

export default class VideoItem5 extends SectionProvider {
  constructor(options = {}) {
    super('VideoItem5', options);

    this.name = name
    this.group = ''
    this.stateSafly = false

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#ffffff',
              align: 'left',
               padding: {
                top: 0,
                bottom: 10,
                x: 0
              }
            },
            lg: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#ffffff',
              align: 'left',
               padding: {
                top: 0,
                bottom: 10,
                x: 0
              }
            }
          },
          text: {
            "type": "doc", "content": [
              {
                "type": "paragraph",
                "content": [{"type": "text", "text": "Old School Marketing"}]
              },
              {
                "type": "paragraph",
                "content": [{"type": "text", "text": "Meets New World Sales"}]
              }
            ]
          },
        },
        subtitle: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '29px',
              color: 'colored',
              align: 'left',
              fontFamily: 'Global Style',
               padding: {
                top: 0,
                bottom: 10,
                x: 0
              }
            },
            lg: {
              fontSize: '29px',
              color: 'colored',
              align: 'left',
              fontFamily: 'Global Style',
               padding: {
                top: 0,
                bottom: 10,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{"type": "paragraph", "content": [{"type": "text", "text": "Discover The ‘Power Close Method’"}]}]
          },
        },
        video: {
          display: true,
          type: 'video-mod',
          placeholder: require('@/assets/svg/placeholder-video.svg'),
          src: null,
          position: 'center',
size: 'cover'
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Watch the Video',
          resolutionStyle: {
            lg: {
              size: 'lg',
              btnStyle: '',
              color: '#ffffff',
              backgroundColor: 'colored',
              shape: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              size: 'lg',
              btnStyle: '',
              color: '#ffffff',
              backgroundColor: 'colored',
              shape: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        layouts: null,
        style: {
          background: {
            background: '#0F0F31'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 140,
              bottom: 140
            },
            lg: {
              top: 140,
              bottom: 140
            }
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
        }
      }
    }
  }
}
