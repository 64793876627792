import SectionProvider from '@/sections/SectionProvider';

export const name = 'Hero 5'
export const group = 'hero'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '42px',
          fontFamily: 'Global Style',
          color: '#fff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 26,
            x: 0
          }
        },
        lg: {
          fontSize: '54px',
          fontFamily: 'Global Style',
          color: '#fff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 20,
            x: 0
          }
        }
      },
      text: "<p><strong>Begin Your Marketing</strong></p><p><strong>Journey Today!</strong></p>",
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Join Now',
      resolutionStyle: {
        lg: {
          size: 'lg',
          btnStyle: 'outlined',
          color: '#fff',
          backgroundColor: '#fff',
          shape: null,
          align: 'center',
          padding: {
            top: 0,
            bottom: 60,
            x: 0
          }
        },
        sm: {
          size: 'lg',
          btnStyle: 'outlined',
          color: '#fff',
          backgroundColor: '#fff',
          shape: null,
          align: 'center',
          padding: {
            top: 0,
            bottom: 60,
            x: 0
          }
        }
      }
    },
    image: {
      display: true,
      type: 'image-mod',
      size: 'contain',
      position: 'bottom',
      placeholder: require('@/assets/svg/previews/hero/placeholder-hero-5.png'),
      src: null
    }
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: '#1F3B9C'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 115,
          bottom: 0
        },
        lg: {
          top: 115,
          bottom: 0
        }
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/hero/main-5.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('HeroItem5', options);

    this.name = name
    this.group = 'hero'

    this.defaultOptions = defaultOptions
  }
}
