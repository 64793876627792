<template>
  <div :style="{...padding, ...backgroundColor, ...backgroundImage, ...opacity, ...shadow}">
    <div :class="container">
      <b-row class="about-us-3">
        <b-col cols="12">
          <slot name="title"></slot>
        </b-col>

        <b-col :cols="cols(6, 12)">
          <slot name="description-1"></slot>
        </b-col>

        <b-col :cols="cols(6, 12)">
          <slot name="description-2"></slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "AboutUs1Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
</style>
