<template>
  <b-modal v-model="modal" size="xl" body-class="blog-modal" id="blog-modal" header-class="border-bottom-0" hide-footer title-class="w-100" hide-header-close>
    <template #modal-title>
      <div class="d-flex align-items-center w-100 px-5 my-3">
        <h4 class="mr-auto">Create a new post</h4>

        <b-btn class="d-flex align-items-center mr-2" @click="addPost" variant="dark" :disabled="loading">
          Publish
          <b-spinner v-if="loading" class="ml-2" variant="white" small></b-spinner>
        </b-btn>
        <b-btn variant="light" @click="cancel()">Cancel</b-btn>
      </div>
    </template>

    <div class="px-5">
      <b-form-group>
        <b-input v-model="form.title" size="lg" placeholder="Title"></b-input>
      </b-form-group>

      <b-form-group>
        <b-input v-model="form.author" placeholder="Author (optional)"></b-input>
      </b-form-group>

      <image-upload class="mb-3" :image="form.image"/>

      <b-form-group>
        <image-fit
            v-if="form.image.src"
            :customs="{}"
            :src="form.image.src"
            :size="form.image.size"
            height="400px"
            :position="form.image.position"
        ></image-fit>
      </b-form-group>

      <b-form-group label="Video">
        <b-input placeholder="Embed video share link (optional)" v-model="form.video"></b-input>
      </b-form-group>
    </div>

    <div class="p-5">
      <NewPostEditor v-model="form.content"/>
    </div>
  </b-modal>
</template>

<script>
import ImageUpload from "../utils/ImageUpload";
import NewPostEditor from "../controls/NewPostEditor";
import ImageFit from "../../utils/ImageFit";
import {mapState} from "vuex";

const form = {
  image: {
    src: '',
  },
  content: `
            <h2>
                My own blog with Estage!
            </h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aperiam dignissimos explicabo quo. Adipisci asperiores consequatur delectus, fugit nemo perferendis.
            </p>
        `,
  title: '',
  video: '',
  author : ''
}

export default {
  name: "NewPostModal",
  components: {ImageFit, NewPostEditor, ImageUpload},
  data() {
    return {
      form: JSON.parse(JSON.stringify(form)),
      modal: false,
      loading: false,
      edit: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  methods: {
    editPost(post) {
      this.form.content = post.description
      this.form.title = post.title
      this.form.image = JSON.parse(post.cover)
      this.form.video = post.video
      this.form.author = post.author

      this.edit = post.id
      this.modal = true
    },
    cancel() {
      this.form = JSON.parse(JSON.stringify(form))
      this.modal = false
      this.edit = false
    },
    canPublish() {
      let state = true

      for (let f in this.form) {
        if (!state) return false

        if (typeof this.form[f] === "object" && f !== 'video') {
          state = !!this.form[f].src
        } else if (f !== 'video' && f !== 'author') {
          state = !!this.form[f].length
        }
      }

      return state
    },
    addPost() {
      this.loading = true

      if (!this.canPublish()) {
        this.loading = false
        return this.$bvToast.toast(`Title, image and content fields is required`, {
          title: 'Validation',
          autoHideDelay: 2000,
          appendToast: false
        })
      }

      axios[this.edit ? 'put' : 'post'](`api/projects/${this.project.id}/blog/manage${this.edit ? '/' + this.edit : ''}`, {
        "cover": JSON.stringify(this.form.image),
        "title": this.form.title,
        "description": this.form.content,
        "video": this.form.video,
        "author": this.form.author,
        "status": 'publish'
      })
      .then(({data}) => {
        this.$emit('addedPost', data)
        this.cancel()
        this.$bvToast.toast(`Post published`, {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: false
        })
      })
      .finally(() => {
        this.loading = false
      })
    }
  },

  watch: {
    modal(val) {
      if (!val) {
        this.form = JSON.parse(JSON.stringify(form))
      }
    }
  },

  mounted() {
    VEvent.listen('blog.post-edit', this.editPost)
  }
}
</script>

<style lang="scss">
.blog-modal {
  .ProseMirror {
    min-height: 100vh;
  }
}
</style>