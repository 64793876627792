<template>
  <div class="mb-4">
    <label class="mb-2">{{config.label}}</label>

    <b-row>
      <b-col cols="6" v-for="(item, index) in config.items" :key="index">
        <b-form-checkbox v-model="config.value" :value="item">{{item}}</b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ControlMixin from "../../mixins/ControlMixin";

export default {
  name: "Opacity",

  mixins: [ControlMixin]
}
</script>