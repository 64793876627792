<template>
    <ActionItem5Template :section="section">
      <template slot="image" v-if="visability('image')">
        <div class="image" :style="{backgroundImage: `url(${section.options.content.image.src || section.options.content.image.placeholder})`, backgroundSize: section.options.content.image.size, backgroundRepeat: 'no-repeat'}"></div>
      </template>

      <template slot="title" v-if="visability('title')">
          <pub-content-editable
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="description" v-if="visability('description')">
          <pub-content-editable
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></pub-content-editable>
      </template>

      <template slot="button" v-if="visability('button')">
          <pub-es-button :customs="section.options.content.button"></pub-es-button>
      </template>
    </ActionItem5Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import ActionItem5Template from '@pub/templates/default/sections/actions/ActionItem5Template';
  import PubEsButton from '../../../builder/utils/buttons/PubEsButton';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';

  export default {
    name: "ActionItem5",

    components: {PubContentEditable, PubEsButton, ActionItem5Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
