<template>
  <div class="d-flex">
    <div class="position-relative d-flex align-items-start font-weight-bold mx-auto" :style="{color: customs.color, fontSize: customs.fontSize}">{{price}} <span style="font-size: 50%; margin-top: 7px; margin-left: 7px; text-decoration: underline;">{{subPrice}}</span></div>
  </div>
</template>

<script>
  import ComponentProvider from '@/components/mixins/ComponentProvider'

  export default {
    name: "EsPrice",

    mixins: [ComponentProvider],

    computed: {
      price() {
        const delimetr = this.customs.text.search(',')
        return delimetr !== -1 ? this.customs.text.substr(0, delimetr) : this.customs.text
      },
      subPrice() {
        const delimetr = this.customs.text.search(',')
        return delimetr !== -1 ? this.customs.text.substr(this.customs.text.search(',') + 1) : null
      }
    }
  }
</script>
