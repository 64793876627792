<template>
 <div :style="{...backgroundImage, backgroundColor}">
   <div class="media quote quote-border-y" :class="[container, cols('', 'd-block'), cols('', 'quote-border-y')]" :style="{...opacity, ...padding}">
     <div class="media-aside px-5 pt-2" :class="cols('', 'justify-content-center')">
       <icon icon="quote-1.svg"></icon>
     </div>

     <div class="media-body py-4" :class="cols('quote-border-y')">
       <slot name="description" v-if="visability('description')"></slot>
     </div>
   </div>
 </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "ImageItem5Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
  .quote {
    &.quote-border-y,
    .quote-border-y {
      border-top: 2px solid #EAECF0;
      border-bottom: 2px solid #EAECF0;
    }
  }
</style>