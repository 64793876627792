<template>
  <div class="images-group-4 d-flex justify-content-center align-items-center" :style="{...opacity}" v-if="visability('image')">
    <div class="position-relative" :style="{...dropShadow}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "ImageItem2Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.images-group-4 {
  padding-top: 30px;
  padding-bottom: 60px;

  img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }
}
</style>