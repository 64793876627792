import SectionProvider from '@/sections/SectionProvider';

export const name = 'NewsLetterItem4'

export default class NewsLetterItem4 extends SectionProvider {
    constructor(options = {}) {
        super('NewsLetterItem4', options);

        this.name = name
        this.group = 'newsLetter'

        this.defaultOptions = {
            content: {
                title: {
                    type: 'tiptap-mod',
                    display: true,
                    resolutionStyle: {
                        sm: {
                            fontSize: '20px',
                            fontFamily: 'Global Style',
                            color: '#0C226C',
                            align: 'left',
                            padding: {
                                top: 0,
                                bottom: 0,
                                x: 20
                            }
                        },
                        lg: {
                            fontSize: '46px',
                            fontFamily: 'Global Style',
                            color: '#0C226C',
                            align: 'left',
                        }
                    },
                    text: {
                        "type": "doc",
                        "content": [{
                            "type": "paragraph",
                            "content": [{
                                "type": "text",
                                "marks": [{"type": "bold"}],
                                "text": "Subscribe to our newsletter"
                            }]
                        }]
                    },
                },
                subtitle: {
                    type: 'tiptap-mod',
                    display: true,
                    resolutionStyle: {
                        sm: {
                            fontSize: '21px',
                            fontFamily: 'Global Style',
                            lineHeight: 1.3,
                            color: '#6D6D6D',
                            align: 'left',
                            padding: {
                                top: 0,
                                bottom: 0,
                                x: 20
                            }
                        },
                        lg: {
                            fontSize: '21px',
                            fontFamily: 'Global Style',
                            lineHeight: 1.3,
                            color: '#6D6D6D',
                            align: 'left',
                        }
                    },
                    text: {
                        "type": "doc",
                        "content": [{
                            "type": "paragraph",
                            "content": [{"type": "text", "text": "Curabitur tristique, lorem et dignissim consequat."}]
                        }]
                    },
                },
                form: {
                    btnPosition: 'append-email',
                    type: 'form-mod',
                    action: null,
                    selectedList: '',
                    fields: [
                        {
                            display: true,
                            type: 'email',
                            name: 'email',
                            placeholder: 'Enter your email address',
                            required: true,
                            label: null,
                            cols: {
                                sm: 12,
                                lg: 12
                            }
                        }
                    ]
                },
                button: {
                    type: 'button-mod',
                    submit: true,
                    display: true,
                    hover: false,
                    text: 'Sign Up',
                    icon: 'fa fa-arrow-right',
                    resolutionStyle: {
                        lg: {
                            color: '#ffffff',
                            backgroundColor: 'colored',
                            size: 'md',
                            shape: null,
                            btnStyle: '',
                            align: 'left',
                            padding: {
                                top: 0,
                                bottom: 0,
                                x: 0
                            }
                        },
                        sm: {
                            color: '#ffffff',
                            backgroundColor: 'colored',
                            size: 'md',
                            shape: null,
                            btnStyle: '',
                            align: 'left',
                            padding: {
                                top: 0,
                                bottom: 0,
                                x: 0
                            }
                        }
                    }
                },
                image: {
                    display: true,
                    type: 'image-mod',
                    placeholder: require('@/assets/images/placeholder-news-letter-4.jpg'),
                    src: null,
                    size: 'cover'
                },
            },
            customize: {
                style: {
                    container: {
                        type: 'container-customs',
                        container: null
                    },
                    padding: {
                        type: 'height-customs',
                        sm: {
                            top: 40,
                            bottom: 40
                        },
                        lg: {
                            top: 40,
                            bottom: 40
                        }
                    }
                }
            }
        }
    }
}
