<template>
  <draggable class="dragArea" tag="ul" :list="children" :group="{ name: 'g1' }" handle=".move">
    <li v-for="el in children" :key="el.name">
      <slot name="item" v-bind:page="el"></slot>
    </li>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: {
    children: {
      required: true,
      type: Array
    }
  },
  components: {
    draggable
  },
  name: "nested-draggable"
};
</script>

<style lang="scss">
.nav-items-els {
  & > .dragArea {
    padding-left: 0;
  }

  .dragArea {
    list-style: none;
  }

  .dragArea {
    min-height: 10px;
  }
}
</style>
