import SectionProvider from '@/sections/SectionProvider';

export const name = 'Button'

export default class FeaturedImage extends SectionProvider {
  constructor(options = {}) {
    super('EsButtonUtil', options);

    this.name = name
    this.group = 'utils'

    this.defaultOptions = {
      content: {
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Click me',
          resolutionStyle: {
            lg: {
              size: 'lg',
              btnStyle: '',
              color: '#ffffff',
              backgroundColor: 'colored',
              shape: null,
              shadow: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              size: 'lg',
              btnStyle: '',
              color: '#ffffff',
              backgroundColor: 'colored',
              shape: null,
              shadow: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        layouts: null
      }
    }
  }
}
