<template>
  <Footer2Template :section="section">
    <!-- Logo -->
    <template slot="logo">
      <pub-logo :customs="section.options.content.logo"></pub-logo>
    </template>
    <!-- End Logo -->

    <!-- Copyright -->
    <template slot="copyright">
      <pub-content-editable
          :uid="tiptapUID('copyright')"
          :customs="section.options.content.copyright"
          v-model="section.options.content.copyright.text"></pub-content-editable>
    </template>
    <!-- End Copyright -->

    <!-- Menu 1 -->
    <template slot="labelMenu1">
      <pub-content-editable
          :uid="tiptapUID('labelMenu1')"
          :customs="section.options.content.labelMenu1"
          v-model="section.options.content.labelMenu1.text"></pub-content-editable>
    </template>

    <template slot="menu-1">
      <ul class="list-unstyled">
        <li v-for="(link, index) in section.options.content.menu1.links" :key="index" :href="link.href">
          <b-link :style="section.options.content.menu1.style" @click="goPage(link)">{{ link.text }}</b-link>
        </li>
      </ul>
    </template>
    <!-- End Menu 1 -->

    <!-- Menu 2 -->
    <template slot="labelMenu2">
      <pub-content-editable
          :uid="tiptapUID('labelMenu2')"
          :customs="section.options.content.labelMenu2"
          v-model="section.options.content.labelMenu2.text"></pub-content-editable>
    </template>

    <template slot="menu-2">
      <ul class="list-unstyled">
        <li v-for="(link, index) in section.options.content.menu2.links" :key="index" :href="link.href">
          <b-link :style="section.options.content.menu2.style" @click="goPage(link)">{{ link.text }}</b-link>
        </li>
      </ul>
    </template>
    <!-- End Menu 2 -->

    <!-- Menu 3 -->
    <template slot="menu-3">
      <ul class="list-unstyled list-inline">
        <li v-for="(link, index) in section.options.content.menu3.links" :key="index" class="list-inline-item mr-4">
          <b-link :style="section.options.content.menu3.style" @click="goPage(link)">{{ link.text }}</b-link>
        </li>
      </ul>
    </template>
    <!-- End Menu 3 -->

    <!-- Social -->
    <template slot="social">
      <ul class="list-unstyled list-inline">
        <li v-for="(link, index) in section.options.content.socialLinks.links" :key="index"
            class="list-inline-item mr-4">
          <b-link :style="section.options.content.socialLinks.style" :href="link.href" :target="link.blank ? 'blank' : ''">
            <i :class="link.icon"></i>
          </b-link>
        </li>
      </ul>
    </template>
    <!-- End Social -->

    <!-- Description -->
    <template slot="labelDescriotion">
      <pub-content-editable
          :uid="tiptapUID('labelDescriotion')"
          :customs="section.options.content.labelDescriotion"
          v-model="section.options.content.labelDescriotion.text"></pub-content-editable>
    </template>

    <template slot="description">
      <pub-content-editable
          :uid="tiptapUID('description')"
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>
    <!-- End Description -->
  </Footer2Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Footer2Template from "./Footer2Template";

export default {
  name: "Carousel1",

  components: {Footer2Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  methods: {
    goPage(link) {
      if (!link.href || link.href === '#') {
        return false
      }

      if (!link.customLink) {
        if (link.blank) {
          window.open(`${window.location.origin}/site/${this.project.subdomain}/${link.href}`)
        } else {
          this.$store.dispatch('router/setPage', link.href)
        }
      } else {
        if (link.blank) {
          window.open(link.href)
        } else {
          window.location = link.href
        }
      }
    },
  }
}
</script>
