<template>
  <b-media class="align-items-center mb-4" :class="cols('d-flex', 'd-block')" :style="{...opacity}">
    <template slot="aside" >
      <div :style="{...shadow}">
        <slot name="image"></slot>
      </div>
    </template>

    <b-media-body class="ml-3 mt-3">
      <slot name="title"></slot>
      <slot name="text"></slot>
    </b-media-body>
  </b-media>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "TextAndImageItem2Template",
    mixins: [TemplateMixin]
  }
</script>

<style scoped>

</style>
