<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <ActionItem3Template :section="section">
      <template slot="title">
        <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter v-show="visability('description')"
                   @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>

      <template slot="price">
        <highliter v-show="visability('price')"
                   :style="{textAlign: section.options.content.price.align}"
                   @click.stop.native="sectionSettings('price')">
          <es-price :customs="section.options.content.price"></es-price>
        </highliter>
      </template>

      <template slot="button">
        <highliter v-show="visability('button')"
                   :style="{textAlign: section.options.content.button.align}"
                   @click.stop.native="sectionSettings('button')">
          <es-button :customs="section.options.content.button"></es-button>
        </highliter>
      </template>

      <template slot="icons" v-if="!style.payments.value.includes('None')">
        <icon v-if="style.payments.value.includes('Visa')" class="mr-3" icon="visa.svg"></icon>
        <icon v-if="style.payments.value.includes('Amex')" class="mr-3" icon="amex.svg"></icon>
        <icon v-if="style.payments.value.includes('MasterCard')" class="mr-3" icon="master-card.svg"></icon>
        <icon v-if="style.payments.value.includes('Paypal')" icon="paypal.svg"></icon>
      </template>
   </ActionItem3Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import ActionItem3Template from '@pub/templates/default/sections/actions/ActionItem3Template';
  import EsPrice from '../../utils/EsPrice';

  export default {
    name: "ActionItem2",

    components: {EsPrice, ActionItem3Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
