<template>
  <highliter class="feature-section" :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <ListItem3Template :section="section">
      <template slot="list">
        <highliter v-show="visability('list')" @click.stop.native="sectionSettings('list')">
          <b-row>
            <b-col :cols="cols(4, 12)" v-for="n in section.options.content.list.rows || 1" :key="n" :class="cols('mb-0', 'mb-4')">
              <li class="d-flex align-items-center" v-for="(item, index) in section.options.content.list.items[n]" :key="item.label">
                <i :style="{color: section.options.customize.style.listIcon.color}" :class="section.options.customize.style.listIcon.icon" class="mr-3"></i>
                <editable v-model="item.text"></editable>
              </li>
            </b-col>
          </b-row>
        </highliter>
      </template>
   </ListItem3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ListItem3Template from '@pub/templates/default/sections/list/ListItem3Template';
import Editable from "../../utils/Editable";

export default {
  name: "FeaturedItems1",

  components: {Editable, ListItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  methods: {
    onInput(event, index) {
      this.section.options.content.list.items[index] = '123'
    }
  },

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
