<template>
  <div :style="{...padding, ...backgroundColor, ...opacity, ...shadow}">
    <div :class="container">
      <div class="border" :style="{...padding}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "AboutUs1Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
</style>
