<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <Template :section="section">
     <template slot="title">
       <highliter @click.stop.native="sectionSettings('title')">
         <content-editable
             :uid="tiptapUID('title')"
             :customs="section.options.content.title"
             v-model="section.options.content.title.text"></content-editable>
       </highliter>
     </template>

     <template slot="text">
       <highliter @click.stop.native="sectionSettings('text')">
         <content-editable
             :uid="tiptapUID('text')"
             :customs="section.options.content.text"
             v-model="section.options.content.text.text"></content-editable>
       </highliter>
     </template>

     <template slot="formLabel">
       <highliter @click.stop.native="sectionSettings('formLabel')">
         <content-editable
             :uid="tiptapUID('formLabel')"
             :customs="section.options.content.formLabel"
             v-model="section.options.content.formLabel.text"></content-editable>
       </highliter>
     </template>

     <template slot="form">
       <highliter @click.stop.native="sectionSettings('form')">
         <es-form :customs="section.options.content.form">
           <template :slot="cols(section.options.content.form.btnPosition, 'submit')">
             <highliter @click.stop.native="sectionSettings('button')">
               <es-button :customs="section.options.content.button"></es-button>
             </highliter>
           </template>
         </es-form>
       </highliter>
     </template>

     <template slot="image">
       <highliter @click.stop.native="sectionSettings('image')">
         <image-fit
             :customs="section.options.content.image"
             :src="section.options.content.image.src || section.options.content.image.placeholder"
             :size="cols('contain', 'contain')"
             :height="cols('400px', '300px')"
             :position="cols(section.options.content.image.position, 'center')"
         ></image-fit>
       </highliter>
     </template>
   </Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Template from './Template';
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "ActionItem2",

    components: {ImageFit, Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
