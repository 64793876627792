export default {
  data() {
    return {
      scrollEvent: null
    }
  },

  mounted() {
    if (!this.section.options.customize.style.hasOwnProperty('navbarScroller')) {
      return false
    }

    let lastScrollTop = 0

    this.scrollEvent = document.addEventListener('scroll', () => {
      // Sticky
      if (this.section.options.customize.style.navbarScroller.scrollType === 'sticky') {
        if (window.scrollY > 400) {
          document.querySelector('.es-root').style.paddingTop = this.$el.offsetHeight + 'px'
          this.$el.classList.add('navbar-scrolled')
        } else {
          document.querySelector('.es-root').style.paddingTop = 0
          this.$el.classList.remove('navbar-scrolled')
        }
      } else if (this.section.options.customize.style.navbarScroller.scrollType === 'reveal') {
        var st = window.pageYOffset || document.documentElement.scrollTop

        console.log(st, window.scrollY)

        if (st > lastScrollTop) {
          document.querySelector('.es-root').style.paddingTop = 0
          this.$el.classList.remove('navbar-scrolled')
        } else {
          if (window.scrollY > 200) {
            document.querySelector('.es-root').style.paddingTop = this.$el.offsetHeight + 'px'
            this.$el.classList.add('navbar-scrolled')
          } else {
            document.querySelector('.es-root').style.paddingTop = 0
            this.$el.classList.remove('navbar-scrolled')
          }
        }

        lastScrollTop = st <= 0 ? 0 : st
      }
    })
  },

  destroyed() {
    removeEventListener('scroll', this.scrollEvent)
  }
}