<template>
  <b-img v-bind="$attrs" :src="customs.src || customs.placeholder" :style="style" :class="classes" fluid></b-img>
</template>

<script>
  import {Editor, EditorContent, EditorMenuBubble} from 'tiptap'
  import {
    Blockquote,
    BulletList,
    CodeBlock,
    HardBreak,
    Heading,
    ListItem,
    OrderedList,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'

  import TextColor from '../../../plugins/TiptapTextColor'
  import ComponentProvider from '@/components/mixins/ComponentProvider'
  import {mapState} from "vuex";

  export default {
    name: "EsImage",

    components: {
      EditorContent,
      EditorMenuBubble
    },

    mixins: [ComponentProvider],

    computed: {
      classes() {
        return [
          {'rounded-circle' : this.customs.shape === 'circle'},
          {'rounded' : this.customs.shape === 'rounded'},
        ]
      },
      style() {
        return {
          // color: this.customs.resolutionStyle[this.resolution].color
        }
      }
    }
  }
</script>
