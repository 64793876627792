import SectionProvider from '@/sections/SectionProvider';

export const name = 'Featured Items 3'

export default class FeaturedItems2 extends SectionProvider {
  constructor(options = {}) {
    super('FeaturedItems3', options);

    this.name = name
    this.group = 'featured'

    this.defaultOptions = {
      content: {
        description: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '25px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
              padding: {
                top: 15,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '38px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
              padding: {
                top: 50,
                bottom: 50,
                x: 70
              }
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit."}]}]},
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/previews/features/placeholder-3.png'),
          src: null,
          position: 'center',
size: 'cover'
        }
      },
      customize: {
        style: {
          background: {
            background: '#ffffff'
          },
          backgroundCard: {
            background: '#f5f5f5',
            type: 'background-customs',
            label: 'Card Background'
          },
          container: {
            type: 'container-customs',
            container: null
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
            },
            lg: {
              top: 115,
              bottom: 115
            }
          }
        }
      }
    }
  }
}
