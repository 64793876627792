<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <span class="indicator mr-2"></span>
      <p class="text-capitalize mb-0">{{ labelCase(componentName) }}</p>
    </div>

    <b-form-group label="Text color">
      <color-picker :color="config.color"
                    @input="event => config.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`" />
    </b-form-group>

    <b-form-group>
      <template slot="label">
        <div class="d-flex align-items-center justify-content-between">
          <div>Show posts per page:</div>
          <div class="text-primary font-weight-bold">{{config.perPage}}</div>
        </div>
      </template>
      <b-input type="range" min="1" max="20" v-model="config.perPage"></b-input>
    </b-form-group>
  </div>
</template>

<script>
import ModMixin from "../../mixins/ModMixin";

export default {
  name: "BlogTextMod",

  mixins: [ModMixin]
}
</script>