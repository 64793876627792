import SectionProvider from '@/sections/SectionProvider';

export const name = 'Testimonial 5'
export const group = 'testimonials'
export const allowRow = true
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          lineHeight: 1.1,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          fontFamily: 'Global Style',
          color: '#0C226C',
          align: 'left',
          lineHeight: 1.1,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Title</strong></p>"
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: '#6D6D6D',
          align: 'left',
          lineHeight: 1.3,
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          color: '#6D6D6D',
          align: 'left',
          lineHeight: 1.3,
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget</p>"
    },
    image: {
      display: true,
      type: 'image-mod',
      placeholder: require('@/assets/images/placeholder-5.jpg'),
      src: null,
      size: 'cover',
      position: 'center',
    },
    button: {
      type: 'button-mod',
      display: true,
      hover: false,
      text: 'Learn More',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'sm',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'sm',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
  },
  customize: {
    layouts: null,
    style: {
      aligment: {
        type: 'aligment-customs',
        align: 'right',
        images: {
          left: require('@/assets/svg/previews/hero/left-1.svg'),
          right: require('@/assets/svg/previews/hero/right-1.svg'),
        }
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 38,
          bottom: 38
        },
        lg: {
          top: 38,
          bottom: 38
        }
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/testimonials/main-5.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('TestimonialItem5', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
