<template>
  <b-modal v-model="dialog" body-class="py-0 pl-0" size="xl" hide-footer title="Image Explorer" centered
           hide-header-close>
    <b-row class="explorer-container" no-gutters>
      <b-col cols="3" class="bg-light rounded-left">
        <div v-if="tab.condition()"
             v-for="tab in tabs"
             :key="tab.key"
             class="d-flex align-items-center explorer-tab-item"
             :class="{'active': tab.key === activeTab}"
             @click="tab.loader">
          <i :class="tab.icon" class="mr-3"></i>
          <div>{{ tab.name }}</div>
        </div>
      </b-col>
      <b-col cols="9">
        <div class="p-5">
          <template v-if="activeTab === 'my-images'">
            <!--            <div class="explorer-upload-box mb-4">-->
            <!--              <b-btn>-->
            <!--                <i class="fa fa-upload mr-2"></i>-->
            <!--                Upload-->
            <!--              </b-btn>-->
            <!--            </div>-->

            <label class="position-relative w-100 mb-4">
              <div class="btn-group w-100">
                <slot name="prepend"></slot>
                <div class="btn btn-es-image-upload btn-block">
                  <icon class="mr-2" icon="plus.svg"></icon>

                  Upload Image…
                </div>
              </div>
              <div class="d-none">
                <b-form-file v-model="file" accept=".jpg, .png, .svg"></b-form-file>
              </div>
            </label>

            <b-row>
              <b-col v-for="(image, index) in mediaList" :key="image.id"
                     class="image-container-box position-relative h-100 mb-4 p-2" cols="4">
                <vue-load-image class="explorer-image bg-light rounded p-2"
                                @click.native.stop="addImageFromMyImages(image)">
                  <b-img slot="image" fluid
                         :src="'https://estage-uploads.s3.us-east-2.amazonaws.com/' + image.name"></b-img>
                  <div slot="preloader" class="loader-container">
                    <b-spinner variant="primary"></b-spinner>
                  </div>
                </vue-load-image>
                <i class="delete-image-icon fa fa-trash text-muted"
                   @click="deleteImageFromMyImages({...image, index})"></i>
              </b-col>
            </b-row>

            <b-pagination v-if="explorerTotalPages > 1" v-model="explorerQuery.page" :per-page="20"
                          :total-rows="explorerTotalPages"></b-pagination>
          </template>

          <template v-else-if="activeTab === 'favorites'">
            <b-row>
              <b-col v-for="image in favoriteList" :key="image.id" class="h-100 mb-4 p-2" cols="4">
                <vue-load-image class="explorer-image bg-light rounded p-2"
                                @click.native.stop="addImageFromUnsplash(image)">
                  <b-img slot="image" fluid
                         :src="image"></b-img>
                  <div slot="preloader" class="loader-container">
                    <b-spinner variant="primary"></b-spinner>
                  </div>
                </vue-load-image>
              </b-col>
            </b-row>
          </template>

          <template v-else-if="activeTab === 'unsplash'">
            <b-form-group>
              <b-input v-model="unsplashQuery.query" placeholder="Search images"></b-input>
            </b-form-group>
            <b-row>
              <b-col v-for="image in mediaList" :key="image.id" class="h-100 mb-4 p-2" cols="4">
                <vue-load-image class="explorer-image bg-light rounded p-2"
                                @click.native.stop="addImageFromUnsplash(image.urls.full)">
                  <b-img slot="image" fluid
                         :src="image.urls.small"></b-img>
                  <div slot="preloader" class="loader-container">
                    <b-spinner variant="primary"></b-spinner>
                  </div>
                </vue-load-image>
              </b-col>
            </b-row>

            <b-pagination v-if="unsplashTotalPages > 1" v-model="unsplashQuery.page" :per-page="unsplashQuery.perPage"
                          :total-rows="unsplashTotalPages"></b-pagination>
          </template>

          <template v-else-if="activeTab === 'unsplash-integration'">
            <unsplash-integration @integrated="loadUnsplash"></unsplash-integration>
          </template>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import VueLoadImage from 'vue-load-image'
import {mapState, mapGetters} from 'vuex'
import UnsplashIntegration from "../../builder/utils/api-services/UnsplashIntegration";

let unsplashSearchTimeout = null;

export default {
  name: "ImagesExploererModal",

  components: {
    UnsplashIntegration,
    VueLoadImage
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      unsplashQuery: {
        query: '',
        page: 1,
        perPage: 9
      },
      explorerQuery: {
        page: 1,
        perPage: 9
      },
      unsplashPaginationTotalPages: null,
      activeTab: 'my-images',
      tabs: [
        {
          name: 'My Images',
          loader: this.loadMyImages,
          key: 'my-images',
          icon: 'fa fa-folder',
          condition: () => true
        },
        {
          name: 'Unsplash',
          loader: this.loadUnsplash,
          key: 'unsplash',
          icon: 'fab fa-unsplash',
          condition: () => this.hasLocalAPIService('unsplash')
        },
        {
          name: 'My favorites',
          loader: () => this.activeTab = 'favorites',
          key: 'favorites',
          icon: 'fa fa-star',
          condition: () => true
        },
        {
          name: 'Unsplash',
          loader: () => this.activeTab = 'unsplash-integration',
          key: 'unsplash-integration',
          icon: 'fab fa-unsplash',
          condition: () => !this.hasLocalAPIService('unsplash')
        }
      ],
      file: [],
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    favoriteList() {
      return this.globalStyles.libraryImages || []
    },

    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
    }),

    ...mapState('explorer', {
      mediaList: state => state.mediaList,
      unsplashTotalPages: state => state.unsplashTotalPages,
      explorerTotalPages: state => state.explorerTotalPages
    }),

    ...mapGetters('apiIntegration', [
      'hasLocalAPIService'
    ])
  },

  methods: {
    uploaded() {
      this.$emit('uploaded', this.image)
    },

    loadMyImages() {
      this.activeTab = null

      this.$store.dispatch('explorer/images', this.explorerQuery)
          .then(() => {
            this.activeTab = 'my-images'
          })
    },

    loadUnsplash() {
      this.activeTab = null

      this.$store.dispatch('explorer/unsplash', {})
          .then(() => {
            this.activeTab = 'unsplash'
          })
      this.unsplashQuery.query = ''
    },

    addImageFromMyImages(image) {
      this.image.id = image.id;
      this.image.src = 'https://estage-uploads.s3.us-east-2.amazonaws.com/' + image.name
      this.uploaded()

      this.dialog = false
    },

    addImageFromUnsplash(path) {
      this.image.id = null
      this.image.src = path
      this.uploaded()

      this.dialog = false
    },

    deleteImageFromMyImages({index, id, src}) {
      axios.delete(`api/media/${id}`)
          .then(() => {
            src = null
            this.mediaList.splice(index, 1)
          })
    },
  },

  watch: {
    unsplashQuery: {
      deep: true,
      handler: function (val) {
        if (!val.query) {
          this.loadUnsplash()

          return false
        }

        window.clearTimeout(unsplashSearchTimeout)

        unsplashSearchTimeout = setTimeout(() => {
          this.$store.dispatch('explorer/unsplashSearch', val)
        }, 400)
      }
    },

    explorerQuery: {
      deep: true,
      handler: function () {
        this.loadMyImages()
      }
    },

    file() {
      // this.url = URL.createObjectURL(this.file)
      // this.image.src = this.url
      // this.$emit('uploaded')

      VEvent.fire('loader', true)
      //
      let formData = new FormData();

      formData.append("file", this.file);

      axios.post('api/media', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
          .then(({data}) => {
            this.image.src = data.data.url
            this.image.id = data.data.id
            this.uploaded()
          })
          .finally(() => {
            VEvent.fire('loader', false)
            this.dialog = false
          })
    }
  },

  mounted() {
    this.loadMyImages()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/main";

.explorer-container {
  min-height: 85vh;

  .explorer-tab-item {
    padding: 15px 20px;
    transition: .4s;

    &:hover,
    &.active {
      cursor: pointer;
      background-color: rgba($primary, .1);
    }
  }

  .explorer-upload-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 3px dashed #eee;
    height: 200px;
  }

  .delete-image-icon {
    display: none;
    position: absolute;
    right: 20px;
    bottom: 15px;
    cursor: pointer;
    transition: .4s;

    &:hover {
      color: $danger !important;
    }
  }

  .image-container-box {
    overflow: hidden;

    &:hover {
      &:before {
        content: "";
        position: absolute;
        left: 8px;
        bottom: 3%;
        background-color: #fff;
        width: 242px;
        box-shadow: 0 0 10px 1px #c3c3c3;
        height: 15%;
        border-radius: 5px;
      }

      .delete-image-icon {
        display: block;
      }
    }
  }

  .explorer-image {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .loader-container {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .page-link {
    height: 35px;
  }
}
</style>