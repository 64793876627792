<template>
  <ImageItem2Template :section="section">
    <b-img :src="section.options.content.image.src || section.options.content.image.placeholder"></b-img>
  </ImageItem2Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ImageItem2Template from '@pub/templates/default/sections/images/ImageItem2Template';

export default {
  name: "ImageItem2",

  components: {ImageItem2Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
