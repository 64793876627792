<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section"
             @click.stop.native="sectionSettings('group')">
    <VideoItems3Template class="hide-add hide-copy" :section="section">
      <template>
        <b-col :cols="cols(9, 12)">
          <highliter
              @click.stop.native="$store.dispatch('editor/sectionSettings', {section: section.options.content.group.items[preview], openedItem: 'video'})">
            <video-play :customs="section.options.content.group.items[preview].options.content.video"></video-play>
          </highliter>
          <div class="my-3"></div>

          <highliter
              @click.stop.native="$store.dispatch('editor/sectionSettings', {section: section.options.content.group.items[preview], openedItem: 'title'})">
            <content-editable
                class="video-preview-title"
                :customs="section.options.content.group.items[preview].options.content.title"
                v-model="section.options.content.group.items[preview].options.content.title.text"></content-editable>
          </highliter>
        </b-col>

        <b-col :cols="cols(3, 12)">
          <video-item1 v-if="index !== preview"
                       v-for="(video, index) in section.options.content.group.items"
                       :key="index"
                       :section="video"
          ></video-item1>
        </b-col>
      </template>
    </VideoItems3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import VideoItems3Template from '@pub/templates/default/sections/videos/VideoItems3Template';

export default {
  name: "VideoItem3",

  components: {VideoItems3Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  data() {
    return {
      preview: 0
    }
  },

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>

<style lang="scss">
.video-preview-title p {
  font-size: 46px;
}

.resolution-sm {
  .video-preview-title p {
    font-size: 28px !important;
  }
}
</style>