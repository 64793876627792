import SectionProvider from '@/sections/SectionProvider';

export const name = 'FloatImage'
export const group = 'utils'
export const standalone = true
export const allowRow = true
export const defaultOptions = {
  content: {
    image: {
      type: 'float-image-mod',
      display: true,
      src: require('@/assets/images/placeholder-float-image.jpg'),
      top: 0,
      left: 0
    },
  },
  customize: null
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/utils/float-image.jpg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('EsFloatImageUtil', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
