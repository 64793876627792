<template>
    <VideoItems5Template :section="section">
      <template slot="title">
          <pub-content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="subtitle">
          <pub-content-editable
              :uid="tiptapUID('subtitle')"
              :customs="section.options.content.subtitle"
              v-model="section.options.content.subtitle.text"></pub-content-editable>
      </template>

      <template #button="{openModal}">
          <pub-es-button :customs="section.options.content.button" @click.native="openModal"></pub-es-button>
      </template>

      <template slot="video">
        <pub-content-editable
            :hardStyles="{fontSize: '32px', color: '#0F0F31', textAlign: 'center', display: 'flex', justifyContent: 'center'}"
            :uid="tiptapUID('title')"
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>

        <pub-content-editable
            :hardStyles="{fontSize: '27px', textAlign: 'center'}"
            :uid="tiptapUID('subtitle')"
            :customs="section.options.content.subtitle"
            v-model="section.options.content.subtitle.text"></pub-content-editable>

        <div class="d-flex justify-content-center w-100">
          <div class="my-4 w-100">
            <video-play  :customs="section.options.content.video"></video-play>
          </div>
        </div>
      </template>
   </VideoItems5Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import VideoItems5Template from '@pub/templates/default/sections/videos/VideoItems5Template';
  import PubContentEditable from "../../../builder/utils/PubContentEditable";
  import PubEsButton from "../../../builder/utils/buttons/PubEsButton";

  export default {
    name: "FeaturedItems1",

    components: {PubEsButton, PubContentEditable, VideoItems5Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
