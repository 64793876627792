<template>
  <highliter :label="section.name" :dublicateDeep="parentUID" :dublicate="section" :uid="section.uid"
             @click.stop.native="sectionSettings(true)">
    <TestimonialItem3Template :section="section">
      <template slot="image">
        <highliter v-show="visability('image')" autoWidth @click.stop.native="sectionSettings('image')">
          <image-fit
              :customs="section.options.content.image"
              :class="[{'mx-auto': section.options.content.image.align === 'center'}, {'ml-auto': section.options.content.image.align === 'right'}]"
              :src="section.options.content.image.src || section.options.content.image.placeholder"
              :size="cols(section.options.content.image.size, 'contain')"
              :position="section.options.content.image.position"
              width="86px"
              height="86px"
          ></image-fit>
        </highliter>
      </template>

      <template slot="name">
        <highliter v-show="visability('name')" @click.stop.native="sectionSettings('name')">
          <content-editable
              :customs="section.options.content.name"
              @init="(editor) => section.classes.tiptap.name = editor"
              v-model="section.options.content.name.text"></content-editable>
        </highliter>
      </template>

      <template slot="subname">
        <highliter v-show="visability('subname')" @click.stop.native="sectionSettings('subname')">
          <content-editable
              :customs="section.options.content.subname"
              @init="(editor) => section.classes.tiptap.subname = editor"
              v-model="section.options.content.subname.text"></content-editable>
        </highliter>
      </template>

      <template slot="text">
        <highliter v-show="visability('text')" @click.stop.native="sectionSettings('text')">
          <content-editable
              :customs="section.options.content.text"
              @init="(editor) => section.classes.tiptap.text = editor"
              v-model="section.options.content.text.text"></content-editable>
        </highliter>
      </template>
    </TestimonialItem3Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import TestimonialItem3Template from '@pub/templates/default/sections/testimonials/TestimonialItem3Template';
import ImageFit from "../../../utils/ImageFit";

export default {
  name: "FeaturedItems1",

  components: {ImageFit, TestimonialItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin],
}
</script>
