<template>
  <div class="images-group-5 d-flex justify-content-center align-items-center" :style="{...opacity}">
    <div class="position-relative">
      <div class="card" style="width: 336px;" :style="{...dropShadow, ...backgroundColor}">
        <slot name="image" v-if="visability('image')"></slot>
        <div class="card-body">
          <h5 class="card-title" v-if="visability('title')">
            <slot name="title"></slot>
          </h5>
          <p class="card-text" v-if="visability('description')">
            <slot name="description"></slot>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "ImageItem5Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.images-group-5 {
  padding-top: 30px;
  padding-bottom: 60px;

  .card {
    border-radius: 12px;
    overflow: hidden;
  }
}
</style>