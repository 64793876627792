import SectionProvider from '@/sections/SectionProvider';

export const name = 'HeadlineItem4'

export default class HeadlineItem4 extends SectionProvider {
  constructor(options = {}) {
    super('HeadlineItem4', options);

    this.name = name
    this.group = 'headlines'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#1F3B9C',
              align: 'center',
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#1F3B9C',
              align: 'center',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"Explore our wide selection today"}]}]},
        },
        subtitle: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '54px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
            },
            lg: {
              fontSize: '54px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
            }
          },
          text: '<p><strong>Lorem ipsum dolor sit amet,</strong></p><p><strong> consectetur adipiscing elit.</strong></p>'
        },
        text: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
            }
          },
          text: '<p>estibulum interdum scelerisque magna, ut venenatis eros tincidunt eu. Aenean viverra lectus ut</p> <p>maximus aliquet. Quisque tristique euismod sodales. Phasellus tellus mi, elementum ac</p> <p>interdum ut, volutpat sed dui. Nulla facilisis id elit eget aliquet. Ut nec consectetur libero.</p>'
        },
      },
      customize: {
       style: {
         backgroundImage: {
           type: 'background-image-customs',
           src: null,
           position: 'center',
            size: 'cover',
           opacity: 1
         },
         padding: {
           type: 'height-customs',
           sm: {
             top: 200,
             bottom: 200
           },
           lg: {
             top: 200,
             bottom: 200
           }
         },
         container: {
           type: 'container-customs',
           container: 'container'
         },
       }
      }
    }
  }
}
