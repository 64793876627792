import SectionProvider from '@/sections/SectionProvider';
import EsRow from "../utils/EsRow";
import TestimonialItem3 from "./TestimonialItem3";

export const name = 'TestimonialItem3Group'

export default class TestimonialItem3Group extends SectionProvider {
  constructor(options, items) {
    super('TestimonialItem3Group', options);

    this.name = name
    this.group = 'testimonials'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'center',
            },
            lg: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'center',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"Testimonials"}]}]},
        },
        description: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6d6d6d',
              align: 'center',
              "padding": {"top": 0, "bottom": 40, "x": 0}
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              color: '#6d6d6d',
              align: 'center',
              "padding": {"top": 0, "bottom": 40, "x": 0}
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit."}]}]},
        },
        group: {
          type: 'row-mod',
          display: true,
          items: [
            new TestimonialItem3({
              content: {
                image: {
                  placeholder: require('@/assets/images/testemonial-placeholder-item-3-1.png')
                }
              }
            }).json(),
            new TestimonialItem3({
              content: {
                image: {
                  placeholder: require('@/assets/images/testemonial-placeholder-item-3-2.png')
                }
              }
            }).json(),
            new TestimonialItem3({
              content: {
                image: {
                  placeholder: require('@/assets/images/testemonial-placeholder-item-3-3.png')
                }
              }
            }).json(),
          ]
        }
      },
      customize: {
        layouts: false,
        style: {
          padding: {
            type: 'height-customs',
            sm: {
              top: 35,
              bottom: 35
            },
            lg: {
              top: 115,
              bottom: 115
            }
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
        }
      }
    }
  }
}
