import SectionProvider from '@/sections/SectionProvider';

export const name = 'Image Item 4'

export default class ImageItem4 extends SectionProvider {
  constructor(options = {}) {
    super('ImageItem4', options);

    this.name = name
    this.group = 'images'

    this.defaultOptions = {
      content: {
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/previews/images/placeholder-image-circle.png'),
          src: null,
          size: null
        }
      },
      customize: {
        style: {
          background: null
        }
      }
    }
  }
}
