<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings('image')">
    <FeaturedItems5Template :section="section">
      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>

      <template slot="button">
        <highliter autoWidth
                   @click.stop.native="sectionSettings('button')">
          <es-button :customs="section.options.content.button"></es-button>
        </highliter>
      </template>
   </FeaturedItems5Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import FeaturedItems5Template from '@pub/templates/default/sections/featured/FeaturedItems5Template';

  export default {
    name: "FeaturedItems5",

    components: {FeaturedItems5Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
