<template>
  <div class="global-styles overflow-y-auto pb-3">
    <b-card-header header-class="editor-toolbar-card-header d-flex justify-content-between align-items-center">
      <h5 class="editor-toolbar-title mb-0">Advanced Font Options</h5>

      <b-link class="d-flex align-items-center font-weight-light small link-muted" @click="$store.commit('editor/SET_PAGE', 'global-styles')">
        <icon class="mr-2" icon="left-arrow.svg"></icon> Go Back
      </b-link>
    </b-card-header>

    <b-container class="py-3">
      <b-form-group label="H1 Font">
        <b-row>
          <b-col cols="8" class="pr-0">
            <b-select :options="fonts" v-model="globalStyles.font.h1.fontFamily"></b-select>
          </b-col>
          <b-col>
            <b-select :options="fontSizes" v-model="globalStyles.font.h1.fontSize"></b-select>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="H2 Font">
        <b-row>
          <b-col cols="8" class="pr-0">
            <b-select :options="fonts" v-model="globalStyles.font.h2.fontFamily"></b-select>
          </b-col>
          <b-col>
            <b-select :options="fontSizes" v-model="globalStyles.font.h1.fontSize"></b-select>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="H3 Font">
        <b-row>
          <b-col cols="8" class="pr-0">
            <b-select :options="fonts" v-model="globalStyles.font.h3.fontFamily"></b-select>
          </b-col>
          <b-col>
            <b-select :options="fontSizes" v-model="globalStyles.font.h3.fontSize"></b-select>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="Body">
        <b-row>
          <b-col cols="8" class="pr-0">
            <b-select :options="fonts" v-model="globalStyles.font.body.fontFamily"></b-select>
          </b-col>
          <b-col>
            <b-select :options="fontSizes" v-model="globalStyles.font.body.fontSize"></b-select>
          </b-col>
        </b-row>
      </b-form-group>
    </b-container>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: "FontAdvanced",

    computed: {
      ...mapState( {
        globalStyles: state => state.editor.globalStyles
      })
    },

    data() {
      return {
        fontSizes: ['1px',
          '2px',
          '3px',
          '4px',
          '5px',
          '6px',
          '7px',
          '8px',
          '9px',
          '10px',
          '11px',
          '12px',
          '13px',
          '14px',
          '15px',
          '16px',
          '17px',
          '18px',
          '19px',
          '20px',
          '21px',
          '22px',
          '23px',
          '24px',
          '25px',
          '26px',
          '27px',
          '28px',
          '29px',
          '30px',
          '31px',
          '32px',
          '33px',
          '34px',
          '35px',
          '36px',
          '37px',
          '38px',
          '39px',
          '40px',
          '41px',
          '42px',
          '43px',
          '44px',
          '45px',
          '46px',
          '47px',
          '48px',
          '49px',
          '50px',
          '51px',
          '52px',
          '53px',
          '54px',
          '55px',
          '56px',
          '57px',
          '58px',
          '59px',
          '60px',
          '61px',
          '62px',
          '63px',
          '64px',
          '65px',
          '66px',
          '67px',
          '68px',
          '69px',
          '70px',
          '71px',
          '72px',
          '73px',
          '74px',
          '75px',
          '76px',
          '77px',
          '78px',
          '79px',
          '80px',
          '81px',
          '82px',
          '83px',
          '84px',
          '85px',
          '86px',
          '87px',
          '88px',
          '89px',
          '90px',
          '91px',
          '92px',
          '93px',
          '94px',
          '95px',
          '96px',
          '97px',
          '98px',
          '99px',
          '100px'],
        fonts: [
          'Global Style',
          'Roboto',
          'Montserrat',
          'Georgia',
          'Playfair Display',
          'Raleway',
          'Spectral',
          'Rubik',
        ]
      }
    }
  }
</script>

<style lang="scss">

</style>
