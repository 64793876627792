import {mapState} from 'vuex';

export default {
  props: {
    customs: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
      resolution: state => state.resolution,
      section: state => state.section
    }),
    ...mapState('projects', {
      project: state => state.project
    })
  },

  methods: {
    cols(lg, sm) {
      return this.resolution === 'lg' ? lg : sm
    },
  }
}
