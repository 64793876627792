<template>
<div>
  <editor-header></editor-header>

  <div class="editor-offset">
    <editor-toolbar></editor-toolbar>

    <div class="d-flex justify-content-center pb-5">
      <div class="es-root web position-relative" :style="{background: globalStyles.colors.siteBackground}"
           :class="['resolution-' + resolution]">
        <component
            v-if="navbar.section"
            :is="navbar.section.component"
            :section="navbar.section"
        >
        </component>

        <template>
          <draggable v-if="sections.length" v-model="sections" class="position-static" handle=".move">
            <component
                v-for="section in sections"
                :key="section.uid"
                :is="section.component"
                :section="section"
            >
            </component>
          </draggable>
        </template>

        <component
            v-if="footer"
            :is="footer.component"
            :section="footer"
        >
        </component>

        <div
            v-if="!sections.length && !navbar.section && !footer"
            class="d-flex align-center justify-content-center h-100 w-100 position-absolute ">
          <div class="mx-auto my-auto">
            <b-btn variant="editor-soft-primary" size="lg" @click="$store.commit('editor/SET_SECTION_MODAL', 'none')">
              Add New Section
              <icon class="ml-1" icon="plus.svg"/>
            </b-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import Navbar1 from '@/sections/navigations/Navbar1';
import draggable from 'vuedraggable'
import EditorToolbar from "../editor/navigation/EditorToolbar";
import html2canvas from "html2canvas";
import CodeInjection from "../mixins/CodeInjection";
import EditorHeader from "../editor/header/EditorHeader";
import NavigationSettings from "../mixins/NavigationSettings";

export default {
  name: "EsRoot",

  components: {EditorHeader, EditorToolbar, draggable},

  mixins: [CodeInjection, NavigationSettings],

  computed: {
    ...mapState('editor', {
      theme: state => state.theme,
      globalStyles: state => state.globalStyles,
      resolution: state => state.resolution
    }),
    ...mapState('sections', {
      navbar: state => state.navbar,
      footer: state => state.footer
    }),
    ...mapGetters('sections', [
      'getSections'
    ]),
    ...mapState('projects', {
      project: state => state.project
    }),
    sections: {
      get() {
        return this.getSections
      },
      set(val) {
        this.$store.dispatch('sections/setSections', val)
      }
    }
  },

  data() {
    return {
      interval: null,
      interval2: null
    }
  },

  methods: {
    async takeScreenShot() {
      await html2canvas(document.querySelector('.es-root'), {
        scrollX: 0,
        scrollY: -window.scrollY
      }).then(canvas => {
        let croppedCanvas = document.createElement('canvas'),
            croppedCanvasContext = croppedCanvas.getContext('2d');

        croppedCanvas.width = canvas.width;
        croppedCanvas.height = 1900

        croppedCanvasContext.drawImage(canvas, 0, 0, canvas.width, 1900, 0, 0, canvas.width, 1900);

        axios.put(`api/projects/${this.project.id}/screenshot`, {
          screenshot: croppedCanvas.toDataURL()
        })
      })
    },
  },

  mounted() {
    setTimeout(() => {
      this.takeScreenShot()
    }, 5000)

    document.querySelector('.es-root').addEventListener('mouseup', e => {
      const parent = e.target.closest('.editor__content')

      if (parent) {
        let fontWeight = e.target.style.fontWeight

        if (fontWeight) {
          setTimeout(() => VEvent.fire('tiptap-mod-font-weight', fontWeight), 400)
        }
      }
    })

  },

  async created() {
    this.go('sections')

    this.interval = setInterval(() => {
      this.$store.dispatch('projects/save')
    }, 10000)

    this.interval2 = setInterval(() => {
      this.takeScreenShot()
    }, 300000)


    switch (this.theme) {
      case 'demo':
        require('@/assets/scss/builder/themes/_demo.scss')
        break
    }
  },

  destroyed() {
    clearInterval(this.interval)
    clearInterval(this.interval2)
  }
}
</script>

<style lang="scss">

.editor {
  .resolution {
    &-lg {
      width: 100%;
    }

    &-sm {
      max-width: 400px;
    }
  }
}
</style>
