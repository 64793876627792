<template>
  <Template :section="section">
    <template slot="title">
      <pub-content-editable
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="button">
      <pub-es-button :customs="section.options.content.button"></pub-es-button>
    </template>

    <template slot="button2">
      <pub-es-button :customs="section.options.content.button2"></pub-es-button>
    </template>
  </Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Template from './Template';
  import PubContentEditable from "../../../utils/PubContentEditable";
  import PubEsButton from "../../../utils/buttons/PubEsButton";

  export default {
    name: "ActionItem2",

    components: {PubEsButton, PubContentEditable, Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
