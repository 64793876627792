<template>
  <div v-if="customs.display" :data-aos="animations.animation" data-aos-once="true" data-aos-offset="200" :data-aos-delay="animations.delay" :data-aos-duration="animations.duration || 400">
    <div class="pub__content" v-html="typeof value === 'object' ? editor.getHTML(): value" style="overflow-wrap: anywhere;" :style="style"></div>
  </div>
</template>

<script>
  import {Editor, EditorContent, EditorMenuBubble} from 'tiptap'
  import {
    Blockquote,
    BulletList,
    CodeBlock,
    HardBreak,
    Heading,
    ListItem,
    OrderedList,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'

  import TextColor from '../../../plugins/TiptapTextColor'
  import ComponentProvider from "../../mixins/ComponentProvider";
  import {mapState} from "vuex";
  import FontWeight from "../../../plugins/TiptapFontWeight";
  import Animations from "../../mixins/Animations";

  export default {
    name: "PubContentEditable",

    components: {
      EditorContent,
      EditorMenuBubble
    },

    mixins: [ComponentProvider, Animations],

    props: {
      value: {
        required: true
      },
      hardStyles: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        keepInBounds: true,
        editor: new Editor({
          extensions: [
            new Blockquote(),
            new BulletList(),
            new CodeBlock(),
            new HardBreak(),
            new Heading({levels: [1, 2, 3]}),
            new ListItem(),
            new OrderedList(),
            new TodoItem(),
            new TodoList(),
            new Underline(),
            new Link(),
            new Bold(),
            new Code(),
            new Italic(),
            new Strike(),
            new History(),
            new TextColor(),
            new FontWeight()
          ],
          onUpdate: ({getJSON}) => {
            const content = getJSON()
            this.$emit('input', content)
          },
          content: this.value
        }),
      }
    },

    computed: {
      ...mapState('editor', {
        resolution: state => state.resolution
      }),
      fontFamaly() {
        if (parseInt(this.customs.resolutionStyle[this.resolution].fontSize.replace('px')) > 30) {
          return this.globalStyles.font.headingStyle || this.customs.resolutionStyle[this.resolution].fontFamily
        } else {
          return this.globalStyles.font.style || this.customs.resolutionStyle[this.resolution].fontFamily
        }
      },
      lineHeight() {
        if (this.customs.resolutionStyle[this.resolution].hasOwnProperty('lineHeight')) {
          return this.customs.resolutionStyle[this.resolution].lineHeight
        } else {
          this.$set(this.customs.resolutionStyle[this.resolution], 'lineHeight', 1)

          return this.customs.resolutionStyle[this.resolution].lineHeight
        }
      },
      style() {

        if (this.hardStyles) {
          return this.hardStyles
        }

        return {
          color: this.customs.resolutionStyle['lg'].color === 'adaptive' ? null : this.customs.resolutionStyle['lg'].color,
          textAlign: this.customs.resolutionStyle[this.resolution].align,
          fontFamily: this.fontFamaly,
          fontSize: this.customs.resolutionStyle[this.resolution].fontSize,
          paddingTop: this.customs.resolutionStyle[this.resolution].padding.top + 'px',
          paddingBottom: this.customs.resolutionStyle[this.resolution].padding.bottom + 'px',
          paddingLeft: this.customs.resolutionStyle[this.resolution].padding.x + 'px',
          paddingRight: this.customs.resolutionStyle[this.resolution].padding.x + 'px',
          lineHeight: this.lineHeight
        }
      }
    },

    beforeDestroy() {
      this.editor.destroy()
    }
  }
</script>

<style>
  .menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s, visibility .2s;
    transition: opacity .2s, visibility .2s
  }

  .menububble.is-active {
    opacity: 1;
    visibility: visible
  }

  .menububble__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer
  }

  .menububble__button:last-child {
    margin-right: 0
  }

  .menububble__button:hover {
    background-color: hsla(0, 0%, 100%, .1)
  }

  .menububble__button.is-active {
    background-color: hsla(0, 0%, 100%, .2)
  }

  .menububble__form {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
  }

  .menububble__input {
    font: inherit;
    border: none;
    background: transparent;
    color: #fff
  }

  .editor__content {
    overflow-wrap: anywhere;
  }
</style>
