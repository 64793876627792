import SectionProvider from '@/sections/SectionProvider';
import VideoItem1 from "./VideoItem1";

export const name = 'VideoItem2'

export default class VideoItem2 extends SectionProvider {
  constructor(options = {}) {
    super('VideoItem2', options);

    this.name = name
    this.group = 'videos'
    this.stateSafly = false

    this.defaultOptions = {
      content: {
        group: {
          type: 'row-mod',
          display: true,
          items: [
            new VideoItem1({
              content: {
                title: {
                  type: 'tiptap-mod',
                  display: true,
                  resolutionStyle: {
                    sm: {
                      fontSize: '46px',
                      fontFamily: 'Global Style',
                      color: '#0C226C',
                      align: 'center',
                      "padding": {"top": 10, "bottom": 0, "x": 0}
                    },
                    lg: {
                      fontSize: '46px',
                      fontFamily: 'Global Style',
                      color: '#0C226C',
                      align: 'center',
                      "padding": {"top": 10, "bottom": 0, "x": 0}
                    }
                  },
                  text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"Our Latest Video Tutorial"}]}]},
                },
                subtitle: {
                  type: 'tiptap-mod',
                  display: true,
                  resolutionStyle: {
                    sm: {
                      fontSize: '21px',
                      fontFamily: 'Global Style',
                      lineHeight: 1.3,
                      color: '#6D6D6D',
                      align: 'center',
                      "padding": {"top": 10, "bottom": 0, "x": 0}
                    },
                    lg: {
                      fontSize: '21px',
                      fontFamily: 'Global Style',
                      lineHeight: 1.3,
                      color: '#6D6D6D',
                      align: 'center',
                      "padding": {"top": 10, "bottom": 0, "x": 0}
                    }
                  },
                  text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Vivamus sagittis, leo vitae commodo condimentum, metus dui efficitur orci, sed iaculis purus ligula in eros. Ut vel molestie neque. In non varius tortor."}]}]},
                },
                video: {
                  placeholder: require('@/assets/images/placeholder-video-4.jpg')
                }
              }
            }).json()
          ]
        }
      },
      customize: {
        style: {
          shadow: null,
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
           },
           lg: {
              top: 100,
              bottom: 100
           }
          }
        }
      }
    }
  }
}
