<template>
  <div class="global-styles overflow-auto pb-3 h-100">
    <b-card-header header-class="editor-toolbar-card-header d-flex justify-content-between align-items-center">
      <h5 class="editor-toolbar-title mb-0">Global Style</h5>

      <b-link class="d-flex align-items-center font-weight-light small link-muted" @click="$store.commit('editor/SET_PAGE', 'sections')">
        <icon class="mr-2" icon="left-arrow.svg"></icon> Go Back
      </b-link>
    </b-card-header>

    <b-card no-body class="border-0 mb-4 d-none">
      <b-card-header v-b-toggle="'colors'" header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between rounded-0 border-bottom-0" header-tag="header" role="tab">
        <div class="text-capitalize" block>Colors</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="colors" role="tabpanel" visible>
       <b-container class="px-4">
         <b-row class="align-items-center mb-2" no-gutters>
           <b-col cols="3">
             <label>Primary</label>
           </b-col>

           <b-col cols="3" offset="2">
             <b-input type="color" v-model="globalStyles.colors.primary" class="color-picker"></b-input>
           </b-col>
         </b-row>

         <b-row class="align-items-center mb-2" no-gutters>
           <b-col cols="3">
             <label>Accent</label>
           </b-col>

           <b-col cols="3" offset="2">
             <b-input type="color" v-model="globalStyles.colors.buttons" class="color-picker"></b-input>
           </b-col>
         </b-row>

         <b-row class="align-items-center mb-2" no-gutters>
           <b-col cols="3">
             <label class="text-nowrap">Site Background</label>
           </b-col>

           <b-col cols="3" offset="2">
             <b-input type="color" v-model="globalStyles.colors.siteBackground" class="color-picker"></b-input>
           </b-col>
         </b-row>

         <b-row class="align-items-center" no-gutters>
           <b-col cols="3">
             <label>Buttons</label>
           </b-col>

           <b-col cols="3" offset="2">
             <b-input type="color" v-model="globalStyles.colors.buttons" class="color-picker"></b-input>
           </b-col>
         </b-row>
       </b-container>
      </b-collapse>
    </b-card>

    <b-card no-body class="border-0 mb-4 d-none">
      <b-card-header v-b-toggle="'font'" header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between border-bottom-0 border-top rounded-0" header-tag="header" role="tab">
        <div class="text-capitalize" block>Fonts</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="font" role="tabpanel" visible>
        <b-container>
          <div class="d-flex mb-2">
            <div class="text-center mr-2">
              <div class="select-box cursor-pinter d-block mb-2 font-roboto" :class="{'active': globalStyles.font.style === 'Roboto'}" @click="globalStyles.font.style !== 'Roboto' ? globalStyles.font.style = 'Roboto' : globalStyles.font.style = ''">
                <span class="font-weight-bold">A</span>a
              </div>
              <label>Modern</label>
            </div>
            <div class="text-center mr-2">
              <div class="select-box cursor-pinter d-block mb-2 font-montserrat" :class="{'active': globalStyles.font.style === 'Montserrat'}" @click="globalStyles.font.style !== 'Montserrat' ? globalStyles.font.style = 'Montserrat' : globalStyles.font.style = ''">
                <span class="font-weight-bold">A</span>a
              </div>
              <label>Strong</label>
            </div>
            <div class="text-center mr-2">
              <div class="select-box cursor-pinter d-block mb-2 font-georgia" :class="{'active': globalStyles.font.style === 'Georgia'}" @click="globalStyles.font.style !== 'Georgia' ? globalStyles.font.style = 'Georgia' : globalStyles.font.style = ''">
                <span class="font-weight-bold">A</span>a
              </div>
              <label>Elegant</label>
            </div>
          </div>

          <div class="d-flex align-items-center shadow-sm border rounded cursor-pinter p-2"  @click="$store.commit('editor/SET_PAGE', 'font-advanced')">
            <icon class="mr-2" icon="right-arrow.svg"></icon>
            <label class="mb-0">Advanced Options</label>
          </div>
        </b-container>
      </b-collapse>
    </b-card>

    <b-card no-body class="border-0 mb-4">
      <b-card-header v-b-toggle="'font'" header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between border-bottom-0 border-top rounded-0" header-tag="header" role="tab">
        <div class="text-capitalize" block>Fonts</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="font" role="tabpanel" visible>
        <b-container>
          <b-row>
            <b-col cols="6">
              <label>Heading Font</label>
              <v-select left v-model="globalStyles.font.headingStyle" class="custom-select" placeholder="Select Font" :searchable="false" :options="fonts"></v-select>
            </b-col>
            <b-col cols="6">
              <label>Content Font</label>
              <v-select v-model="globalStyles.font.style" class="custom-select" placeholder="Select Font" :searchable="false" :options="fonts"></v-select>
            </b-col>
          </b-row>
        </b-container>
      </b-collapse>
    </b-card>

    <b-card no-body class="border-0 mb-4">
      <b-card-header v-b-toggle="'colors'" header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between rounded-0 border-bottom-0" header-tag="header" role="tab">
        <div class="text-capitalize" block>Theme Colors</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="colors" role="tabpanel" visible>
        <b-container class="colors-palette px-4">
          <compact-picker :value="colors" @input="e => globalStyles.colors.primary = e.hex" :palette="globalStyles.colors.palette || [
    '#1f3b9c', '#fcb045', '#1abc9c', '#2ecc71', '#9b59b6', '#3498db', '#34495e', '#e67e22', '#e74c3c', '#bdc3c7',
  ]"></compact-picker>
        </b-container>
      </b-collapse>
    </b-card>

    <b-card no-body class="border-0 mb-4">
      <b-card-header v-b-toggle="'button-style'" header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between rounded-0 border-bottom-0 border-top" header-tag="header" role="tab">
        <div class="text-capitalize" block>Button Style</div>
        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>
      </b-card-header>
      <b-collapse id="button-style" role="tabpanel" visible>
        <b-container class="colors-palette mb-3">
          <compact-picker :value="colors" @input="e => globalStyles.colors.buttons = e.hex" :palette="globalStyles.colors.palette || [
    '#1f3b9c', '#fcb045', '#1abc9c', '#2ecc71', '#9b59b6', '#3498db', '#34495e', '#e67e22', '#e74c3c', '#bdc3c7',
  ]"></compact-picker>
        </b-container>

        <b-container>
          <div class="d-flex mb-3">
            <icon class="select-box border-0 cursor-pinter mr-2" :class="{'active': globalStyles.buttons.shape === 'squared'}" @click.native="setShape(globalStyles.buttons.shape !== 'squared' ? 'squared' :  null)" icon="btn-squared.svg"></icon>
            <icon class="select-box border-0 cursor-pinter mr-2" :class="{'active': globalStyles.buttons.shape === 'rounded'}" @click.native="setShape(globalStyles.buttons.shape !== 'rounded' ? 'rounded' : null)" icon="btn-rounded.svg"></icon>
            <icon class="select-box border-0 cursor-pinter" :class="{'active': globalStyles.buttons.shape === 'pill'}" @click.native="setShape(globalStyles.buttons.shape !== 'pill' ? 'pill' : null)" icon="btn-pilled.svg"></icon>
          </div>

          <b-form-checkbox v-model="globalStyles.buttons.outlined">Outlined</b-form-checkbox>
        </b-container>
      </b-collapse>
    </b-card>

<!--    <b-card no-body class="border-0">-->
<!--      <b-card-header v-b-toggle="'layout-style'" header-class="editor-toolbar-collapse-header d-flex align-items-center justify-content-between rounded-0 border-bottom-0 border-top" header-tag="header" role="tab">-->
<!--        <div class="text-capitalize" block>Layout Styles</div>-->
<!--        <icon class="toggle-icon" icon="chevron-toggle.svg"></icon>-->
<!--      </b-card-header>-->
<!--      <b-collapse id="layout-style" role="tabpanel" visible>-->
<!--        <b-container>-->
<!--         <b-form-radio-group-->
<!--             id="btn-radios-1"-->
<!--             button-variant="outline-primary"-->
<!--             v-model="globalStyles.container"-->
<!--             :options="[{text: 'Container', value: 'container'}, {text: 'Fluid', value: 'container-fluid'}]"-->
<!--             name="radios-btn-default"-->
<!--             buttons>-->
<!--         </b-form-radio-group>-->
<!--        </b-container>-->
<!--      </b-collapse>-->
<!--    </b-card>-->
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import CompactPicker from 'vue-color/src/components/Compact'

  const colors = {
    hex: '#194d33e6'
  }

  export default {
    name: "GlobalStyles",

    components: {CompactPicker},

    data() {
      return {
        colors: colors,
        fonts: [
          'Roboto',
          'Montserrat',
          'Georgia',
          'Playfair Display',
          'Raleway',
          'Spectral',
          'Rubik',
        ],
      }
    },

    methods: {
      setShape(shape) {
        this.globalStyles.buttons.shape = shape
        VEvent.fire('editor-btn-shape', shape)
      }
    },

    computed: {
      ...mapState( {
        globalStyles: state => state.editor.globalStyles
      })
    }
  }
</script>

<style lang="scss">
.global-styles {
  .select-box {
    border-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
    text-align: center;
    font-size: 34px;
    width: 88px;
    height: 80px;
    display: flex !important;
    border: 1px solid #e2e5ec;
    align-items: center;
    justify-content: center;

    &.active {
      box-shadow: 0 0 0 2px #7CACFA !important;
    }
  }

  label {
    font-size: 15px;
  }

  .color-picker {
    padding: 0 !important;
    border: none !important;
  }

  .colors-palette {
    .vc-compact-color-item {
      width: 36px;
      height: 36px;
      border-radius: 2px;
    }

    .vc-compact {
      box-shadow: none;
    }

    .vc-compact-dot {
      background: transparent;
      font: normal normal normal 14px/1 FontAwesome;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &:before {
        content: "\f00c";
      }
    }
  }
}
</style>
