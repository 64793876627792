<template>
  <b-modal class="position-relative" v-model="modal" body-class="py-0" content-class="new-section-modal" size="xl" hide-footer hide-header>
    <icon class="close-icon cursor-pinter ml-3" icon="close.svg" @click.native="modal = false"></icon>

    <b-row class="new-section-modal-height">
      <b-col md="3" class="offset-top px-0" style="border-right: 10px solid #F7F8F9">
        <h3 class="mb-4">Add Section</h3>

        <ul class="sections-scroll list-unstyled">
          <b-card no-body class="border-0" v-for="(section, index) in sections" v-if="hasToShow(section)" :key="index">
            <b-card-header v-b-toggle="index" :header-class="[{'active': index === group.name}, ' new-section-modal-toolbar-collapse-header d-flex align-items-center rounded-0']" header-tag="header" role="tab" @click="group = {name: index, sections: section}">
              <icon v-if="index === group.name" size="25px" :icon="'menu-sections/' + index + '-active.svg'"></icon>
              <icon v-else size="25px" :icon="'menu-sections/' + index + '.svg'"></icon>
              <div class="text-capitalize ml-4" block>{{startCase(index)}}</div>
            </b-card-header>
          </b-card>
        </ul>
      </b-col>

      <b-col md="9" class="offset-top" v-if="group">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h5 class="text-capitalize">{{group.name}}</h5>
          <b-btn variant="primary" class="font-weight-bold px-4" :disabled="!Object.keys(selected).length" @click="addSection">Insert Now</b-btn>
        </div>

        <b-row class="scroller">
          <b-col v-if="section.standalone" v-for="(section, index) in group.sections" :key="index" cols="6" class="section mb-4" @click="selected = section">
            <div class="section-preview-container overflow-hidden" :class="{'active': isActive(section.name)}">
              <b-img class="cursor-pinter" :src="section.previews.main" fluid></b-img>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
  import {mapState} from 'vuex'
  import sections from '@/sections'
  import TextMixins from "../../mixins/TextMixins";
  import _ from "lodash";

  export default {
    name: "NewInnerSectionModal",

    mixins: [TextMixins],

    data() {
      return {
        sections: sections,
        group: {},
        selected: {},
        modal: false
      }
    },

    methods: {
      hasToShow(group) {
        return group.filter(section => section.standalone).length
      },
      openModal() {
        this.modal = true
      },
      isActive(name) {
        if (this.selected.hasOwnProperty('name')) {
          return this.selected.name === name
        }
      },
      addSection() {
        const section = new this.selected.section().json()

        this.$emit('addedSection', section)

        this.modal = false
      }
    }
  }
</script>