import {mapState} from 'vuex';
import CustomStylesMixin from "./CustomStylesMixin";
import deepmerge from "deepmerge";

export default {
  props: {
    section: {
      type: Object,
      required: true
    },
    parentUID: {
      type: String,
      default: null
    }
  },

  mixins: [CustomStylesMixin],

  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    style() {
      return this.section.options.customize.style
    }
  },

  methods: {
    offset(obj) {
      if (!obj.hasOwnProperty('offset')) {
        this.$set(obj, 'offset', {
          lg: 0,
          sm: 0
        })
      }

      return obj.offset[this.resolution]
    },
    order(obj) {
      if (obj.hasOwnProperty('order')) {
        return obj.order[this.resolution]
      }
    },
    cols(lg, sm) {
      return this.resolution === 'lg' ? lg : sm
    },
    sectionSettings(openedItem = null) {
      this.$store.dispatch('editor/sectionSettings', {section: this.section, openedItem})
    },
    tiptapUID(name) {
      return this.section.uid + '_' + name
    },
    merger(defaultOptions) {
      this.section.options = deepmerge(defaultOptions, this.section.options)
    },
    setStyles() {
      // if (this.$el.querySelector('[data-bg]')) {
      //   this.$el.querySelector('[data-bg]').style.backgroundColor = this.style.background.background
      // } else {
      //   this.$el.style.backgroundColor = this.style.background.background
      // }

      // if (this.$el.querySelector('[data-opacity]')) {
      //   this.$el.querySelector('[data-opacity]').style.opacity = this.style.opacity.opacity
      // } else {
      //   this.$el.style.opacity = this.style.opacity.opacity
      // }

      // if (this.$el.querySelector('[data-shadow]')) {
      //   this.$el.querySelector('[data-shadow]').style.boxShadow = this.style.shadow.shadow
      // }

      // if (this.style.hasOwnProperty('padding')) {
      //   if (this.$el.querySelector('[data-padding]')) {
      //     this.$el.querySelector('[data-padding]').style.paddingTop = this.style.padding[this.resolution].top + 'px'
      //     this.$el.querySelector('[data-padding]').style.paddingBottom = this.style.padding[this.resolution].bottom + 'px'
      //   } else {
      //     this.$el.style.paddingTop = this.style.padding[this.resolution].top + 'px'
      //     this.$el.style.paddingBottom = this.style.padding[this.resolution].bottom + 'px'
      //   }
      // }

      // if (this.style.hasOwnProperty('color')) {
      //   if (this.$el.querySelectorAll('[data-color]').length) {
      //     this.$el.querySelectorAll('[data-color]').forEach(el => {
      //       el.style.color = this.style.color.color
      //     })
      //   } else {
      //     this.$el.style.color = this.style.color.color
      //   }
      // }

      // if (this.style.hasOwnProperty('backgroundImage')) {
      //   if (this.$el.querySelector('[data-bg]')) {
      //     this.$el.querySelector('[data-bg]').style.backgroundImage = `url(${this.style.backgroundImage.src})`
      //     this.$el.querySelector('[data-bg]').style.backgroundSize = this.style.backgroundImage.size
      //   }
      //   else {
      //     this.$el.style.backgroundImage = `url(${this.style.backgroundImage.src})`
      //     this.$el.style.backgroundSize = this.style.backgroundImage.size
      //   }
      // }
    },
    visability(el) {
      const display = this.section.options.content[el].display
      return display === true || display === this.resolution
    }
  },

  mounted() {
    setTimeout(() => {
      this.setStyles()
    })
  }
}
