<template>
  <ActionItem4Template :section="section">
    <template slot="leading" v-if="visability('leading')">
      <pub-content-editable
          :customs="section.options.content.leading"
          v-model="section.options.content.leading.text"></pub-content-editable>
    </template>

    <template slot="title" v-if="visability('title')">
      <pub-content-editable
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description" v-if="visability('description')">
      <pub-content-editable
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="button" v-if="visability('button')">
      <pub-es-button :customs="section.options.content.button"></pub-es-button>
    </template>
  </ActionItem4Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import ActionItem4Template from '@pub/templates/default/sections/actions/ActionItem4Template';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';
  import PubEsButton from '../../../builder/utils/buttons/PubEsButton';

  export default {
    name: "ActionItem2",

    components: {PubEsButton, PubContentEditable, ActionItem4Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
