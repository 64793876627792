import SectionProvider from '@/sections/SectionProvider';

export const name = 'Action Item 12'
export const group = 'actions'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '21px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 20,
            x: 0
          }
        },
        lg: {
          fontSize: '21px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Order Your Product Right Here and see</p><p>How it Goes from here</p>"
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Book a Call',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    button2: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Learn More',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: 'outlined',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: '#ffffff',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: '#1F3B9C'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 40,
          bottom: 40
        },
        lg: {
          top: 67,
          bottom: 67
        }
      },
      container: {
        type: 'container-customs',
        container: 'container'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: '',
        position: 'center',
        size: 'cover',
        opacity: 1
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/actions/main-12.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('ActionItem12', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
