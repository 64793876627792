<template>
  <div class="d-flex" :class="`justify-content-${btnAlign}`" :style="padding"
       :data-aos="animations.animation" data-aos-once="true" data-aos-offset="200" :data-aos-delay="animations.delay" :data-aos-duration="animations.duration || 400">
    <b-button :style="style"
              v-bind="$attrs"
              :block="customs.btnBlock"
              @mouseenter="event => buttonHover(event, true)"
              @mouseleave="event => buttonHover(event, false)"
              :type="type" @click="goPage"
              :class="[customs.resolutionStyle['lg'].shadow ,'size-' + customs.resolutionStyle['lg'].size, btnStyle, customs.resolutionStyle['lg'].shape || globalStyles.buttons.shape, theme, {'btn-icon': !customs.text}]"
              class="d-flex align-items-center">
      <div class="w-100">{{ customs.text }}</div>
      <i v-if="customs.icon" :style="{color: customs.iconColor}" :class="[customs.icon, {'ml-2': customs.text}]"></i>
    </b-button>
  </div>
</template>

<script>
import ComponentProvider from '@/components/mixins/ComponentProvider'
import ThemeMixin from "../../../mixins/ThemeMixin";
import Animations from "../../../mixins/Animations";
import RouterMixin from "../../../mixins/RouterMixin";

export default {
  name: "PubEsButton",

  mixins: [ComponentProvider, ThemeMixin, Animations, RouterMixin],

  props: {
    type: {
      type: String,
      default: 'button'
    }
  },

  computed: {
    btnStyle() {
      return !this.customs.resolutionStyle['lg'].btnStyle ? this.globalStyles.buttons.outlined ? 'outlined' : 'filled' : this.customs.resolutionStyle['lg'].btnStyle
    },
    btnAlign() {
      if (!this.customs.resolutionStyle[this.resolution].hasOwnProperty('align') || this.customs.resolutionStyle[this.resolution].left === 'left') {
        this.$set(this.customs.resolutionStyle.lg, 'align', 'start')
        this.$set(this.customs.resolutionStyle.sm, 'align', 'start')
      }

      return this.customs.resolutionStyle[this.resolution].align
    },
    style() {
      return {
        fontFamily: this.globalStyles.font.style || '',
        color: this.btnStyle === 'outlined' ? this.customs.resolutionStyle['lg'].backgroundColor === 'colored' ? this.globalStyles.colors.buttons : this.customs.resolutionStyle['lg'].backgroundColor : this.customs.resolutionStyle['lg'].color === 'adaptive' ? null : this.customs.resolutionStyle['lg'].color,
        backgroundColor: this.customs.resolutionStyle['lg'].backgroundColor === 'colored' ? this.globalStyles.colors.buttons : this.customs.resolutionStyle['lg'].backgroundColor,
        borderColor: this.customs.resolutionStyle['lg'].backgroundColor === 'colored' ? this.globalStyles.colors.buttons : this.customs.resolutionStyle['lg'].backgroundColor,
      }
    },
    padding() {
      return {
        paddingTop: this.customs.resolutionStyle[this.resolution].padding.top + 'px',
        paddingBottom: this.customs.resolutionStyle[this.resolution].padding.bottom + 'px',
        paddingLeft: this.customs.resolutionStyle[this.resolution].padding.x + 'px',
        paddingRight: this.customs.resolutionStyle[this.resolution].padding.x + 'px',
      }
    }
  },

  methods: {
    goPage() {
      if (this.customs.page && this.customs.page !== 'custom-link[builder]') {
        if (this.customs.blank_page) {
          window.open(`${this.getPageBlankURL}/${this.customs.page}`)
        } else {
          this.$store.dispatch('router/setPage', this.customs.page)
        }
      } else if (this.customs.page === 'custom-link[builder]') {
        if (this.customs.blank_page) {
          window.open(this.customs.customLink)
        } else {
          window.location = this.customs.customLink
        }
      }
    },
    buttonHover(e, hover) {
      if (this.customs.hover && this.customs.hasOwnProperty('hoverBg')) {
        if (hover) {
          e.target.style.backgroundColor = this.customs.hoverBg
          e.target.style.borderColor = this.customs.hoverBg

          if (this.customs.resolutionStyle[this.resolution].btnStyle === 'outlined') {
            e.target.style.color = this.customs.hoverBg
          }
        } else {
          e.target.style.backgroundColor = this.customs.resolutionStyle[this.resolution].backgroundColor || this.globalStyles.colors.buttons
          e.target.style.borderColor = this.customs.resolutionStyle[this.resolution].backgroundColor || this.globalStyles.colors.buttons

          if (this.customs.resolutionStyle[this.resolution].btnStyle === 'outlined') {
            e.target.style.color = this.customs.resolutionStyle[this.resolution].color
          }
        }
      }
    }
  }
}
</script>
