import SectionProvider from '@/sections/SectionProvider';

export const name = 'Testimonial Item 3'

export default class TestimonialItem3 extends SectionProvider {
  constructor(options = {}) {
    super('TestimonialItem3', options);

    this.name = name
    this.group = 'testimonials'

    this.defaultOptions = {
      content: {
        name: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'center',
              padding: {
                top: 20,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'center',
              padding: {
                top: 20,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"JOHN THOMAS"}]}]},
        },
        subname: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              lilineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
               padding: {
                top: 0,
                bottom: 20,
                x: 0
              }
            },
            lg: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
               padding: {
                top: 0,
                bottom: 20,
                x: 0
              }
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Marketing Mix CEO"}]}]},
        },
        text: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '16px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
               padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6D6D6D',
              align: 'center',
               padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit. Curabitur tristique"}]}]},
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/images/placeholder-testemonial-item-2.png'),
          size: 'cover',
          position: 'center',
          shape: null,
          align: 'center',
          src: ''
        }
      },
      customize: {
        layouts: false
      }
    }
  }
}
