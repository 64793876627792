<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <span class="indicator mr-2"></span>
      <p class="text-capitalize mb-0">{{ labelCase(componentName) }}</p>
    </div>

    <b-form-group label="Color">
      <color-picker :color="color" v-model="color"></color-picker>
    </b-form-group>

    <b-form-group label="Font Family">
      <v-select v-model="fontFamily"
                class="custom-select"
                :reduce="option => option.value"
                :searchable="false"
                :options="fonts"></v-select>
    </b-form-group>

    <b-form-group label="Font Weight">
      <v-select v-model="fontWeight"
                class="custom-select"
                :reduce="option => option.value"
                :searchable="false"
                :options="fontsWeight"></v-select>
    </b-form-group>
  </div>
</template>

<script>
import ModMixin from "../../mixins/ModMixin";

export default {
  name: "BlogTextMod",

  mixins: [ModMixin],

  computed: {
    fontFamily: {
      get() {
        if (!this.config.fontFamily) return this.globalStyles.font.style
        return this.config.fontFamily
      },
      set(val) {
        this.config.fontFamily = val
      }
    },
    fontWeight: {
      get() {
        if (!this.config.fontWeight) return '400'
        return this.config.fontWeight
      },
      set(val) {
        this.config.fontWeight = val
      }
    },
    color: {
      get() {
        if (!this.config.color) return '#2d2d2d'
        return this.config.color
      },
      set(val) {
        this.config.color = `rgba(${val.rgba.r}, ${val.rgba.g}, ${val.rgba.b}, ${val.rgba.a})`
      }
    }
  },

  data() {
    return {
      fonts: [
        {
          value: null,
          label: 'Global Style'
        },
        {
          value: 'Roboto',
          label: 'Roboto'
        },
        {
          value: 'Montserrat',
          label: 'Montserrat'
        },
        {
          value: 'Georgia',
          label: 'Georgia'
        },
        {
          value: 'Playfair Display',
          label: 'Playfair Display'
        },
        {
          value: 'Raleway',
          label: 'Raleway'
        },
        {
          value: 'Spectral',
          label: 'Spectral'
        },
        {
          value: 'Rubik',
          label: 'Rubik'
        },
      ],
      fontsWeight: [
        {
          label: 'Light',
          value: '300'
        },
        {
          label: 'Regular',
          value: '400'
        },
        {
          label: 'Medium',
          value: '500'
        },
        {
          label: 'Bold',
          value: '600'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>