import SectionProvider from '@/sections/SectionProvider';

export const name = 'NewsLetterItem3'

export default class NewsLetterItem3 extends SectionProvider {
  constructor(options = {}) {
    super('NewsLetterItem3', options);

    this.name = name
    this.group = 'newsLetter'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#fff',
              align: 'center',
            },
            lg: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#fff',
              align: 'center',
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "Subscribe to our newsletter"}]
            }]
          },
        },
        subtitle: {
          type: 'tiptap-mod',
          display: true,
          mods: ['strike', 'list'],
          resolutionStyle: {
            sm: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#CCC4FF',
              align: 'center',
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#CCC4FF',
              align: 'center',
            }
          },
          text: {
            "type": "doc",
            "content": [{"type": "paragraph", "content": [{"type": "text", "text": "Curabitur tristique, lorem et dignissim consequat."}]}]
          },
        },
        form: {
          type: 'form-mod',
          btnPosition: 'append-email',
          action: null,
          selectedList: '',
          fields: [
            {
              display: true,
              type: 'email',
              name: 'email',
              placeholder: 'Enter your email address',
              required: true,
              label: '',
              cols: {
                sm: 12,
                lg: 12
              }
            }
          ]
        },
        button: {
          type: 'button-mod',
          submit: true,
          display: true,
          hover: false,
          text: 'Sign Up',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        style: {
          container: {
            type: 'container-customs',
            container: 'container'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: require('@/assets/svg/previews/newsLetter/placeholder-newsletter-3.png'),
            position: 'center',
size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
            },
            lg: {
              top: 115,
              bottom: 115
            }
          }
        }
      }
    }
  }
}
