import {mapState} from 'vuex'

export default {
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('editor', {
      allConfig: state => state.config,
      section: state => state.section,
      openedItem: state => state.openedItem,
      resolution: state => state.resolution
    })
  }
}
