<template>
  <FeaturedIImageTemplate :section="section">
    <template slot="image">
      <es-image :customs="section.options.content.image"></es-image>
    </template>

    <template slot="description">
      <pub-content-editable
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>
  </FeaturedIImageTemplate>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import FeaturedIImageTemplate from '@pub/templates/default/sections/featured/FeaturedIImageTemplate';
  import PubContentEditable from "../../../builder/utils/PubContentEditable";

  export default {
    name: "FeaturedItems1",

    components: {PubContentEditable, FeaturedIImageTemplate, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
