const state = () => ({
  preview: false,
  page: 'sections',
  config: {},
  section: null,
  openedItem: null,
  theme: 'demo',
  resolution: 'lg',
  globalStyles: {
    colors: {
      primary: '',
      accent: '#B83636',
      siteBackground: '#ffffff',
      buttons: '#B83636'
    },
    font: {
      style: '',
      headingStyle: '',
      h1: {
        fontFamily: 'Global Style',
        fontSize: '23px'
      },
      h2: {
        fontFamily: 'Global Style',
        fontSize: '23px'
      },
      h3: {
        fontFamily: 'Global Style',
        fontSize: '16px'
      },
      body: {
        fontFamily: 'Global Style',
        fontSize: '16px'
      }
    },
    buttons: {
      shape: null,
      outlined: false
    }
  },
  newSectionModal: false,
  tiptap: null
})

const getters = {

}

const actions = {
  sectionSettings({commit, rootState}, paylaod) {
    commit('SET_SECTION', paylaod.section)
    commit('SET_OPENED_MENU', paylaod.openedItem || null)
    commit('SET_PAGE', 'section-settings')
  },
  codeInjection({commit, dispatch}, payload) {
    VEvent.fire('loader', true)

    dispatch('projects/save', {}, {root: true})
      .finally(() => {
        VEvent.fire('loader', false)
      })
  }
}

const mutations = {
  SET_TIPTAP(state, payload) {
    state.tiptap = payload
  },
  SET_RESOLUTION(state, payload) {
    state.resolution = payload
  },
  SET_CONFIG(state, payload) {
    state.config = payload
  },
  SET_SECTION_MODAL(state, payload) {
    state.newSectionModal = payload
  },
  SET_SECTION(state, payload) {
    state.section = payload
  },
  SET_OPENED_MENU(state, payload) {
    state.openedItem = payload
  },
  SET_PAGE(state, payload) {
    state.page = payload
  },
  RESET(state) {
    state.section = null
    state.openedItem = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
