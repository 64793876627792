<template>
  <div :style="{...backgroundImage, ...padding, ...backgroundColor, ...opacity, ...shadow}">
    <div class="action-item-8" :class="container">
     <b-row>
       <b-col :cols="cols(6, 12)" :offset="cols(6, 0)" :class="cols('border p-3', '')">
         <slot name="title"></slot>
         <div class="my-2"></div>
         <slot name="description"></slot>
         <div class="my-5"></div>
         <slot name="button"></slot>
       </b-col>
     </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>