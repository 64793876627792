import SectionProvider from '@/sections/SectionProvider';

export const name = 'Image Item 1'

export default class ImageItem1 extends SectionProvider {
  constructor(options = {}) {
    super('ImageItem1', options);

    this.name = name
    this.group = 'images'

    this.defaultOptions = {
      content: {
        image: {
          display: true,
          type: 'images-mod',
          items: [
            {
              placeholder: require('@/assets/svg/placeholder-image.svg'),
              src: null,
              position: 'center',
size: 'cover',
              opacity: 1,
              alt: ''
            },
            {
              placeholder: require('@/assets/svg/placeholder-image.svg'),
              src: null,
              position: 'center',
size: 'cover',
              opacity: 1,
              alt: ''
            },
            {
              placeholder: require('@/assets/svg/placeholder-image.svg'),
              src: null,
              position: 'center',
size: 'cover',
              opacity: 1,
              alt: ''
            }
          ]
        }
      },
      customize: {
        backgroundColor: null,
        shadow: null
      }
    }
  }
}
