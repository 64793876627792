<template>
  <div class="d-flex">
    <b-nav class="settings-nav">
      <b-nav-item :active="$route.name === 'settings'" :to="{name: 'settings'}">General</b-nav-item>
      <b-nav-item :active="$route.name === 'settings.domain'" :to="{name: 'settings.domain'}">Domain</b-nav-item>
<!--      <b-nav-item disabled>Fonts</b-nav-item>-->
      <b-nav-item :active="$route.name === 'settings.integrations'" :to="{name: 'settings.integrations'}">Integrations</b-nav-item>
      <b-nav-item  :active="$route.name === 'settings.injection'" :to="{name: 'settings.injection'}">Code Injection</b-nav-item>
    </b-nav>
  </div>
</template>

<script>
export default {
  name: "SettingsNav"
}
</script>

<style lang="scss">
.settings-nav {
  border: 1px solid #E2E5EC;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  .nav-link {
    color: #43474A;
    font-size: 16px;
    padding: 20px 0 !important;
    margin: 0 20px;

    &.active {
      border-bottom: 2px solid #43474A !important;
      font-weight: bold;
    }
  }
}
</style>