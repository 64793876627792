import SectionProvider from '@/sections/SectionProvider';
import {default as PriceCard1} from "../price-1/Section";
import EsRow from "../../../../../sections/utils/EsRow";

export const name = 'Price Group'
export const group = 'Price'

export const defaultOptions = new EsRow(
  {
    customize: {
      layouts: false
    }
  },
  [
    new PriceCard1(
      {
        customize: {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          },
          order: {
            sm: 1,
            lg: 0
          },
        },
      }
    ).json(),
    new PriceCard1(
      {
        "customize": {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          },
          order: {
            sm: 0,
            lg: 1
          },
          "style": {
            "background": {
              "type": "background-customs",
              "background": "colored"
            },
            "shadow": {
              "type": "shadow-customs",
              "shadow": null
            },
            "opacity": {
              "type": "opacity-customs",
              "opacity": 1
            },
            "listIcon": {
              "type": "list-icon-customs",
              "icon": 'fa fa-check-circle',
              "color": "rgba(255, 255, 255, 1)"
            },
            "color": {
              "type": "color-customs",
              "color": "rgba(255, 255, 255, 1)"
            }
          }
        },
        "content": {
          "title": {
            "type": "tiptap-mod",
            "display": true,
            "resolutionStyle": {
              "sm": {
                "fontSize": "18px",
                "fontFamily": "Proxima Nova",
                "color": "#000000",
                "align": "left",
                "padding": {
                  "top": 0,
                  "bottom": 0,
                  "x": 0
                }
              },
              "lg": {
                "fontSize": "18px",
                "fontFamily": "Proxima Nova",
                "color": "#000000",
                "align": "left",
                "padding": {
                  "top": 0,
                  "bottom": 0,
                  "x": 0
                },
                "lineHeight": 1
              }
            },
            "text": "<p><strong><span style=\"color:#ffffff\">Popular</span></strong></p>"
          },
          "description": {
            "type": "tiptap-mod",
            "display": true,
            "resolutionStyle": {
              "sm": {
                "lineHeight": 1.4,
                "fontSize": "14px",
                "fontFamily": "Proxima Nova",
                "color": "#6E747A",
                "align": "left",
                "padding": {
                  "top": 0,
                  "bottom": 0,
                  "x": 0
                }
              },
              "lg": {
                "lineHeight": 1.4,
                "fontSize": "14px",
                "fontFamily": "Proxima Nova",
                "color": "#6E747A",
                "align": "left",
                "padding": {
                  "top": 0,
                  "bottom": 0,
                  "x": 0
                }
              }
            },
            "text": "<p><span style=\"color:#ffffff\">For most business that want to overhaul their business</span></p>"
          },
          "price": {
            "type": "tiptap-mod",
            "display": true,
            "resolutionStyle": {
              "sm": {
                "fontSize": "14px",
                "fontFamily": "Proxima Nova",
                "color": "#6E747A",
                "align": "left",
                "padding": {
                  "top": 0,
                  "bottom": 0,
                  "x": 0
                }
              },
              "lg": {
                "fontSize": "19px",
                "fontFamily": "Proxima Nova",
                "color": "#6E747A",
                "align": "left",
                "padding": {
                  "top": 0,
                  "bottom": 0,
                  "x": 0
                },
                "lineHeight": 1
              }
            },
            "text": "<p><strong><span style=\"color:#ffffff\"><spanr style=\"font-size:48px\">36$</spanr> </span></strong><span style=\"color:#dedede\">/ <spanr style=\"font-size:14px\">Month</spanr></span></p>"
          },
          "button": {
            "display": true,
            "hover": false,
            "type": "button-mod",
            "text": "Choose Plan",
            "icon": null,
            "iconPlacement": "append",
            "resolutionStyle": {
              "lg": {
                "color": "#1d1d1d",
                "backgroundColor": '#ffffff',
                "size": "lg",
                "shape": "rounded",
                "btnStyle": "null",
                "align": "center",
                "padding": {
                  "top": 0,
                  "bottom": 0,
                  "x": 0
                },
                "shadow": "null"
              },
              "sm": {
                "color": "#ffffff",
                "backgroundColor": 'colored',
                "size": "lg",
                "shape": "rounded",
                "btnStyle": "outlined",
                "align": "center",
                "padding": {
                  "top": 0,
                  "bottom": 0,
                  "x": 0
                }
              }
            }
          },
          "list": {
            "type": "list-mod",
            "display": true,
            "items": [
              {
                "label": "Item 1",
                "text": "Improved Performance"
              },
              {
                "label": "Item 2",
                "text": "Attention to Detail"
              },
              {
                "label": "Item 3",
                "text": "Customer Support"
              },
              {
                "label": "Item 4",
                "text": "All Deliverables"
              },
              {
                "label": "Item 5",
                "text": "Unlimited Requests"
              }
            ]
          }
        }
      }
    ).json(),
    new PriceCard1(
      {
        customize: {
          cols: {
            sm: 12,
            md: 4,
            lg: 4
          },
          order: {
            sm: 2,
            lg: 2
          },
        },
      }
    ).json()
  ]
).defaultOptions

export const previews = {
  left: require('@/assets/svg/previews/price/left-2.svg'),
  right: null,
  main: require('@/assets/svg/previews/price/main-2.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('EsRow', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
