<template>
  <div :style="{...backgroundImage}" :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
    <div :class="container" :style="{...padding}">
      <b-row class="news-letter-5" :style="{...opacity}">
        <b-col :cols="cols(6, 12)" :order="cols(0, 1)" :class="cols('border rounded', '')">
          <div class="d-flex align-items-center justify-content-center text-content h-100 w-100">
            <b-card :style="{...backgroundColor, ...shadow}" class="border-0">
              <slot name="title"></slot>
              <slot name="subtitle"></slot>
              <div class="my-4"></div>

              <b-row>
                <b-col :cols="cols(8, 12)" :offset="cols(2, 0)" :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
                  <slot name="form"></slot>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>

        <b-col :cols="cols(6, 12)" :order="cols(1, 0)" :class="{'mb-4' : resolution === 'sm'}">
          <div class="text-content">
            <slot name="image"></slot>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "NewsLetterItem4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.news-letter-5 {
  .text-content {
    .card {
      background: #F5F5F5;
      padding-top: 41px;
      padding-bottom: 41px;

      .btn {
        display: block;
        width: 100%;
      }

      p {
        line-height: 1;
      }
    }
  }
}
</style>