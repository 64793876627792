<template>
  <highliter :label="section.name" :dublicate="section" :uid="section.uid" @click.stop.native="sectionSettings('button')"
             v-if="visability('button')">
    <div class="d-block w-100 text-center">
      <es-button :customs="section.options.content.button"></es-button>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin';
import Highliter from '../../../utils/Highliter';
import CustomStylesMixin from '../../../../mixins/CustomStylesMixin';
import {defaultOptions} from "./Section";

export default {
  name: "EsButtonUtil",
  components: {Highliter},
  mixins: [SectionMixin, CustomStylesMixin],

  created() {
    this.merger(defaultOptions)
  }
}
</script>

