<template>
    <TestimonialItem2Template :section="section">
    <template slot="avatar" v-if="visability('image')">
      <es-image :customs="section.options.content.image"></es-image>
    </template>

    <template slot="name" v-if="visability('name')">
        <pub-content-editable
            :customs="section.options.content.name"
            v-model="section.options.content.name.text"></pub-content-editable>
    </template>

    <template slot="text" v-if="visability('text')">
        <pub-content-editable
            :customs="section.options.content.text"
            v-model="section.options.content.text.text"></pub-content-editable>
    </template>
  </TestimonialItem2Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import TestimonialItem2Template from '@pub/templates/default/sections/testimonials/TestimonialItem2Template';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';

  export default {
    name: "FeaturedItems1",

    components: {PubContentEditable, TestimonialItem2Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
