<template>
  <highliter :label="section.name" :dublicate="section" :uid="section.uid" @click.stop.native="sectionSettings('horizontalLine')"
             v-if="visability('horizontalLine')">
    <div :style="{paddingTop: style.padding[resolution].top + 'px', paddingBottom: style.padding[resolution].bottom + 'px'}">
      <div class="headline-divider w-100"
           :style="{height: section.options.content.horizontalLine.width, background: section.options.content.horizontalLine.color, borderRadius: section.options.content.horizontalLine.pilled ? '25rem' : 0}"></div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin';
import Highliter from '../../../utils/Highliter';
import CustomStylesMixin from '../../../../mixins/CustomStylesMixin';
import {defaultOptions} from "./Section";

export default {
  name: "EsButtonUtil",
  components: {Highliter},
  mixins: [SectionMixin, CustomStylesMixin],

  created() {
    this.merger(defaultOptions)
  }
}
</script>

