<template>
  <highliter class="hide-add"
             :label="section.name"
             uid="#footer"
             @click.stop.native="sectionSettings()">
    <Template :section="section">
      <!-- Logo -->
      <template slot="logo">
        <highliter @click.stop.native="sectionSettings('logo')">
          <es-logo :customs="section.options.content.logo"></es-logo>
        </highliter>
      </template>
      <!-- End Logo -->

      <!-- Copyright -->
      <template slot="copyright">
        <highliter @click.stop.native="sectionSettings('copyright')">
          <content-editable
              :uid="tiptapUID('copyright')"
              :customs="section.options.content.copyright"
              v-model="section.options.content.copyright.text"></content-editable>
        </highliter>
      </template>
      <!-- End Copyright -->

      <!-- Menu -->
      <template slot="menu">
        <highliter @click.stop.native="sectionSettings('menu')">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item mx-3" v-for="(link, index) in section.options.content.menu.links" :key="index">
              <b-link :style="section.options.content.menu.style">{{ link.text }}</b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Menu -->

      <!-- Social -->
      <template slot="social">
        <highliter @click.stop.native="sectionSettings('socialLinks')">
          <ul class="list-unstyled list-inline">
            <li v-for="(link, index) in section.options.content.socialLinks.links" :key="index"
                class="list-inline-item mr-3">
              <b-link :class="'social-link social-link-' + link.shape"
                      :style="{...section.options.content.socialLinks.style, ...{color: link.color, backgroundColor: link.background}}">
                <i :class="link.icon"></i>
              </b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Social -->
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from "./Template";

export default {
  name: "Carousel1",

  components: {Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>