import SectionProvider from '@/sections/SectionProvider';

export const name = 'VideoItem1'

export default class VideoItem1 extends SectionProvider {
  constructor(options = {}) {
    super('VideoItem1', options);

    this.name = name
    this.group = ''
    this.stateSafly = false

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"Behind the Curtain Course Updates"}]}]},
        },
        subtitle: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '14px',
              color: '#6D6D6D',
              align: 'center',
              fontFamily: 'Global Style',
            },
            lg: {
              fontSize: '14px',
              color: '#6D6D6D',
              align: 'center',
              fontFamily: 'Global Style',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Alex Antonio"}]}]},
        },
        video: {
          display: true,
          type: 'video-mod',
          placeholder: require('@/assets/images/placeholder-video-1.jpg'),
          src: null,
          position: 'center',
size: 'cover'
        }
      },
      customize: {
        style: {
          background: null,
          padding: {
            type: 'height-customs',
            sm: {
              top: 0,
              bottom: 0
            },
            lg: {
              top: 0,
              bottom: 0
            }
          },
        }
      }
    }
  }
}
