import SectionProvider from '@/sections/SectionProvider';

export const name = 'Navigation'
export const group = 'navbar'

export const defaultOptions = {
  content: {
    logo: {
      type: 'logo-mod',
      display: true,
      fontFamily: 'Global Style',
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#0C226C',
      text: 'AppStarter'
    },
    menuItems: {
      type: 'menu-items-mod',
      display: true,
      color: '#0C226C',
      uppercase: false
    },
    button: {
      type: 'button-mod',
      display: true,
      hover: false,
      text: 'Book Now',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          btnStyle: '',
          shape: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: 'colored',
          size: 'sm',
          btnStyle: 'outlined',
          shape: null,
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {
    display: true,
    layouts: null,
    style: {
      navbarScroller: {
        type: 'navbar-scroller-customs',
        scrollType: null
      },
    }
  }
}

export const previews = {
  left: require('@/assets/svg/previews/price/left-1.svg'),
  right: null,
  main: require('@/assets/svg/previews/navbar/main-1.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Navbar1', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
