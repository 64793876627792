<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">Navbar Layouts</p>
    </div>

    <div class="d-flex justify-content-between flex-wrap">
      <div v-for="section in sections"
           :style="{backgroundImage: `url(${section.previews.left})`}"
           class="section-image"
           :class="{'active': section.name === config.name}"
           @click="section.name !== config.name ? $store.dispatch('sections/changeNavbarLayout', {uid: config.uid, oldConfig: config, layout: section.section}) : null"
      >
      </div>
    </div>
  </div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import allSections from '@/sections';
  import {default as Navbar1, name as Navbar1Name} from "../../../sections/navigations/Navbar1";
  import {default as Navbar2, name as Navbar2Name} from "../../../sections/navigations/Navbar2";

  const sections = [
    {
      previews: {
        left: require('@/assets/svg/previews/navbar/navbar-1.svg')
      },
      name: Navbar1Name,
      section: Navbar1
    },
    {
      previews: {
        left: require('@/assets/svg/previews/navbar/navbar-2.svg')
      },
      name: Navbar2Name,
      section: Navbar2
    },
  ]

  export default {
    name: "LayoutNavbarMode",

    mixins: [ModMixin],

    computed: {
      sections() {
        return sections
      }
    }
  }
</script>

<style lang="scss" scoped>
  .section-image {
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.26));
    width: 130px;
    height: 76px;
    background-position: center;
    border-radius: 3px;
    margin-bottom: 30px;

    &.active {
      box-shadow: 0 0 0 3px #7CACFA;
    }
  }
</style>
