<template>
  <footer :style="{...backgroundColor , ...padding, ...backgroundImage}" class="footer-2 footer-bottom" :class="theme">
    <b-container>
      <b-row>
        <b-col :cols="cols(4, 12)">
          <div v-if="visability('logo')"
               class="mb-3"
          >
            <slot name="logo"></slot>
          </div>

          <template v-if="visability('copyright')">
            <slot name="copyright"></slot>
          </template>
        </b-col>

        <b-col :cols="cols(3, 12)">
          <template v-if="visability('labelMenu1')">
            <slot name="labelMenu1"></slot>
          </template>

          <template v-if="visability('menu1')">
            <slot name="menu-1"></slot>
          </template>
        </b-col>

        <b-col :cols="cols(3, 12)">
          <template v-if="visability('labelMenu2')">
            <slot name="labelMenu2"></slot>
          </template>

          <template v-if="visability('menu2')">
            <slot name="menu-2"></slot>
          </template>
        </b-col>

        <b-col :cols="cols(2, 12)">
          <template v-if="visability('labelDescriotion')">
            <slot name="labelDescriotion"></slot>
          </template>

          <template v-if="visability('description')">
            <slot name="description"></slot>
          </template>
        </b-col>
      </b-row>

      <div v-if="visability('horizontalLine')" class="mt-3 mb-5" :style="{height: section.options.content.horizontalLine.width, background: section.options.content.horizontalLine.color, borderRadius: section.options.content.horizontalLine.pilled ? '25rem' : 0}"></div>

      <b-row>
        <b-col :cols="cols(6, 12)">
          <template v-if="visability('socialLinks')">
            <slot name="social"></slot>
          </template>
        </b-col>

        <b-col v-if="visability('menu3')" :class="cols('text-right', '')" :cols="cols(6, 12)">
          <slot name="menu-3"></slot>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import ThemeMixin from "../../../../mixins/ThemeMixin";
  import {mapGetters} from "vuex";

  export default {
    name: "Carousel1Template",
    mixins: [TemplateMixin, ThemeMixin],
    computed: {
      ...mapGetters('sections', [
        'getSections'
      ]),
    },
    created() {
      if (this.section.options.content.logo.type !== 'logo-mod') {
        const doctorLogoMod = {
          type: 'logo-mod',
          display: true,
          image: {
            src: this.section.options.content.logo.src || this.section.options.content.logo.placeholder
          },
          text: ''
        }

        this.section.options.content.logo = doctorLogoMod
      }
    }
  }
</script>

<style lang="scss">
.footer-2 {
  z-index: 1 !important;

  a,
  .editor__content,
  .pub__content {
    transition: 1s;
  }

  &.light {
    a,
    .editor__content,
    .pub__content {
      color: #000;
    }
  }

  &.dark {
    a,
    .editor__content,
    .pub__content {
      color: #fff;
    }
  }
}
</style>
