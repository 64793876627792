<template>
  <highliter :label="section.name" :dublicate="section" :uid="section.uid" @click.stop.native="sectionSettings('text')"
             v-if="visability('text')">
    <content-editable
        :uid="tiptapUID('text')"
        :customs="section.options.content.text"
        v-model="section.options.content.text.text"></content-editable>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin';
import Highliter from '../../../utils/Highliter';
import CustomStylesMixin from '../../../../mixins/CustomStylesMixin';
import {defaultOptions} from "./Section";

export default {
  name: "EsButtonUtil",
  components: {Highliter},
  mixins: [SectionMixin, CustomStylesMixin],

  created() {
    this.merger(defaultOptions)
  }
}
</script>

