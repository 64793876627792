<template>
  <b-card class="border-0" body-class="border">
    <b-form-group>
      <label>Links to</label>
      <v-select left v-model="form.page" label="name" :reduce="option => option.key" class="custom-select"
                :options="[{key: null, name: 'Not selected'}, ...pages, {key: 'custom-link', name: 'Custom Link'}]"></v-select>
    </b-form-group>

    <b-form-group v-if="form.page === 'custom-link'">
      <label>Custom link</label>
      <b-input type="url" placeholder="https://" v-model="form.customLink"></b-input>
    </b-form-group>

    <b-checkbox v-model="form.target">Open in new tab</b-checkbox>
  </b-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "TiptapLink",

  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters('sections', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
  },

  data() {
    return {
      form: {
        page: null,
        customLink: '',
        target: false
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler: function (val) {
        let link = {
          href: this.form.customLink,
          target: this.form.target ? '__blank' : '__self'
        }

        if (this.form.page !== 'custom-link') {
          link.href = this.form.page
        }

        this.$emit('change', link)
      }
    }
  },

  created() {
    if (this.value) {
      if (!this.pages.filter(p => p.key === this.value.href).length) {
        this.form.page = 'custom-link'
        this.form.customLink = this.value.href
      } else if (this.value.href) {
        this.form.page = this.value.href
      }

      this.form.target = this.value.target === '__blank' ? true : false
    }
  }
}
</script>