<template>
  <div>
    <module-header title="Blog Module"></module-header>

    <!-- Blog -->
    <b-container>
      <b-row>
        <b-col md="8">
          <b-form-group>
            <b-input-group>
              <b-input v-model="search" type="search" autocomplete="false" placeholder="Search a post"></b-input>

              <template slot="append">
                <b-btn variant="outline-primary" @click="fetchBlog">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">Search</div>
                    <i class="fa fa-search fa-sm"></i>
                  </div>
                </b-btn>
                <b-btn v-if="search" variant="outline-danger" @click="reset">
                  <div class="d-flex align-items-center">
                    <div class="mr-2">Reset</div>
                  </div>
                </b-btn>
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="1">
        </b-col>

        <b-col class="text-right">
          <b-btn variant="primary" v-b-modal.blog-modal>
            <div class="d-flex align-items-center">
              <div class="mr-2">Add new post</div>
              <i class="fa fa-plus fa-sm"></i>
            </div>
          </b-btn>
        </b-col>
      </b-row>

      <div class="py-5">
        <div v-if="!loading">
          <div v-if="blog.total !== 0">
            <b-card
                v-for="(post, index) in blog.data"
                :key="post.id"
                class="mb-3">
              <small class="d-block mb-3">{{ post.created_at | moment("from", "now") }}</small>
              <b-card-title v-text="post.title"></b-card-title>
              <b-card-text class="text-truncate">{{ post.description | strippedContent }}</b-card-text>

              <div class="d-flex align-items-center justify-content-between">
                <b-btn :href="`${getPageBlankURL}/blog/${post.slug}`" target="_blank">
                  View <i class="fa fa-external-link-alt fa-sm ml-1"></i>
                </b-btn>

                <div>
                  <b-btn class="mr-2" @click="edit(post)">
                    Edit <i class="fa fa-edit fa-sm ml-1"></i>
                  </b-btn>
                  <b-btn variant="danger" @click="deletePost(post, index)">
                    Delete <i class="fa fa-trash fa-sm ml-1"></i>
                  </b-btn>
                </div>
              </div>
            </b-card>

            <b-pagination
                v-if="blog.total > blog.per_page"
                v-model="currentPage"
                :per-page="blog.per_page"
                :total-rows="blog.total"
            ></b-pagination>
          </div>
          <div v-else>
            <p class="text-muted text-center">You have not published any posts yet</p>
          </div>
        </div>
        <div v-else class="d-flex align-items-center justify-content-center py-5">
          <b-spinner variant="primary" lareg></b-spinner>
        </div>
      </div>
    </b-container>
    <!-- End Blog -->

    <!-- Create Post Modal -->
    <new-post-modal @addedPost="fetchBlog()"/>
    <!-- End Create Post Modal -->
  </div>
</template>

<script>
import {mapState} from "vuex";
import OveralSettingsMixin from "../../components/mixins/OveralSettingsMixin";
import ImageFit from "../../components/utils/ImageFit";
import Section from "@/components/builder/sections/blog/blog-container/Section";
import NewPostModal from "../../components/editor/modals/NewPostModal";
import ModuleHeader from "../../components/editor/header/ModuleHeader";
import RouterMixin from "../../components/mixins/RouterMixin";

export default {
  name: "EsBlog",
  components: {ModuleHeader, NewPostModal, ImageFit},
  mixins: [OveralSettingsMixin, RouterMixin],

  data() {
    return {
      search: '',
      loading: true,
      blog: {},
      currentPage: 1
    }
  },

  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/ig, " ");
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
  },

  methods: {
    async deletePost(post, index) {
      this.loading = true
      await axios.delete(`api/projects/${this.project.id}/blog/manage/${post.id}`)
          .then(() => {
            this.blog.data.splice(index, 0)
            this.$bvToast.toast(`Post deleted`, {
              title: 'Success',
              autoHideDelay: 5000,
              appendToast: false
            })
          })
          .finally(() => {
            this.loading = false
          })
    },
    reset() {
      this.search = ''
      this.currentPage = 1
      this.fetchBlog()
    },
    async fetchBlog() {
      this.loading = true

      await axios.get(`api/projects/${this.project.id}/blog?page=${this.currentPage}&q=${this.search}`)
          .then(({data}) => {
            this.blog = data.data
          })
          .finally(() => {
            this.loading = false
          })
    },
    edit(post) {
      VEvent.fire('blog.post-edit', post)
    }
  },

  watch: {
    currentPage() {
      this.fetchBlog()
    }
  },

  created() {
    this.fetchBlog()
  }
}
</script>

<style>
.page-item.active .page-link {
  height: 100%;
}
</style>