<template>
  <div>
    <div class="h6">Title</div>
<!--    <blog-text-mod :config=""></blog-text-mod>-->
    <hr>

    <div class="h6">Description</div>

    <hr>

    <div class="h6">Date</div>

    <hr>

    <div class="h6">Button</div>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin';
import ThemeMixin from '@/components/mixins/ThemeMixin';
import {mapGetters, mapState} from 'vuex';
import NewPostModal from "../modals/NewPostModal";
import BlogTextMod from "./BlogTextMod";

export default {
  name: "ButtonMod",
  components: {BlogTextMod, NewPostModal},
  mixins: [ModMixin, ThemeMixin],

  data() {
    return {

    }
  }
}
</script>
