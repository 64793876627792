<template>
  <b-media vertical-align="center" :class="cols('', 'd-block')" class="align-items-center px-3" :style="{...opacity, ...shadow, ...backgroundColor, ...padding}">
    <template v-if="aligment('right')">
      <template v-if="visability('image')" slot="aside">
        <slot name="image"></slot>
      </template>

      <div class="ml-3">
        <template v-if="visability('title')">
          <slot name="title"></slot>
        </template>
        <template v-if="visability('description')">
          <slot name="description"></slot>
        </template>
        <template>
          <slot name="button"></slot>
        </template>
      </div>
    </template>

    <template v-else>
      <template slot="aside">
       <div>
         <template v-if="visability('title')">
           <slot name="title"></slot>
         </template>
         <template v-if="visability('description')">
           <slot name="description"></slot>
         </template>
         <slot name="button"></slot>
       </div>
      </template>

      <div v-if="visability('image')" class="ml-3">
        <slot name="image"></slot>
      </div>
    </template>
  </b-media>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import ThemeMixin from "@/components/mixins/ThemeMixin";
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1Template",
    components: {ImageFit},
    mixins: [TemplateMixin, ThemeMixin]
  }
</script>
