import SectionProvider from '@/sections/SectionProvider';

export const name = 'Option 2'
export const group = 'option-forms'
export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '44px',
          fontFamily: 'Global Style',
          color: '#fff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '54px',
          fontFamily: 'Global Style',
          color: '#fff',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Get a Free</strong></p><p><strong>30-Minute Strategy</strong></p><p><strong>Call With Me</strong></p>"
    },
    text: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '21px',
          fontFamily: 'Global Style',
          lineHeight: 1.3,
          color: '#FFFFFF',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '21px',
          fontFamily: 'Global Style',
          lineHeight: 1.4,
          color: '#FFFFFF',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Curabitur tristique, lorem et dignissim consequat.</p>"
    },
    image: {
      display: true,
      type: 'image-mod',
      size: 'cover',
      position: 'left',
      placeholder: require('@/assets/svg/previews/options/placeholder-2.png'),
      src: null
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Book Now!',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    form: {
      type: 'form-mod',
      display: true,
      action: null,
      selectedList: '',
      btnPosition: 'append-email',
      fields: [
        {
          display: true,
          type: 'text',
          name: 'name',
          placeholder: 'Enter your first name',
          required: true,
          label: null,
          cols: {
            sm: 12,
            lg: 4,
          }
        },
        {
          display: true,
          type: 'email',
          name: 'email',
          placeholder: 'Enter your email address',
          required: true,
          label: null,
          cols: {
            sm: 12,
            lg: 7,
          }
        }
      ]
    },
    formLabel: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '17px',
          fontFamily: 'Global Style',
          color: '#FFFFFF',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '17px',
          fontFamily: 'Global Style',
          color: '#FFFFFF',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Enter Your Details:</p>"
    },
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: '#fff'
      },
      backgroundForm: {
        background: '#1F3B9C',
        type: 'background-customs',
        label: 'Form Background'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 30,
          bottom: 30
        },
        lg: {
          top: 50,
          bottom: 50
        }
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: require('@/assets/svg/previews/options/placeholder-2-1.png'),
        position: 'center',
        size: 'cover',
        opacity: 1
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/options/main-2.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Option2', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
