import SectionProvider from '@/sections/SectionProvider';

export const name = 'Featured Items 1'

export default class FeaturedItems1 extends SectionProvider {
  constructor(options = {}) {
    super('FeaturedItems1', options);

    this.name = name
    this.group = 'featured'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '20px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
              padding: {
                top: 20,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '38px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "Pre-headline Here"}]
            }]
          },
        },
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '18px',
              color: '#6D6D6D',
              align: 'left',
              fontFamily: 'Global Style',
              lineHeight: 1.6,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              color: '#6D6D6D',
              align: 'left',
              fontFamily: 'Global Style',
              lineHeight: 1.6,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{
                "type": "text",
                "text": "Curabitur tristique, lorem et dignissim consequat, nisi nisi tincidunt mauris, ac dictum neque odio eget elit. In sit amet malesuada risus. Integer lacinia, nisl quis condimentum tristique, eros nisl maximus purus, eget congue felis tellus id metus. Nulla egestas turpis at pharetra interdum."
              }]
            }]
          },
        },
        image: {
          display: true,
          type: 'image-mod',
          size: 'cover',
          position: 'center',
          placeholder: require('@/assets/images/placeholder-featured-1.jpg'),
          src: null
        },
        button: {
          display: false,
          hover: false,
          type: 'button-mod',
          text: 'Click me',
          resolutionStyle: {
            lg: {
              size: 'md',
              btnStyle: '',
              color: '#ffffff',
              backgroundColor: 'colored',
              shape: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              size: 'md',
              btnStyle: '',
              color: '#ffffff',
              backgroundColor: 'colored',
              shape: null,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        style: {
          container: {
            type: 'container-customs',
            container: null
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
            },
            lg: {
              top: 115,
              bottom: 115
            }
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          aligment: {
            type: 'aligment-customs',
            align: 'left',
            images: {
              left: require('@/assets/svg/previews/featured-items-1-left.png'),
              right: require('@/assets/svg/previews/featured-items-1-right.png'),
            }
          }
        }
      }
    }
  }
}
