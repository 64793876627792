import SectionProvider from '@/sections/SectionProvider';

export const name = 'Carousel'
export const group = 'Carousel'

export const defaultOptions = {
  content: {
    images: {
      display: true,
      type: 'slider-mod',
      atuoplay: false,
      centerMode: true,
      pauseOnHover: false,
      centerPadding: 20,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: {
        color: null
      },
      items: [
        {
          placeholder: require('@/assets/svg/placeholder-image.svg'),
          src: require('@/assets/svg/previews/carousel/image-1.png'),
          position: 'center',
          size: 'cover',
        },
        {
          placeholder: require('@/assets/svg/placeholder-image.svg'),
          src: require('@/assets/svg/previews/carousel/image-2.png'),
          position: 'center',
          size: 'cover',
        },
        {
          placeholder: require('@/assets/svg/placeholder-image.svg'),
          src: require('@/assets/svg/previews/carousel/image-3.png'),
          position: 'center',
          size: 'cover',
        },
        {
          placeholder: require('@/assets/svg/placeholder-image.svg'),
          src: require('@/assets/svg/previews/carousel/image-2.png'),
          position: 'center',
          size: 'cover',
        }
      ]
    }
  },
  customize: {
    style: {
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
        size: 'cover',
        opacity: 1
      },
      shadow: null,
      opacity: null,
      padding: {
        type: 'height-customs',
        sm: {
          top: 40,
          bottom: 40
        },
        lg: {
          top: 50,
          bottom: 50
        }
      }
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/carousel/main-3.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Carousel1', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
