<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">Logo</p>

      <b-checkbox switch v-model="config.display"></b-checkbox>
    </div>

    <image-upload :image="config.image" class="w-100 mb-3"></image-upload>

    <b-form-group v-if="config.image.src">
      <b-form-group label="Size">
        <v-select left :reduce="option => option.key" v-model="config.image.logoSize" class="custom-select" :options="logoSizeOptions"></v-select>
      </b-form-group>
    </b-form-group>

    <b-form-group v-else>
      <b-input placeholder="Logo text" v-model="config.text"></b-input>
    </b-form-group>

    <b-row no-gutters class="align-items-end">
      <b-col cols="6" class="mr-2">
        <b-form-group label="Font">
          <v-select left v-model="config.fontFamily" class="custom-select" :options="fonts"></v-select>
        </b-form-group>
      </b-col>

      <b-col cols="3" class="mr-2">
        <b-form-group label="Size">
          <v-select left v-model="config.fontSize" :reduce="option => option.key" class="custom-select" :options="fontSizeOptions"></v-select>
        </b-form-group>
      </b-col>

      <b-col cols="1">
        <b-form-group>
          <text-color v-model="config.color"></text-color>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import ImageUpload from "../utils/ImageUpload";
  import TextColor from "../controls/TextColor";

  export default {
    name: "LogoMod",
    components: {TextColor, ImageUpload},
    mixins: [ModMixin],

    data() {
      return {
        fonts: [
          'Global Style',
          'Roboto',
          'Montserrat',
          'Georgia',
          'Playfair Display',
          'Raleway',
          'Spectral',
          'Rubik',
        ],
        logoSizeOptions: [
          {
            key: "30px",
            label: "Small"
          },
          {
            key: "40px",
            label: "Default"
          },
          {
            key: "60px",
            label: "Large"
          }
        ],
        fontSizeOptions: [
          {
            key: "18px",
            label: "Small"
          },
          {
            key: "24px",
            label: "Default"
          },
          {
            key: "31px",
            label: "Large"
          }
        ]
      }
    },

    created() {
      if (!this.config.hasOwnProperty('image')) {
        this.$set(this.config, 'image', {
          src: null
        })
      }
    }
  }
</script>
