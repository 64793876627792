<template>
  <div>
    <dashboard-header></dashboard-header>

    <div class="mb-3">New project</div>

    <b-row class="mb-4">
      <b-col lg="3" md="5" class="mb-4">
        <div>
          <div class="new-blank mb-2" @click="openModal(1)">
            Blank
          </div>

          <div class="site-title">Blank Site</div>
          <div class="site-sub-title">Free</div>
        </div>
      </b-col>
    </b-row>

    <div class="mb-3">Premium Templates</div>

    <b-row>
      <b-col lg="3" md="5" class="mb-4">
        <div>
          <div class="new-blank mb-2" @click="openModal(3)">
            <img height="240px" src="@/assets/images/templates/demo-template.png">
          </div>

          <div class="site-title">Full Personal Brand Money Site</div>
          <div class="site-sub-title">$495.00</div>
        </div>
      </b-col>
    </b-row>

    <b-modal class="position-relative" v-model="modal" body-class="py-0" content-class="new-section-modal" size="lg"
             hide-footer hide-header>
      <icon class="close-icon cursor-pinter ml-3" icon="close.svg" @click.native="modal = false"></icon>

      <b-form class="my-5" @submit.prevent="createProject">
        <b-row>
          <b-col lg="8" offset-lg="2">
            <h3 class="mb-4 text-center mt-4 mr-5">Name Your Project</h3>

            <b-form-group label="Enter Project Name" class="mb-4">
              <b-input required v-model="projectName"></b-input>
            </b-form-group>

            <b-form-group label="Sub Domain" class="mb-4">
              <b-input required v-model="subdomain"></b-input>
              <span class="text-danger" v-if="errors.hasOwnProperty('subdomain')">{{errors.subdomain[0]}}</span>
            </b-form-group>

            <b-form-group class="text-center">
              <b-btn class="btn-wide" variant="primary" type="submit">Get Started</b-btn>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DashboardHeader from "../../layouts/dashboard/DashboardHeader";

export default {
  name: "Dashboard",
  components: {DashboardHeader},
  computed: {
    ...mapState('projects', {
      projects: state => state.projects
    })
  },

  data() {
    return {
      errors: [],
      modal: false,
      projectName: '',
      subdomain: 'my-web-' + new Date().getTime(),
      temp: null
    }
  },

  methods: {
    openModal(temp) {
      this.temp = temp
      this.modal = true
    },
    async createProject() {
      VEvent.fire('loader', true)

      await this.$store.dispatch('projects/create', {
        temp: this.temp,
        name: this.projectName,
        subdomain: this.subdomain
      })
          .then(() => {
            this.modal = false
          })
          .catch(error => {
            // this.errors= error.response.data.errors
          })
          .finally(() => {
            VEvent.fire('loader', false)
          })
    },
    async loadProject(id) {
      VEvent.fire('loader', true)

      await this.$store.dispatch('projects/load', id)
          .then(() => {
            this.$router.push('builder/' + id)
          })
          .finally(() => {
            VEvent.fire('loader', false)
          })
    }
  },

  async created() {
    VEvent.fire('loader', true)

    await this.$store.dispatch('projects/projects')
        .finally(() => {
          VEvent.fire('loader', false)
        })
  }
}
</script>

<style lang="scss">
.site-title {
  font-size: 15px;
  color: #44474a;
}

.site-sub-title {
  font-size: 14px;
  color: #6e747a;
}

.new-blank {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 54px;
  text-align: center;
  color: #b8bec8;
  text-transform: uppercase;
  width: 292px;
  height: 270px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #e2e5ec;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);
  cursor: pointer;
}
</style>
