<template>
  <div class="mb-5">
    <label class="mb-2">Background Image</label>
    <image-upload :image="config" class="w-100 mb-3"></image-upload>
  </div>
</template>

<script>
  import SliederToggle from '@/components/editor/controls/SliederToggle';
  import Panel from '../controls/Panel';
  import ControlMixin from '../../mixins/ControlMixin';
  import ImageUpload from "../utils/ImageUpload";

  export default {
    name: "BackroundImage",
    components: {ImageUpload, Panel, SliederToggle},
    mixins: [ControlMixin],

    data() {
      return {
        file: [],
        url: ''
      }
    },

    watch: {
      file() {
        // this.url = URL.createObjectURL(this.file)
        // this.config.src= this.url

        let formData = new FormData();

        formData.append("file", this.file);

        axios.post('api/media', formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
          .then(({data}) => {
            this.config.src = data.data.url
            this.config.id = data.data.id
          })
      }
    }
  }
</script>

<style lang="scss">
  .btn-es-image-upload {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #E2E5EC;
    font-size: 14px;
    font-weight: 600;
    color: #6E747A;
    height: 38px;
    box-shadow: 0 1px 2px 0 rgba(226,226,226,0.5)
  }

  .dropdown-toggle {
    background: none !important;
    padding: 0;
    border: none !important;
    margin-left: auto;

    &:after {
      display: none;
    }
  }
</style>
