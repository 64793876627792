import SectionProvider from '@/sections/SectionProvider';

export const name = 'Footer 2'
export const group = 'Footer'

export const defaultOptions = {
  content: {
    logo: {
      display: true,
      type: 'logo-mod',
      image: {
        src: require('@/assets/svg/previews/footer/placeholder-2.svg')
      },
      fontFamily: 'Global Style',
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#0C226C',
      text: 'FourPercent'
    },
    copyright: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '14px',
          color: 'adaptive',
          align: 'center',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '14px',
          color: 'adaptive',
          align: 'right',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p>© 2020 YourSite. All rights reserved.</p>"
    },
    labelMenu1: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p><strong>Quick Links</strong></p>"
    },
    menu1: {
      display: true,
      type: 'menu-links-mod',
      style: {
        fontSize: '15px',
        color: 'adaptive',
        fontWeight: 'default',
        lineHeight: 2,
      },
      links: [
        {
          href: '#',
          text: 'About',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Work with Me',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Resources',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Blog',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Contact',
          customLink: false,
          blank: false
        }
      ]
    },
    labelMenu2: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p><strong>Products</strong></p>"
    },
    menu2: {
      display: true,
      type: 'menu-links-mod',
      style: {
        fontSize: '15px',
        color: 'adaptive',
        fontWeight: 'default',
        lineHeight: 2,
      },
      links: [
        {
          href: '#',
          text: 'Products / Training',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Videos',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Live Streaming',
          customLink: false,
          blank: false
        }
      ]
    },
    labelMenu3: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'center',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '18px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p><strong>Stay up to date</strong></p>"
    },
    formDescription: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '14px',
          color: 'adaptive',
          align: 'center',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '14px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p>We Never spam.</p>"
    },
    form: {
      type: 'form-mod',
      display: true,
      action: null,
      selectedList: '',
      btnPosition: 'append-email',
      fields: [
        {
          display: true,
          type: 'email',
          name: 'email',
          placeholder: 'Email address',
          required: true,
          label: null,
          cols: {
            sm: 12,
            lg: 12
          }
        }
      ]
    },
    button: {
      type: 'button-mod',
      submit: true,
      display: true,
      hover: false,
      text: 'Go',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: '#1F3B9C',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: 'adaptive',
          backgroundColor: '#1F3B9C',
          size: 'md',
          shape: null,
          btnStyle: '',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: '#F2F2F2'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
        size: 'cover',
        opacity: 1
      },
      shadow: null,
      opacity: null,
      padding: {
        type: 'height-customs',
        sm: {
          top: 40,
          bottom: 40
        },
        lg: {
          top: 60,
          bottom: 60
        }
      }
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/footer/main-2.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Footer1', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
