<template>
  <div class="option-1" :style="{...backgroundImage, ...padding, ...backgroundColor, ...opacity, ...shadow}">
    <div :class="container">
      <b-row>
        <b-col :class="cols('', 'mb-4')" :cols="cols(4, 12)">
          <template v-show="visability('image')">
            <slot name="image"></slot>
          </template>
        </b-col>

        <b-col :cols="cols(8, 12)">
          <template v-show="visability('title')">
            <slot name="title"></slot>
          </template>

          <template v-show="visability('text')">
            <slot name="text"></slot>
          </template>

          <div :class="cols('mb-5', 'mb-2')"></div>

          <div v-show="visability('form')"  :class="'form-' + (section.options.content.button.resolutionStyle['lg'].shape || globalStyles.buttons.shape)">
            <slot name="form"></slot>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.resolution-sm {
  .option-1 {
    .btn {
      display: block;
      width: 100%;
    }
  }
}
</style>