<template>
  <highliter :label="section.name" :dublicate="section" :uid="section.uid" @click.stop.native="sectionSettings()">
    <HeroItem1Template :section="section">
      <template slot="image">
        <highliter @click.stop.native="sectionSettings('image')">
          <es-image :customs="section.options.content.image"></es-image>
        </highliter>
      </template>

      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>

      <template slot="button">
        <highliter autoWidth
                   @click.stop.native="sectionSettings('button')">
          <es-button :customs="section.options.content.button"></es-button>
        </highliter>
      </template>

      <template slot="button2">
        <highliter autoWidth
                   @click.stop.native="sectionSettings('button2')">
          <es-button :customs="section.options.content.button2"></es-button>
        </highliter>
      </template>
    </HeroItem1Template>
  </highliter>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import HeroItem1Template from '@pub/templates/default/sections/hero/HeroItem1Template';

export default {
  name: "HeroItem1",

  components: {HeroItem1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
