var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center min-vh-100"},[_c('b-spinner',{attrs:{"large":""}})],1):_c('b-container',{staticClass:"min-vh-100 py-5"},[_c('div',{staticClass:"text-center mb-5"},[_c('h1',{style:({color: _vm.globalStyles.colors.primary})},[_c('b',[_vm._v(_vm._s(_vm.post.title))])]),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(_vm.post.author)?_c('div',[_vm._v("Posted by "),_c('span',{style:({color: _vm.globalStyles.colors.primary})},[_vm._v(_vm._s(_vm.post.author))])]):_vm._e(),_vm._v(" "),(_vm.post.author)?_c('span',{staticClass:"mx-2"},[_vm._v("|")]):_vm._e(),_vm._v(" "),_c('time',[_vm._v(_vm._s(_vm._f("moment")(_vm.post.created_at,"from", "now")))])])]),(!_vm.post.video)?_c('image-fit',{staticClass:"mb-5",attrs:{"customs":{},"src":JSON.parse(_vm.post.cover).src,"height":"40vh","size":"contain"}}):_c('video-play',{staticClass:"mb-5",attrs:{"customs":{
    display: true,
    placeholder: JSON.parse(_vm.post.cover).src,
    src: _vm.post.video,
    height: '40vh',
    position: 'center',
    size: 'cover'
  }}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.post.description)}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }