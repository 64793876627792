<template>
  <ImageItem4Template :section="section">
    <b-img :src="section.options.content.image.src || section.options.content.image.placeholder"></b-img>
  </ImageItem4Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from "../../../builder/utils/PubContentEditable";
import ImageItem4Template from '@pub/templates/default/sections/images/ImageItem4Template';

export default {
  name: "ImageItem3",

  components: {ImageItem4Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
