<template>
  <AboutUs2Template :section="section">
    <template slot="description-1">
        <pub-content-editable
            :uid="tiptapUID('description')"
            :customs="section.options.content.description"
            v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="description-2">
        <pub-content-editable
            :uid="tiptapUID('description2')"
            :customs="section.options.content.description2"
            v-model="section.options.content.description2.text"></pub-content-editable>
    </template>
  </AboutUs2Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ActionItem1Template from '@pub/templates/default/sections/actions/ActionItem1Template';
import PubContentEditable from '@/components/builder/utils/PubContentEditable';
import EsImage from "@/components/builder/utils/EsImage";
import AboutUs2Template from "./AboutUs2Template";
import ImageItem1Template from '@pub/templates/default/sections/images/ImageItem1Template'

export default {
  name: "FeaturedItems1",

  components: {
    AboutUs2Template,
    EsImage, PubContentEditable, ActionItem1Template, ContentEditable, Highliter,
    ImageItem1Template
  },

  mixins: [SectionMixin],
}
</script>
