<template>
  <div :style="{...backgroundColor, ...shadow, ...backgroundImage, ...padding}">
    <div class="headline-item-4" :class="container">
      <div class="leading">
        <slot name="title"></slot>
      </div>

      <div class="my-5"></div>

      <slot name="subtitle"></slot>

      <div class="my-5"></div>

      <div class="text-center">
        <slot name="text"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "HeadlineItemTemplate4",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
  .headline-item-4 {
    .leading {
      text-transform: uppercase;

    }

    p {
      line-height: 1;
    }
  }
</style>