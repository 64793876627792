<template>
  <div :style="{...backgroundImage, ...backgroundColor}">
    <div :class="container" class="time-item-2" :style="{...opacity, ...shadow, ...color, ...padding}">
      <slot name="time" v-bind="{color: colorCustom('Subtitle')}"></slot>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import ThemeMixin from "../../../../mixins/ThemeMixin";

  export default {
    name: "Carousel1Template",
    mixins: [TemplateMixin, ThemeMixin]
  }
</script>

<style lang="scss">
.time-item-2 {
  .timer-block {
    .timer-item {
      .time {
        position: relative;
        width: 120px;
        display: block;
        font-weight: 700;
        font-size: 94px;
        text-align: center;
        line-height: 1;
      }

      .time-divider {
        margin-right: 30px;
        margin-left: 30px;
        display: block;
        font-weight: 500;
        font-size: 94px;
        text-align: center;
      }

      .time-divider + .subtitle {
        opacity: 0;
      }

      .subtitle {
        text-align: center;
        font-weight: 700;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
  }
}

.resolution-sm {
  .time-item-2 {
    .timer-block {
      .timer-item {
        .time {
          font-size: 40px;
          width: auto;
        }

        .time-divider {
          font-size: 30px;
          margin-right: 10px;
          margin-left: 10px;
        }

        .subtitle {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
