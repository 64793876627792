<template>
  <div class="images-mod">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">{{labelCase(componentName)}}</p>

      <b-checkbox switch size="lg" v-model="config.display"></b-checkbox>
    </div>

    <b-form-group>
      <b-form-group v-for="(image, index) in config.items" :key="index" class="border-bottom">
        <div class="d-flex align-items-center mb-4">
          <image-upload :image="image" :class="{'active': getActiveImage === index}" class="w-100 mb-2" @uploaded="$store.dispatch('editor/sectionSettings', {section: section, openedItem: {item: 'image', index}})">
            <template slot="prepend">
              <b-input-group-text class="num">{{index + 1}}</b-input-group-text>
            </template>
          </image-upload>
        </div>
      </b-form-group>
    </b-form-group>

    <span class="d-block section-subtitle mb-2">Opacity</span>
    <b-input type="range" min="0" step="0.1" max="1.0" v-model="config.items[getActiveImage]['opacity']"></b-input>
  </div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import SliederToggle from '@/components/editor/controls/SliederToggle';
  import ImageUpload from "../utils/ImageUpload";

  export default {
    name: "ImagesMod",
    components: {ImageUpload, SliederToggle},
    mixins: [ModMixin],
    computed: {
      getActiveImage() {
        if (this.getOpenedItemObj) {
          return this.getOpenedItemObj.index
        }

        return 0
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/scss/main";

  .images-mod {
    .selected {
      .num {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .active {
    .btn-es-image-upload {
      box-shadow: 0 0 0 2px $primary !important;
    }

    .num {
      box-shadow: 0 0 0 2px $primary;
      color: #fff;
      background: $primary;
      border-color: $primary;
    }
  }

  .num {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #E2E5EC;
    background: #fff;
    font-size: 16px;
    color: #BEC3CE;
  }
</style>
