<template>
  <QuoteItem2Template :section="section">
    <template slot="description">
      <content-editable
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></content-editable>
    </template>

    <template slot="signature">
      <content-editable
          :customs="section.options.content.signature"
          v-model="section.options.content.signature.text"></content-editable>
    </template>
  </QuoteItem2Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from "../../../builder/utils/PubContentEditable";
import QuoteItem2Template from '@pub/templates/default/sections/quotes/QuoteItem2Template';

export default {
  name: "ImageItem3",

  components: {QuoteItem2Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
