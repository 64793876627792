<template>
  <div>
    <div class="d-flex flex-wrap">
      <div class="text-center mb-3" :id="target">
        <div class="d-flex align-items-center grid-control shadow rounded cursor-pinter">
          <div class="row-controls">
            <div class="control-col" :style="{width: col * 8.3 + '%'}"></div>
          </div>
        </div>
        <span class="small">({{ col }} column{{col > 1 ? 's' : ''}})</span>
      </div>
    </div>

    <b-popover custom-class="grid-popover" :target="target" triggers="hover" placement="top" style="width: 400px;">
      <div class="d-flex flex-wrap" style="width: 400px;">
        <div v-for="n in 12" :key="n" class="text-center mb-3">
          <div class="d-flex align-items-center grid-control shadow rounded cursor-pinter mx-3" :class="{'active': n === col}" @click="col = n">
            <div class="row-controls">
              <div class="control-col" :style="{width: n * 8.3 + '%'}"></div>
            </div>
          </div>
          <span class="small">({{ n }} column{{n > 1 ? 's' : ''}})</span>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "GridControl",

  props: {
    value: {
      type: Number,
      required: true
    },
    target: {
      type: String,
      required: true
    }
  },

  computed: {
    col: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.row-controls {
  width: 100%;
  background: #eaeaea;
}

.control-col {
  height: 15px;
  background: #c4c4c4;
  box-shadow: 0 0 0 2px #fff;
}

.grid-control {
  height: 50px;
  width: 100px;
  padding: 4px;

  &.active {
    box-shadow: 0 0 0 2px $primary !important;
  }
}
</style>
