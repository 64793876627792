import Vue from 'vue'
import Vuex from 'vuex'
import editor from '@/store/modules/editor'
import sections from '@/store/modules/sections'
import router from '@/store/modules/router'
import auth from '@/store/modules/auth'
import projects from '@/store/modules/projects'
import apiIntegration from '@/store/modules/api-integration'
import explorer from '@/store/modules/explorer'
import filter from '@/store/modules/filter'
import blog from '@/store/modules/blog'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    editor,
    sections,
    router,
    auth,
    projects,
    apiIntegration,
    explorer,
    filter,
    blog
  }
})
