import SectionProvider from '@/sections/SectionProvider';

export const name = 'Testimonial Item'

export default class TestimonialItem1 extends SectionProvider {
  constructor(options = {}) {
    super('TestimonialItem1', options);

    this.name = name
    this.group = 'testimonials'

    this.defaultOptions = {
      content: {
        name: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
            },
            lg: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: 'adaptive',
              align: 'left',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"JOHN THOMAS"}]}]},
        },
        subname: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: '#6E747A',
              align: 'left',
            },
            lg: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: 'adaptive',
              align: 'left',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Marketing Mix CEO"}]}]},
        },
        text: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#0C226C',
              align: 'center',
            },
            lg: {
              fontSize: '18px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: 'adaptive',
              align: 'center',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit. Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit."}]}]},
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/images/placeholder-testemonialitem-1.png'),
          src: null,
          position: 'center',
size: 'cover'
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'JOIN NOW',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'lg',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: '#44474A',
              size: 'lg',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        style: {
          background: {
            background: '#ffffff'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 0,
              bottom: 0
           },
           lg: {
              top: 0,
              bottom: 0
           }
          }
        }
      }
    }
  }
}
