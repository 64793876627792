<template>
  <b-modal v-model="newLinkDialog" size="lg" hide-footer hide-header>
    <icon class="cursor-pinter ml-3 mt-3" icon="close.svg" @click.native="newLinkDialog = false"></icon>

    <h4 class="h2 text-center my-4">Add Menu Item</h4>

    <b-form @submit.prevent="saveLink">
      <b-row class="pb-5">
        <b-col cols="8" offset="2">
          <div class="w-100">

            <b-form-group label="Name">
              <b-input v-model="name" required></b-input>

              <b-checkbox v-model="isCustomLink" class="mt-2">Custom Link</b-checkbox>
            </b-form-group>

            <b-form-group v-if="!isCustomLink" label="Page">
              <v-select class="custom-select" v-model="page"
                        :options="[{name: 'Create new page', key: null}, ...pages]"
                        label="name" :reduce="option => option.key"
                        :searchable="false">
                <option value="null" selected>Create new page</option>
              </v-select>
            </b-form-group>

            <b-form-group v-else label="Custom Link">
              <b-input v-model="custom_link" required type="url"></b-input>
            </b-form-group>

            <div class="d-flex">
              <b-btn class="btn-wide mx-auto" variant="primary" type="submit">Add Item</b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NewLinkModal",
  data() {
    return {
      newLinkDialog: false,
      name: null,
      page: null,
      custom_link: null,
      isCustomLink: false,
      activeItem: null
    }
  },
  computed: {
    ...mapGetters('sections', {
      pages: 'getPages'
    })
  },
  methods: {
    saveLink() {
      this.$store.dispatch('sections/addLink', {
        name: this.name,
        key: this.page,
        path: this.custom_link,
        activeItem: this.activeItem
      })

      this.name = ''
      this.custom_link = ''
      this.newLinkDialog = false
    }
  },
  mounted() {
    VEvent.listen('newPageDialog', (activeItem) => {
      this.activeItem = activeItem
      this.newLinkDialog = true
    })
  }
}
</script>

<style scoped>

</style>
