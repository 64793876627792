<template>
    <HeroItem1Template :section="section">
      <template slot="image">
          <es-image :customs="section.options.content.image"></es-image>
      </template>

      <template slot="title">
          <pub-content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="description">
          <pub-content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></pub-content-editable>
      </template>

      <template slot="button">
          <pub-es-button :customs="section.options.content.button"></pub-es-button>
      </template>

      <template slot="button2">
          <pub-es-button :customs="section.options.content.button2"></pub-es-button>
      </template>
    </HeroItem1Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import HeroItem1Template from '@pub/templates/default/sections/hero/HeroItem1Template';

export default {
  name: "HeroItem1",

  components: {HeroItem1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
