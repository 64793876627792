<template>
  <div :class="options.classes" class="es-text-component">
    <content-editable v-model="options.text"></content-editable>
  </div>
</template>

<script>
  import ComponentProvider from '@/components/mixins/ComponentProvider'
  import ContentEditable from '@/components/builder/utils/ContentEditable';

  export default {
    name: "EsText",

    mixins: [ComponentProvider],

    components: {
      ContentEditable
    }
  }
</script>

<style>
  .menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s, visibility .2s;
    transition: opacity .2s, visibility .2s
  }

  .menububble.is-active {
    opacity: 1;
    visibility: visible
  }

  .menububble__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer
  }

  .menububble__button:last-child {
    margin-right: 0
  }

  .menububble__button:hover {
    background-color: hsla(0, 0%, 100%, .1)
  }

  .menububble__button.is-active {
    background-color: hsla(0, 0%, 100%, .2)
  }

  .menububble__form {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
  }

  .menububble__input {
    font: inherit;
    border: none;
    background: transparent;
    color: #fff
  }
</style>
