import SectionProvider from '@/sections/SectionProvider';

export const name = 'NewsLetterItem1'

export default class NewsLetterItem1 extends SectionProvider {
    constructor(options = {}) {
        super('NewsLetterItem1', options);

        this.name = name
        this.group = 'newsLetter'

        this.defaultOptions = {
            content: {
                title: {
                    type: 'tiptap-mod',
                    display: true,
                    resolutionStyle: {
                        sm: {
                            fontSize: '41px',
                            fontFamily: 'Global Style',
                            color: '#0C226C',
                            align: 'left',
                        },
                        lg: {
                            fontSize: '41px',
                            fontFamily: 'Global Style',
                            color: '#0C226C',
                            align: 'left',
                        }
                    },
                    text: {
                        "type": "doc",
                        "content": [{
                            "type": "paragraph",
                            "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "BMarketing Secrets"}]
                        }]
                    },
                },
                subtitle: {
                    type: 'tiptap-mod',
                    display: true,
                    resolutionStyle: {
                        sm: {
                            fontSize: '24px',
                            fontFamily: 'Global Style',
                            color: '#0C226C',
                            align: 'left',
                        },
                        lg: {
                            fontSize: '24px',
                            fontFamily: 'Global Style',
                            color: '#0C226C',
                            align: 'left',
                        }
                    },
                    text: {
                        "type": "doc",
                        "content": [{
                            "type": "paragraph",
                            "content": [{"type": "text", "text": "Subscribe Today to …"}]
                        }]
                    },
                },
                image: {
                    display: true,
                    type: 'image-mod',
                    placeholder: require('@/assets/svg/placeholder-image.svg'),
                    src: null,
                    size: null
                },
                form: {
                    type: 'form-mod',
                    action: null,
                    selectedList: '',
                    fields: [
                        {
                            display: true,
                            type: 'email',
                            name: 'email',
                            placeholder: 'Enter your email address',
                            required: true,
                            label: null,
                            cols: {
                                sm: 12,
                                lg: 12
                            }
                        }
                    ]
                },
                button: {
                    type: 'button-mod',
                    submit: true,
                    display: true,
                    hover: false,
                    text: 'Go',
                    resolutionStyle: {
                        lg: {
                            color: '#ffffff',
                            backgroundColor: 'colored',
                            size: 'md',
                            shape: null,
                            btnStyle: '',
                            align: 'left',
                            padding: {
                                top: 0,
                                bottom: 0,
                                x: 0
                            }
                        },
                        sm: {
                            color: '#ffffff',
                            backgroundColor: 'colored',
                            size: 'md',
                            shape: null,
                            btnStyle: '',
                            align: 'left',
                            padding: {
                                top: 0,
                                bottom: 0,
                                x: 0
                            }
                        }
                    }
                }
            },
            customize: {
                style: {
                    background: {
                        background: '#EDEDED'
                    },
                    padding: {
                        type: 'height-customs',
                        sm: {
                            top: 60,
                            bottom: 60
                        },
                        lg: {
                            top: 60,
                            bottom: 60
                        }
                    }
                }
            }
        }
    }
}
