<template>
  <div class="feature-section">
    <ListItem2Template :section="section">
      <template slot="list" v-if="visability('list')">
        <b-row>
          <b-col :cols="cols(4, 12)" v-for="n in section.options.content.list.rows || 1" :key="n" :class="cols('mb-0', 'mb-4')">
            <b-list-group-item class="d-flex align-items-center" v-for="(item, index) in section.options.content.list.items[n]" :key="item.label">
              <i :style="{color: section.options.customize.style.listIcon.color}" :class="section.options.customize.style.listIcon.icon" class="mr-3"></i>
              <div>{{item.text}}</div>
            </b-list-group-item>
          </b-col>
        </b-row>
      </template>
    </ListItem2Template>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import ListItem2Template from '@pub/templates/default/sections/list/ListItem2Template';
import PubContentEditable from '../../../builder/utils/PubContentEditable';

export default {
  name: "FeaturedItems1",

  components: {PubContentEditable, ListItem2Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
