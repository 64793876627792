<template>
  <div class="mb-4">
    <label class="mb-2">Padding on {{resolution === 'lg' ? 'desktop' : 'mobile'}}</label>

    <div>
     <label class="d-flex mb-2">Top <span class="ml-auto">{{config[resolution].top}}px</span></label>
     <b-input type="range" v-model="config[resolution].top" max="500"></b-input>

     <label class="d-flex mb-2">Bottom <span class="ml-auto">{{config[resolution].bottom}}px</span></label>
     <b-input type="range" v-model="config[resolution].bottom" max="500"></b-input>
    </div>
  </div>
</template>

<script>
import ControlMixin from "../../mixins/ControlMixin";

export default {
  name: "Height",

  mixins: [ControlMixin],

  data() {
    return {
      options: ['s', 'm', 'l', 'xl']
    }
  }
}
</script>

<style lang="scss">
.btn-outline-custom {
  border-color:  #e2e5ec;
  text-transform: uppercase;
  font-size: 15px;
  color: #6e747a;

  &.active {
    box-shadow: inset 0 0 0 2px #7CACFA !important;
  }
}
</style>