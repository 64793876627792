import SectionProvider from '@/sections/SectionProvider';
import EsRow from "../../../../../sections/utils/EsRow";
import {default as Resource2} from '../resource-2/Section'

export const name = 'Resource Item 3'
export const group = 'resource'
export const defaultOptions = new EsRow({}, [
  new Resource2().json(),
  new Resource2().json(),
  new Resource2().json()
]).json().options

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/resource/main-3.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('EsRow', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
