<template>
  <div :style="{...opacity, ...backgroundColor, ...backgroundImage, ...shadow, ...padding}">
    <div class="testimonial-item-group-3" :class="container">
      <div class="heading mb-4">
        <slot name="title"></slot>
        <slot name="description"></slot>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "TestimonialItem3GroupTemplate",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .testimonial-item-group-3 {

    .heading {
      p {
        margin-bottom: 0;
      }
    }
  }
</style>
