<template>
  <div :style="{...backgroundImage, ...padding, ...opacity, ...backgroundColor}">
    <div :class="container">
      <b-row no-gutters class="featured-items-2 align-items-end" :style="{...shadow, ...padding}">
        <b-col :cols="cols(12, 12)">
          <div class="featured-text mb-5">
            <template v-if="visability('title')">
              <slot name="title"></slot>
            </template>
            <template v-if="visability('description')">
              <slot name="description"></slot>
            </template>
          </div>
        </b-col>

        <div class="d-flex justify-content-center w-100 px-5">
          <template v-if="visability('group')">
            <slot name="images"></slot>
          </template>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems1Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.featured-items-2 {
  .featured-text {
    p {
      margin-bottom: 0;
    }
  }

  .images {
    & > div {
      margin: 0 10px;
      text-align: center;
      font-size: 19px;

      span {
        display: block;
        margin-top: 10px;
      }
    }

    & > div:first-child {
      margin-left: 0;
    }

    & > div:last-child {
      margin-right: 0;
    }
  }
}
</style>
