<template>
  <div :style="{...padding, ...backgroundColor, ...backgroundImage}">
    <div :class="container">
      <b-row class="align-items-center">
        <b-col :cols="cols(4, 12)">
          <b-img @click="modal = true" class="cursor-pinter" :src="require('@/assets/svg/previews/videos/play-video.svg')" fluid></b-img>
        </b-col>

        <b-col :cols="cols(8, 12)">
          <template v-if="visability('title')">
            <slot name="title"></slot>
          </template>

          <template v-if="visability('subtitle')">
            <slot name="subtitle"></slot>
          </template>

          <template v-if="visability('button')">
            <slot name="button" :openModal="openModal"></slot>
          </template>
        </b-col>
      </b-row>

      <b-modal class="position-relative" v-model="modal" body-class="py-0" content-class="new-section-modal" size="xl" centered hide-footer hide-header>
        <icon class="close-icon cursor-pinter ml-3" icon="close.svg" @click.native="modal = false"></icon>

        <div class="py-5 mt-4">
          <slot name="video"></slot>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "VideoItems3Template",
  mixins: [TemplateMixin],
  data() {
    return {
      modal: false
    }
  },
  methods: {
    openModal() {
      this.modal = true
    }
  }
}
</script>
