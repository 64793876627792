<template>
  <div class="mb-5">
    <b-form-group>
      <v-select left :searchable="false" v-model="animations.animation" class="custom-select"
                :reduce="option => option.key"
                top
                :options="animationOptions"></v-select>
    </b-form-group>

    <b-form-group label="Animation delay">
      <b-input v-model="animations.delay"></b-input>
    </b-form-group>

    <b-form-group label="Animation duration (ms)">
      <b-input v-model="duration"></b-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "Animations",

  props: {
    animations: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      animationOptions: [
        {
          label: 'Select Animation',
          key: null
        },
        {
          label: 'Fade In',
          key: 'fade-in'
        },
        {
          label: 'Fade Up',
          key: 'fade-up'
        },
        {
          label: 'Fade Right',
          key: 'fade-right'
        },
        {
          label: 'Fade Left',
          key: 'fade-left'
        },
        {
          label: 'Fade Up Right',
          key: 'fade-up-right'
        },
        {
          label: 'Fade Up Left',
          key: 'fade-up-left'
        },
        {
          label: 'Slide Up',
          key: 'slide-up'
        },
        {
          label: 'Slide Left',
          key: 'slide-left'
        },
        {
          label: 'Slide Right',
          key: 'slide-right'
        },
        {
          label: 'Zoom In',
          key: 'zoom-in'
        },
        {
          label: 'Zoom In Up',
          key: 'zoom-in-up'
        },
        {
          label: 'Zoom In Left',
          key: 'zoom-in-left'
        },
        {
          label: 'Zoom In Right',
          key: 'zoom-in-right'
        }
      ]
    }
  },

  computed: {
    duration: {
      get() {
        return this.animations.duration || 400
      },
      set(val) {
        this.animations.duration = val
      }
    },
    animationObj: {
      get() {
        return this.animations
      },
      set(val) {

      }
    }
  }
}
</script>

<style scoped>

</style>