<template>
  <ActionItem3Template :section="section">
    <template slot="title" v-if="visability('title')">
        <pub-content-editable
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description" v-if="visability('description')">
        <pub-content-editable
            :customs="section.options.content.description"
            v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="price" v-if="visability('price')">
        <es-price :customs="section.options.content.price"></es-price>
    </template>

    <template slot="button" v-if="visability('button')">
        <pub-es-button :customs="section.options.content.button"></pub-es-button>
    </template>

    <template slot="icons" v-if="!style.payments.value.includes('None')">
      <icon v-if="style.payments.value.includes('Visa')" class="mr-3" icon="visa.svg"></icon>
      <icon v-if="style.payments.value.includes('Amex')" class="mr-3" icon="amex.svg"></icon>
      <icon v-if="style.payments.value.includes('MasterCard')" class="mr-3" icon="master-card.svg"></icon>
      <icon v-if="style.payments.value.includes('Paypal')" icon="paypal.svg"></icon>
    </template>
  </ActionItem3Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import ActionItem3Template from '@pub/templates/default/sections/actions/ActionItem3Template';
  import PubEsButton from '../../../builder/utils/buttons/PubEsButton';
  import PubContentEditable from '../../../builder/utils/PubContentEditable';
  import EsPrice from '../../../builder/utils/EsPrice';

  export default {
    name: "ActionItem2",

    components: {EsPrice, PubContentEditable, PubEsButton, ActionItem3Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
