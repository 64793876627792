<template>
<!--  v-if="section.options.content.form.action === 'Ontraport' || section.options.content.form.selectedList"-->
  <Template :section="section">
    <template slot="image" v-if="visability('image')">
      <image-fit
          :customs="section.options.content.image"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="cols(section.options.content.image.size, 'contain')"
          :position="section.options.content.image.position"
          :height="cols('191px', '191px')"
          :width="cols('223px', '223px')"
      ></image-fit>
    </template>

    <template slot="title" v-if="visability('title')">
        <pub-content-editable
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="subtitle" v-if="visability('subtitle')">
        <pub-content-editable
            :customs="section.options.content.subtitle"
            v-model="section.options.content.subtitle.text"></pub-content-editable>
    </template>

    <template slot="form">
      <es-form :customs="section.options.content.form" size="lg">
        <template :slot="cols(section.options.content.form.btnPosition || 'append-email', 'submit')">
          <pub-es-button type="submit" :customs="section.options.content.button"></pub-es-button>
        </template>
      </es-form>
    </template>
  </Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import Template from './Template';
import {mapGetters} from "vuex";
import ImageFit from "../../../../utils/ImageFit";

export default {
  name: "NewsLetterItem1",

  components: {ImageFit, Template, Highliter},

  mixins: [SectionMixin]
}
</script>
