<template>
  <highliter class="feature-section hide-add"
             :label="section.name"
             uid="#footer"
             @click.stop.native="sectionSettings()">
    <Template :section="section">
      <!-- Logo -->
      <template slot="logo">
        <highliter @click.stop.native="sectionSettings('logo')">
          <es-logo :customs="section.options.content.logo"></es-logo>
        </highliter>
      </template>
      <!-- End Logo -->

      <!-- Copyright -->
      <template slot="copyright">
        <highliter @click.stop.native="sectionSettings('copyright')">
          <content-editable
              :uid="tiptapUID('copyright')"
              :customs="section.options.content.copyright"
              v-model="section.options.content.copyright.text"></content-editable>
        </highliter>
      </template>
      <!-- End Copyright -->

      <!-- Menu 1 -->
      <template slot="labelMenu1">
        <highliter @click.stop.native="sectionSettings('labelMenu1')">
          <content-editable
              :uid="tiptapUID('labelMenu1')"
              :customs="section.options.content.labelMenu1"
              v-model="section.options.content.labelMenu1.text"></content-editable>
        </highliter>
      </template>

      <template slot="menu-1">
        <highliter @click.stop.native="sectionSettings('menu1')">
          <ul class="list-unstyled">
            <li v-for="(link, index) in section.options.content.menu1.links" :key="index">
              <b-link :style="section.options.content.menu1.style">{{ link.text }}</b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Menu 1 -->

      <!-- Menu 2 -->
      <template slot="labelMenu2">
        <highliter @click.stop.native="sectionSettings('labelMenu2')">
          <content-editable
              :uid="tiptapUID('labelMenu2')"
              :customs="section.options.content.labelMenu2"
              v-model="section.options.content.labelMenu2.text"></content-editable>
        </highliter>
      </template>

      <template slot="menu-2">
        <highliter @click.stop.native="sectionSettings('menu2')">
          <ul class="list-unstyled">
            <li v-for="(link, index) in section.options.content.menu2.links" :key="index">
              <b-link :style="section.options.content.menu2.style">{{ link.text }}</b-link>
            </li>
          </ul>
        </highliter>
      </template>
      <!-- End Menu 2 -->

      <!-- Menu Label 3 -->
      <template slot="labelMenu3">
        <highliter @click.stop.native="sectionSettings('labelMenu3')">
          <content-editable
              :uid="tiptapUID('labelMenu3')"
              :customs="section.options.content.labelMenu3"
              v-model="section.options.content.labelMenu3.text"></content-editable>
        </highliter>
      </template>
      <!-- Menu Label 3 -->

      <!-- Description -->
      <template slot="formDescription">
        <highliter @click.stop.native="sectionSettings('formDescription')">
          <content-editable
              :uid="tiptapUID('formDescription')"
              :customs="section.options.content.formDescription"
              v-model="section.options.content.formDescription.text"></content-editable>
        </highliter>
      </template>
      <!-- End Description -->

      <!-- Form -->
      <template slot="form">
        <highliter @click.stop.native="sectionSettings('form')">
          <es-form :customs="section.options.content.form">
            <template :slot="cols(section.options.content.form.btnPosition, 'submit')">
              <highliter v-show="visability('button')"
                         autoWidth
                         @click.stop.native="sectionSettings('button')">
                <es-button :customs="section.options.content.button"></es-button>
              </highliter>
            </template>
          </es-form>
        </highliter>
      </template>
      <!-- End Form -->
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from "./Template";

export default {
  name: "Carousel1",

  components: {Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>