<template>
  <div :style="{...backgroundImage, ...backgroundColor, ...padding}">
    <div :class="container">
      <b-row no-gutters class="featured-items-5" :style="{...opacity}">
        <b-col :offset="aligment('left') ? 0 : 6" :cols="cols(6, 12)">
          <div class="featured-text d-flex align-items-center px-5" :style="{...backgroundColorCustom('Card'), ...shadow}">
            <div>
              <template v-if="visability('title')">
                <div class="mb-3">
                  <slot name="title"></slot>
                </div>
              </template>

              <template v-if="visability('description')">
                <div class="mb-4">
                  <slot name="description"></slot>
                </div>
              </template>

              <template v-if="visability('button')">
                <slot name="button"></slot>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems5Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.resolution-sm {
  .featured-items-5 {
    .btn {
      display: block !important;
      width: 100% !important;
    }
  }
}

.featured-items-5 {
  min-height: 642px;

  .featured-text {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    height: 70%;
    background: #fff;
    transform: translateY(-50%);
  }
}
</style>
