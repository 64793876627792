<template>
  <Template :section="section">
    <template slot="title">
        <pub-content-editable
            :uid="tiptapUID('title')"
            :customs="section.options.content.title"
            v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="text">
        <pub-content-editable
            :uid="tiptapUID('text')"
            :customs="section.options.content.text"
            v-model="section.options.content.text.text"></pub-content-editable>
    </template>

    <template slot="form">
        <es-form :customs="section.options.content.form">
          <template :slot="cols(section.options.content.form.btnPosition, 'submit')">
              <pub-es-button :customs="section.options.content.button"></pub-es-button>
          </template>
        </es-form>
    </template>

    <template slot="image">
        <image-fit
            :customs="section.options.content.image"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
            :size="cols('contain', 'contain')"
            :height="cols('300px', '300px')"
            :position="section.options.content.image.position"
        ></image-fit>
    </template>
  </Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Template from './Template';
  import PubContentEditable from "../../../utils/PubContentEditable";
  import PubEsButton from "../../../utils/buttons/PubEsButton";
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "ActionItem2",

    components: {ImageFit, PubEsButton, PubContentEditable, Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
