<template>
  <div class="slider-toggle" @click="slide">
    <slot name="icon">
      <icon class="icon" icon="resize.svg"></icon>
    </slot>

    <div class="slieds">
      <div v-for="(option, index) in options" :key="index" :class="{'active': index === active}" class="slied"></div>
    </div>

    <div class="label text-center">{{ label }} ({{ value }})</div>
  </div>
</template>

<script>
export default {
  name: "SliederToggle",

  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      active: 0
    }
  },

  methods: {
    slide() {
      if (this.active < this.options.length - 1) {
        this.active++
      } else {
        this.active = 0
      }

      this.$emit('input', this.options[this.active])
    },
    prepateState() {
      this.active = this.options.findIndex(o => o === this.value)
    }
  },

  watch: {
    value() {
      this.prepateState()
    }
  },

  mounted() {
    this.prepateState()
  }
}
</script>

<style lang="scss" scoped>
.slider-toggle {
  position: relative;
  width: 83px;
  height: 76px;
  border-radius: 3px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.26);
  cursor: pointer;
  margin-bottom: 20px;
  transition: box-shadow .3s;

  &:hover {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.26);
  }

  .icon {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-45%, -50%);
  }

  .slieds {
    position: absolute;
    padding: 0 4px;
    width: 100%;
    display: flex;
    bottom: 5px;

    .slied {
      width: 100%;
      height: 7px;
      border-radius: 3.5px;
      background-color: #d8d8d8;

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.active {
        background-color: #4f83e3;
      }
    }
  }

  .label {
    position: absolute;
    top: 107%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #44474a;
  }
}
</style>
