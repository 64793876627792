<template>
    <NewsLetterItem2Template v-if="section.options.content.form.action === 'Ontraport' || section.options.content.form.selectedList" :section="section">
      <template slot="title" v-if="visability('title')">
          <pub-content-editable
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></pub-content-editable>
      </template>

      <template slot="form">
          <es-form :customs="section.options.content.form" size="lg" inline>
            <template :slot="section.options.content.form.btnPosition || 'submit'"
                         autoWidth
                         @click.stop.native="sectionSettings('button')">
                <pub-es-button :customs="section.options.content.button"></pub-es-button>
            </template>
          </es-form>
      </template>
  </NewsLetterItem2Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import NewsLetterItem2Template from '@pub/templates/default/sections/newsLetter/NewsLetterItem2Template';
import EsForm from '../../../builder/utils/EsForm';
import PubContentEditable from '../../../builder/utils/PubContentEditable';
import PubEsButton from '../../../builder/utils/buttons/PubEsButton';
import {mapGetters} from "vuex";

export default {
  name: "NewsLetterItem1",

  components: {PubEsButton, PubContentEditable, EsForm, NewsLetterItem2Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created() {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
