<template>
 <div :style="{...padding}">
   <div class="blog-1-card p-2 rounded" :style="{...opacity, ...shadow, ...backgroundColor}">
     <slot name="image"></slot>

     <div class="my-4"></div>

     <slot name="date"></slot>

     <div class="my-2"></div>

     <slot name="title"></slot>

     <div class="my-2"></div>

     <slot name="description"></slot>

     <div class="my-2"></div>

     <slot name="button"></slot>
   </div>
 </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "FeaturedItems4Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">

</style>