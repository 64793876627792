<template>
  <div :style="{...backgroundImage, ...backgroundColor}">
    <div class="action-item-3" :style="{...opacity, ...padding}" :class="container">
      <slot name="title"></slot>
      <slot name="description"></slot>

      <div class="d-flex justify-content-center">
        <b-card class="border-0" :style="{...shadow}">
          <slot name="price"></slot>
          <div class="w-100 text-center">
            <slot name="button"></slot>
          </div>
          <div class="my-4"></div>
          <div class="d-flex justify-content-center">
            <slot name="icons"></slot>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "FeaturedItems3Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
.action-item-3 {
  .card {
    border-radius: 2px;
    padding: 10px;
  }

  p {
    margin-bottom: 0;
  }
}
</style>
