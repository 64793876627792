<template>
  <div :style="{...backgroundImage, ...padding}">
    <div class="accordion" :class="container" role="tablist" v-if="visability('collapse')">
      <b-card no-body v-for="(collapse, index) in section.options.content.collapse.items" :key="collapse.uid" class="rounded-0">
        <b-card-header header-tag="header" class="d-flex justify-content-between align-items-center rounded-0 py-3" role="tab" @click="active = index" :style="{...backgroundColor, ...color}">
          <slot name="header" :content="collapse"></slot>
          <i v-if="active === index" class="fa fa-minus"></i>
          <i v-else class="fa fa-plus"></i>
        </b-card-header>
        <b-collapse :visible="active === index" role="tabpanel">
          <b-card-body :style="{color: section.options.customize.style.bodyColor.color}">
            <slot name="body" :content="collapse"></slot>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "HeroItem1Template",
  mixins: [TemplateMixin],

  data() {
    return {
      active: 0
    }
  }
}
</script>