import SectionProvider from '@/sections/SectionProvider';

export const name = 'Testimonial Item 2'

export default class TestimonialItem2 extends SectionProvider {
  constructor(options = {}) {
    super('TestimonialItem2', options);

    this.name = name
    this.group = 'testimonials'

    this.defaultOptions = {
      content: {
        name: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: '#B7B0FF',
              align: 'center',
            },
            lg: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: '#B7B0FF',
              align: 'center',
            }
          },
          text: {
            "type": "doc",
            "content": [
              {
                "type": "paragraph",
                "content": [
                  {
                    "type": "text",
                    "marks": [
                      {
                        "type": "bold"
                      }
                    ],
                    "text": "JOHN THOMAS"
                  },
                  {
                    "type": "text",
                    "text": ", MARKETING MIX CEO"
                  }
                ]
              }
            ]
          }
        },
        text: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#FFFFFF',
              align: 'center',
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#FFFFFF',
              align: 'center',
            }
          },
          text: {
            "type": "doc",
            "content": [
              {
                "type": "paragraph",
                "content": [
                  {
                    "type": "text",
                    "text": "“Curabitur tristique, lorem et consequat, nisi nisi ac dictum neque odio eget elit. Curabitur tristique, "
                  },
                  {
                    "type": "text",
                    "marks": [
                      {
                        "type": "bold"
                      }
                    ],
                    "text": "lorem et consequat"
                  },
                  {
                    "type": "text",
                    "text": ", nisi nisi ac dictum neque odio eget elit.”"
                  }
                ]
              }
            ]
          },
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/images/placeholder-testemonial-item-2.png'),
          src: null,
          position: 'center',
size: 'cover'
        }
      },
      customize: {
        layouts: false,
        style: {
          background: {
            background: '#1F3B9C'
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
           },
           lg: {
              top: 115,
              bottom: 115
           }
          }
        }
      }
    }
  }
}
