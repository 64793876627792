<template>
  <div class="mb-4">
    <label class="mb-2">{{ config.label || 'Background' }}</label>

    <b-form-group class="mb-1">
      <color-picker :color="config.background === 'colored' ? globalStyles.colors.primary : config.background"
                    @input="event => config.background = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"></color-picker>
    </b-form-group>

    <b-link class="small" @click.native="config.background = 'colored'">Set from global styles</b-link>
  </div>
</template>

<script>
import ControlMixin from "../../mixins/ControlMixin";
import ColorPicker from "../utils/ColorPicker";
import {mapState} from "vuex";

export default {
  name: "Background",
  components: {ColorPicker},
  mixins: [ControlMixin],

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  }
}
</script>