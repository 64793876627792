import {mapState} from 'vuex';
import CustomStylesMixin from "../../modules/builder/src/components/mixins/CustomStylesMixin";
import OveralSettingsMixin from "../../modules/builder/src/components/mixins/OveralSettingsMixin";

export default {
  props: {
    section: Object
  },

  mixins: [CustomStylesMixin, OveralSettingsMixin],

  methods: {
    aligment(align) {
      try {
        return this.section.options.customize.style.aligment.align === align
      } catch {
        return false
      }
    },
    visability(el) {
      const display = this.section.options.content[el].display
      return display === true || display === this.resolution
    },
    align(el) {
      return {
        textAlign: this.section.options.content[el].align
      }
    }
  },

  mounted() {
    this.$el.setAttribute('id', this.section.uid)
  }
}
