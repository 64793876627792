<template>
  <div>
    <b-modal body-class="text-center py-5" v-model="confirmDialog" size="lg" hide-footer hide-header centered>
      <h4 class="h3 font-weight-bold my-4" v-html="title"></h4>

      <p v-html="description"></p>


      <div class="d-flex align-items-center justify-content-center">
        <b-btn variant="danger" class="btn-wide mr-2" @click="confirm">Yes</b-btn>
        <b-btn class="btn-wide" @click="confirmDialog = false">No</b-btn>
      </div>
    </b-modal>

    <slot name="activator" @click="confirmDialog = true" v-bind="{on: handlers}"></slot>
  </div>
</template>

<script>
export default {
  name: "DeleteConfirm",
  props: {
    title: String,
    description: String
  },
  data () {
    return {
      confirmDialog: false,
      handlers: {
        click: () => this.onClick()
      }
    }
  },
  methods: {
    onClick() {
      this.confirmDialog = true
    },
    confirm() {
      this.confirmDialog = false
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>

</style>