<template>
  <div>
    <div class="editor" v-if="loaded">
      <router-view></router-view>
      <new-section-modal></new-section-modal>
      <new-link-modal></new-link-modal>
      <new-page-modal></new-page-modal>
      <injection-page-modal/>
    </div>
  </div>
</template>

<script>
import EsRoot from '@/components/builder/EsRoot';
import EditorToolbar from '@/components/editor/navigation/EditorToolbar';
import EditorHeader from '@/components/editor/header/EditorHeader';
import NewSectionModal from '@/components/editor/modals/NewSectionModal';
import NewLinkModal from "../../components/editor/modals/NewLinkModal";
import {mapState} from 'vuex'
import NewPageModal from "../../components/editor/modals/NewPageModal";
import InjectionModal from "../../components/editor/modals/InjectionModal";
import InjectionPageModal from "../../components/editor/modals/InjectionPageModal";
import CodeInjectionPageMixin from "../../components/mixins/CodeInjectionPageMixin";

export default {
  name: "Builder",
  components: {
    InjectionPageModal,
    InjectionModal,
    NewPageModal, NewLinkModal, NewSectionModal, EditorToolbar, EsRoot, EditorHeader},

  mixins: [CodeInjectionPageMixin],

  computed: {
    ...mapState('projects', {
      loaded: state => state.loaded
    })
  },

  created() {
    VEvent.fire('loader', true)

    this.$store.dispatch('projects/load', {id: this.$route.params.id, path: this.$route.params.path})
        .then(() => {
          this.$store.dispatch('apiIntegration/getlAllMailServices')
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
  }
}
</script>
