<template>
  <highliter :label="section.name" :dublicate="section" :uid="section.uid" @click.stop.native="sectionSettings('group')"
             v-if="visability('group')">
    <div :style="{...backgroundColor, ...backgroundImage, ...padding, ...shadow, ...opacity}">
      <div :class="container">
        <div v-if="!sections.length" class="d-flex align-items-center justify-content-center">
          <div class="editor-toolbar-btn-spacing my-4">
            <b-btn variant="editor-soft-primary" size="lg" block @click="newSectionModal">
              Add New Section
              <icon class="ml-1" icon="plus.svg"/>
            </b-btn>
          </div>
        </div>
        <div v-else class="overflow-hidden">
          <b-row class="es-row editabel-cols" :class="'align-items-' + section.options.customize.style.alignRow.align"
                 :no-gutters="section.options.customize.noGutters" data-padding data-bg>
            <b-col v-for="(child, index) in sections"
                   :key="child.uid"
                   :offset="offset(child.options.customize)"
                   :order="order(child.options.customize)"
                   :cols="child.options.customize.cols[resolution]">
              <div class="inner-content-section__tools">
                <b-link variant="outline-primary" class="border-right-0" @click="deleteInnerSection(index)">
                  <icon size="14px" icon="delete-primary.svg"></icon>
                </b-link>

                <b-link variant="primary" @click="duplicateInnerSection(child)">
                  <icon size="14px" icon="copy-2.svg"></icon>
                </b-link>
              </div>
              <component
                  :is="child.component"
                  :section="child"
                  :parentUID="section.uid"
              >
              </component>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin';
import Highliter from '../../utils/Highliter';
import CustomStylesMixin from '../../../mixins/CustomStylesMixin';
import shortid from "shortid";

export default {
  name: "EsRow",
  components: {Highliter},
  mixins: [SectionMixin, CustomStylesMixin],

  computed: {
    sections() {
      return this.section.options.content.group.items
    }
  },

  methods: {
    deleteInnerSection(index) {
      this.section.options.content.group.items.splice(index, 1)
    },
    duplicateInnerSection(section) {
      this.section.options.content.group.items.push(
          {
            ...JSON.parse(JSON.stringify(section)),
            uid: shortid.generate(),
          }
      )
    },
    newSectionModal() {
      this.$store.commit('editor/SET_SECTION_MODAL', {
        uid: this.section.uid,
      })
    },
    doctor() {
      this.section.options.content.group.items.forEach(section => {
        if (!section.options.customize.hasOwnProperty('cols')) {
          this.$set(section.options.customize, 'cols',
              {
                sm: 12,
                md: 6,
                lg: 6
              })
        }
      })
    }
  },

  watch: {
    sections() {
      this.doctor()
    }
  }
}
</script>

<style lang="scss">
.inner-content-section__tools {
  position: absolute;
  display: none;
  right: 17px;
  top: 3px;
  z-index: 100;

  a {
    width: 30px;
    height: 30px;
    padding: 2px 5px;
    border: 1px solid #4F83E3;
    background: #fff;
  }
}

.editabel-cols > div {
  &:hover {
    .inner-content-section__tools {
      display: block !important;
    }

    > .higliter {
      box-shadow: 0 0 0 2px #7CACFA;
      z-index: 1;
    }
  }
}

.higliter.active + .inner-content-section__tools,
.inner-content-section__tools:hover {
  display: block !important;
}

//.es-row {
//  padding-left: 20px;
//  padding-right: 20px;
//}
</style>
