<template>
  <section class="hero-4" :style="{...opacity, ...backgroundColor, ...padding}" :class="container">
    <b-container fluid>
      <b-row class="align-items-center">
        <b-col :cols="cols(6, 12)" :class="cols('px-5', 'px-2')" :order="cols(1, 2)">
          <div v-if="visability('title')">
            <slot name="title"></slot>
          </div>

          <div v-if="visability('description')">
            <slot name="description"></slot>
          </div>

          <div class="d-flex align-items-center flex-wrap btns">
            <div v-if="visability('button')" :class="cols('mb-2 mr-2', 'mb-3')">
              <slot name="button"></slot>
            </div>

            <div v-if="visability('button2')" :class="cols('mb-2')">
              <slot name="button2"></slot>
            </div>
          </div>
        </b-col>

        <b-col v-if="visability('image')"
               :cols="cols(6, 12)"
               :order="cols(2, 1)"
               :class="cols('', 'mb-4')">
          <slot name="image"></slot>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";
import ImageFit from "../../../../utils/ImageFit";

export default {
  name: "HeroItem1Template",
  components: {ImageFit},
  mixins: [TemplateMixin],

  data() {
    return {
      dragging: false,
      x: 0,
      y: 0,
      offset: {
        x: 0,
        y: 0
      }
    }
  },

  methods: {
    startDrag() {
      this.dragging = true;
      // this.x = this.y = 0;
      var rect = this.$el.querySelector(".resizer-img").getBoundingClientRect();

      var offset = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      };

      this.offset.x = event.clientX - offset.left
      this.offset.y = event.clientY - offset.top
    },
    stopDrag() {

      this.dragging = false;
      // this.x = this.y = 'no';
    },
    doDrag(event) {
      if (this.dragging) {
        var rect = this.$el.querySelector(".resizer-img").getBoundingClientRect();

        var offset = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        };

        this.x = this.x + ((event.clientX - offset.left) - this.offset.x)
        // this.y = this.y + ((event.clientY - offset.top) - this.offset.y)

        this.offset.x = event.clientX - offset.left
        this.offset.y = event.clientY - offset.top

        // this.y = event.clientY - offset.top;
      }
    }
  },
  mounted() {
    window.addEventListener('mouseup', this.stopDrag);
  }
}
</script>

<style lang="scss">
.resolution-sm {
  .hero-4 {
    .btns,
    .btn {
      display: block !important;
      width: 100% !important;
    }
  }
}
</style>