<template>
  <highliter class="feature-section" :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <AboutUs1Template :section="section">
      <template slot="title">
        <highliter @click.stop.native="sectionSettings('title')">
          <content-editable
              :uid="tiptapUID('title')"
              :customs="section.options.content.title"
              v-model="section.options.content.title.text"></content-editable>
        </highliter>
      </template>

      <template slot="description-1">
        <highliter @click.stop.native="sectionSettings('description')">
          <content-editable
              :uid="tiptapUID('description')"
              :customs="section.options.content.description"
              v-model="section.options.content.description.text"></content-editable>
        </highliter>
      </template>

     <template slot="description-2">
       <highliter @click.stop.native="sectionSettings('description2')">
         <content-editable
             :uid="tiptapUID('description2')"
             :customs="section.options.content.description2"
             v-model="section.options.content.description2.text"></content-editable>
       </highliter>
     </template>
   </AboutUs1Template>
  </highliter>
</template>

<script>
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import AboutUs1Template from "./AboutUs1Template";
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {ImageFit, AboutUs1Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
