import SectionProvider from '@/sections/SectionProvider';
import VideoItem1 from "./VideoItem1";
import EsRow from "../utils/EsRow";

export const name = 'Video Items Group 3'

export default class VideoItem3Group extends SectionProvider {
  constructor(options, items) {
    super('VideoItem3', options);

    this.name = name
    this.group = 'videos'
    this.stateSafly = false

    this.defaultOptions = {
      content: {
        group: {
          type: 'playlist-mod',
          display: true,
          items: [
            new VideoItem1({
                content: {
                  title: {
                    resolutionStyle: {
                      sm: {
                        align: 'left',
                      },
                      lg: {
                        align: 'left',
                      }
                    }
                  },
                  subtitle: {
                    display: false,
                    resolutionStyle: {
                      sm: {
                        align: 'left',
                      },
                      lg: {
                        align: 'left',
                      }
                    }
                  },
                  video: {
                    placeholder: require('@/assets/svg/previews/features/placeholder-3.png')
                  }
                }
              }).json(),
            new VideoItem1({
              content: {
                title: {
                  resolutionStyle: {
                    sm: {
                      align: 'left',
                    },
                    lg: {
                      align: 'left',
                    }
                  }
                },
                subtitle: {
                  display: false,
                  resolutionStyle: {
                    sm: {
                      align: 'left',
                    },
                    lg: {
                      align: 'left',
                    }
                  }
                }
              }
            }).json(),
            new VideoItem1({
              content: {
                title: {
                  resolutionStyle: {
                    sm: {
                      align: 'left',
                    },
                    lg: {
                      align: 'left',
                    }
                  }
                },
                subtitle: {
                  display: false,
                  resolutionStyle: {
                    sm: {
                      align: 'left',
                    },
                    lg: {
                      align: 'left',
                    }
                  }
                }
              }
            }).json(),
            new VideoItem1({
              content: {
                title: {
                  resolutionStyle: {
                    sm: {
                      align: 'left',
                    },
                    lg: {
                      align: 'left',
                    }
                  }
                },
                subtitle: {
                  display: false,
                  resolutionStyle: {
                    sm: {
                      align: 'left',
                    },
                    lg: {
                      align: 'left',
                    }
                  }
                }
              }
            }).json(),
          ]
        }
      },
      customize: {
        style: {
          padding: {
            type: 'height-customs',
            sm: {
              top: 70,
              bottom: 70
           },
           lg: {
              top: 100,
              bottom: 100
           }
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
size: 'cover',
            opacity: 1
          },
          container: {
            type: 'container-customs',
            container: 'container'
          },
        }
      }
    }
  }
}
