<template>
  <highliter :label="section.name" :dublicate="section" :uid="section.uid" @click.stop.native="sectionSettings('collapse')">
    <CollapseItems1Template :section="section">
      <template slot="header" scope="content">
        <editable v-model="content.content.title" class="d-block w-100"></editable>
      </template>

      <template slot="body" scope="content">
        <editable v-model="content.content.text" tag-name="b-card-text"></editable>
      </template>
   </CollapseItems1Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import CollapseItems1Template from '@pub/templates/default/sections/collapse/CollapseItems1Template';
  import Editable from "../../utils/Editable";

  export default {
    name: "CollapseItem1",

    components: {Editable, CollapseItems1Template, ContentEditable, Highliter},

    mixins: [SectionMixin]
  }
</script>
