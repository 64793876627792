import SectionProvider from '@/sections/SectionProvider';

export const name = 'Paragraph 2'
export const group = 'Paragraph'

export const defaultOptions = {
  content: {
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '17px',
          fontFamily: 'Global Style',
          color: '#44474A',
          align: 'left',
          lineHeight: '1.5'
        },
        lg: {
          fontSize: '17px',
          fontFamily: 'Global Style',
          color: '#44474A',
          align: 'left',
          lineHeight: '1.5'
        }
      },
      text: "<p>Cras faucibus purus tincidunt tincidunt mollis. Fusce placerat augue et porttitor sodales. Ut tincidunt leo in arcu suscipit ultrices. Morbi volutpat imperdiet molestie. Pellentesque magna tellus, tempor in tincidunt sit amet, placerat a mi. In dolor quam, hendrerit a orci eget, accumsan bibendum sapien. Curabitur hendrerit mi massa.</p>",
    },
    description2: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '17px',
          fontFamily: 'Global Style',
          color: '#44474A',
          align: 'left',
          lineHeight: '1.5'
        },
        lg: {
          fontSize: '17px',
          fontFamily: 'Global Style',
          color: '#44474A',
          align: 'left',
          lineHeight: '1.5'
        }
      },
      text: "<p>Cras faucibus purus tincidunt tincidunt mollis. Fusce placerat augue et porttitor sodales. Ut tincidunt leo in arcu suscipit ultrices. Morbi volutpat imperdiet molestie. Pellentesque magna tellus, tempor in tincidunt sit amet, placerat a mi. In dolor quam, hendrerit a orci eget, accumsan bibendum sapien. Curabitur hendrerit mi massa.</p>",
    },
  },
  customize: {
    style: {
      padding: {
        type: 'height-customs',
        sm: {
          top: 80,
          bottom: 80
        },
        lg: {
          top: 80,
          bottom: 80
        }
      }
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/paragraph/main-2.svg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Paragraph2', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
