<template>
  <div class="menubar mb-4">
    <label class="mb-2">Scroll Effect</label>

    <b-form-group>
      <b-form-radio v-model="config.scrollType" value="sticky"><div class="pt-1">Sticky Menu</div></b-form-radio>
      <b-form-radio v-model="config.scrollType" value="reveal"><div class="pt-1">Reveal on Scroll Up</div></b-form-radio>
      <b-form-radio v-model="config.scrollType" :value="null"><div class="pt-1">No Effects</div></b-form-radio>
    </b-form-group>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin';

export default {
  mixins: [ControlMixin]
}
</script>
