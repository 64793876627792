import router from '@/router/index'


const state = () => ({
  authenticated: false,
  user: null
})

const getters = {
  authenticated(state) {
    return state.authenticated
  },

  user(state) {
    return state.user
  }
}

const actions = {
  async signIn({dispatch}, credentials) {
    await axios.post('api/auth/login', credentials)
      .then(({data}) => {
        localStorage.setItem('sanctum_token', data.access_token)

        axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`

        return dispatch('me')
      })
  },

  async signUp({dispatch}, credentials) {
    await axios.post('api/auth/signup', credentials)
      .then(({data}) => {
        localStorage.setItem('sanctum_token', data)

        axios.defaults.headers.common.Authorization = `Bearer ${data}`

        return dispatch('me')
      })
  },

  async signOut({dispatch, commit}) {
   await axios.get('api/auth/logout')
     .then(() => {
       localStorage.removeItem('sanctum_token')

       commit('SET_AUTHENTICATED', false)
       commit('SET_USER', null)

       router.push('/login')
     })
  },

  async me({commit}) {
    if (!localStorage.getItem('sanctum_token')) return false

    return axios.get('api/auth/user')
      .then(({data}) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', data)

        return this
      })
      .catch(() => {
        localStorage.removeItem('sanctum_token')

        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
  }
}

const mutations = {
  SET_AUTHENTICATED(state, paylaod) {
    state.authenticated = paylaod
  },

  SET_USER(state, payload) {
    state.user = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
