import { Mark } from 'tiptap'
import { updateMark } from 'tiptap-commands'

export default class FontWeight extends Mark {

  get name () {
    return 'fontweight'
  }

  get defaultOptions () {
    return {
      fontWeight: ['400'],
    }
  }

  get schema () {
    return {
      attrs: {
        fontWeight: {
          default: '400',
        },
      },
      parseDOM: this.options.fontWeight.map(fontWeight => ({
        tag: `spane`,
        getAttrs(dom){
          return {
            fontWeight:dom.style.fontWeight
          }
        }
      })),
      toDOM:
        node => {
          return ['spane', {
            style: `font-weight:${node.attrs.fontWeight}`
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => updateMark(type, attrs)
  }
}
