import SectionProvider from '@/sections/SectionProvider';

export const name = 'Footer 3'
export const group = 'Footer'

export const defaultOptions = {
  content: {
    logo: {
      display: true,
      type: 'logo-mod',
      image: {
        src: require('@/assets/svg/previews/footer/placeholder-1.svg')
      },
      fontFamily: 'Global Style',
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#0C226C',
      text: 'FourPercent'
    },
    copyright: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '12px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '12px',
          color: 'adaptive',
          align: 'left',
          fontFamily: 'Global Style',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      "text": "<p>© Copyright. Your Site. All Rights Reserved</p>"
    },
    menu: {
      display: true,
      type: 'menu-links-mod',
      style: {
        fontSize: '15px',
        color: 'adaptive',
        fontWeight: 'bold',
        lineHeight: 2,
      },
      links: [
        {
          href: '#',
          text: 'About',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Work with Me',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Resources',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Blog',
          customLink: false,
          blank: false
        },
        {
          href: '#',
          text: 'Contact',
          customLink: false,
          blank: false
        }
      ]
    },
    socialLinks: {
      display: true,
      type: 'social-links-mod',
      style: {
        color: 'adaptive',
        fontSize: '14px',
        fontWeight: 'bold'
      },
      links: [
        {
          shape: null,
          color: '#fff',
          href: '#',
          icon: 'fa fa-facebook',
          background: 'rgba(255, 255, 255, 0)'
        },
        {
          shape: null,
          color: '#fff',
          href: '#',
          icon: 'fa fa-google',
          background: 'rgba(255, 255, 255, 0)'
        },
        {
          shape: null,
          color: '#fff',
          href: '#',
          icon: 'fa fa-twitter',
          background: 'rgba(255, 255, 255, 0)'
        }
      ]
    },
  },
  customize: {
    layouts: null,
    style: {
      background: {
        background: 'colored'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: null,
        position: 'center',
size: 'cover',
        opacity: 1
      },
      shadow: null,
      opacity: null,
      padding: {
        type: 'height-customs',
        sm: {
          top: 40,
          bottom: 40
        },
        lg: {
          top: 80,
          bottom: 120
        }
      }
    },
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/footer/main-3.jpg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Footer3', options);

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
