<template>
  <b-media class="testimonial-item-4 flex-wrap p-4 mb-5 rounded" :style="{...backgroundColor, ...padding, ...shadow, ...opacity}">
    <template slot="aside">
      <slot name="image"></slot>
    </template>

    <b-media-body class="ml-3 mt-2">
      <slot name="text"></slot>

      <div class="d-flex align-items-center text-nowrap mt-2">
        <div class="mr-5">
          <slot name="title"></slot>
          <slot name="subtitle"></slot>
        </div>
      </div>
    </b-media-body>
  </b-media>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "TestimonialItem4Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .testimonial-item-4 {
    padding: 35px 0;

    img {
      width: 78px;
      height: 71px;
    }

    p {
      margin-bottom: 0;
    }
  }
</style>
