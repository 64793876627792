export default {
  props: {
    service: {
      required: true,
      type: Object
    }
  },

  methods: {
    async integrateMailService(service) {
      VEvent.fire('loader', true)

      await this.$store.dispatch('apiIntegration/integrateMailService', {...this.service, auto_responder_id: this.service.id})
        .then(({data}) => {
          this.service.status = data.data
          this.$emit('integrated')
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },

    async deleteMailService() {
      VEvent.fire('loader', true)

      await this.$store.dispatch('apiIntegration/deleteMailService', {...this.service, auto_responder_id: this.service.id})
        .then(({data}) => {
          this.service.status = data.data
          this.$emit('remove-integration')
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    }
  }
}