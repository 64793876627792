import {mapState} from "vuex";

export default {
  methods: {
    cols(lg, sm) {
      return this.resolution === 'lg' ? lg : sm
    },
  },

  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles
    })
  },
}