<template>
  <div :style="{...backgroundColor, ...padding, ...backgroundImage}" v-if="visability('images')">
    <div class="carousel-1 position-relative" :class="[cols('carousel-1-lg', ''), container]">
      <VueSlickCarousel v-bind="settings">
        <slot></slot>

        <template #prevArrow="arrowOption">
          <div class="custom-arrow-prev">
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.27083L0 40.7292C0 43.6367 2.36325 46 5.27083 46H40.7292C43.6367 46 46 43.6367 46 40.7292V5.27083C46 2.36325 43.6367 0 40.7292 0L5.27083 0C2.36325 0 0 2.36325 0 5.27083ZM30.6667 31.1458C30.6667 31.6576 30.3945 32.131 29.9537 32.3878C29.5109 32.6447 28.9647 32.6485 28.52 32.3974L14.145 24.2516C13.2461 23.7418 13.2461 22.2602 14.145 21.7503L28.52 13.6045C28.9647 13.3515 29.5128 13.3553 29.9537 13.6122C30.3945 13.869 30.6667 14.3424 30.6667 14.8542L30.6667 31.1458Z" :fill="section.options.content.images.arrows.color || globalStyles.colors.primary"/>
            </svg>
          </div>
        </template>

        <template #nextArrow="arrowOption">
          <div class="custom-arrow-next">
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M46 5.27083V40.7292C46 43.6367 43.6367 46 40.7292 46H5.27083C2.36325 46 0 43.6367 0 40.7292V5.27083C0 2.36325 2.36325 0 5.27083 0L40.7292 0C43.6367 0 46 2.36325 46 5.27083ZM15.3333 31.1458C15.3333 31.6576 15.6055 32.131 16.0463 32.3878C16.4891 32.6447 17.0353 32.6485 17.48 32.3974L31.855 24.2516C32.7539 23.7418 32.7539 22.2602 31.855 21.7503L17.48 13.6045C17.0353 13.3515 16.4872 13.3553 16.0463 13.6122C15.6055 13.869 15.3333 14.3424 15.3333 14.8542L15.3333 31.1458Z" :fill="section.options.content.images.arrows.color || globalStyles.colors.primary"/>
            </svg>
          </div>
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "Carousel1Template",
    mixins: [TemplateMixin],

    computed: {
      settings() {
        return {
          "centerMode": this.cols(this.section.options.content.images.centerMode, false),
          "centerPadding": this.section.options.content.images.centerPadding + "px",
          "focusOnSelect": true,
          "autoplay": this.section.options.content.images.autoplay,
          "infinite": true,
          "slidesToShow": this.cols(parseInt(this.section.options.content.images.slidesToShow), 1),
          "slidesToScroll": parseInt(this.section.options.content.images.slidesToScroll),
          "speed": 500,
          "arrows": this.cols(true, false),
          "pauseOnHover": this.section.options.content.images.pauseOnHover
        }
      }
    }
  }
</script>

<style lang="scss">
.carousel-1 {
  .slick-arrow:before {
    display: none;
  }

  .custom-arrow-prev {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    z-index: 3;
  }

  .custom-arrow-next {
    position: absolute;
    top: 40%;
    right: 0;
    transform: translateY(-50%);
  }

  .slick-track {
    display: flex;
    flex-wrap: nowrap;

    .inner-slide {
      border-radius: 7px;
      height: 300px;
      transform: scale(.8);
      transition: .3s;
    }
  }
}

.carousel-1-lg {
  .slick-track .slick-center {
    .inner-slide {
      transform: scale(1);
    }
  }
}
</style>
