<template>
  <Template :section="section">
    <template slot="title">
      <pub-content-editable
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
          v-model="section.options.content.title.text"></pub-content-editable>
    </template>

    <template slot="description">
      <pub-content-editable
          :uid="tiptapUID('description')"
          :customs="section.options.content.description"
          v-model="section.options.content.description.text"></pub-content-editable>
    </template>

    <template slot="button">
      <pub-es-button :customs="section.options.content.button"></pub-es-button>
    </template>

    <template slot="button2">
      <highliter v-show="visability('button2')"
                 :style="{textAlign: section.options.content.button2.align}"
                 @click.stop.native="sectionSettings('button2')">
        <es-button :customs="section.options.content.button2"></es-button>
      </highliter>
    </template>

    <template slot="image">
      <image-fit
          :customs="section.options.content.image"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="cols('cover', 'contain')"
          :height="cols('55vh', '300px')"
          :position="section.options.content.image.position"
      ></image-fit>
    </template>
  </Template>
</template>

<script>
import shortid from 'shortid'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from './Template';
import ImageFit from "../../../../utils/ImageFit";
import PubContentEditable from "../../../utils/PubContentEditable";
import PubEsButton from "../../../utils/buttons/PubEsButton";

export default {
  name: "ActionItem2",

  components: {PubEsButton, PubContentEditable, ImageFit, Template, ContentEditable, Highliter},

  mixins: [SectionMixin],
}
</script>
