<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">Collapse</p>

      <b-checkbox switch v-model="config.display"></b-checkbox>
    </div>

    <draggable v-model="config.items" handle=".move">
      <transition-group name="shufle">
        <b-card v-for="(section, index) in config.items"
                :key="index"
                no-body
                class="border-0">
          <b-card-header header-class="editor-toolbar-collapse-header d-flex align-items-center rounded-0">
            <icon class="chevron mr-3" icon="chevron.svg" />
            <icon class="move mr-3" icon="move.svg" />
            <div block class="text-truncate">{{section.title}}</div>
            <icon class="move ml-auto" icon="trash.svg" @click.native.stop="config.items.splice(index, 1)"></icon>
          </b-card-header>
        </b-card>
      </transition-group>
    </draggable>

    <div class="editor-toolbar-btn-spacing mb-4">
      <b-btn variant="editor-soft-primary" size="lg" block @click="addCollapse">
        Add New Collapse
        <icon class="ml-1" icon="plus.svg" />
      </b-btn>
    </div>
  </div>
</template>

<script>
  import shortid from 'shortid';
  import ModMixin from '@/components/mixins/ModMixin';
  import draggable from 'vuedraggable'

  export default {
    name: "CollapseMod",
    components: {draggable},
    mixins: [ModMixin],
    methods: {
      addCollapse() {
        this.config.items.push({
          uid: shortid.generate(),
          title: 'Title text',
          text: 'Body text'
        })
      }
    }
  }
</script>
