<template>
  <div class="mb-4">
    <label class="mb-2">Shadow</label>

    <div class="d-flex align-items-center">
      <div class="shadow-control-box text-uppercase" :class="{'active': !config.shadow}"
           @click="config.shadow = 'none'">
        <icon icon="shadow-none.svg"></icon>
      </div>

      <div v-for="(shadow, index) in shadows"
           :key="index"
           class="shadow-control-box text-uppercase"
           :class="['custom-shadow-' + shadow.key, {'active': config.shadow === shadow.shadow}]"
           @click="config.shadow = shadow.shadow">
        {{shadow.key}}
      </div>
    </div>
  </div>
</template>

<script>
import ControlMixin from "../../mixins/ControlMixin";

export default {
  name: "Shadow",

  mixins: [ControlMixin],

  data() {
    return {
      shadows: [
        {
          key: 's',
          shadow: '0px 2px 4px rgba(184, 184, 184, 0.5)'
        },
        {
          key: 'm',
          shadow: '0px 2px 10px rgba(188, 188, 188, 0.5)'
        },
        {
          key: 'l',
          shadow: '0px 5px 22px rgba(177, 177, 177, 0.5)'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.shadow-control-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 39px;
  border-radius: 3px;
  background: #fff;
  cursor: pointer;

  & {
    font-weight: 500;
    font-size: 15px;
    color: #6e747a;
  }

  &.active {
    box-shadow: 0 0 0 2px #7CACFA !important;
  }

  &:not(:last-child) {
    margin-right: 19px;
  }
}
</style>