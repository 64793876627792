<template>
 <b-card class="settings-page-card">

   <div class="px-4 mb-4">
     <h5 class="title font-weight-sami-bold">{{title}}
       <span class="font-weight-normal h6">{{subtitle}}</span>
     </h5>
   </div>
   <slot></slot>
 </b-card>
</template>

<script>
export default {
  name: "SettingsPageCard",

  props: {
    title: String,
    subtitle: String
  }
}
</script>

<style lang="scss">
.settings-page-card {
  border: 1px solid #E2E5EC;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;

  .title {
    font-size: 20px;
  }

  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>