import SectionProvider from '@/sections/SectionProvider';

export const name = 'Action Item 2'

export default class ActionItem2 extends SectionProvider {
  constructor(options = {}) {
    super('ActionItem2', options);

    this.name = name
    this.group = 'actions'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
            },
            lg: {
              fontSize: '46px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'center',
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "Join the The Community"}]
            }]
          },
        },
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
            },
            lg: {
              fontSize: '21px',
              fontFamily: 'Global Style',
              color: '#6D6D6D',
              align: 'center',
            }
          },
          text: {
            "type": "doc",
            "content": [{"type": "paragraph", "content": [{"type": "text", "text": "Join the FourPercent community."}]}]
          },
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Join Now',
          icon: 'fa fa-arrow-right',
          resolutionStyle: {
            lg: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#ffffff',
              backgroundColor: 'colored',
              size: 'md',
              shape: null,
              btnStyle: '',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        layouts: null,
        style: {
          padding: {
            type: 'height-customs',
            sm: {
              top: 90,
              bottom: 90
            },
            lg: {
              top: 115,
              bottom: 115
            }
          },
          container: {
            type: 'container-customs',
            container: null
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
            size: 'cover',
            opacity: 1
          },
        }
      }
    }
  }
}
