<template>
  <div :style="{...opacity, ...backgroundColor, ...backgroundImage, ...shadow, ...padding}">
    <div class="testimonial-item-2" :class="container">
      <div class="text-center">
        <slot name="avatar"></slot>
      </div>

      <div class="my-5"></div>

      <slot name="text"></slot>

      <div class="my-5"></div>

      <div class="name">
        <slot name="name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";

  export default {
    name: "FeaturedItems1Template",
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .testimonial-item-2 {
    .name {
      letter-spacing: 0.21em;
    }
  }
</style>