import SectionProvider from '@/sections/SectionProvider';

export const name = 'Featured 6'
export const group = 'featured'
export const defaultOptions = {
  content: {
    image: {
      display: true,
      type: 'image-mod',
      size: 'cover',
      position: 'center',
      placeholder: require('@/assets/svg/previews/features/placeholder-featured-6.png'),
      src: null
    },
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '27px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '38px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p><strong>Pre-headline Goes Here</strong></p>"
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: '21px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        lg: {
          fontSize: '21px',
          fontFamily: 'Global Style',
          color: '#ffffff',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      text: "<p>Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit.</p>"
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Learn More',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'md',
          shape: null,
          btnStyle: 'outlined',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: '#ffffff',
          size: 'md',
          shape: null,
          btnStyle: 'outlined',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    }
  },
  customize: {
    style: {
      background: {
        background: '#1F3B9C'
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 70,
          bottom: 70
        },
        lg: {
          top: 115,
          bottom: 115
        }
      },
      container: {
        type: 'container-customs',
        container: 'container'
      },
      backgroundImage: {
        type: 'background-image-customs',
        src: '',
        position: 'center',
        placeholder: require('@/assets/svg/previews/features/placeholder-featured-6.svg'),
        size: 'cover',
        opacity: 1
      },
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/features/main-6.png')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('Featured6', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
