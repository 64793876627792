import SectionProvider from '@/sections/SectionProvider';

export const name = 'Image Item 5'

export default class ImageItem5 extends SectionProvider {
  constructor(options = {}) {
    super('ImageItem5', options);

    this.name = name
    this.group = 'images'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '25px',
              fontFamily: 'Global Style',
              color: '#ffffff',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '25px',
              fontFamily: 'Global Style',
              color: '#ffffff',
              align: 'center',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "Image Card"}]
            }]
          },
        },
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '18px',
              color: '#fff',
              align: 'center',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              color: '#fff',
              align: 'center',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur</p>"
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/image-placeholder.png'),
          src: null,
          size: null
        }
      },
      customize: {
        style: {
          background: {
            background: '#1F3B9C'
          }
        }
      }
    }
  }
}
