<template>
  <div>
    <settings-page-card title="Code Injection" subtitle="(will apply globally to entire project)">
      <div class="px-4">
        <b-form @submit.prevent="submit">
          <b-form-group>
            <slot name="label">
              <h6>Embed Custom/Tracking Code Into The &lt;HEAD&gt;</h6>
            </slot>
            <b-textarea v-model="head_js" rows="5" class="mb-2"></b-textarea>
          </b-form-group>

          <b-form-group>
            <slot name="label">
              <h6>Embed Custom/Tracking Code Into The &lt;BODY&gt;</h6>
            </slot>
            <b-textarea v-model="body_js" rows="5" class="mb-2"></b-textarea>
          </b-form-group>

          <div class="d-flex w-100 justify-content-end">
            <b-btn variant="primary" type="submit">Save Changes</b-btn>
          </div>
        </b-form>
      </div>
    </settings-page-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SettingsPageCard from "../../../layouts/dashboard/SettingsPageCard";
import HsAddField from "../../../plugins/hs-add-field/HsAddField";

export default {
  name: "InjectionModal",
  components: {HsAddField, SettingsPageCard},
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
    }),
    head_js: {
      get() {
        return this.globalStyles.hasOwnProperty('code_injection') ? this.globalStyles.code_injection.head : ''
      },
      set(val) {
        this.globalStyles.code_injection.head = val
      }
    },
    body_js: {
      get() {
        return this.globalStyles.hasOwnProperty('code_injection') ? this.globalStyles.code_injection.body : ''
      },
      set(val) {
        this.globalStyles.code_injection.body = val
      }
    }
  },

  data() {
    return {
      cdn: []
    }
  },

  methods: {
    submit() {
      VEvent.fire('loader', true)
      this.globalStyles.code_injection.cdn = this.cdn

      this.$store.dispatch('projects/save')
          .finally(() => {
            VEvent.fire('loader', false)
          })
    }
  },

  created() {
    if (!this.globalStyles.hasOwnProperty('code_injection')) {
      this.$set(this.globalStyles, 'code_injection', {})
    } else {
      if (this.globalStyles.code_injection.hasOwnProperty('cdn')) {
        this.cdn = this.globalStyles.code_injection.cdn
      }
    }
  }
}
</script>