import SectionProvider from '@/sections/SectionProvider';

export const name = 'Testimonial Item 4'

export default class TestimonialItem4 extends SectionProvider {
  constructor(options = {}) {
    super('TestimonialItem4', options);

    this.name = name
    this.group = 'testimonials'
    this.allowRow = true

    this.defaultOptions = {
      content: {
        name: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: '#B7B0FF',
              align: 'left',
            },
            lg: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: '#B7B0FF',
              align: 'left',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"JOHN THOMAS"}]}]},
        },
        subname: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: '#B7B0FF',
              align: 'left',
            },
            lg: {
              fontSize: '14px',
              fontFamily: 'Global Style',
              color: '#B7B0FF',
              align: 'left',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"Marketing Mix CEO"}]}]},
        },
        text: {
          type: 'tiptap-mod',
          display: true,
 resolutionStyle: {
            sm: {
              fontSize: '16px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6d6d6d',
              align: 'left',
            },
            lg: {
              fontSize: '16px',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              color: '#6d6d6d',
              align: 'left',
            }
          },
          text: {"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","text":"“Curabitur tristique, lorem et consequat, nisi nisi  ac dictum neque odio eget elit. Curabitur tristique, lorem et” nisi nisi  ac dictum neque odio eget elit"}]}]},
        },
        image: {
          display: true,
          type: 'image-mod',
          placeholder: require('@/assets/svg/placeholder-image-2.svg'),
          src: null,
          position: 'center',
size: 'cover'
        }
      },
      customize: {
        backgroundColor: null,
        shadow: null
      }
    }
  }
}
