<template>
  <Template :section="section">
    <!-- Logo -->
    <template slot="logo">
      <pub-logo :customs="section.options.content.logo"></pub-logo>
    </template>
    <!-- End Logo -->

    <!-- Copyright -->
    <template slot="copyright">
      <pub-content-editable
          :uid="tiptapUID('copyright')"
          :customs="section.options.content.copyright"
          v-model="section.options.content.copyright.text"></pub-content-editable>
    </template>
    <!-- End Copyright -->

    <!-- Menu 1 -->
    <template slot="menu">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item mx-3" v-for="(link, index) in section.options.content.menu.links" :key="index" :href="link.href">
          <b-link :style="section.options.content.menu.style" @click="goPage(link)">{{ link.text }}</b-link>
        </li>
      </ul>
    </template>
    <!-- End Menu 1 -->

    <!-- Social -->
    <template slot="social">
      <ul class="list-unstyled list-inline">
        <li v-for="(link, index) in section.options.content.socialLinks.links" :key="index"
            class="list-inline-item mr-4">
          <b-link :style="section.options.content.socialLinks.style" :href="link.href" :target="link.blank ? 'blank' : ''">
            <i :class="link.icon"></i>
          </b-link>
        </li>
      </ul>
    </template>
    <!-- End Social -->
  </Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import Template from "./Template";

export default {
  name: "Carousel1",

  components: {Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  methods: {
    goPage(link) {
      if (!link.href || link.href === '#') {
        return false
      }

      if (!link.customLink) {
        if (link.blank) {
          window.open(`${window.location.origin}/site/${this.project.subdomain}/${link.href}`)
        } else {
          this.$store.dispatch('router/setPage', link.href)
        }
      } else {
        if (link.blank) {
          window.open(link.href)
        } else {
          window.location = link.href
        }
      }
    },
  }
}
</script>
