<template>
  <div class="icon-editable" :style="style">
    <i :class="[customs.icon, customs.size]"></i>
  </div>
</template>

<script>
  import ComponentProvider from '@/components/mixins/ComponentProvider'
  import {mapState} from "vuex"

  export default {
    name: "ContentEditable",

    mixins: [ComponentProvider],

    computed: {
      style() {
        return {
          color: this.customs.color === 'adaptive' ? null : this.customs.color,
          textAlign: this.customs.align
        }
      }
    },
  }
</script>

<style lang="scss">
  .icon-editable {
    font-size: 30px;

    .sm {
      font-size: 20px;
    }

    .lg {
      font-size: 60px;
    }

    .xl {
      font-size: 90px;
    }
  }
</style>