<template>
  <Carousel1Template :section="section">
    <div class="inner-slide" v-for="(image, index) in section.options.content.images.items" :key="index">
      <image-fit
          :customs="image"
          :src="image.src || image.placeholder"
          :size="cols(image.size, 'contain')"
          :position="image.position"
          height="285px"
      ></image-fit>
    </div>
  </Carousel1Template>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import PubContentEditable from '@/components/builder/utils/PubContentEditable';
  import EsImage from "@/components/builder/utils/EsImage";
  import Carousel1Template from "./Carousel1Template";
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "FeaturedItems1",

    components: {
      ImageFit,
      Carousel1Template,
      EsImage, PubContentEditable, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
