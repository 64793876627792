import SectionProvider from '@/sections/SectionProvider';

export const name = 'Text And Image Item 2'

export default class TextAndImageItem2 extends SectionProvider {
  constructor(options = {}) {
    super('TextAndImageItem2', options);

    this.name = name
    this.group = 'text-and-image'

    this.defaultOptions = {
      content: {
        image: {
          display: true,
          src: null,
          type: 'image-mod',
          placeholder: require('@/assets/svg/previews/textAndImage/placeholder-2.png'),
          size: 'cover',
          position: 'center',
        },
      },
      customize: {
        style: {
          background: null,
        }
      }
    }
  }
}
