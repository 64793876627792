<template>
  <highliter :label="section.name" :uid="section.uid" :dublicateDeep="parentUID" :dublicate="section" @click.stop.native="sectionSettings(true)">
   <Template :section="section">
     <template slot="title">
       <highliter v-show="visability('title')" @click.stop.native="sectionSettings('title')">
         <content-editable
             :uid="tiptapUID('title')"
             :customs="section.options.content.title"
             v-model="section.options.content.title.text"></content-editable>
       </highliter>
     </template>

     <template slot="description">
       <highliter v-show="visability('description')" @click.stop.native="sectionSettings('description')">
         <content-editable
             :uid="tiptapUID('description')"
             :customs="section.options.content.description"
             v-model="section.options.content.description.text"></content-editable>
       </highliter>
     </template>
     
     <template slot="left-icon">
       <highliter v-show="visability('leftIcon')" @click.stop.native="sectionSettings('leftIcon')">
         <icon-editable :customs="section.options.content.leftIcon"></icon-editable>
       </highliter>
     </template>

     <template slot="left-title">
       <highliter v-show="visability('leftTitle')" @click.stop.native="sectionSettings('leftTitle')">
         <content-editable
             :uid="tiptapUID('leftTitle')"
             :customs="section.options.content.leftTitle"
             v-model="section.options.content.leftTitle.text"></content-editable>
       </highliter>
     </template>

     <template slot="left-description">
       <highliter v-show="visability('leftDescription')" @click.stop.native="sectionSettings('leftDescription')">
         <content-editable
             :uid="tiptapUID('leftDescription')"
             :customs="section.options.content.leftDescription"
             v-model="section.options.content.leftDescription.text"></content-editable>
       </highliter>
     </template>
     
      <template slot="left-button">
        <highliter v-show="visability('leftButton')"
                   :style="{textAlign: section.options.content.leftButton.align}"
                   @click.stop.native="sectionSettings('leftButton')">
          <es-button :customs="section.options.content.leftButton"></es-button>
        </highliter>
      </template>


     <template slot="right-icon">
       <highliter v-show="visability('rightIcon')" @click.stop.native="sectionSettings('rightIcon')">
         <icon-editable :customs="section.options.content.rightIcon"></icon-editable>
       </highliter>
     </template>

     <template slot="right-title">
       <highliter v-show="visability('rightTitle')" @click.stop.native="sectionSettings('rightTitle')">
         <content-editable
             :uid="tiptapUID('rightTitle')"
             :customs="section.options.content.rightTitle"
             v-model="section.options.content.rightTitle.text"></content-editable>
       </highliter>
     </template>

     <template slot="right-description">
       <highliter v-show="visability('rightDescription')" @click.stop.native="sectionSettings('rightDescription')">
         <content-editable
             :uid="tiptapUID('rightDescription')"
             :customs="section.options.content.rightDescription"
             v-model="section.options.content.rightDescription.text"></content-editable>
       </highliter>
     </template>

     <template slot="right-button">
       <highliter v-show="visability('rightButton')"
                  :style="{textAlign: section.options.content.rightButton.align}"
                  @click.stop.native="sectionSettings('rightButton')">
         <es-button :customs="section.options.content.rightButton"></es-button>
       </highliter>
     </template>
   </Template>
  </highliter>
</template>

<script>
  import shortid from 'shortid'
  import SectionMixin from '@/components/mixins/SectionMixin'
  import Highliter from '@/components/builder/utils/Highliter'
  import ContentEditable from '@/components/builder/utils/ContentEditable';
  import Template from './Template';

  export default {
    name: "ActionItem2",

    components: {Template, ContentEditable, Highliter},

    mixins: [SectionMixin],
  }
</script>
