<template>
  <VideoItems3Template :section="section">
    <template>
      <b-col :cols="cols(9, 12)">
        <video-play :customs="section.options.content.group.items[preview].options.content.video"></video-play>

        <div class="my-3"></div>

        <pub-content-editable
            class="video-preview-title"
            :customs="section.options.content.group.items[preview].options.content.title"
            v-model="section.options.content.group.items[preview].options.content.title.text"></pub-content-editable>
      </b-col>

      <b-col :cols="cols(3, 12)">
        <div>
          <preview-video-item1 v-if="index !== preview"
                               v-for="(video, index) in section.options.content.group.items"
                               :key="index"
                               :section="video"
                               overlay
                               @click.native="preview = index"
          />
        </div>
      </b-col>
    </template>
  </VideoItems3Template>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ContentEditable from '@/components/builder/utils/ContentEditable';
import VideoItems3Template from '@pub/templates/default/sections/videos/VideoItems3Template';

export default {
  name: "VideoItem3",

  components: {VideoItems3Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  data() {
    return {
      preview: 0
    }
  },

  computed: {
    previewSrc() {
      return this.section.options.content.group.items[this.preview].options.content.video.src + "?autoplay=1"
    }
  },

  methods: {
    setPreview(video) {

    }
  }
}
</script>

<style lang="scss">
.video-preview-title p {
  font-size: 46px;
}

.resolution-sm {
  .video-preview-title p {
    font-size: 28px !important;
  }
}
</style>