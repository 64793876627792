<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">{{labelCase(componentName)}}</p>

      <b-checkbox switch size="lg" v-model="config.display"></b-checkbox>
    </div>

    <div v-if="config.hasOwnProperty('rows')" class="menubar mb-4">
      <b-btn-group class="d-block">
        <b-btn
            variant="menubar-button"
            @click="config.rows = 1"
        >
          <icon v-if="config.rows === 1" icon="active-list-1.svg"></icon>
          <icon v-else icon="list-1.svg"></icon>
        </b-btn>
        <b-btn
            variant="menubar-button"
            @click="config.rows = 2"
        >
          <icon v-if="config.rows === 2" icon="active-list-2.svg"></icon>
          <icon v-else icon="list-2.svg"></icon>
        </b-btn>
        <b-btn
            variant="menubar-button"
            @click="config.rows = 3"
        >
          <icon v-if="config.rows === 3" icon="active-list-3.svg"></icon>
          <icon v-else icon="list-3.svg"></icon>
        </b-btn>
      </b-btn-group>
    </div>

    <template v-if="Array.isArray(config.items)">
      <draggable v-model="config.items" handle=".move" class="list-group mb-3">
        <b-list-group-item v-for="(item, index) in config.items" :key="item.label" class="d-flex align-items-center text-capitalize">
          <icon class="move mr-3" icon="move.svg" />
          <div class="text-truncate">{{item.label}}</div>

          <icon class="cursor-pinter ml-auto" icon="trash.svg" @click.native="config.items.splice(index, 1)" />
        </b-list-group-item>
      </draggable>

      <div class="d-flex align-items-center justify-content-end cursor-pinter font-weight-bold text-primary w-100" @click="newListItemModal = true">
        <icon class="mr-2" icon="plus.svg"></icon> Add List Item
      </div>
    </template>

    <template v-else>
      <div v-for="n in config.rows" :key="n" class="mb-4">
        <draggable v-model="config.items[n]" handle=".move" class="list-group mb-3">
          <b-list-group-item v-for="(item, index) in config.items[n]" :key="item.label" class="d-flex align-items-center text-capitalize">
            <icon class="move mr-3" icon="move.svg" />
            <div class="text-truncate">{{item.label}}</div>

            <icon class="cursor-pinter ml-auto" icon="trash.svg" @click.native="config.items[n].splice(index, 1)" />
          </b-list-group-item>
        </draggable>

        <div class="d-flex align-items-center justify-content-end cursor-pinter font-weight-bold text-primary w-100" @click="openModal(n)">
          <icon class="mr-2" icon="plus.svg"></icon> Add List Item
        </div>
      </div>
    </template>

    <b-modal v-model="newListItemModal" hide-footer hide-header>
      <icon class="cursor-pinter ml-3" icon="close.svg" @click.native="newListItemModal = false"></icon>

      <h4 class="h2 font-weight-bold text-center my-4">Add A List Item</h4>

      <div class="p-5">
        <b-form-group label="Item label">
          <b-input v-model="label"></b-input>
        </b-form-group>

        <b-form-group label="Item text">
          <b-textarea v-model="item"></b-textarea>
        </b-form-group>

        <div class="d-flex justify-content-center">
          <b-btn variant="primary" @click="addListItem">Add List Item</b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import draggable from 'vuedraggable'

  export default {
    name: "ListMod",
    mixins: [ModMixin],
    components: {draggable},
    data() {
      return {
        newListItemModal: false,
        item: '',
        label: '',
        listIndex: null
      }
    },
    methods: {
      addListItem() {
        if (this.listIndex) {
          this.config.items[this.listIndex].push({
            label: this.label,
            text: this.item
          })
        } else {
          this.config.items.push({
            label: this.label,
            text: this.item
          })
        }

        this.label = ''
        this.item = ''
        this.newListItemModal = false
      },
      openModal(listIndex) {
        this.listIndex = listIndex
        this.newListItemModal = true
      }
    }
  }
</script>
