<template>
  <div :style="{...padding, ...backgroundColor, ...opacity, ...shadow}">
    <div :class="container">
      <div v-if="loading" class="text-center">
        <b-spinner variant="primary"></b-spinner>
      </div>

      <template v-else-if="getBlogPosts.length > 0">
        <b-row deck>
          <b-col
              v-for="(post, index) in getBlogPosts.slice(0, section.options.content.pagination.perPage)"
              :key="index"
              :cols="cols(4, 12)"
              :class="cols('mb-5', 'mb-4')">

            <b-card class="border-0">
              <image-fit
                  :customs="{}"
                  :src="post.cover.src"
                  height="180px"
                  size="cover"
                  class="mb-3"
              />
              <p class="font-size-14" :style="section.options.content.date">{{
                  post.created_at | moment("from", "now")
                }}</p>
              <b-card-title :style="section.options.content.title" v-text="post.title"/>
              <b-card-text :style="section.options.content.description" class="line-clamp">
                {{ post.description | strippedContent }}
              </b-card-text>
              <slot name="button" v-bind:button="{section}">
                <pub-es-button :to="`/site/${isCurrentCustomDomain ? '' : project.subdomain + '/'}blog/${post.slug}`" :customs="section.options.content.button"></pub-es-button>
              </slot>
            </b-card>
          </b-col>
        </b-row>

        <div v-if="blog.data.total > section.options.content.pagination.perPage"
             class="d-flex justify-content-center">
          <b-pagination
              v-model="currentPage"
              :total-rows="blog.data.total"
              :per-page="section.options.content.pagination.perPage"
              aria-controls="my-table"
          >
            <template #page="{ page, active }">
              <div :style="section.options.content.pagination">{{ page }}</div>
            </template>
          </b-pagination>
        </div>
      </template>

      <div class="d-flex justify-content-center align-items-center min-vh-50" v-else>
        <div class="text-center" :style="{color: globalStyles.colors.primary}">
          <i class="fa fa-compass fa-7x mb-3"></i>
          <h4 class="h2 font-weight-bold">Blog is empty</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";
import {mapState} from "vuex";
import ImageFit from "../../../../utils/ImageFit";
import RouterMixin from "../../../../mixins/RouterMixin";

export default {
  name: "FeaturedItems4Template",
  components: {ImageFit},
  mixins: [TemplateMixin, RouterMixin],

  data() {
    return {
      loading: true,
      blog: {},
      currentPage: 1
    }
  },

  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/ig, " ");
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    getBlogPosts() {
      const posts = this.blog.data.data
      if (posts.length === 0) return []

      return posts.map(post => {
        return {
          ...post,
          cover: JSON.parse(post.cover)
        }
      })
    }
  },

  methods: {
    async fetchBlog(page = 1) {
      await axios.get(`api/projects/${this.project.id}/blog?page=${page}&perPage=${this.section.options.content.pagination.perPage}`)
          .then(({data}) => {
            this.blog = data
          })
          .finally(() => {
            this.loading = false
          })
    }
  },

  watch: {
    currentPage(val) {
      this.fetchBlog(val)
    }
  },

  created() {
    this.fetchBlog()

    this.section.options.content.button = {
      display: true,
      hover: false,
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'sm',
          shape: null,
          btnStyle: 'outlined',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: '#ffffff',
          size: 'sm',
          shape: null,
          btnStyle: 'outlined',
          align: 'left',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      },
      ...this.section.options.content.button,
    }

    VEvent.listen('es-blog-updated', post => this.blog.data.data.unshift(post))
  }
}
</script>