export default {
  computed: {
    animations: {
      get() {
        if (this.customs && !this.customs.hasOwnProperty('animations')) {
          this.$set(this.customs, 'animations', {
            animation: null,
            once: true,
            offset: 200,
            delay: 50
          })
        }

        if (this.$route.name == 'preview' || this.$route.name == 'published') {
          return this.customs !== undefined ? this.customs.animations : ''
        } else {
          return {}
        }
      },
      set(val) {
        if (this.customs !== undefined) {
          this.customs.animations = val
        }
      }
    }
  }
}