import router from '@/router/index'


const state = () => ({
  byName: ''
})

const getters = {

}

const actions = {

}

const mutations = {
  SET_BY_NAME(state, paylaod) {
    state.byName = paylaod
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
