<template>
  <div :class="container" :style="{...shadow, ...padding}">
    <b-row no-gutters class="about-us-4 align-items-center" :style="{...opacity}">
      <image-fit
          :customs="section.options.content.backgroundImage"
          tag="b-col"
          :src="section.options.customize.style.backgroundImage.src"
          :size="section.options.customize.style.backgroundImage.size"
          :position="section.options.customize.style.backgroundImage.position"
          :cols="cols(6, 12)"></image-fit>

      <b-col class="position-relative offset-text" :cols="cols(6, 12)" :style="{...backgroundColor}">
        <template v-if="visability('title')">
          <slot name="title"></slot>
        </template>

        <div class="d-flex justify-content-center mt-1 mb-5" v-if="visability('horizontalLine')">
          <div class="headline-divider"
               :style="{height: section.options.content.horizontalLine.width, background: section.options.content.horizontalLine.color, borderRadius: section.options.content.horizontalLine.pilled ? '25rem' : 0}"></div>
        </div>
        <template v-if="visability('description')">
          <slot name="description-1"></slot>
        </template>

        <template v-if="visability('description2')">
          <slot name="description-2"></slot>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import TemplateMixin from "../../../../mixins/TemplateMixin";
  import ImageFit from "../../../../utils/ImageFit";

  export default {
    name: "AboutUs1Template",
    components: {ImageFit},
    mixins: [TemplateMixin]
  }
</script>

<style lang="scss">
  .about-us-4 {
    .headline-divider {
      width: 180px;
    }
  }
</style>
