<template>
  <div class="higliter" :class="[{'active': active}, {'deep': dublicateDeep}]" @mouseenter.stop="active = true" @mouseleave="active = false">
    <b-btn-group size="sm" class="higliter-buttons">
      <b-btn class="copy-section p-2" v-if="canDelete && dublicate" variant="outline-primary" @click.stop="addSection">
        <icon icon="copy-2.svg"></icon>
      </b-btn>

      <b-btn v-if="canDuplicate && (uid || dublicateDeep)" class="delete p-2" variant="outline-primary" @click.stop="confirmDialog = true">
        <icon icon="delete-primary.svg"></icon>
      </b-btn>
    </b-btn-group>

    <template v-if="label">
      <div class="higliter-left-toobar">
        <div class="higliter-label">{{labelCase(label)}}</div>

        <div class="move higliter-item" v-if="uid !== '#navigation'">
          <icon icon="move-primary.svg"></icon>
        </div>
      </div>
    </template>

    <template v-if="uid">
      <div class="higliter-add" @click.stop="newSectionModal">
        <icon icon="plus-section.svg" />
      </div>
    </template>
    <slot></slot>

    <b-modal body-class="text-center py-5" v-model="confirmDialog" size="lg" hide-footer hide-header centered>
      <h4 class="h3 font-weight-bold my-4">Are you sure you want to delete this section?</h4>

      <div class="d-flex align-items-center justify-content-center">
        <b-btn variant="danger" class="btn-wide mr-2" @click="deleteSection">Yes</b-btn>
        <b-btn class="btn-wide" @click="confirmDialog = false">No</b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import FeaturedItems1 from '@/sections/featured/FeaturedItems1';
  import Icon from '@/components/editor/utils/Icon';
  import shortid from 'shortid';
  var _ = require('lodash');

  export default {
    name: "Highliter",
    components: {Icon},
    props: {
      canDelete: {
        type: Boolean,
        default: true
      },
      canDuplicate: {
        type: Boolean,
        default: true
      },
      label: {
        type: String,
        default: null
      },
      uid: {
        type: String,
        default: null
      },
      autoWidth: {
        type: Boolean,
        default: false
      },
      dublicate: {
        type: Object,
        default: null
      },
      dublicateDeep: {
        type: String,
        default: null
      }
    },

    data() {
      return {
        active: false,
        confirmDialog: false
      }
    },

    methods: {
      labelCase(label) {
        return _.startCase(label)
      },
      deleteSection() {
        this.$store.commit('editor/SET_PAGE', 'sections')
        this.$store.dispatch('sections/removeSection', {uid: this.uid, deep: this.dublicateDeep})
      },
      addSection() {
        let dubSection = _.cloneDeep(this.dublicate)
        dubSection = {...dubSection, uid: shortid.generate()}

        this.$store.dispatch('sections/addSection', {
          after: this.dublicateDeep || this.uid,
          deep: this.dublicateDeep,
          section: dubSection
        })
      },
      newSectionModal() {
        this.$store.commit('editor/SET_SECTION_MODAL', this.uid)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .higliter {
    position: relative;
    margin: 3px;

    .higliter-buttons {
      position: absolute;
      display: none;
      top: -1px;
      right: -1px;
      z-index: 1000;

      .btn {
        background: #fff;
        border: 1px solid #7CACFA;
      }
    }

    .higliter-item {
      position: relative;
      display: flex;
      left: -2px;
      padding-left: 2px;
      background: white;
      justify-content: center;
      border: 3px solid #7CACFA;
      width: 30px;
      align-items: center;
      border-color: #7CACFA !important;
      border-left: none !important;
      border-bottom-right-radius: 8px;
    }

    .higliter-left-toobar {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
    }

    .higliter-label {
      background-color: #7CACFA;
      font-size: 12px;
      font-weight: bold;
      padding: 8px 15px;
      color: #fff;
      border-bottom-right-radius: 3px;
    }

    .higliter-add {
      display: none;
      position: absolute;
      color: #7CACFA;
      box-sizing: border-box;
      height: 26px;
      width: 26px;
      border: 2px solid #7CACFA;
      background-color: #EAF2FF;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(50%);
      cursor: pointer;
    }

    &.active {
      box-shadow: 0 0 0 2px #7CACFA;
      z-index: 3;

      > .higliter-left-toobar {
        display: flex;
      }

      > .higliter-add {
        display: flex;
      }
      
      > .higliter-buttons {
        display: block;
      }
    }
  }

  .es-row {
    .higliter-add {
      display: none !important;
    }

    .higliter-item {
      display: none;
    }

    .higliter-buttons {
      display: none !important;
    }

    .delete {
      display: none !important;
    }
  }

  .es-row {
    .deep {
      &.active {
        .higliter-buttons {
          display: block !important;
        }
      }
    }
  }
</style>
