import SectionProvider from '@/sections/SectionProvider';

export const name = 'Divider'
export const group = 'utils'
export const standalone = true
export const allowRow = true
export const defaultOptions = {
  content: {
    horizontalLine: {
      type: 'horizontal-line-mod',
      display: true,
      color: '#AEB1B4',
      width: '2px',
      pilled: false
    },
  },
  customize: {
    style: {
      padding: {
        type: 'height-customs',
        sm: {
          top: 20,
          bottom: 20
        },
        lg: {
          top: 20,
          bottom: 20
        }
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@/assets/svg/previews/utils/divider.jpg')
}

export default class Section extends SectionProvider {
  constructor(options = {}) {
    super('EsDividerUtil', options);

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
