<template>
  <div class="images-mod">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">{{labelCase(componentName)}}</p>

      <b-checkbox switch size="lg" v-model="config.display"></b-checkbox>
    </div>

    <b-form-group>
      <div class="d-flex align-items-center" v-for="(image, index) in config.items" :key="index">
        <image-upload :image="image" :class="{'active': getActiveImage === index}"
                      class="w-100 mb-4"
                      @uploaded="sectionSettings({item: 'images', index}, index)"
                      @imageDeleted="deleted(index)">
          <template slot="prepend">
            <b-input-group-text class="num">{{index + 1}}</b-input-group-text>
          </template>
        </image-upload>
      </div>
    </b-form-group>

    <b-form-group label="Add slide">
      <image-upload :image="file" class="w-100 mb-3" @uploaded="uploaded"></image-upload>
    </b-form-group>

    <b-form-group label="Arrows color">
      <color-picker :color="section.options.content.images.arrows.color || globalStyles.colors.primary" @input="event => section.options.content.images.arrows.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"></color-picker>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="section.options.content.images.autoplay">Autoplay</b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="section.options.content.images.centerMode" value="true">Center mode</b-checkbox>
    </b-form-group>

    <b-form-group label="Center padding" v-if="section.options.content.images.centerMode">
      <b-input type="range" min="0" max="100" step="1" v-model="section.options.content.images.centerPadding"></b-input>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="section.options.content.images.pauseOnHover">Pause on hover</b-checkbox>
    </b-form-group>

    <b-form-group v-if="!section.options.content.images.centerMode">
      <b-row>
        <b-col md="6">
          <b-form-group label="Slides to show">
            <b-input type="number" min="1" :max="config.items.length" v-model="section.options.content.images.slidesToShow"></b-input>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Slides to scroll">
            <b-input type="number" min="1" :max="config.items.length" v-model="section.options.content.images.slidesToScroll"></b-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
  import ModMixin from '@/components/mixins/ModMixin';
  import SliederToggle from '@/components/editor/controls/SliederToggle';
  import ImageUpload from "../utils/ImageUpload";
  import ColorPicker from "../utils/ColorPicker";

  export default {
    name: "ImagesMod",
    components: {ColorPicker, ImageUpload, SliederToggle},
    mixins: [ModMixin],
    computed: {
      getActiveImage() {
        if (this.getOpenedItemObj) {
          return this.getOpenedItemObj.index
        }

        return 0
      }
    },

    data() {
      return {
        file: {}
      }
    },

    methods: {
      deleted(index) {
        if (this.config.items.length > 4) {
          this.config.items.splice(index, 1)
        }
      },
      
      uploaded(image) {
        this.config.items.push({
          position: 'center',
          size: 'cover',
          placeholder: require('@/assets/svg/placeholder-image.svg'),
          ...image
        })
        const index = this.config.items.length - 1
        this.sectionSettings({item: 'images', index}, index)
        this.file = {}
      }
    }
  }
</script>

<style lang="scss">
  @import "@/assets/scss/main";

  .images-mod {
    .selected {
      .num {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .active {
    .btn-es-image-upload {
      box-shadow: 0 0 0 2px $primary !important;
    }

    .num {
      box-shadow: 0 0 0 2px $primary;
      color: #fff;
      background: $primary;
      border-color: $primary;
    }
  }

  .num {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #E2E5EC;
    background: #fff;
    font-size: 16px;
    color: #BEC3CE;
  }
</style>
