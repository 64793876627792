<template>
  <b-dropdown
      variant="link"
      size="lg"
      toggle-class="text-decoration-none p-0"
      no-caret
      menu-class="py-0"
      lazy
      right
      @show="updateLocalColor"
      @hidden="changeColor(colors, 'change')"
  >
    <template slot="button-content">
      <slot>
        <div class="pollite" :style="{background: color}"></div>
      </slot>
    </template>
    <div>
      <chrome-picker :preset-colors="['#1f3b9c', '#fcb045', '#1abc9c', '#2ecc71', '#9b59b6', '#3498db', '#34495e', '#e67e22', '#e74c3c', '#bdc3c7']" :value="colors" @input="changeColor($event, 'input')"></chrome-picker>
    </div>
  </b-dropdown>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ColorPicker",

  props: {
    color: {
      required: true
    }
  },

  watch: {
    color() {
      this.colors =  {
        hex: this.color
      }
    }
  },

  data() {
    return {
      colors: {
        hex: this.color
      }
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  },

  methods: {
    updateLocalColor() {
      this.colors.hex = this.color
    },
    changeColor(event, emit) {
      this.colors.hex = event.hex
      this.colors.rgba = event.rgba

      this.updateLocalColor()
      this.$emit(emit, event)

      if (emit === 'change') {
        setTimeout(() => {
          if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
              window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
              window.getSelection().removeAllRanges();
            }
          } else if (document.selection) {  // IE?
            document.selection.empty();
          }
        }, 100)
      }
    },
  }
}
</script>

<style>
  .pollite {
    width: 38px;
    height: 38px;
    border-radius: 5px;
    border: 4px solid #eee;
    background: #000;
  }
</style>
