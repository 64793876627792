<template>
  <div :style="{...backgroundColor, ...backgroundImage, ...shadow}" :class="[theme]">
    <div class="headline-item-1" :class="container" :style="{...opacity, ...padding}">
      <slot name="title"></slot>
      <slot name="subtitle"></slot>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";
import {mapState} from "vuex";
const hexRgb = require('hex-rgb');

export default {
  name: "HeadlineItemTemplate1",

  mixins: [TemplateMixin],

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),

    theme() {
      const rgb = this.toRGBArray(this.backgroundColor.backgroundColor)
      const o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000)

      if (o > 125) {
        return 'light'
      } else {
        return 'dark'
      }
    }
  },

  methods: {
    toRGBArray(color) {
      if (color.indexOf('rgb') !== -1) {
        return color.split("(")[1].split(")")[0].split(",");
      }

      return hexRgb(color, {format: 'array'})
    }
  }
}
</script>