<template>
  <div class="resource-1 d-flex justify-content-center rounded align-items-center px-4" :style="{...opacity, ...padding, ...backgroundColor}">
    <div class="media align-items-start rounded position-relative" :class="cols('', 'd-block')">
      <div class="media-aside mt-2 mr-0" v-if="visability('icon')">
        <slot name="icon"></slot>
      </div>

      <div class="media-body">
        <div class="px-2 py-2">
          <slot name="title" v-if="visability('title')"></slot>
          <slot name="description" v-if="visability('description')"></slot>
          <slot name="button" v-if="visability('button')"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from "../../../../mixins/TemplateMixin";

export default {
  name: "ImageItem5Template",
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.resource-1 {
  p {
    margin-bottom: 5px;
  }

  .before-seprate:before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    width: 1px;
    background: #dee2e6;
  }

 img {
    width: 65px;
    height: 65px;
  }
}
</style>