import SectionProvider from '@/sections/SectionProvider';

export const name = 'Resource Item 1'

export default class ResourceItem1 extends SectionProvider {
  constructor(options = {}) {
    super('ResourceItem1', options);

    this.name = name
    this.group = 'images'

    this.defaultOptions = {
      content: {
        title: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '23px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            lg: {
              fontSize: '38px',
              fontFamily: 'Global Style',
              color: '#0C226C',
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{"type": "text", "marks": [{"type": "bold"}], "text": "Resource #1"}]
            }]
          },
        },
        description: {
          type: 'tiptap-mod',
          display: true,
          resolutionStyle: {
            sm: {
              fontSize: '18px',
              color: '#6D6D6D',
              align: 'left',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              padding: {
                top: 0,
                bottom: 10,
                x: 0
              }
            },
            lg: {
              fontSize: '18px',
              color: '#6D6D6D',
              align: 'left',
              fontFamily: 'Global Style',
              lineHeight: 1.3,
              padding: {
                top: 0,
                bottom: 10,
                x: 0
              }
            }
          },
          text: {
            "type": "doc",
            "content": [{
              "type": "paragraph",
              "content": [{
                "type": "text",
                "text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis quam vitae posuere mattis. Aliquam dictum maximus."
              }]
            }]
          },
        },
        icon: {
          type: 'icon-mod',
          display: true,
          icon: 'fa fa-gem',
          size: 'md',
          color: '#0C226C',
          align: 'center',
        },
        button: {
          display: true,
          hover: false,
          type: 'button-mod',
          text: 'Learn More',
          resolutionStyle: {
            lg: {
              color: '#0C226C',
              backgroundColor: '#0C226C',
              size: 'md',
              btnStyle: 'outlined',
              shape: null,
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            },
            sm: {
              color: '#0C226C',
              backgroundColor: '#0C226C',
              size: 'md',
              btnStyle: 'outlined',
              shape: null,
              align: 'left',
              padding: {
                top: 0,
                bottom: 0,
                x: 0
              }
            }
          }
        }
      },
      customize: {
        cols: {
          sm: 12,
          md: 6,
          lg: 6
        },
        style: {
          background: {
            background: '#ffffff'
          },
          container: {
            type: 'container-customs',
            container: null
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 40,
              bottom: 40
            },
            lg: {
              top: 40,
              bottom: 40
            }
          }
        }
      }
    }
  }
}
